import { memo, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, CircleIcon, CloseIcon, HStack, IconButton, Pressable, Spacer, Spinner, VStack, useMediaQuery, useTheme } from 'native-base';
import AntDesignIcons from 'react-native-vector-icons/AntDesign';
import FeatherIcons from 'react-native-vector-icons/Feather';
import Octicons from 'react-native-vector-icons/Octicons';
import { filter, find, first, map, startCase, toLower } from 'lodash';

import { Button, ChevronSelectorVerticalIcon, DragVerticalIcon, Text, UserPlusIcon } from '@pimm/base';
import { KitchenPositionDto } from '@pimm/services/lib/sms-positioning';
import { AddUpdateAssigneeRequest, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatToISOString, formatToTimeOnly } from '@app/utils/date-formatter';
import { hexToRGBA } from '@app/utils/string-formatter';
import { useKitchenLayout, useKitchenPositioning } from '../context';
import { UnionIcon } from '../icons';
import { KitchenEmployee, KitchenPosition, Timeslot } from '../types';

type PositionCardProps = {
  isDisabled?: boolean;
  position: KitchenPosition;
  onAssign?: () => void;
  onChange: (position: Partial<KitchenPosition>) => void;
  onEditPosition?: (isPrimary?: boolean) => void;
};

const PositionCard = ({ isDisabled, position: { positionId, ...position }, ...props }: PositionCardProps) => {
  const { colors } = useTheme();
  const [isTabletScreen] = useMediaQuery({ maxWidth: 1200 });
  const { positionLookup } = useKitchenLayout();
  const { dayBlock } = useKitchenPositioning();
  const [assignee, setAssignee] = useState<Timeslot | undefined>(first(position.timeslots));
  const [kitchenPosition, setKitchenPosition] = useState<KitchenPositionDto>();

  const addUpdateJob = useMutation({ mutationFn: SmsWorkforceApi.AddUpdateSlotJob });
  const assignEmployee = useMutation({ mutationFn: SmsWorkforceApi.AssignEmployee });

  const isAssigned = !!assignee?.employeeId;
  const startTimeText = formatToTimeOnly(assignee?.startTime);
  const endTimeText = formatToTimeOnly(assignee?.endTime);
  const color = kitchenPosition?.color ?? colors.gray[500];
  let circleColor = colors.gray[500];

  // When the persons Shift End time is before the active Dayblock end time
  if (dayBlock && assignee?.endTime) {
    circleColor = colors.success[500];

    if (assignee.endTime < dayBlock.endTime) {
      circleColor = colors.warning[500];
    }
  }

  const handlePressEditPosition = (isPrimary?: boolean) => () => {
    if (props.onEditPosition) props.onEditPosition(isPrimary);
  };

  const handlePressDelete = async () => {
    // await deleteAssignee.mutateAsync({
    //   employeeId: assignee?.employeeId,
    //   positionJobId: position.positionJobId,
    // });
    // props.onChange({ id: position.id, timeslots: [] });
  };

  const handlePressDeleteJob = (positionId?: string) => () => {
    const secondaryJobs = filter(position.secondaryJobs, _ => _.positionId !== positionId);

    addUpdateJob.mutateAsync({
      positionSlotId: position.id,
      positionId: positionId,
      secondaryJobs: secondaryJobs,
    });

    props.onChange({ id: position.id, secondaryJobs: secondaryJobs });
  };

  useEffect(() => {
    if (positionId) {
      setKitchenPosition(positionLookup[positionId]);
    }
  }, [positionId, positionLookup]);

  useEffect(() => {
    setAssignee(first(position.timeslots));
  }, [position.timeslots]);

  return (
    <Box
      rounded="2xl"
      p={2}
      borderWidth={1}
      borderColor="white"
      bg="white"
      style={{
        shadowColor: colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 7,
      }}
    >
      <VStack rounded="xl" space={2} p={2} borderWidth={1} borderColor={hexToRGBA(color, 0.4)} bg={hexToRGBA(color, 0.2)}>
        {/* Position Title */}
        <HStack rounded="lg" space={2} alignItems="center" h={8}>
          <Box rounded="md" alignContent="center" justifyContent="center" h="full" w={isTabletScreen ? 6 : 8} bg={hexToRGBA(color, 0.4)}>
            <Text size={isTabletScreen ? 'md' : 'lg'} color="black" fontWeight={700} textAlign="center" lineHeight="xs">
              {position.slotNumber}
            </Text>
          </Box>

          <HStack flex={1} alignItems="center" justifyContent="space-between" h="full">
            {!!position.id && (
              <Text size={{ base: 'md', lg: 'lg' }} color="black" fontWeight={700} lineHeight="xs" numberOfLines={2}>
                {position?.title}
              </Text>
            )}

            {!position.title && (
              <Pressable
                rounded="lg"
                flex={1}
                h={8}
                borderWidth={1}
                borderColor="primary.200"
                bg="primary.50"
                isDisabled={isDisabled}
                _disabled={{ borderColor: 'gray.300', bg: 'gray.50' }}
                _hover={{ borderColor: 'primary.500' }}
                onPress={handlePressEditPosition(true)}
              >
                <HStack alignItems="center" justifyContent="space-between" pl={3} pr={2} h="full">
                  <Text size="md" fontWeight={600} color={isDisabled ? 'gray.500' : 'primary.500'}>
                    Select Position
                  </Text>

                  <ChevronSelectorVerticalIcon size={4} color={isDisabled ? 'gray.500' : 'primary.500'} />
                </HStack>
              </Pressable>
            )}

            {!!position.title && (
              <HStack space={{ base: 1, lg: 2 }}>
                {/* Ops Leader */}
                <Pressable
                  rounded="lg"
                  alignItems="center"
                  justifyContent="center"
                  w={8}
                  h={8}
                  borderWidth={1}
                  borderColor="gray.200"
                  bg="white"
                  opacity={!positionId ? 0.3 : 1}
                  _disabled={{ opacity: 1 }}
                  _hover={{ borderColor: 'gray.300' }}
                  onPress={() => props.onChange({ id: position.id, isOpsLeader: true })}
                  isDisabled={isDisabled}
                >
                  {({ isHovered }) => (
                    <Box
                      rounded="full"
                      alignItems="center"
                      justifyContent="center"
                      w={5}
                      h={5}
                      bg={isHovered || position.isOpsLeader ? 'warning.300' : 'gray.100'}
                    >
                      <Octicons name="star" size={14} color={isHovered || position.isOpsLeader ? 'black' : colors.gray[600]} />
                    </Box>
                  )}
                </Pressable>

                {!isDisabled && (
                  <Pressable
                    rounded="md"
                    alignItems="center"
                    justifyContent="center"
                    w={8}
                    h={8}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    _hover={{ borderColor: 'gray.400' }}
                    onPress={handlePressEditPosition(true)}
                  >
                    <ChevronSelectorVerticalIcon size="18px" color="black" />
                  </Pressable>
                )}
              </HStack>
            )}
          </HStack>
        </HStack>

        {/* Assignee */}
        {isAssigned ? (
          <HStack rounded="lg" alignItems="center" pl={1} pr={2} h="54px" borderWidth={1} borderColor={hexToRGBA(color, 0.4)} bg="white">
            {/* Draggable Indicator */}
            <Pressable>
              <DragVerticalIcon size={{ base: '18px', lg: '22px' }} color={colors.gray[400]} />
            </Pressable>

            <Box pl={1} maxW="80%" overflow="hidden">
              <Text size={{ base: 'md', lg: 'lg' }} fontWeight={700} color="black" lineHeight="md" numberOfLines={1} ellipsizeMode="tail">
                {startCase(toLower(assignee?.name ?? ''))}
              </Text>
              <HStack space={1.5} alignItems="center">
                <Text size="sm" fontWeight={600} color="black" lineHeight="xs">
                  {startTimeText && endTimeText ? `${startTimeText} - ${endTimeText}` : ''}
                </Text>
                <CircleIcon size={2} color={circleColor} />
              </HStack>
            </Box>
            <Spacer />
            {assignEmployee.isLoading && <Spinner size={16} color={colors.gray[300]} mr={1} />}
            {!isDisabled && !!assignee.employeeId && !assignEmployee.isLoading && (
              <IconButton
                h={7}
                w={7}
                p={1}
                borderWidth={0}
                rounded="md"
                onPress={handlePressDelete}
                icon={<FeatherIcons name="trash-2" size={18} color={colors.primary[600]} />}
              />
            )}
          </HStack>
        ) : (
          <HStack space={2} alignItems="center" p={2} h="54px" borderRadius={10} bg="gray.700">
            <HStack
              flex={1}
              space={2}
              rounded="lg"
              alignItems="center"
              justifyContent="center"
              h="full"
              bg={isDisabled ? 'gray.100' : 'white'}
            >
              <UnionIcon size="22px" color={colors.gray[isDisabled ? 400 : 600]} />
              <Text size="md" color={colors.gray[isDisabled ? 400 : 600]} fontWeight={500} lineHeight="xs">
                Drag and drop
              </Text>
            </HStack>
            <IconButton
              rounded="lg"
              p={2}
              boxSize={10}
              borderWidth={1}
              borderColor="primary.300"
              bg="primary.50"
              _hover={{ bg: 'primary.50' }}
              _pressed={{ bg: 'primary.50' }}
              _disabled={{ bg: 'gray.50', borderColor: 'gray.50', _icon: { color: 'gray.700' } }}
              icon={<UserPlusIcon size="22px" color={colors.primary[600]} />}
              isDisabled={isDisabled}
              onPress={props.onAssign}
            />
          </HStack>
        )}
      </VStack>

      {/* Secondary Jobs */}
      {position.secondaryJobs.length > 0 && (
        <HStack flexWrap="wrap" pt={2}>
          {map(position.secondaryJobs, job => {
            return (
              <HStack
                key={job.positionId}
                space={1}
                rounded="lg"
                alignItems="center"
                mb={1}
                mr={1}
                pl={2}
                pr={isDisabled ? 2 : 1}
                h={7}
                borderWidth={1}
                borderColor={hexToRGBA(color, 0.4)}
                bg={hexToRGBA(color, 0.2)}
              >
                <Text size="md" fontWeight={500} color="black">
                  {job.title}
                </Text>

                {!isDisabled && (
                  <IconButton
                    p={1}
                    _hover={{ bg: hexToRGBA(color, 0.3) }}
                    icon={<CloseIcon size="10px " color="black" />}
                    onPress={handlePressDeleteJob(job.positionId)}
                  />
                )}
              </HStack>
            );
          })}
        </HStack>
      )}

      {!isDisabled && position.secondaryJobs.length !== 3 && (
        <HStack pt={position.secondaryJobs.length ? 0 : 2}>
          <Button
            variant="unstyled"
            px={2}
            minH={8}
            _hover={{ borderColor: 'gray.400' }}
            startIcon={<AntDesignIcons name="pluscircleo" size={16} color="gray.400" />}
            onPress={handlePressEditPosition(false)}
          >
            {position.secondaryJobs.length === 0 ? 'Add Secondary Position' : undefined}
          </Button>
          <Spacer />
        </HStack>
      )}
    </Box>
  );
};

export default memo(PositionCard);
