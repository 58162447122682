import { useMutation } from 'react-query';
import { HStack } from 'native-base';
import { reduce } from 'lodash';

import { Button, ReverseLeftIcon, UploadCloudIcon } from '@pimm/base';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { ResetConfirmAndAssignGroup } from '@pimm/services/lib/sms-workforce/services';
import { useSiteTime } from '@app/features/store-core';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { usePositioningPlan } from '../context';

type PositioningActionToolsProps = {
  isDisabled?: boolean;
};

export const PositioningActionTools = ({ isDisabled }: PositioningActionToolsProps) => {
  const siteTime = useSiteTime();
  const { positioning, refetchPlan, resetChanges } = usePositioningPlan();

  const resetConfirmAndAssign = useMutation({ mutationFn: ResetConfirmAndAssignGroup });

  const isLoading = resetConfirmAndAssign.status === 'loading';

  const handlePressSaveChange = async () => {
    if (!isDisabled && positioning && positioning.id) {
      const dayBlock = positioning.dayBlock;
      const subBlockDateTime = stringToDateLocal(positioning.subBlockTime);
      const timeNow = siteTime.today();
      let confirmDateTime = dayBlock.startTime;

      if (timeNow >= dayBlock.startTime && timeNow < dayBlock.endTime) {
        confirmDateTime = timeNow;
      }

      // confirmDateTime should be equal to the subBlockDateTime for all suBlocks set in the future.
      if (subBlockDateTime && confirmDateTime < subBlockDateTime) {
        confirmDateTime = subBlockDateTime;
      }

      const nonServiceEmployeeIds = reduce(
        positioning.positionSlots,
        (employeeIds: string[], _) => {
          if (_.assignee && _.positionType === PositionTypeEnum.NonService) return [...employeeIds, _.assignee.employeeId];
          return employeeIds;
        },
        [],
      );

      await resetConfirmAndAssign.mutateAsync({
        confirmDateTime: formatToISOString(confirmDateTime),
        nonServiceEmployeeIds: nonServiceEmployeeIds,
        positionGroupId: positioning.id,
        subBlockDateTime: positioning.subBlockTime?.replace('Z', ''),
      });

      refetchPlan();
    }
  };

  return (
    <HStack space={2}>
      <Button
        flex={1}
        variant="unstyled"
        isDisabled={isLoading}
        bg="white"
        startIcon={<ReverseLeftIcon size="14px" />}
        onPress={resetChanges}
      >
        Undo All
      </Button>

      <Button
        flex={1}
        isDisabled={isDisabled}
        isLoading={isLoading}
        startIcon={<UploadCloudIcon size="18px" color="black" />}
        onPress={handlePressSaveChange}
      >
        Save
      </Button>
    </HStack>
  );
};
