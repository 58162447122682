import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, Pressable, Spacer, VStack, View, ScrollView } from 'native-base';
import { find, first, sortBy, map, orderBy, isEmpty, uniqBy, flatMap } from 'lodash';

import { Button, Text, SearchField } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalCategoryDto, GoalDto } from '@pimm/services/lib/sms-positioning';
import { AddUpdateDailyPositionGoalRequest, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { ResourceLoader } from '@app/components/shared';
import { formatToISOString } from '@app/utils/date-formatter';
import { hexToRGBA } from '@app/utils/string-formatter';
import { useDailyPositionGoals, useGoalSettings } from '../context';
import CategoryIcon from './category-icon';
import { Chip } from './chip';

type DailyGoalSelectionProps = {
  category: GoalCategoryDto;
  goalId: string;
  goalTypeIds?: number[];
  onPressCancel?: () => void;
  onPressSave?: (goal: Partial<AddUpdateDailyPositionGoalRequest>) => void;
};

export const DailyGoalSelection = ({ category, goalId, goalTypeIds, ...props }: DailyGoalSelectionProps) => {
  const { translate, locale } = useAppLocale();
  const { goals: allGoals } = useGoalSettings();
  const { blockNumber, dayBlocks, siteId, addUpdateQueryData } = useDailyPositionGoals();
  const [selectedGoal, setSelectedGoal] = useState<AddUpdateDailyPositionGoalRequest>();
  const [searchValue, setSearchValue] = useState<string>();
  const [filterBy, setFilterBy] = useState<string>();

  const addUpdateDailyGoal = useMutation({
    mutationFn: SmsWorkforceApi.AddUpdateDailyPositionGoal,
    onSuccess: response => {
      addUpdateQueryData(response);
      if (props.onPressCancel) props.onPressCancel();
    },
  });

  const searchResults = useMemo(() => {
    const keyword = searchValue?.trim()?.toLowerCase() || filterBy;

    if (!allGoals?.data) return [];

    return orderBy(
      allGoals.data
        .filter(goal => {
          return (
            // Check if the goal matches the category or the description and its translation
            goal?.categories?.some(({ id }) => id === category.id) &&
            (!keyword ||
              goal.description?.toLowerCase().includes(keyword) ||
              goal.goalTypes?.some(
                ({ title, translations }) =>
                  title?.toLowerCase().includes(keyword) ||
                  translations?.some(({ culture, text }) => culture === locale && text?.toLowerCase().includes(keyword)),
              ) ||
              goal.translations?.some(({ culture, text }) => culture === locale && text?.toLowerCase().includes(keyword)))
          );
        })
        .map(goal => ({
          ...goal,
          // Sort goalTypes within each goal in goalTypeIds and sequence
          goalTypes: orderBy(
            goal.goalTypes,
            [({ id }) => (goalTypeIds?.includes(id ?? -1) ? goalTypeIds.indexOf(id ?? -1) : Number.MAX_VALUE), 'seq'],
            ['asc', 'asc'],
          ),
        })),
      // Sort the filtered goals by the sequence of the first goalType and then by the translation text for the current locale
      [
        goal => (goal.goalTypes.some(gt => goalTypeIds?.includes(gt.id ?? -1)) ? 0 : 1),
        goal => goal.goalTypes[0]?.seq ?? Number.MAX_VALUE,
        goal => goal.translations?.find(({ culture }) => culture === locale)?.text || first(goal.translations)?.text || '',
      ],
    );
  }, [allGoals?.data, category, searchValue, filterBy, goalTypeIds, locale]);

  const goalTypes = useMemo(() => {
    return sortBy(
      uniqBy(
        flatMap(searchResults, item => item.goalTypes),
        'id',
      ),
      'seq',
    );
  }, [allGoals.data]);

  const handlePressSave = () => {
    const dayBlock = find(dayBlocks, _ => _.blockNumber === blockNumber) ?? first(dayBlocks);
    const startTime = dayBlock?.startTime ?? first(dayBlocks)?.startTime;

    if (selectedGoal && startTime) {
      const isAllDay = selectedGoal.isAllDay ?? !blockNumber;
      let payload = {
        ...selectedGoal,
        isAllDay: isAllDay,
        goalDate: selectedGoal?.goalDate ?? formatToISOString(startTime),
        siteId: selectedGoal.siteId ?? siteId,
      };
      // Check if isAllDay and new, change goalDate time into 11:59 PM
      if (!payload.id && payload.isAllDay) {
        payload.goalDate = `${formatToISOString(startTime, true)}T23:59:59`;
      }
      addUpdateDailyGoal.mutate(payload);
    }
  };

  const handlePressSelect = (goal: GoalDto) => {
    const payload: Partial<AddUpdateDailyPositionGoalRequest> = {
      goalCategoryId: category.id,
      selectedGoalId: goal.id,
    };
    setSelectedGoal(payload);
  };

  const handlePressFilterByGoal = (goalType: string | undefined) => {
    if (filterBy !== goalType) {
      setSearchValue(undefined);
      setFilterBy(goalType);
    }
  };

  useEffect(() => {
    if (!isEmpty(allGoals.data)) {
      const goal = allGoals.data?.find(_goal => _goal.id === goalId);
      setSelectedGoal({
        goalCategoryId: category.id,
        selectedGoalId: goal?.id,
      });
    }
  }, [goalId]);

  return (
    <View w="full" h="full">
      <Box alignItems="flex-start" justifyContent="center" minHeight="55px" px={5} borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color="black">
          Select a Daily Positional Goal
        </Text>
      </Box>

      <HStack flex={1} p={4} space={5}>
        {/* Goal List */}
        <VStack overflow="hidden" h="full" w="full" flex={1} position="relative" space={4}>
          <HStack height={44} alignItems="center" space={4}>
            <HStack alignItems="center">
              <Box
                alignItems="center"
                justifyContent="center"
                mr={2}
                h={8}
                w={8}
                bg="gray.300"
                rounded="md"
                backgroundColor={category.color}
              >
                <CategoryIcon iconUri={category.icon} size={36} color="black" />
              </Box>
              <Text
                size="2xl"
                fontWeight={700}
                color="black"
                lineHeight="xs"
                ellipsizeMode="tail"
                numberOfLines={2}
                textTransform="uppercase"
              >
                {translate(category?.title, category.translations)}
              </Text>
            </HStack>
            <Spacer />

            <VStack>
              <Text size="md" fontWeight={500} lineHeight="sm" color="gray.900">
                Filter by category
              </Text>
              <HStack space={1}>
                <Pressable onPress={() => handlePressFilterByGoal(undefined)} disabled={isEmpty(allGoals.data)}>
                  {({ isHovered }) => (
                    <Chip
                      value="All"
                      variant="outline"
                      _container={{
                        borderWidth: !filterBy ? 2 : 1,
                        minH: 6,
                        minW: 12,
                        opacity: isHovered || !filterBy ? 1 : 0.5,
                      }}
                      textAlign="center"
                      size="sm"
                    />
                  )}
                </Pressable>
                {map(goalTypes, goal => {
                  const isActive = goal?.title?.toLowerCase() === filterBy;
                  return (
                    <Pressable
                      key={goal?.id}
                      rounded="lg"
                      onPress={() => handlePressFilterByGoal(goal?.title?.toLowerCase())}
                      disabled={isEmpty(allGoals.data)}
                    >
                      {({ isHovered }) => (
                        <Chip
                          value={translate(goal?.title!, goal?.translations)}
                          _container={{
                            backgroundColor: hexToRGBA(goal?.color, 0.1),
                            borderColor: hexToRGBA(goal?.color, 0.2),
                            borderWidth: 1,
                            minH: 6,
                            minW: 12,
                            opacity: isHovered || isActive ? 1 : 0.5,
                          }}
                          textAlign="center"
                          size="sm"
                          colorScheme={goal?.color}
                        />
                      )}
                    </Pressable>
                  );
                })}
              </HStack>
            </VStack>
            <Box flex={1} maxW="320px">
              <SearchField
                isDisabled={isEmpty(allGoals.data)}
                placeholder="Search Goal"
                value={searchValue}
                onChangeText={setSearchValue}
                onClear={() => setSearchValue(undefined)}
              />
            </Box>
          </HStack>
          <Box flex={1} bg="gray.50" borderColor="gray.200" borderWidth={1} rounded="xl">
            <ResourceLoader h="full" w="full" emptyMessage="No goals available" isEmpty={isEmpty(allGoals.data)}>
              <ScrollView p={3} h="full">
                <VStack space={2}>
                  <HStack flexWrap="wrap">
                    {map(searchResults, item => {
                      const isActive = selectedGoal && selectedGoal.selectedGoalId === item?.id;

                      return (
                        <Box key={item?.id} flex="none" p={1} w={{ md: '1/2', lg: '1/3', xl: '1/4' }}>
                          <Pressable rounded="lg" onPress={() => handlePressSelect(item)} flex={1}>
                            {({ isHovered }) => (
                              <VStack
                                flex={1}
                                space={1.5}
                                width="full"
                                minH="80px"
                                maxH="100px"
                                bg={isActive ? 'gray.900' : isHovered ? 'gray.200' : 'white'}
                                justifyContent="space-between"
                                px={3}
                                py={1.5}
                                rounded="lg"
                              >
                                <Text
                                  size="lg"
                                  fontWeight={600}
                                  color={isActive ? 'white' : 'gray.900'}
                                  lineHeight="xs"
                                  numberOfLines={3}
                                  ellipsizeMode="tail"
                                >
                                  {translate(item.description, item?.translations)}
                                </Text>
                                <HStack flexWrap="wrap" space={1}>
                                  {map(item.goalTypes, gt => (
                                    <Chip
                                      key={gt.id}
                                      value={translate(gt.title!, gt?.translations)}
                                      fontWeight={500}
                                      size="sm"
                                      _container={{
                                        backgroundColor: isHovered || isActive ? 'white' : hexToRGBA(gt?.color, 0.1),
                                        borderColor: isHovered || isActive ? 'white' : hexToRGBA(gt?.color, 0.2),
                                      }}
                                      colorScheme={gt?.color}
                                    />
                                  ))}
                                </HStack>
                              </VStack>
                            )}
                          </Pressable>
                        </Box>
                      );
                    })}
                  </HStack>
                </VStack>
              </ScrollView>
            </ResourceLoader>
          </Box>
        </VStack>
      </HStack>

      <HStack space={2} py={3} px={5} justifyContent="flex-end" borderTopWidth={1}>
        <Button variant="unstyled" minWidth={90} disabled={addUpdateDailyGoal.isLoading} onPress={props.onPressCancel}>
          Cancel
        </Button>

        <Button
          minWidth={90}
          disabled={!selectedGoal || addUpdateDailyGoal.isLoading || goalId === selectedGoal?.selectedGoalId}
          isLoading={addUpdateDailyGoal.isLoading}
          onPress={handlePressSave}
        >
          Save
        </Button>
      </HStack>
    </View>
  );
};
