import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-native';
import { useMutation, useQueryClient } from 'react-query';
import { MenuProvider } from 'react-native-popup-menu';
import { ArrowBackIcon, ArrowForwardIcon, Box, CloseIcon, HStack, IconButton, Spacer, View, useMediaQuery, useTheme } from 'native-base';
import { filter, find, findIndex, map, some } from 'lodash';
import moment from 'moment';

import { Button, ButtonGroup, CalendarIcon, ResponsiveScrollView, SearchField, Text } from '@pimm/base';
import { Employee, ShiftDto, UpdateScheduleRequest } from '@pimm/services/lib/sms-workforce';
import { GetSchedules, UpdateSchedule } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString, millisToMinutes, stringToDateLocal } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { CalendarPickerMode, PopoverMenuCalendar, ResourceLoader } from '@app/components/shared';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { ScheduleEmployeesProvider } from '../context/schedule-employees.context';
import { GetEmployeeSchedulesParams, useGetEmployeeSchedules } from '../hooks';
import EmployeeDailySchedules from './employee-daily-schedules';
import EmployeeWeeklySchedules from './employee-weekly-schedules';
import { ScheduleTimeEditor } from './schedule-time-editor';

type EditSchedulePayload = Pick<React.ComponentProps<typeof ScheduleTimeEditor>, 'displayText' | 'referenceId' | 'startTime' | 'endTime'>;

type GetEmployeeSchedulesReturn = Awaited<typeof GetSchedules>;

type EmployeeSchedulesProps = {
  customerId: string;
  defaultDate?: Date;
  employees?: Employee[];
  schedules?: ShiftDto[];
  siteId: string;
  onCancel?: () => void;
};

const WeeklyScheduleTabs = {
  Scheduled: 'Scheduled',
  FullRoster: 'Full Roster',
} as const;

export type WeeklyScheduleType = keyof typeof WeeklyScheduleTabs;

const EmployeeSchedules = ({ customerId, defaultDate, employees = [], siteId, ...props }: EmployeeSchedulesProps) => {
  const { colors } = useTheme();
  const queryClient = useQueryClient();
  // For screen width below 1200, show scrollview with vertical/horizontal scroll display
  const [isTabletScreen] = useMediaQuery({ maxWidth: 1200 });
  const siteTime = useSiteTime();
  const modalEditSchedule = useModalFocus<EditSchedulePayload>();
  const [calendarMode, setCalendarMode] = useState<CalendarPickerMode>('single');
  const [shiftInputs, setShiftInputs] = useState<ShiftDto[]>([]);
  const [dayBlocks, setDayBlocks] = useState<DayBlock[]>(siteTime.toDayBlocks(defaultDate ?? siteTime.today()));
  const [startEndOfBlock, setStartEndOfBlock] = useState<{ startTime?: Date; endTime?: Date }>(
    siteTime.toStartEndOfBlock(defaultDate ?? siteTime.today()),
  );
  const [searchValue, setSearchValue] = useState<string>();
  const [tabFocus, setTabFocus] = useState<WeeklyScheduleType>('Scheduled');

  const employeeSchedules = useGetEmployeeSchedules({
    siteId: siteId,
    startDate: startEndOfBlock.startTime,
    endDate: startEndOfBlock.endTime,
    showAll: true,
  });

  const updateSchedules = useMutation({ mutationFn: UpdateSchedule });

  const today = siteTime.today();
  const isEditable =
    startEndOfBlock.startTime && today > startEndOfBlock.startTime && startEndOfBlock.endTime && today < startEndOfBlock.endTime;
  const isWeekly = calendarMode === 'week';
  const isSaveEnabled = shiftInputs.length > 0;

  const displayDateText = [
    moment(startEndOfBlock.startTime).format('ll'),
    isWeekly ? moment(startEndOfBlock.startTime).add(6, 'days').format('ll') : undefined,
  ]
    .filter(Boolean)
    .join('- ');

  // This will update the active cache data of these queries (GetEmployeeSchedules, GetPositionScheduleLive)
  const updateQueryData = (inputs: ShiftDto[]) => {
    const queryCache = queryClient.getQueryCache();
    const startOfDay = moment(startEndOfBlock.startTime).format().slice(0, 19);

    queryCache.getAll().forEach(cache => {
      // Refetch live schedules
      if (cache.queryKey.includes('GetEmployeeSchedules')) {
        const startDate = (cache.queryKey[1] as GetEmployeeSchedulesParams).startDate;

        // Check if the startOfDay is the same, before overriding the active data
        if (moment(startDate).format().slice(0, 19) === startOfDay) {
          queryClient.setQueryData<GetEmployeeSchedulesReturn>(cache.queryKey, (previous): GetEmployeeSchedulesReturn => {
            // Update query data with the newly added schedule or deleted schedule
            const nextData = previous.data?.reduce((next: ShiftDto[], shift) => {
              const input = inputs.find(_ => _.id === shift.id);
              if (!input) return [...next, shift];
              if (input.employee) return [...next, input];
              return next;
            }, []);

            return {
              ...previous,
              data: nextData,
            };
          });
        }
      }

      // Let's refetch live schedules
      if (cache.queryKey.includes('GetPositionScheduleLive')) {
        queryClient.refetchQueries({ queryKey: cache.queryKey });
      }
    });
  };

  const scheduledEmployees = useMemo(() => {
    return filter(employees, employee =>
      some(employeeSchedules.data?.data, schedule => schedule?.employee && schedule.employee.id === employee.id),
    );
  }, [employees, employeeSchedules.data]);

  const handleChangeDay = (days: number) => () => {
    const startDate = moment(startEndOfBlock.startTime)
      .add(days * (isWeekly ? 7 : 1), 'days')
      .toDate();
    let _startEndOfBlock = siteTime.toStartEndOfBlock(startDate);
    if (calendarMode === 'week') {
      _startEndOfBlock = siteTime.toStartEndOfWeekBlock(startDate);
    }
    setStartEndOfBlock(_startEndOfBlock);
  };

  const handleChangeDate = (date?: Date) => {
    let _startEndOfBlock = siteTime.toStartEndOfBlock(date);
    if (calendarMode === 'week') {
      _startEndOfBlock = siteTime.toStartEndOfWeekBlock(date);
    }
    setStartEndOfBlock(_startEndOfBlock);
  };

  const handlePressToday = () => {
    let _startEndOfBlock = siteTime.toStartEndOfBlock();
    if (calendarMode === 'week') {
      _startEndOfBlock = siteTime.toStartEndOfWeekBlock();
    }
    setStartEndOfBlock(_startEndOfBlock);
  };

  const handlePressEdit = (shift?: ShiftDto) => {
    if (updateSchedules.isLoading) return;

    const employee = shift?.employee;
    const displayText = employee ? `${employee?.firstName} ${employee?.lastName}` : undefined;

    modalEditSchedule.setOpen({
      referenceId: shift?.id ?? undefined,
      displayText: displayText,
      startTime: stringToDateLocal(shift?.startTime),
      endTime: stringToDateLocal(shift?.endTime),
    });
  };

  const handleDeleteSchedule = useCallback((id: number) => {
    updateQueryData([{ id: id }]);
  }, []);

  const handleUpdateSchedule = (inputs: Parameters<React.ComponentProps<typeof ScheduleTimeEditor>['onApply']>[0]) => {
    const shift = find(employeeSchedules.data?.data, ['id', inputs.referenceId]);

    if (shift) {
      const index = findIndex(shiftInputs, ['id', inputs.referenceId]);
      const nextEntries = [...shiftInputs];

      shift.durationInMinutes = millisToMinutes(inputs.endTime.getTime() - inputs.startTime.getTime());
      shift.startTime = formatToISOString(inputs.startTime);
      shift.endTime = formatToISOString(inputs.endTime);

      if (index === -1) nextEntries.push(shift);
      else nextEntries[index] = shift;

      setShiftInputs(nextEntries);
      modalEditSchedule.setHide();
    }
  };

  const handleTabChange = _mode => {
    let _startEndOfBlock = siteTime.toStartEndOfBlock();
    if (_mode === 'week') {
      _startEndOfBlock = siteTime.toStartEndOfWeekBlock();
    }
    setCalendarMode(_mode);
    setStartEndOfBlock(_startEndOfBlock);
    setSearchValue(undefined);
    setTabFocus('Scheduled');
  };

  const handleSaveChanges = async () => {
    const payload: UpdateScheduleRequest = {
      shiftInputs: shiftInputs.map(shift => ({
        id: shift.id,
        startTime: shift.startTime,
        endTime: shift.endTime,
      })),
    };
    await updateSchedules.mutateAsync(payload);
    // Try updating active cache data
    updateQueryData(shiftInputs);
    // Force hide itself
    if (props.onCancel) props.onCancel();
  };

  return (
    <Fragment>
      <View w="full" h="full">
        {/* Header */}
        <HStack py={2} pl={4} pr={2} alignItems="center" justifyContent="center" borderBottomWidth={1}>
          <Box flex={1}>
            <Text size="2xl" fontWeight={700} color="gray.900">
              Employee Schedule
            </Text>
          </Box>

          <ButtonGroup value={calendarMode} onChange={handleTabChange}>
            <ButtonGroup.Item value="single" w={140}>
              Daily Schedule
            </ButtonGroup.Item>
            <ButtonGroup.Item value="week" w={140}>
              Weekly Schedule
            </ButtonGroup.Item>
          </ButtonGroup>

          <Box alignItems="flex-end" flex={1}>
            {!!props.onCancel && (
              <IconButton
                rounded="lg"
                variant="unstyled"
                boxSize={9}
                p={0}
                _pressed={{ bg: 'gray.50' }}
                icon={<CloseIcon size="18px" color={colors.gray[700]} />}
                onPress={props.onCancel}
              />
            )}
          </Box>
        </HStack>

        <ScheduleEmployeesProvider
          customerId={customerId}
          dayBlocks={dayBlocks}
          employees={tabFocus === 'Scheduled' && isWeekly ? scheduledEmployees : employees}
          isEditable={isEditable}
          siteId={siteId}
        >
          {/* Toolbar */}
          <HStack space={5} alignItems="center" py={2} px={4}>
            <HStack flex={1} space={2} alignItems="center">
              {isWeekly && (
                <Fragment>
                  <SearchField w="320px" value={searchValue} onChangeText={setSearchValue} onClear={() => setSearchValue(undefined)} />
                  <ButtonGroup onChange={value => setTabFocus(value as WeeklyScheduleType)} value={tabFocus}>
                    {map(WeeklyScheduleTabs, (label, tab) => {
                      const count = tab === 'FullRoster' ? employees.length : scheduledEmployees.length;
                      return (
                        <ButtonGroup.Item key={tab} value={tab} badgeIcon={{ show: true, value: count }}>
                          {label}
                        </ButtonGroup.Item>
                      );
                    })}
                  </ButtonGroup>
                </Fragment>
              )}
            </HStack>

            {!!startEndOfBlock.startTime && (
              <HStack space={2} alignItems="center">
                <Button variant="unstyled" w={10} onPress={handleChangeDay(-1)}>
                  <ArrowBackIcon color="gray.700" />
                </Button>

                <PopoverMenuCalendar
                  mode={calendarMode}
                  selectedDate={startEndOfBlock.startTime}
                  onChange={date => handleChangeDate(date)}
                  trigger={triggerProps => (
                    <Button variant="unstyled" minWidth={140} startIcon={<CalendarIcon size={4} />} {...triggerProps}>
                      {displayDateText}
                    </Button>
                  )}
                />

                <Button variant="unstyled" w={10} onPress={handleChangeDay(1)}>
                  <ArrowForwardIcon color="gray.700" />
                </Button>

                <Button variant="unstyled" px={3} minWidth="74px" onPress={handlePressToday}>
                  {isWeekly ? 'Current Week' : 'Today'}
                </Button>
              </HStack>
            )}
          </HStack>

          {/* Content */}
          <View flex={1}>
            {calendarMode === 'single' ? (
              <View h="full" borderTopWidth={1} borderBottomWidth={1}>
                <ResponsiveScrollView h="full" w="full" showScroll={isTabletScreen} contentContainerStyle={{ flexGrow: 1 }}>
                  <EmployeeDailySchedules
                    entries={shiftInputs}
                    employeeSchedules={employeeSchedules}
                    scheduledDate={startEndOfBlock.startTime}
                    onPressEdit={handlePressEdit}
                    onDeleted={handleDeleteSchedule}
                  />
                </ResponsiveScrollView>
              </View>
            ) : (
              <View px={4} h="full">
                <ResourceLoader
                  h="full"
                  isEmpty={!employeeSchedules.data || employeeSchedules.data.totalCount === 0}
                  isLoading={employeeSchedules.isLoading}
                >
                  {!!startEndOfBlock.startTime && (
                    <ResponsiveScrollView h="full" w="full" borderRadius="md" rounded="md" showScroll={isTabletScreen} horizontal>
                      <EmployeeWeeklySchedules
                        key={dayBlocks[0].startTime?.toDateString()}
                        schedules={employeeSchedules.data?.data}
                        startOfWeek={startEndOfBlock.startTime}
                        searchKeyword={searchValue}
                      />
                    </ResponsiveScrollView>
                  )}
                </ResourceLoader>
              </View>
            )}
          </View>
        </ScheduleEmployeesProvider>

        {/* Footer */}
        <HStack space={2} alignItems="center" py={2.5} px={4}>
          <HStack
            alignItems="center"
            justifyContent="center"
            borderRadius="lg"
            px={5.2}
            h={8}
            minW="120px"
            borderWidth={1}
            backgroundColor="warning.200"
            space={1}
          >
            {['Start', 'End'].map(label => {
              return (
                <Box key={label} flex={1} justifyContent="center" px={1.5} h={5} borderRadius="md" borderWidth={1} backgroundColor="white">
                  <Text size="xs" fontWeight={500} color="gray.900" textAlign="center" lineHeight="xs">
                    {label}
                  </Text>
                </Box>
              );
            })}
            <Box flex={1} h={5} justifyContent="center" borderRadius="md" px={2.06} py={4.8} borderWidth={1} bg="blue.200" />
          </HStack>

          <Text size={{ xl: 'md', md: 'sm' }} fontWeight={600} color="gray.700" lineHeight="sm" numberOfLines={2}>
            Manually adjusted schedules are highlighted in yellow.
          </Text>

          <HStack
            alignItems="center"
            justifyContent="center"
            borderRadius="lg"
            px={5.2}
            h={8}
            minW="120px"
            borderWidth={1}
            backgroundColor="red.500"
            space={1}
          >
            {['Start', 'End'].map(label => {
              return (
                <Box key={label} flex={1} justifyContent="center" px={1.5} h={5} borderRadius="md" borderWidth={1} backgroundColor="white">
                  <Text size="xs" fontWeight={500} color="gray.900" textAlign="center" lineHeight="xs">
                    {label}
                  </Text>
                </Box>
              );
            })}
            <Box flex={1} h={5} justifyContent="center" borderRadius="md" px={2.06} py={4.8} borderWidth={1} bg="blue.200" />
          </HStack>

          <Text size={{ xl: 'md', md: 'sm' }} fontWeight={600} color="gray.700" lineHeight="sm" numberOfLines={2}>
            Employee has multiple shifts scheduled in a single day
          </Text>

          <Spacer />

          {isSaveEnabled && (
            <Fragment>
              <Button variant="unstyled" minWidth={100} disabled={updateSchedules.isLoading} onPress={props.onCancel}>
                Cancel
              </Button>

              <Button
                minWidth={100}
                disabled={!isSaveEnabled}
                isLoading={updateSchedules.isLoading}
                isLoadingText="Saving"
                onPress={handleSaveChanges}
              >
                Save Changes
              </Button>
            </Fragment>
          )}
        </HStack>
      </View>

      <Modal animationType="none" presentationStyle="fullScreen" transparent={true} visible={modalEditSchedule.isOpen}>
        <MenuProvider>
          <View alignItems="center" justifyContent="center" w="full" h="full" bgColor={'rgba(0, 0, 0, 0.8)'}>
            <View rounded="xl" width={620} bg="white">
              {!!startEndOfBlock.startTime && !!startEndOfBlock.endTime && (
                <ScheduleTimeEditor
                  startEndOfBlock={{ startTime: startEndOfBlock.startTime, endTime: startEndOfBlock.endTime }}
                  {...modalEditSchedule.payload}
                  onApply={handleUpdateSchedule}
                  onCancel={modalEditSchedule.setHide}
                />
              )}
            </View>
          </View>
        </MenuProvider>
      </Modal>
    </Fragment>
  );
};

export default memo(EmployeeSchedules);
