import { PayloadAction } from '@reduxjs/toolkit';
import { GenericState } from '@pimm/common';
import { Employee } from '@pimm/services/lib/sms-workforce';
import { ASYNC_STORE_FOCUS } from '../consts';
import { StoreFocusState } from '../state';
import { createGenericSlice } from './genericSlice';

export const StoreFocusStateDefault: StoreFocusState = {
  employees: [],
  store: undefined,
};

export const storeFocusSlice = createGenericSlice<StoreFocusState>({
  name: ASYNC_STORE_FOCUS,
  initialState: {
    status: 'idle',
    data: StoreFocusStateDefault,
  },
  reducers: {
    setEmployees(state: GenericState<StoreFocusState>, { payload }: PayloadAction<Employee[]>) {
      state.data.employees = payload;
    },
    update(state: GenericState<StoreFocusState>, { payload }: PayloadAction<Partial<StoreFocusState>>) {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
    clear(state: GenericState<StoreFocusState>) {
      state.status = 'idle';
      state.data = StoreFocusStateDefault;
    },
  },
});

export const storeFocusReducer = storeFocusSlice.reducer;
