import { useEffect, useState } from 'react';
import { Box, HStack, ScrollView, VStack } from 'native-base';
import { orderBy } from 'lodash';

import { Button, Modal, Text } from '@pimm/base';
import { DpTimerDataDto, GoalProfileDto } from '@pimm/services/lib/sms-drivethru/types';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { Daypart } from '@app/features/store-core';
import { useQuerySettingsAndGoals } from '../context';
import { CarPerformance } from './car-performance';
import { Guidelines } from './guidelines';
import { TimeBreakdown } from './time-breakdown';
import { WeeklyPerformance } from './weekly-performance';
import { DetailedLanelInfo } from './detailed-lane-info';

type DaypartAnalyticsProps = {
  _container?: React.ComponentProps<typeof ScrollView>;
  daypart: Daypart;
  timerData?: DpTimerDataDto;
};

export const DaypartAnalytics = ({ _container, daypart, timerData }: DaypartAnalyticsProps) => {
  const settingsAndGoals = useQuerySettingsAndGoals();
  const modalDetailedLane = useModalFocus();
  const [profiles, setProfiles] = useState<GoalProfileDto[]>([]);

  const isYLane = timerData?.laneConfig === 'Y Lane';
  const numOfLanes = timerData?.details?.length ?? 0;

  useEffect(() => {
    const _profiles = settingsAndGoals.data?.brandGoalProfiles?.filter(_ => {
      const dpStartTime = daypart.startTime?.toTimeValue(_.dpStartTime);
      return dpStartTime && dpStartTime >= daypart.startTime && dpStartTime < daypart.endTime;
    });
    setProfiles(orderBy(_profiles ?? [], ['name']));
  }, [daypart, settingsAndGoals.data]);

  return (
    <>
      <ScrollView h="full" contentContainerStyle={{ height: '100%' }} {..._container}>
        <VStack>
          <HStack space={4} alignItems="center" height="54px">
            <Text size="2xl" fontWeight={700} color="black" lineHeight="xs">
              {daypart.label}
            </Text>

            {isYLane && (
              <Button
                variant="unstyled"
                disabled={numOfLanes < 3}
                bg="white"
                _hover={{ bg: 'white' }}
                _pressed={{ bg: 'white' }}
                onPress={modalDetailedLane.setOpen}
              >
                Lane Breakdown
              </Button>
            )}
          </HStack>

          <Box flexDirection={{ base: 'column', xl: 'row' }} w="full">
            <HStack space={3} w={{ base: 'full', xl: '1/2' }}>
              <Box flex={1} h={{ xl: 'full' }}>
                <Box rounded="xl" py={3} px={4} w="full" h="full" borderWidth={1} bg="white">
                  <TimeBreakdown
                    title={isYLane ? 'Y-Lane Average Times' : 'Single Lane Average Times'}
                    caption={
                      isYLane
                        ? 'The data provided shows the average time for all lanes. Click the button above to view speed of service metrics for each lane'
                        : undefined
                    }
                    goal={timerData?.siteDaypartGoal}
                    timerDetails={timerData?.details?.[0]}
                  />
                </Box>
              </Box>

              <VStack flex={1} space={3} h={{ xl: 'full' }}>
                <Box rounded="xl" py={3} px={4} w="full" borderWidth={1} bg="white">
                  <CarPerformance goal={timerData?.siteDaypartGoal} profiles={profiles} timerDetails={timerData?.details?.[0]} />
                </Box>

                <Guidelines
                  _container={{ flex: 1 }}
                  goal={timerData?.siteDaypartGoal}
                  profiles={profiles}
                  timerDetails={timerData?.details?.[0]}
                />
              </VStack>
            </HStack>

            <Box
              mt={{ base: 3, xl: 0 }}
              mb={{ base: 4, xl: 0 }}
              pl={{ xl: 3 }}
              w={{ base: 'full', xl: '1/2' }}
              minH={380}
              h={{ xl: 'full' }}
            >
              <WeeklyPerformance daypart={daypart} />
            </Box>
          </Box>
        </VStack>
      </ScrollView>

      <Modal
        _content={{ rounded: 'xl', maxWidth: 880, minHeight: 700, overflow: 'hidden' }}
        size="xl"
        noPadding
        hideClose
        isOpen={modalDetailedLane.isOpen}
        onClose={modalDetailedLane.setHide}
      >
        <DetailedLanelInfo profiles={profiles} timerData={timerData} onClose={modalDetailedLane.setHide} />
      </Modal>
    </>
  );
};
