import { Icon, IIconProps, useTheme } from 'native-base';
import { EllipseBlankIcon, EllipseCompletedIcon, EllipsePartialIcon } from '@pimm/base';
import { Path } from 'react-native-svg';

type CompletionIconProps = IIconProps & {
  variant?: 'blank' | 'partial' | 'complete';
};

export const CompletionIcon = ({ variant, ...iconProps }: CompletionIconProps) => {
  const { colors } = useTheme();
  if (variant === 'complete') return <EllipseCompletedIcon color={colors.success[500]} {...iconProps} />;
  if (variant === 'partial') return <EllipsePartialIcon color={colors.warning[400]} {...iconProps} />;
  return <EllipseBlankIcon color={colors.gray[500]} {...iconProps} />;
};
