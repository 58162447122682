import { useEffect, useState } from 'react';
import { Box, Divider, HStack, IconButton, VStack, useTheme } from 'native-base';
import { find, isEmpty } from 'lodash';
import moment from 'moment';

import { ButtonGroup, Text } from '@pimm/base';
import { EquipmentInstancesDto, LinkedInstanceChartDataDto } from '@pimm/services/lib/store-equipment';
import { tempValue } from '@app/utils/string-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useSiteTime } from '@app/features/store-core';
import { useGetChartData, useGetEventCounts, useGetStoreEvents } from '../hooks';
import { AlertOctagonIcon, OutlineBadgeCheckIcon, PlusSquareIcon, Setting01Icon } from '../icons';
import { TemperatureChart } from './temperature-chart';
import { ChartStatistics } from './chart-statistics';
import { EventLog } from './event-log-data';
import { EventLogs } from './event-logs';

export type EquipmentChartDataProps = {
  equipmentFocus: EquipmentInstancesDto;
  siteId: string;
};

export const EquipmentChartData = ({ equipmentFocus, siteId }: EquipmentChartDataProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const [queryDates, setQueryDates] = useState<{ startDate: Date; endDate: Date }>({
    startDate: moment(siteTime.today()).add(-3, 'day').startOf('day').toDate(),
    endDate: siteTime.today().toLastNearestMinutes(5),
  });
  const [linkedTemperature, setLinkedTemperature] = useState<LinkedInstanceChartDataDto>();
  const [selectedEventLog, setSelectedEventLog] = useState<EventLog>();

  const queryChartData = useGetChartData({ ...queryDates, siteId: siteId, equipmentId: equipmentFocus.EquipmentId });
  const queryStoreEvents = useGetStoreEvents({ ...queryDates, siteId: siteId, equipmentId: equipmentFocus.EquipmentId });
  const queryEventCounts = useGetEventCounts({ ...queryDates, siteId: siteId, equipmentId: equipmentFocus.EquipmentId });

  const numOfDays = moment(queryDates.endDate).diff(queryDates.startDate, 'days') * -1;
  const thresholds = equipmentFocus.TemperatureMetrics?.Temperature?.Thresholds;

  const handleChangeDays = (days: number) => {
    const today = siteTime.today();
    const startDate = moment(today).add(days, 'day').startOf('day').toDate();
    const endDate = siteTime.today().toLastNearestMinutes(5);

    setQueryDates({
      startDate: startDate,
      endDate: endDate,
    });
  };

  useEffect(() => {
    const linkedTemperatureDto = find<LinkedInstanceChartDataDto>(queryChartData.data, _ => !!_.Temperature);
    setLinkedTemperature(linkedTemperatureDto);
  }, [queryChartData.data]);

  return (
    <VStack flex={1}>
      <VStack pb={2} space={2}>
        {/* Title */}
        <HStack space={3} alignItems="center" justifyContent="space-between" px={3} h="56px" borderBottomWidth={1}>
          <HStack space={2} flex={1} alignItems="center">
            <Box>
              <Text size={{ base: '2xl', xl: '4xl' }} color="black" fontWeight={600} lineHeight="xs">
                {equipmentFocus.TemperatureMetrics?.Temperature?.Description ?? ''}
              </Text>
            </Box>

            {/* Thresholds */}
            <HStack flex={1} space={1} alignItems="center">
              {/* Lower Red */}
              <HStack
                rounded="full"
                borderWidth={1}
                borderColor="gray.100"
                alignItems="center"
                p={1}
                pr={{ base: 1, xl: 2 }}
                space={{ base: 1, xl: 2 }}
              >
                <Box rounded="full" alignItems="center" justifyContent="center" p={0.5} bgColor="error.600">
                  <AlertOctagonIcon color="white" size={{ base: 2, xl: 4 }} />
                </Box>
                <Text size={{ base: 'sm', xl: 'md' }} color="gray.700" fontWeight={600} lineHeight="xs">
                  {`<${tempValue(thresholds?.LowerRed)}`}
                </Text>
              </HStack>

              {/* Lower and Upper Green */}
              <HStack rounded="full" borderWidth={1} borderColor="gray.100" alignItems="center" p={1} space={{ base: 1, xl: 2 }}>
                <Box rounded="full" bgColor="blueLight.500" alignItems="center" p={0.5}>
                  <OutlineBadgeCheckIcon color="white" size={{ base: 2, xl: 4 }} />
                </Box>
                <Text size={{ base: 'sm', xl: 'md' }} color="gray.700" fontWeight={600} lineHeight="xs">
                  {`${`${(thresholds?.LowerGreen ?? 0).toFixed(1)} -`} ${`${tempValue(thresholds?.UpperGreen)}`}`}
                </Text>
              </HStack>

              {/* Upper Red */}
              <HStack rounded="full" borderWidth={1} borderColor="gray.100" alignItems="center" p={1} space={{ base: 1, xl: 2 }}>
                <Box rounded="full" bgColor="error.600" alignItems="center" p={0.5}>
                  <AlertOctagonIcon color="white" size={{ base: 2, xl: 4 }} />
                </Box>
                <Text size={{ base: 'sm', xl: 'md' }} color="gray.700" fontWeight={600} lineHeight="xs">
                  {`>${tempValue(thresholds?.UpperRed)}`}
                </Text>
              </HStack>
            </HStack>
          </HStack>

          <ButtonGroup value={numOfDays} onChange={handleChangeDays}>
            <ButtonGroup.Item value={0}>Today</ButtonGroup.Item>
            <ButtonGroup.Item value={-3}>3 Days</ButtonGroup.Item>
            <ButtonGroup.Item value={-7}>7 Days</ButtonGroup.Item>
            <ButtonGroup.Item value={-30}>30 Days</ButtonGroup.Item>
          </ButtonGroup>
        </HStack>

        {/* Chart */}
        <VStack space={2} px={3} mb={{ xl: 1 }}>
          <HStack space={2} justifyContent="space-between">
            {/* Chart Stats UI */}
            <ChartStatistics statistics={linkedTemperature?.Temperature?.Statistics} />

            {/* Action Tools */}
            <HStack space={2} alignItems="center" justifyContent="center">
              <IconButton p={0} disabled icon={<PlusSquareIcon color={colors.gray[400]} size={{ base: 5, xl: 6 }} />} />
              <IconButton p={0} disabled icon={<Setting01Icon color={colors.gray[400]} size={{ base: 5, xl: 6 }} />} />
            </HStack>
          </HStack>

          {/* Line Chart  */}
          <ResourceLoader
            minHeight={{ base: 340, xl: 365 }}
            isLoading={queryChartData.isIdle || queryChartData.isLoading}
            isEmpty={(queryChartData.isSuccess && isEmpty(linkedTemperature?.Temperature?.Data)) || queryChartData.isError}
            emptyMessage={
              <Text size="lg" fontWeight={600} color="gray.500" textAlign="center">
                No data found
              </Text>
            }
          >
            <Box flex={1} justifyContent="center" alignItems="center" h="full">
              <TemperatureChart
                startDate={queryDates.startDate}
                endDate={queryDates.endDate}
                linkedInstances={queryChartData.data}
                thresholds={equipmentFocus.TemperatureMetrics?.Temperature?.Thresholds}
                eventLog={selectedEventLog}
              />
            </Box>
          </ResourceLoader>
        </VStack>
      </VStack>

      <Divider bg="gray.200" />

      {/* Event Logs */}
      <Box flex={1} px={3} pb={2}>
        <EventLogs queryStoreEvents={queryStoreEvents} queryEventCounts={queryEventCounts} onSelectEvent={setSelectedEventLog} />
      </Box>
    </VStack>
  );
};
