import { useEffect, useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Box, FlatList, HStack, Spacer } from 'native-base';
import { filter, find, findLast, first, map, orderBy, range, size } from 'lodash';
import moment from 'moment';

import { Button, ButtonGroup, CopyIcon } from '@pimm/base';
import { GoalCategoryDto } from '@pimm/services/lib/sms-positioning';
import { useSiteTime } from '@app/features/store-core';
import { formatToDateOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { useDailyPositionGoals, useGoalSettings } from '../context';
import DailyGoalCard from './daily-goal-card';

export type DailyGoalsListProps = {
  categories?: GoalCategoryDto[];
  disabled?: boolean;
  goalTypeIds?: number[];
  onPressCopy: () => void;
  onPressSelect: (payload: { category: GoalCategoryDto; goalId?: string }) => void;
};

export const DailyGoalsList = ({ categories, disabled, ...props }: DailyGoalsListProps) => {
  const siteTime = useSiteTime();
  const { goals } = useGoalSettings();

  const isCopyEnabled = useBoolean();
  const { blockNumber, dayBlocks, dailyGoals, addUpdateQueryData, setBlockNumber } = useDailyPositionGoals();

  const memoizeBlocks = useMemo(
    () =>
      map(range(0, size(dayBlocks) + 1), blockNumber => {
        const dayBlock = find(dayBlocks, ['blockNumber', blockNumber]);
        const positionGoals = filter(dailyGoals.data, goal => {
          const goalDate = stringToDateLocal(goal.goalDate);
          if (blockNumber === 0) {
            return !!goal.isAllDay && formatToDateOnly(first(dayBlocks)?.startTime) === formatToDateOnly(goalDate);
          } else if (!goal.isAllDay) {
            if (dayBlock && goalDate) {
              return moment(goalDate).isBetween(dayBlock.startTime, dayBlock.endTime, 'minutes', '[)');
            }
          }
          return false;
        });

        const items = map(orderBy(categories, 'seq'), category => {
          const positionGoal = findLast(positionGoals, goal => goal.goalCategoryId === category.id);
          const goal = find(goals.data, ['id', positionGoal?.selectedGoalId]);

          return {
            category,
            goal: goal,
            positionGoal: positionGoal,
          };
        });

        return {
          blockNumber: dayBlock?.blockNumber ?? 0,
          items: items,
          size: positionGoals.length,
        };
      }),
    [categories, dayBlocks, dailyGoals.data, goals.data],
  );

  // Check if, dayblock is allowed to use copy
  useEffect(() => {
    const week = siteTime.toStartEndOfWeekBlock();
    const startTime = first(dayBlocks)?.startTime;

    isCopyEnabled.setValue(!!(startTime && startTime >= week.startTime && week.endTime >= siteTime.today()));
  }, [dayBlocks]);

  return (
    <FlatList
      pb={3}
      px={3.5}
      w="full"
      h="full"
      bg="gray.50"
      data={memoizeBlocks[blockNumber ?? 0].items}
      extraData={[dailyGoals.isLoading, dayBlocks]}
      scrollEnabled
      numColumns={3}
      stickyHeaderIndices={[0]}
      ItemSeparatorComponent={() => <Box h={3} />}
      ListHeaderComponent={() => (
        <HStack space={2} alignItems="center" py={2.5} px={1.5} bg="gray.50">
          <Button
            variant="unstyled"
            bg={blockNumber ? 'white' : 'gray.300'}
            _hover={{ bg: blockNumber ? undefined : 'gray.300' }}
            onPress={() => setBlockNumber(undefined)}
          >
            All Day
          </Button>

          {isCopyEnabled.value && <Button variant="unstyled" w={10} startIcon={<CopyIcon size="md" />} onPress={props.onPressCopy} />}

          <Spacer />

          <ButtonGroup value={blockNumber} onChange={setBlockNumber}>
            {map(dayBlocks, dayBlock => (
              <ButtonGroup.Item
                key={dayBlock.title}
                badgeIcon={{ show: true, value: memoizeBlocks[dayBlock.blockNumber as number].size ?? 0 }}
                value={dayBlock.blockNumber}
              >
                {dayBlock.title}
              </ButtonGroup.Item>
            ))}
          </ButtonGroup>
        </HStack>
      )}
      renderItem={({ item }) => {
        return (
          <DailyGoalCard
            category={item.category}
            goal={item.goal}
            isLoading={dailyGoals.isLoading}
            positionGoal={item.positionGoal}
            onChange={addUpdateQueryData}
            onPressSelect={props.onPressSelect}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
