import { HStack, IIconProps, Pressable, useTheme, VStack } from 'native-base';
import { filter, includes, map, sortBy } from 'lodash';
import { SvgProps } from 'react-native-svg';

import { CleanIcon, ClockStopWatchIcon, FaceSmileIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AggGoalTypeDto } from '@pimm/services/lib/sms-workforce';
import { BurgerIcon, CheckSquareIcon } from '../icons';

type GoalTypesSelectionProps = {
  _container?: React.ComponentProps<typeof HStack>;
  _caption?: React.ComponentProps<typeof Text>;
  _score?: React.ComponentProps<typeof Text>;
  _icon?: IIconProps;
  disabled?: boolean;
  showTitle?: boolean;
  isGoalHistory?: boolean;
  goals?: number[];
  goalTypes?: AggGoalTypeDto[];
  onAddGoalType?: (goalType: AggGoalTypeDto) => void;
};

export const GoalTypesSelection = ({
  _caption,
  _container,
  _icon,
  _score,
  disabled,
  isGoalHistory,
  showTitle,
  goals,
  ...props
}: GoalTypesSelectionProps) => {
  const { translate } = useAppLocale();

  const sortedGoals = filter(sortBy(props.goalTypes, 'seq'), _ => _.seq !== 0);
  const { colors } = useTheme();

  const handlePressGoal = (goalType: AggGoalTypeDto) => () => {
    if (props.onAddGoalType) props.onAddGoalType(goalType);
  };

  const getIconForGoal = (title?: string) => {
    switch (title) {
      case 'Taste':
        return <BurgerIcon color={colors.gray[700]} {...(_icon as SvgProps)} />;
      case 'Accuracy':
        return <CheckSquareIcon size={5} color={colors.gray[700]} {..._icon} />;
      case 'Speed':
        return <ClockStopWatchIcon size={5} color={colors.gray[700]} {..._icon} />;
      case 'Friendliness':
        return <FaceSmileIcon size={5} color={colors.gray[700]} {..._icon} />;
      case 'Cleanliness':
        return <CleanIcon size={5} color={colors.gray[700]} {..._icon} />;
      default:
        return null;
    }
  };

  return (
    <HStack space={0.5} height="40px" {..._container} width="full">
      {map(sortedGoals, goalType => {
        const isActive = includes(goals, goalType.id);
        const goalIcon = getIconForGoal(goalType.title);

        return (
          <Pressable
            key={`weekly-goals[${goalType.id}]`}
            flex={1}
            rounded="lg"
            h="full"
            disabled={disabled}
            onPress={handlePressGoal(goalType)}
          >
            {({ isHovered }) => (
              <VStack
                flex={1}
                alignItems="center"
                justifyContent="center"
                rounded="lg"
                py={1}
                h="full"
                borderWidth={isGoalHistory ? 1 : 2}
                bg={isGoalHistory && isActive ? 'gray.200' : isActive || isHovered ? 'primary.200' : 'white'}
                borderColor={isGoalHistory && isActive ? 'gray.700' : isActive || isHovered ? 'primary.500' : 'gray.200'}
              >
                <HStack alignItems="center" space={1}>
                  {isGoalHistory && goalIcon}
                  {showTitle && (
                    <Text size="sm" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" {..._caption}>
                      {translate(goalType?.title, goalType?.translations)}
                    </Text>
                  )}
                </HStack>

                {!isGoalHistory && (
                  <HStack space={1} alignItems="center">
                    {goalIcon}
                    <Text size="md" fontWeight={700} color="black" lineHeight="xs" {..._score}>
                      {`${goalType.score ?? 0}%`}
                    </Text>
                  </HStack>
                )}
              </VStack>
            )}
          </Pressable>
        );
      })}
    </HStack>
  );
};
