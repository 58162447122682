import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, CheckIcon, HStack, IconButton, Pressable, Switch } from 'native-base';
import { startCase, toLower } from 'lodash';

import { FileAttachmentIcon, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AddUpdateCleaningTaskAssignment } from '@pimm/services/lib/sms-workforce/services';
import { formatTo, formatToISOString, formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import {
  AddUpdateTaskAssignmentRequest,
  CleaningScheduleTypeEnum,
  CleaningTaskStatusEnum,
  Employee,
} from '@pimm/services/lib/sms-workforce/types';
import { DateTimePopover, useSiteTime } from '@app/features/store-core';

import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useCleaningAssignments } from '../context';
import { CleaningTaskAssignee, CleaningTaskItem } from '../reducers';
import { PressableSelect } from './pressable-select';
import { ConfirmIncompleteTask } from './confirm-incomplete-task';
import { SelectEmployee } from './select-employee';
import { ScheduleTimePicker } from './schedule-time-picker';

type CleaningTasksTableItemProps = {
  scheduleDate: Date;
  scheduleType: CleaningScheduleTypeEnum;
  task: CleaningTaskItem;
  isEditable?: boolean;
  onOpenLinkDocument?: (task: CleaningTaskItem) => void;
};

export const CleaningTasksTableItem = ({ isEditable = false, scheduleDate, scheduleType, task, ...props }: CleaningTasksTableItemProps) => {
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const { siteId, updateCleaningTask } = useCleaningAssignments();
  const modalEmployee = useModalFocus<CleaningTaskItem>();
  const modalConfirm = useModalFocus();
  const modalScheduleTime = useModalFocus();
  const [assignee, setAssignee] = useState<CleaningTaskAssignee | undefined>(task.assignee);

  const currentWeek = siteTime.toStartEndOfWeek(scheduleDate);
  const employeeName = startCase(toLower([assignee?.firstName, assignee?.lastName].filter(Boolean).join(' ')));
  const isDailyCleaning = scheduleType === CleaningScheduleTypeEnum.Daily;
  const isCompleted = assignee?.status === CleaningTaskStatusEnum.Complete;
  const isNotApplicable = assignee?.status === CleaningTaskStatusEnum.NotApplicable;

  const addUpdateCleaningTask = useMutation({
    mutationFn: async (payload: AddUpdateTaskAssignmentRequest) => {
      const result = await AddUpdateCleaningTaskAssignment({ ...payload, cleaningTaskId: task.id, siteId: siteId });
      return result;
    },
  });

  const submitChanges = async (payload: CleaningTaskAssignee) => {
    const statusDateISO = payload?.statusDate ? formatToISOString(payload?.statusDate) : formatToISOString(currentWeek.startDate);
    const dueDateISO = payload?.dueDate ? formatToISOString(payload?.dueDate) : formatToISOString(scheduleDate);

    const result = await addUpdateCleaningTask.mutateAsync({
      id: payload?.id,
      cleaningTaskId: task.id,
      cleaningTaskStatus: payload?.status,
      employeeId: payload.employeeId,
      statusDate: statusDateISO,
      dueDate: dueDateISO,
      scheduleType: scheduleType,
      siteId: siteId,
    });

    updateCleaningTask({
      id: task.id,
      assignee: {
        ...payload,
        id: result.id,
        dueDate: stringToDateLocal(result.dueDate),
        status: result.cleaningTaskStatus,
        statusDate: stringToDateLocal(result.statusDate),
      },
    });
  };

  const handleChangeAssignee = (employee?: Employee, date?: Date) => {
    modalEmployee.setHide();
    const nextAssignee = {
      ...assignee,
      employeeId: employee?.id,
      firstName: employee?.firstName,
      lastName: employee?.lastName,
      dueDate: isDailyCleaning ? assignee?.dueDate ?? scheduleDate : date,
    };

    setAssignee(nextAssignee);
    submitChanges(nextAssignee);
  };

  const handleDeleteAssignee = () => handleChangeAssignee();

  const handleCompletion = (complete?: boolean) => {
    if (!assignee) return;

    modalConfirm.setHide();

    const today = siteTime.today();
    const statusDate = complete ? today : undefined;
    const status = complete ? CleaningTaskStatusEnum.Complete : CleaningTaskStatusEnum.Incomplete;
    const nextAssignee = {
      ...assignee,
      status: status,
      statusDate,
    };

    setAssignee(nextAssignee);
    submitChanges(nextAssignee);
  };

  const handleToggleNotApplicable = (value: boolean) => {
    const dueDate = assignee?.dueDate ?? scheduleDate;
    const status = value ? CleaningTaskStatusEnum.NotApplicable : CleaningTaskStatusEnum.Incomplete;
    const nextAssignee = {
      id: assignee?.id,
      status: status,
      statusDate: dueDate,
    };
    setAssignee(nextAssignee);
    submitChanges(nextAssignee);
  };

  const handleChangeDueDate = (dueDate?: Date) => {
    modalScheduleTime.setHide();
    const nextAssignee = {
      ...assignee,
      dueDate,
      statusDate: scheduleType === 'Daily' ? dueDate : assignee?.statusDate,
    };
    setAssignee(nextAssignee);
    submitChanges(nextAssignee);
  };

  const handleDeleteDueDate = () => handleChangeDueDate();

  const handlePressLinkedDocument = () => {
    if (props.onOpenLinkDocument) props.onOpenLinkDocument(task);
  };

  useEffect(() => setAssignee(task.assignee), [task.assignee]);

  return (
    <>
      <HStack minH="56px" borderBottomWidth={1} backgroundColor={isCompleted ? 'success.50' : 'white'}>
        <Box alignItems="center" justifyContent="center" px={2} w="50px" h="full">
          {!isNotApplicable ? (
            <Pressable
              w={7}
              h={7}
              alignItems="center"
              justifyContent="center"
              borderWidth={1}
              borderColor={isCompleted ? 'success.600' : 'gray.300'}
              bg="white"
              rounded="md"
              _disabled={{ borderColor: isCompleted ? 'success.600' : 'gray.100' }}
              _hover={{ borderColor: isCompleted ? 'success.600' : 'gray.500' }}
              disabled={
                !isEditable ||
                !assignee?.employeeId ||
                assignee.status === CleaningTaskStatusEnum.NotApplicable ||
                !assignee.id ||
                !siteTime.isTimeBetweenWeekBlocks(scheduleDate)
              }
              onPress={() => (assignee?.status === CleaningTaskStatusEnum.Incomplete ? handleCompletion(true) : modalConfirm.setOpen())}
            >
              {({ isHovered }) => {
                return (isHovered || isCompleted) && <CheckIcon size={6} color={isCompleted ? 'success.600' : 'gray.300'} />;
              }}
            </Pressable>
          ) : (
            <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs" numberOfLines={2}>
              N/A
            </Text>
          )}
        </Box>
        <HStack alignItems="center" justifyContent="space-between" space={3} py={1.5} px={2} pr={1} flex={1} h="full">
          <Box flex={1}>
            <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs" numberOfLines={2}>
              {translate(task.title, task.translations)}
            </Text>
            {!!task.positionTitle && (
              <Text size="md" fontWeight={400} color="gray.600" lineHeight="xs">
                {translate(task.positionTitle, task.positionTitleTranslations)}
              </Text>
            )}
            {assignee?.status === CleaningTaskStatusEnum.Complete && !!assignee?.statusDate && (
              <Text size="md" fontWeight={600} color="black" lineHeight="xs" mt={1}>
                {assignee?.statusDate ? formatTo(assignee.statusDate, 'ddd, MMM DD hh:mm A') : '-'}
              </Text>
            )}
          </Box>

          <IconButton
            py={1.5}
            px={1}
            isDisabled={!task.linkedDoc}
            _disabled={{ _icon: { color: 'gray.400' } }}
            icon={<FileAttachmentIcon size="sm" />}
            onPress={handlePressLinkedDocument}
          />
        </HStack>

        <Box justifyContent="center" px={2} w="20%" maxWidth="140px" h="full">
          {isCompleted && (
            <Text size="md" fontWeight={600} color="black" lineHeight="xs">
              {isDailyCleaning ? formatToTimeOnly(assignee?.dueDate ?? scheduleDate) : formatTo(assignee?.dueDate, 'ddd, MMM DD')}
            </Text>
          )}
          {!isCompleted && isDailyCleaning && (
            <PressableSelect
              hideClear
              _container={{ opacity: isNotApplicable ? 0.3 : 1 }}
              _text={isNotApplicable ? { color: 'gray.600', fontWeight: 400 } : undefined}
              isDisabled={!isEditable || isNotApplicable}
              isReadOnly={!isEditable || isCompleted || isNotApplicable}
              placeholder={isNotApplicable ? '' : undefined}
              value={formatToTimeOnly(assignee?.dueDate ?? scheduleDate) ?? ''}
              onPress={modalScheduleTime.setOpen}
            />
          )}
          {!isCompleted && !isDailyCleaning && (
            <DateTimePopover
              selectedDate={scheduleDate}
              onChange={handleChangeDueDate}
              min={currentWeek.startDate}
              max={currentWeek.endDate}
            >
              <PressableSelect
                hideClear={isCompleted}
                _container={{ opacity: isNotApplicable ? 0.3 : 1 }}
                isDisabled={!isEditable || isNotApplicable}
                isLoading={addUpdateCleaningTask.isLoading && !task.assignee?.dueDate && assignee?.dueDate !== task.assignee?.dueDate}
                isReadOnly={!isEditable || isCompleted || isNotApplicable}
                value={formatTo(assignee?.dueDate ?? scheduleDate, 'ddd, MMM DD') ?? ''}
                onClear={handleDeleteDueDate}
              />
            </DateTimePopover>
          )}
        </Box>
        {/* )} */}

        <Box justifyContent="center" px={2} w="25%" maxWidth="280px" h="full">
          {isCompleted ? (
            <Text size="md" fontWeight={600} color="black" lineHeight="xs">
              {employeeName}
            </Text>
          ) : (
            <PressableSelect
              hideClear={!isEditable || isCompleted}
              _container={{ opacity: isNotApplicable ? 0.3 : 1 }}
              isDisabled={!isEditable || isCompleted || isNotApplicable}
              isLoading={
                addUpdateCleaningTask.isLoading && !task.assignee?.employeeId && assignee?.employeeId !== task.assignee?.employeeId
              }
              isReadOnly={!isEditable || isCompleted || isNotApplicable}
              placeholder="Name"
              value={!isNotApplicable ? employeeName : undefined}
              onPress={modalEmployee.setOpen}
              onClear={handleDeleteAssignee}
            />
          )}
        </Box>
        <Box alignItems="center" justifyContent="center" w="70px" h="full">
          <Switch isChecked={isNotApplicable} isDisabled={!isEditable || isCompleted} onValueChange={handleToggleNotApplicable} />
        </Box>
      </HStack>

      <Modal
        hideClose
        isOpen={modalConfirm.isOpen}
        onClose={modalConfirm.setHide}
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
      >
        <ConfirmIncompleteTask onClose={modalConfirm.setHide} onConfirm={() => handleCompletion(false)} />
      </Modal>

      <Modal
        size="full"
        _content={{ rounded: 'xl', maxWidth: { md: 1080, xl: 1200 }, height: '95%', maxHeight: 900 }}
        isOpen={modalEmployee.isOpen}
        noPadding
        hideClose
      >
        <SelectEmployee
          assignee={assignee}
          scheduleDate={scheduleDate}
          scheduleType={scheduleType}
          taskId={task.id!}
          onChange={handleChangeAssignee}
          onClose={modalEmployee.setHide}
        />
      </Modal>

      <Modal size="md" _content={{ rounded: 'xl', maxWidth: 350 }} isOpen={modalScheduleTime.isOpen} hideClose noPadding>
        <ScheduleTimePicker
          displayText={task.title}
          displaySubText={task.positionTitle}
          scheduledDate={assignee?.dueDate ?? scheduleDate}
          onApply={handleChangeDueDate}
          onCancel={modalScheduleTime.setHide}
        />
      </Modal>
    </>
  );
};
