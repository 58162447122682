import { useMutation } from 'react-query';
import { Box, VStack } from 'native-base';
import { startCase, toLower } from 'lodash';

import { Button, Text } from '@pimm/base';
import { ValidatePinRequest } from '@pimm/services/lib/sms-tenants';
import { ValidatePin } from '@pimm/services/lib/sms-tenants/services';
import { HubStatDto } from '@pimm/services/lib/sms-workforce';
import { useSiteConfig } from '@app/features/store-core';
import { PinCode } from '@app/components/shared';
import { PinSetupModalType } from './learning-hub-preview';

type FormPasscodeProps = {
  team?: HubStatDto;
  learningPathUrl?: string;
  onClose: () => void;
  onOpenModal: (modalName: PinSetupModalType, url?: string) => () => void;
};

export const FormPasscode = ({ learningPathUrl, team, ...props }: FormPasscodeProps) => {
  const { siteConfig } = useSiteConfig();

  const validatePin = useMutation({
    mutationFn: ValidatePin,
    onSuccess: () => {
      window.open(learningPathUrl, '_blank', 'noreferrer');
      props.onClose();
    },
  });

  const handleValidatePin = async (pincode?: string): Promise<void> => {
    const payload: ValidatePinRequest = {
      userId: team?.id!,
      siteId: siteConfig.id!,
      pin: pincode ?? '',
    };

    await validatePin.mutateAsync(payload);
  };

  return (
    <Box p={6}>
      <PinCode
        pinHeaderComponent={
          <VStack w="full">
            <Text size="xl" color="gray.900" fontWeight={700}>
              PIN number required
            </Text>
            <Text size="md" color="black" fontWeight={500}>
              Please enter the PIN number for:
            </Text>
            <Text size="lg" color="gray.900" fontWeight={700}>
              {startCase(toLower([team?.firstName, team?.lastName].filter(Boolean).join(' ')))}
            </Text>
          </VStack>
        }
        pinFootNote={
          <Button
            variant="unstyled"
            borderWidth={0}
            onPress={props.onOpenModal('pin-setup', learningPathUrl)}
            _text={{ color: 'primary.600', fontWeight: 500 }}
          >
            Reset PIN code
          </Button>
        }
        onPressCancel={props.onClose}
        onPressOk={handleValidatePin}
        okLabel="Validate"
      />
    </Box>
  );
};

export default FormPasscode;
