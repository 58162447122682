import { useMutation } from 'react-query';
import { Box, Divider, HStack, VStack } from 'native-base';

import { Button, ControlInput, Text } from '@pimm/base';
import { ChangePassword } from '@pimm/services/lib/sms-tenants/services';
import { ChangePasswordRequest, PasswordResetDto } from '@pimm/services/lib/sms-tenants';
import { useFormPasswordUpdate } from '../hooks/form-password-update.hook';
import { ListItem } from './list-item';

type FormPasswordUpdateProps = {
  userId: string;
  onCancel?: () => void;
  onEndChange: (payload?: PasswordResetDto) => void;
};

export const FormPasswordUpdate = ({ userId, ...props }: FormPasswordUpdateProps) => {
  const form = useFormPasswordUpdate();

  const changePassword = useMutation({
    mutationFn: ChangePassword,
    onSuccess: data => props.onEndChange(data),
    onError: (error: { code: number; message: string }) => {
      // Reset input fields but keep the error message
      form.resetField('newPassword');
      form.resetField('confirmPassword');

      if (error.code === 400) {
        form.setError('currentPassword', {
          type: 'custom',
          message: 'You have entered an invalid password',
        });
      } else {
        form.setError('root', {
          type: 'custom',
          message: 'Sorry, something went wrong there. Please try again',
        });
      }
    },
  });

  const handleUpdatePassword = () => {
    const { currentPassword, newPassword } = form.getValues();
    const payload: ChangePasswordRequest = {
      userId: userId,
      newPassword: btoa(newPassword),
      oldPassword: btoa(currentPassword),
    };
    changePassword.mutate(payload);
  };

  return (
    <VStack height="full" w="full">
      <HStack alignItems="center" px={4} h="52px" borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color="gray.900">
          Password Update
        </Text>
      </HStack>

      <VStack space={4} p={4}>
        <Box pb={2}>
          <Text fontWeight={700} size="md" color="gray.700" lineHeight="xs">
            Please enter your current password to change your password.
          </Text>
        </Box>

        {/* Current Password */}
        <ListItem _title={{ w: '1/3', h: 9, justifyContent: 'center' }} text="Current password">
          <ControlInput control={form.control} name="currentPassword" type="password" />
        </ListItem>

        <Divider bg="gray.200" />

        {/* New Password */}
        <ListItem _title={{ w: '1/3', h: 9, justifyContent: 'center' }} text="New password">
          <ControlInput control={form.control} name="newPassword" type="password" />
          <Text fontWeight={500} size="md" color="gray.600" lineHeight="xs" mt={2}>
            Your new password must be more than 8 characters and consist of an upper case, a lower case, a special character and a number.
          </Text>
        </ListItem>

        <Divider bg="gray.200" />

        {/* Confirm New Password */}
        <ListItem _title={{ w: '1/3', h: 9, justifyContent: 'center' }} text="Confirm New Password">
          <ControlInput control={form.control} name="confirmPassword" type="password" />
        </ListItem>
      </VStack>

      <HStack alignItems="center" py={3} px={4} borderTopWidth={1}>
        <Box flex={1} pr={1}>
          {!!form.formState.errors.root && (
            <Text size="md" fontWeight={500} color="error.500" lineHeight="xs" numberOfLines={2}>
              {form.formState.errors.root.message}
            </Text>
          )}
        </Box>

        <HStack space={3} justifyContent="flex-end">
          <Button variant="unstyled" minWidth={120} disabled={changePassword.isLoading} onPress={props.onCancel}>
            Cancel
          </Button>

          <Button minW={120} isLoading={changePassword.isLoading} onPress={form.handleSubmit(handleUpdatePassword)}>
            Update Password
          </Button>
        </HStack>
      </HStack>
    </VStack>
  );
};
