import React from 'react';
import { Pressable, HStack, Text, Popover, ChevronDownIcon, Spacer, VStack } from 'native-base';

import { CurrencyDollarCircle } from '@app/features/flow-chart/icons';
import { SalesVolumeProfile } from '@pimm/services/lib/sms-tenants';
import { NumberText } from '@pimm/base';

type VolumeSelectionProps = {
  isOpenVolumeDropdown: {
    value: boolean;
    setFalse: () => void;
    setTrue: () => void;
  };
  selectedCategory?: string;
  opsPhase?: { phase?: string };
  data?: SalesVolumeProfile[];
  handleVolumeSelection: (volumeId: number | undefined) => void;
};

export const VolumeSelection = ({
  isOpenVolumeDropdown,
  selectedCategory,
  opsPhase,
  data,
  handleVolumeSelection,
  ...props
}: VolumeSelectionProps) => {
  return (
    <Popover
      isKeyboardDismissable
      offset={5}
      isOpen={isOpenVolumeDropdown.value}
      onClose={isOpenVolumeDropdown.setFalse}
      placement="bottom right"
      trigger={triggerProps => (
        <Pressable
          {...triggerProps}
          onPress={isOpenVolumeDropdown.setTrue}
          rounded="lg"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          h={9}
          minW={120}
          w={{ xl: 160 }}
          borderWidth={1}
          borderColor="gray.300"
        >
          <HStack space={2} alignItems="center">
            <CurrencyDollarCircle color="primary.600" size="sm" />
            <Text fontWeight={500} color="black" fontSize="lg">
              {selectedCategory || 'Select Category'}
            </Text>
          </HStack>
          <Spacer />
          <ChevronDownIcon color="gray.700" size="xs" />
        </Pressable>
      )}
    >
      <Popover.Content accessibilityLabel="Sales Volume Profile Selector" shadow="1">
        <Popover.Body p={0} w="auto" minWidth="240px" borderColor="gray.100">
          <VStack px={3} py={2} borderBottomWidth={1} borderBottomColor="gray.200">
            <Text fontWeight={600} color="gray.700" textAlign="center" fontSize="md">
              Select a Sales Volume for:
            </Text>
            <Text fontWeight={600} color="black" textAlign="center" fontSize="md">
              {opsPhase?.phase || 'Phase Not Available'}
            </Text>
          </VStack>
          <VStack py={1}>
            {data?.map((volume, index) => (
              <Pressable key={index} onPress={() => handleVolumeSelection(volume.id)}>
                {({ isHovered }) => (
                  <HStack alignItems="center" py={2} px={4} bg={isHovered ? 'gray.50' : undefined} justifyContent="space-between">
                    <Text fontWeight={500} color="gray.700" textTransform="capitalize" fontSize="md">
                      {volume.category}
                    </Text>
                    <NumberText size="sm" fontWeight={400} color="gray.500" prefix="$" value={volume?.minimumAmount} />
                  </HStack>
                )}
              </Pressable>
            ))}
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
