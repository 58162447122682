import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { HStack, VStack, View, IconButton } from 'native-base';
import { isEmpty, map } from 'lodash';

import { ButtonGroup, LightBulbIcon, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { FlowChartAggDto, OpsPhaseProfileDto, OpsTaskAssignmentDto, ShiftDto } from '@pimm/services/lib/sms-workforce';
import { formatToShortTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { MainContainer } from '@app/components/core';
import { SignalRContext, SignalRData, SignalREffectKey } from '@app/features/signalr/context';
import { useSiteConfig, useSiteTime } from '@app/features/store-core';
import { ModuleTitle } from '@app/features/app';
import { useGetClientId } from '@app/features/app/hooks';
import { MainScreenProps } from '@app/navigations/root';
import {
  FlowChartChecklist,
  FlowChartChecklistProvider,
  GetOpsTasksFlowChartAggLiveParams,
  SelectConfirmSalesVolumeProfile,
  SelectSalesVolumeProfiles,
  useGetBrandSalesVolumeProfiles,
  useGetOpsTasksFlowChartAggLive,
} from '@app/features/flow-chart';
import { ResourceLoader } from '@app/components/shared';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { useModalFocus } from '@app/hooks/modal-focus.hook';

export type FlowChartScreenProps = MainScreenProps<'FlowChart'>;

export const FlowChartScreen: React.FC<FlowChartScreenProps> = ({ navigation, route }) => {
  const queryClient = useQueryClient();
  const xClientId = useGetClientId();
  const { translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const modalConfirm = useModalFocus<number>();
  const [flowChartAgg, setFlowChartAggParams] = useGetOpsTasksFlowChartAggLive();
  const [salesVolumeProfiles, setSalesVolumeProfilesParams] = useGetBrandSalesVolumeProfiles();
  const [opsPhaseProfiles, setOpsPhaseProfiles] = useState<OpsPhaseProfileDto[]>([]);
  const [opsPhaseFocusId, setOpsPhaseFocusId] = useState<number | undefined>(
    route.params?.opsPhaseId ? Number(route.params.opsPhaseId) : undefined,
  );

  const activeOpsPhaseId = opsPhaseFocusId || flowChartAgg.data?.flowChartData?.opsPhaseId;
  const startDateTime = stringToDateLocal(flowChartAgg.data?.flowChartData?.startDateTime);
  const isDisabled = !startDateTime || siteTime.today() < new Date(startDateTime.getTime() - 60 * 60 * 1000);

  SignalRContext.useSignalREffect(
    SignalREffectKey,
    jsonString => {
      const queryCache = queryClient.getQueryCache();
      const message: SignalRData<
        OpsTaskAssignmentDto & { opsTaskAssignment?: { opsPhaseId?: number; siteId?: string }; opsTaskId?: string }
      > = JSON.parse(jsonString);
      const _data = message.data.data;

      if (!xClientId || message.data.clientId === xClientId) return;

      if (message.moduleName === 'OpsPlan') {
        const opsTaskAssignment = _data.opsTaskAssignment;

        if (message.actionName === 'ProfileChanged') {
          queryClient.invalidateQueries({ queryKey: ['GetOpsTasksFlowChartAggLive'] });
          flowChartAgg.refetch();
          return;
        }

        const cachedGetOpsTasksFlowChartAggLive = queryCache.getAll().find(cache => {
          const params = cache.queryKey[1] as GetOpsTasksFlowChartAggLiveParams;
          if (cache.queryKey.includes('GetOpsTasksFlowChartAggLive') && params.siteId === (_data.siteId || opsTaskAssignment?.siteId)) {
            const _queryData = queryClient.getQueryData<FlowChartAggDto>(cache.queryKey);
            return _queryData?.flowChartData?.opsPhaseId === _data.opsPhaseId || opsTaskAssignment?.opsPhaseId;
          }
          return false;
        });

        if (cachedGetOpsTasksFlowChartAggLive) {
          const queryKey = cachedGetOpsTasksFlowChartAggLive?.queryKey;
          const isDeleteRequest = ['DeleteOpsTaskAssignmentRequestHandler', 'DeleteOpsTaskTrackerRequestHandler'].includes(
            message.data.origin,
          );

          queryClient.setQueryData<FlowChartAggDto>(queryKey, (_flowChartAgg): FlowChartAggDto => {
            const opsTaskAssigment = message.data.data;
            const opsTaskId = message.data.data.opsTaskId;
            const positions = [...(_flowChartAgg?.flowChartData?.positions ?? [])];
            const index = positions?.findIndex(_ => _.positionId === _data.positionId || _.opsTasks?.some(t => t.opsTaskId === opsTaskId));

            if (!isEmpty(positions) && index >= 0) {
              if (message.actionName === 'AssignmentChanged') {
                let shift: ShiftDto | undefined;

                if (message.data.origin === 'AddUpdateOpsTaskAssignmentRequestHandler') {
                  shift = _flowChartAgg?.eligibleEmployees?.find(_ => !isDeleteRequest && _.employee?.id === opsTaskAssigment.employeeId);
                }

                // position assignment
                positions[index] = {
                  ...positions[index],
                  opsTaskAssignmentId: !isDeleteRequest ? _data.id : undefined,
                  employeeId: !isDeleteRequest ? _data.employeeId : undefined,
                  lastName: shift?.employee?.lastName,
                  firstName: shift?.employee?.firstName,
                  opsTasks: positions[index].opsTasks?.map(_opsTask => {
                    return {
                      ..._opsTask,
                      isComplete: isDeleteRequest ? false : _opsTask.isComplete,
                      opsTaskTrackerId: isDeleteRequest ? undefined : _opsTask.opsTaskTrackerId,
                    };
                  }),
                };
              } else if (message.actionName === 'TaskStatusChanged') {
                const _opsTaskTrackers = message.data.data.opsTaskTrackers ?? [];

                // completion of position task
                positions[index] = {
                  ...positions[index],
                  opsTasks: positions[index].opsTasks?.map(_opsTask => {
                    const _tracker = _opsTaskTrackers.find(_ => _.opsTaskId === _opsTask.opsTaskId);

                    if (_tracker || (isDeleteRequest && _opsTask.opsTaskId === opsTaskId)) {
                      return {
                        ..._opsTask,
                        isComplete: !!_tracker?.id,
                        opsTaskTrackerId: _tracker?.id,
                      };
                    }
                    return _opsTask;
                  }),
                };
              }
            }

            return {
              ..._flowChartAgg,
              flowChartData: {
                ..._flowChartAgg?.flowChartData,
                positions: positions,
              },
            };
          });
        }
      }
    },
    [],
  );

  const handleConfirmRefetch = () => {
    queryClient.invalidateQueries({ queryKey: ['GetOpsTasksFlowChartAggLive'] });
    flowChartAgg.refetch();
  };

  const handleChangeFocusOpsPhaseId = (opsPhaseId: number) => {
    // Remember the last known selected opsPhaseId
    navigation.setParams({ ...route.params, opsPhaseId: opsPhaseId });
    setOpsPhaseFocusId(opsPhaseId);
  };

  useEffect(() => {
    if (siteConfig) {
      setSalesVolumeProfilesParams({
        brandId: siteConfig.companyInfo?.brand?.id,
        marketId: siteConfig.config?.marketId,
      });
    }
  }, [siteConfig]);

  useEffect(() => {
    if (siteConfig.id) {
      setFlowChartAggParams({
        opsPhaseId: opsPhaseFocusId,
        siteId: siteConfig.id,
      });
    }
  }, [opsPhaseFocusId, siteConfig?.id]);

  useEffect(() => {
    if (flowChartAgg.data?.opsPhaseProfiles?.length) {
      setOpsPhaseProfiles(flowChartAgg.data.opsPhaseProfiles);
    }
  }, [flowChartAgg.data?.opsPhaseProfiles]);

  return (
    <MainContainer>
      <VStack w="full" h="full">
        <HStack px={4} height={60} alignItems="center" borderWidth={1}>
          <HStack flex={1} alignItems="center">
            <ModuleTitle name={route.name} defaultText="Flow Chart" />
            <ModalAppDocuments
              identifierName="Module.OpsTasks"
              moduleType="Ops Tasks"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>

          {!isEmpty(opsPhaseProfiles) && (
            <ButtonGroup value={activeOpsPhaseId} onChange={handleChangeFocusOpsPhaseId}>
              {map(opsPhaseProfiles, opsPhase => {
                const isActive = opsPhase.opsPhaseId === activeOpsPhaseId;
                const startTime = stringToDateLocal(opsPhase.rangeStartTime);
                return (
                  <ButtonGroup.Item key={opsPhase.opsPhaseId} value={opsPhase.opsPhaseId}>
                    <View flexDirection={{ md: 'column', xl: 'row' }} alignItems="center">
                      <Text
                        color={isActive ? 'black' : 'gray.500'}
                        fontWeight={isActive ? 700 : 600}
                        size={{ md: 'sm', xl: 'md' }}
                        lineHeight="xs"
                      >
                        {translate(opsPhase.phase, opsPhase?.translations)}
                      </Text>
                      <View
                        px={{ xl: 1 }}
                        ml={{ xl: 1 }}
                        borderWidth={{ xl: 1 }}
                        borderRadius={4}
                        borderColor={isActive ? 'black' : 'gray.300'}
                        bg={{ xl: isActive ? 'black' : 'gray.200' }}
                      >
                        <Text
                          size="xs"
                          fontWeight={600}
                          color={{ xl: isActive ? 'white' : 'gray.500', base: isActive ? 'gray.600' : 'gray.500' }}
                        >
                          {formatToShortTimeOnly(startTime)}
                        </Text>
                      </View>
                    </View>
                  </ButtonGroup.Item>
                );
              })}
            </ButtonGroup>
          )}

          <HStack flex={1} space={3} alignItems="center" justifyContent="flex-end">
            {salesVolumeProfiles.isSuccess && !!flowChartAgg.data?.flowChartData && (
              <SelectSalesVolumeProfiles
                opsPhaseTitle={flowChartAgg.data?.flowChartData?.opsPhase?.phase}
                salesVolumeProfileId={flowChartAgg.data?.flowChartData?.salesVolumeProfileId}
                salesVolumeProfiles={salesVolumeProfiles}
                onChange={modalConfirm.setOpen}
              />
            )}
          </HStack>
        </HStack>
        <View flex={1}>
          <ResourceLoader h="full" w="full" isLoading={flowChartAgg.isIdle || flowChartAgg.isLoading}>
            {!!siteConfig.id && (
              <FlowChartChecklistProvider opsTaskFlowChartAgg={flowChartAgg} siteId={siteConfig.id}>
                <FlowChartChecklist isDisabled={isDisabled} />
              </FlowChartChecklistProvider>
            )}
          </ResourceLoader>
        </View>
      </VStack>

      <Modal
        hideClose
        isOpen={modalConfirm.isOpen}
        onClose={modalConfirm.setHide}
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
      >
        <SelectConfirmSalesVolumeProfile
          opsPhaseId={flowChartAgg.data?.flowChartData?.opsPhaseId}
          salesVolumeProfileId={modalConfirm.payload}
          siteId={siteConfig.id}
          confirmRefetch={handleConfirmRefetch}
          onClose={modalConfirm.setHide}
        />
      </Modal>
    </MainContainer>
  );
};
