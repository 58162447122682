import { VStack, HStack, IconButton, ChevronDownIcon, ChevronUpIcon, Box } from 'native-base';
import moment from 'moment';

import { ButtonGroup, Text } from '@pimm/base';
import { padStart } from 'lodash';

type TimePickerProps = {
  _container?: React.ComponentProps<typeof HStack>;
  defaultValue?: Date;
  disabled?: boolean;
  isError?: boolean;
  value?: Date;
  onChange?: (value: Date) => void;
};

export const TimePicker = ({ _container, defaultValue, disabled, isError = false, value, ...props }: TimePickerProps) => {
  const timeValue = value ?? defaultValue;
  const inputDate = moment(timeValue).format('YYYY-MM-DD');
  const inputTime = moment(timeValue).format('HH:mm');
  const hours = Number(inputTime?.slice(0, 2));
  const minutes = Number(inputTime.slice(3, 5));
  const meridiem = hours >= 12 && hours < 24 ? 'PM' : 'AM';


  const handleChangeTime = (hrs: number, mins: number) => {
    if (props.onChange) {
      const nextDate = moment(inputDate).set({ h: hrs, m: mins, s: 0 }).toDate();
      props.onChange(nextDate);
    }
  };

  const handleChangeHours = (x: number) => () => {
    let adjustedHours = hours + x;
    if (adjustedHours > 23) adjustedHours = 0; // Wrap from 23 to 00
    else if (adjustedHours < 0) adjustedHours = 23; // Wrap from 00 to 23
    handleChangeTime(adjustedHours, minutes);
  };

  const handleChangeMinutes = (increment: boolean) => () => {
    let mins = minutes;
    if (increment) {
      // Check if minutes are at 45 to wrap to 0, otherwise increment to next quarter
      mins = mins >= 45 ? 0 : (Math.floor(mins / 15) + 1) * 15;
    } else {
      // If minutes are 0, wrap back to 45, otherwise decrement to previous quarter
      mins = mins === 0 ? 45 : Math.floor((mins - 1) / 15) * 15;
    }
    handleChangeTime(Number(hours), mins);
  };

  const handleChangeMeridiem = (m: string) => {
    if (meridiem !== m) {
      const hrs = m === 'AM' ? -12 : 12;
      handleChangeTime(Number(hours) + hrs, Number(minutes));
    }
  };

  return (
    <HStack space={3} alignItems="center" justifyContent="space-between" {..._container}>
      <HStack flex={1} alignItems="center" justifyContent="space-between" maxWidth={110}>
        {/* Hours */}
        <VStack space={1} alignItems="center">
          <IconButton
            borderColor="gray.300"
            borderWidth={1}
            width={10}
            height={10}
            borderRadius="lg"
            isDisabled={disabled}
            icon={<ChevronUpIcon size={4} color="gray.700" />}
            _hover={{ backgroundColor: 'gray.100' }}
            onPress={handleChangeHours(1)}
          />
          <Text size="xl" lineHeight="3xl" color={disabled ? 'gray.400' : 'black'} fontWeight={700}>
            {padStart(`${hours % 12 || 12}`, 2, '0') ?? '00'}
          </Text>
          <IconButton
            borderColor="gray.300"
            borderWidth={1}
            width={10}
            height={10}
            borderRadius="lg"
            isDisabled={disabled}
            icon={<ChevronDownIcon size={4} color="gray.700" />}
            _hover={{ backgroundColor: 'gray.100' }}
            onPress={handleChangeHours(-1)}
          />
        </VStack>

        <Box>
          <Text size="7xl" color={disabled ? 'gray.400' : 'black'} lineHeight="xs">
            :
          </Text>
        </Box>

        {/* Minutes */}
        <VStack space={1} alignItems="center">
          <IconButton
            borderColor="gray.300"
            borderWidth={1}
            width={10}
            height={10}
            borderRadius="lg"
            isDisabled={disabled}
            icon={<ChevronUpIcon size={4} color="gray.700" />}
            _hover={{ bg: 'gray.100' }}
            onPress={handleChangeMinutes(true)}
          />
          <Text size="xl" lineHeight="3xl" color={disabled ? 'gray.400' : 'black'} fontWeight={700}>
            {padStart(`${minutes}`, 2, '0') ?? '00'}
          </Text>
          <IconButton
            borderColor="gray.300"
            borderWidth={1}
            width={10}
            height={10}
            borderRadius="lg"
            isDisabled={disabled}
            icon={<ChevronDownIcon size={4} color="gray.700" />}
            _hover={{ bg: 'gray.100' }}
            onPress={handleChangeMinutes(false)}
          />
        </VStack>
      </HStack>

      {/* Meridiem */}
      <ButtonGroup flex={1} maxW={120} isDisabled={disabled} onChange={handleChangeMeridiem} value={meridiem}>
        <ButtonGroup.Item flex={1} value="AM" _text={{ color: disabled ? 'gray.500' : 'black' }}>
          AM
        </ButtonGroup.Item>
        <ButtonGroup.Item flex={1} value="PM" _text={{ color: disabled ? 'gray.500' : 'black' }}>
          PM
        </ButtonGroup.Item>
      </ButtonGroup>
    </HStack>
  );
};

export default TimePicker;
