import { ComponentProps, useEffect, useState } from 'react';
import { Menu, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu';
import { useBoolean } from 'usehooks-ts';
import { useTheme } from 'native-base';

import { CalendarPicker, CalendarPickerProps } from './calendar-picker';
import { Button } from '@pimm/base';

const { Popover } = renderers;

type PopoverMenuCalendarProps = ComponentProps<typeof Menu> &
  Pick<CalendarPickerProps, 'max' | 'min' | 'mode' | 'weekday'> & {
    selectedDate?: Date;
    trigger: (triggerProps: { onPress: () => void }) => React.ReactNode;
    onChange?: (date?: Date, endDate?: Date) => void;
  };

export const PopoverMenuCalendar = ({ max, min, mode, weekday, selectedDate, trigger, ...props }: PopoverMenuCalendarProps) => {
  const { colors } = useTheme();
  const isOpen = useBoolean();

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const handleChange = () => {
    if (props.onChange) props.onChange(selectedDates[0], selectedDates[1]);
    isOpen.setFalse();
  };

  const handleDateChange = (date: Date, endDate) => {
    setSelectedDates([date, endDate].filter(Date));
  };

  useEffect(() => {
    setSelectedDates([selectedDate ?? new Date()]);
  }, [selectedDate, isOpen.value]);

  return (
    <Menu
      renderer={Popover}
      opened={isOpen.value}
      rendererProps={{ anchorStyle: { display: 'none' }, preferredPlacement: 'bottom' }}
      {...props}
      onBackdropPress={isOpen.setFalse}
      onClose={isOpen.setFalse}
    >
      <MenuTrigger>{trigger({ onPress: isOpen.setTrue })}</MenuTrigger>
      <MenuOptions
        // For reference only
        // customStyles={{ optionsContainer: { marginTop: 0 } }}
        optionsContainerStyle={{
          shadowOpacity: 0.5,
          minWidth: 268,
          borderRadius: 6,
          backgroundColor: 'white',
          borderWidth: 1,
          borderColor: colors.gray[300],
          shadowColor: colors.gray[300],
          shadowOffset: { width: 0, height: 2 },
        }}
      >
        <CalendarPicker date={selectedDates[0]} max={max} min={min} mode={mode} weekday={weekday} onChange={handleDateChange} />
        <Button size="lg" rounded="none" minH={12} borderBottomRadius={6} onPress={handleChange}>
          Apply
        </Button>
      </MenuOptions>
    </Menu>
  );
};
