import { memo, useCallback } from 'react';
import { Box, HStack, VStack, useTheme } from 'native-base';
import { filter, isEqual } from 'lodash';

import { useAppLocale } from '@pimm/common';
import { Modal, Text } from '@pimm/base';
import { OpsTaskTrackerDto } from '@pimm/services/lib/sms-workforce';
import { formatToShortTimeOnly } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { FlowChartPosition } from '../reducers/flow-chart-checklist.reducer';
import { OpsPhasePositionAssignee } from '../types';
import { FlowTimeline } from './flow-timeline';
import { FlowPositionDetailed } from './flow-position-detailed';
import PressablePositionAssignee from './pressable-position-assignee';

type FlowPositionChecklistProps = {
  isDisabled?: boolean;
  opsPhaseId: number;
  position: FlowChartPosition;
  onAssign?: (position: FlowChartPosition) => void;
  onChange?: (position: Partial<FlowChartPosition>) => void;
  onCompleteTask?: (positionId: string, opsTaskTracker: OpsTaskTrackerDto) => void;
};

const FlowPositionChecklist = ({ isDisabled, opsPhaseId, position, ...props }: FlowPositionChecklistProps) => {
  const { translate } = useAppLocale();
  const { colors } = useTheme();
  const modalTask = useModalFocus<string>();

  const color = position.color ?? colors.gray[900];
  const numOfTasks = position?.tasks.length ?? 0;
  const numOfCompleted = filter(position.tasks, 'isComplete').length;
  const count = numOfTasks - numOfCompleted;

  const handleChange = (assignee?: OpsPhasePositionAssignee, isDelete?: boolean) => {
    if (props.onChange) {
      props.onChange({ positionId: position.positionId, opsTaskAssignmentId: assignee?.id, assignee: isDelete ? undefined : assignee });
    }
  };

  const handlePressAssign = () => {
    if (props.onAssign) props.onAssign(position);
  };

  const handlePressTask = useCallback((taskId: string) => modalTask.setOpen(taskId), [position]);

  const handlePressComplete = opsTaskTracker => {
    if (props.onCompleteTask) props.onCompleteTask(position.positionId, opsTaskTracker);
  };

  return (
    <>
      <Box w="full" h="full">
        <VStack rounded="xl" h="full" borderWidth={1} bg="gray.25">
          <VStack space={2} p={2.5} borderTopLeftRadius="xl" borderTopRightRadius="xl" bg="gray.100">
            {/* Position Title */}
            <HStack space={2} justifyContent="space-between">
              <VStack flex={1}>
                <Text size="2xl" color={color} fontWeight={700} mb={1} lineHeight="xs" textTransform="uppercase">
                  {translate(position.title, position?.translations)}
                </Text>

                <HStack>
                  {!!position.startTime && !!position.endTime && (
                    <Box flex="none" rounded="md" justifyContent="center" px={1} h={5} borderWidth={1} borderColor="gray.300" bg="white">
                      <Text size="sm" color="gray.700" fontWeight={600} lineHeight="sm" textAlign="center">
                        {`${formatToShortTimeOnly(position.startTime)} - ${formatToShortTimeOnly(position.endTime)}`}
                      </Text>
                    </Box>
                  )}
                </HStack>
              </VStack>

              <Box flex="none" rounded="lg" justifyContent="center" px={1.5} h={7} bg={color}>
                {count ? (
                  <Text size="2xl" color="white" fontWeight={700} lineHeight="xs">
                    {count}
                    <Text size="md" color="white" fontWeight={700} lineHeight="xs">
                      {' left'}
                    </Text>
                  </Text>
                ) : (
                  <Text size="xl" color="white" fontWeight={700} lineHeight="xs">
                    Completed
                  </Text>
                )}
              </Box>
            </HStack>

            {/* Assignee */}
            <Box minH={9}>
              <PressablePositionAssignee
                _container={{
                  borderWidth: !!position.assignee?.id ? 1 : 2,
                  borderColor: !position.assignee ? 'primary.400' : 'gray.400',
                }}
                assignee={position.assignee}
                opsPhaseId={opsPhaseId}
                positionId={position.positionId}
                primary={!position.assignee}
                onClear={handleChange}
                onPress={handlePressAssign}
              />
            </Box>
          </VStack>

          <Box flex={1} w="full">
            <FlowTimeline
              isDisabled={isDisabled || !position.assignee}
              opsTaskAssignmentId={position.opsTaskAssignmentId ?? position.assignee?.id}
              tasks={position.tasks}
              onPressTask={handlePressTask}
              onComplete={handlePressComplete}
            />
          </Box>
        </VStack>
      </Box>

      <Modal
        noPadding
        size="full"
        _content={{ rounded: 'xl', maxWidth: 1200, height: 800, maxHeight: '95%' }}
        isOpen={modalTask.isOpen}
        hideClose
      >
        {modalTask.payload && <FlowPositionDetailed position={position} taskId={modalTask.payload} onClose={modalTask.setHide} />}
      </Modal>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  const { opsPhaseId: prevOpsPhaseId, position: prevPosition } = prevProps;
  const { opsPhaseId, position } = nextProps;
  /* if the props are equal, it won't update*/
  return prevOpsPhaseId !== opsPhaseId && isEqual(prevPosition, position);
};

export default memo(FlowPositionChecklist, areEqual);
