import React, { useEffect, useState } from 'react';
import { HStack, View, IconButton } from 'native-base';
import { find, first, map, sortBy } from 'lodash';

import { ButtonGroup, LightBulbIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { ScreenLayout } from '@app/components/layout';
import { useSiteConfig } from '@app/features/store-core';
import { ModuleTitle, useAppModules } from '@app/features/app';
import { StoreRosters, StoreEmployeesProvider, StoreEmployeesConsumer, StoreHours, StoreUsers } from '@app/features/store-info';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { useGetUsersWithSiteKeys } from '@app/features/store-info/hooks';

type StoreSettingsTabIdentifier = 'settingsHours' | 'settingsRoster' | 'settingsUsers';

interface StoreSettingsTabItem {
  identifier: StoreSettingsTabIdentifier;
  seq?: number;
  title: string;
}

export type StoreInfoScreenProps = MainScreenProps<'StoreInfo'>;

export const StoreInfoScreen: React.FC<StoreInfoScreenProps> = ({ route }) => {
  const { locale, translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const { screens } = useAppModules();
  const [tabFocus, setTabFocus] = useState<StoreSettingsTabIdentifier>();
  const [tabItems, setTabItems] = useState<StoreSettingsTabItem[]>([]);
  const queryUsersWithSiteKeys = useGetUsersWithSiteKeys({ siteId: siteConfig.id, siteLevelOnly: true });

  useEffect(() => {
    const screen = find(screens, _ => _.name === route.name);
    const items = sortBy(
      [
        {
          seq: 1,
          identifier: 'settingsHours',
          title: 'Store Hours',
        },
        {
          seq: 2,
          identifier: 'settingsRoster',
          title: 'Roster',
        },
        {
          seq: 3,
          identifier: 'settingsUsers',
          title: 'Users',
        },
      ].map(item => {
        const subModule = find(screen?.subModules, _ => _.identifier === item.identifier);
        const title = translate(subModule?.title ?? item.title, subModule?.titleTranslations);
        return {
          ...item,
          seq: subModule?.seq,
          title: title,
        } as StoreSettingsTabItem;
      }),
      ['seq'],
    );

    setTabItems(items);
    // if tabFocus is undefined, select the first item in the array
    if (!tabFocus) setTabFocus(first(items)?.identifier);
  }, [locale, route.name, screens, siteConfig]);

  return (
    <MainContainer>
      <ScreenLayout
        title={
          <HStack flex={1} alignItems="center">
            <ModuleTitle name={route.name} defaultText="Store Settings" />
            <ModalAppDocuments
              identifierName="Module.StoreInfo"
              moduleType="Store Settings"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>
        }
        actionTool={
          <HStack space={2} justifyContent="flex-end">
            {tabItems.length > 1 && (
              <ButtonGroup key={locale} value={tabFocus} onChange={setTabFocus}>
                {map(tabItems, tab => (
                  <ButtonGroup.Item key={tab.identifier} alignItems="center" value={tab.identifier} minW={90}>
                    {tab.title}
                  </ButtonGroup.Item>
                ))}
              </ButtonGroup>
            )}
          </HStack>
        }
      >
        <View display="flex" flexDirection={{ md: 'column', xl: 'row' }} h="full" w="full">
          {tabFocus === 'settingsHours' && (
            <View key="settingsHours" flex={1} w="full" h="full">
              <StoreHours />
            </View>
          )}
          {!!siteConfig.id && tabFocus === 'settingsRoster' && (
            <View key="settingsRoster" flex={1} h="full" w="full">
              <StoreEmployeesProvider showAll siteId={siteConfig.id}>
                <StoreEmployeesConsumer>{({ queryEmployees }) => <StoreRosters queryEmployees={queryEmployees} />}</StoreEmployeesConsumer>
              </StoreEmployeesProvider>
            </View>
          )}
          {!!siteConfig.id && tabFocus === 'settingsUsers' && (
            <View key="settingsUsers" flex={1} h="full" w="full">
              <StoreUsers queryUsers={queryUsersWithSiteKeys} />
            </View>
          )}
          {/* {storeTabFocus === 'Contacts' && (
            <View key="storeContacts" flex={1} w="full" h="full">
              <StoreContactsProvider siteId={siteConfig.id}>
                <StoreContacts />
              </StoreContactsProvider>
            </View>
          )} */}
        </View>
      </ScreenLayout>
    </MainContainer>
  );
};
