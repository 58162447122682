import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Box, HStack, VStack, View, Image, IconButton, Spacer, Pressable, FlatList } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { filter, find, isEmpty, isEqual, map, sortBy } from 'lodash';

import { Button, Edit05Icon, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { WeeklyFocusCategoryDto, WeeklyFocusSettingDto } from '@pimm/services/lib/sms-positioning';
import { AddUpdateWeeklyFocusRequest, AggGoalTypeDto, WeeklyFocusDto } from '@pimm/services/lib/sms-workforce';
import { formatToStartEndOfWeek } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useSiteTime } from '@app/features/store-core';
import { useGetWeeklyFocus } from '../hooks';
import { Target05Icon } from '../icons';
import { GoalTypesSelection } from './goal-type-selection';
import { WeeklyFocusCategories } from './weekly-focus-categories';
import { WeeklyFocusVoc } from './weekly-focus-voc';

type WeeklyFocusItemListProps = {
  goalTypes?: AggGoalTypeDto[];
  isEditable?: boolean;
  siteId: string;
  week: ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeekBlock']>;
  weeklyFocus: ReturnType<typeof useGetWeeklyFocus>;
  weeklyFocusSettings: WeeklyFocusSettingDto;
  onChange: (payload: Partial<AddUpdateWeeklyFocusRequest>) => void;
};

export const WeeklyFocusItemList = ({
  isEditable,
  goalTypes,
  siteId,
  week,
  weeklyFocus,
  weeklyFocusSettings,
  ...props
}: WeeklyFocusItemListProps) => {
  const queryClient = useQueryClient();
  const { translate } = useAppLocale();
  const modalCategory = useModalFocus<{ categoryId?: number }>();
  const modalVOC = useModalFocus();
  const osat = find(goalTypes, ['seq', 0]);

  const [categories] = useState<WeeklyFocusCategoryDto[]>(sortBy(weeklyFocusSettings.categories, ['seq']));

  const handleAddGoalType = (goalTypeIds: number[]) => {
    props.onChange({ goalTypeIds });
    modalVOC.setHide();
  };

  const handleUpdateQueryData = (_weeklyFocus?: WeeklyFocusDto) => {
    modalCategory.setHide();
    modalVOC.setHide();

    if (_weeklyFocus) {
      const queryCache = queryClient.getQueryCache();
      const params: Parameters<typeof useGetWeeklyFocus>[0] = {
        siteId: siteId ?? weeklyFocus.data?.siteId,
        weekStartDate: week.startTime,
      };

      const cachedQueryWeeklyFocus = queryCache.getAll().find(cache => {
        return cache.queryKey.includes('GetWeeklyFocus') && isEqual(cache.queryKey[1], params);
      });

      if (cachedQueryWeeklyFocus?.queryKey) {
        queryClient.setQueryData<WeeklyFocusDto>(cachedQueryWeeklyFocus?.queryKey, (_prevData): WeeklyFocusDto => {
          return {
            ..._weeklyFocus,
            previousWeeklyFocus: isEmpty(_weeklyFocus.previousWeeklyFocus)
              ? weeklyFocus.data?.previousWeeklyFocus
              : _weeklyFocus.previousWeeklyFocus,
          };
        });
      }
    }
  };

  const weeklyFocusItem = useMemo(() => {
    const result = map(weeklyFocusSettings.categories, category => {
      const items = filter(weeklyFocus?.data?.focusItems, ['weeklyFocusCategoryId', category.id]);
      return {
        category: category,
        items: items,
      };
    });
    return result;
  }, [weeklyFocus?.data?.focusItems, weeklyFocusSettings.categories]);

  return (
    <View height="full">
      {/* SMG Surveys */}
      <HStack space={{ md: 2, xl: 4 }} alignItems="center" justifyContent="space-between" py={2} px={5} borderBottomWidth={1}>
        <HStack alignItems="center" justifyContent="space-between" py={2} px={3} rounded="2xl" bg="error.500">
          <Image source={{ uri: weeklyFocusSettings.headerImg }} style={{ width: 90, height: 100 }} resizeMode="contain" />
          <Box w={{ md: '280px', xl: '120px' }}>
            <HStack justifyContent="flex-start">
              <MaterialCommunityIcons name="format-quote-open" size={18} color="white" style={{ marginTop: -4 }} />
              <Text size="sm" fontWeight={500} lineHeight="2xs" color="white">
                {translate(weeklyFocusSettings?.headerImgCaption, weeklyFocusSettings?.headerImgCaptionTranslations)}
                <MaterialCommunityIcons name="format-quote-close" size={18} color="white" style={{ alignSelf: 'flex-end' }} />
              </Text>
            </HStack>
          </Box>
        </HStack>

        <VStack flex={1}>
          <HStack alignItems="center" space={2}>
            <VStack>
              <Text size="xl" fontWeight={700} lineHeight="xs" color="black">
                {translate(weeklyFocusSettings.subHeader, weeklyFocusSettings?.subHeaderTranslations)}
              </Text>
              <Text size="md" fontWeight={500} lineHeight="xs" color="gray.600">
                {translate(weeklyFocusSettings.subHeaderCaption, weeklyFocusSettings?.subHeaderCaptionTranslations)}
              </Text>
            </VStack>

            <Spacer />

            <HStack space={2} alignItems="center">
              <Text size="xl" fontWeight={700} color="blue.500" lineHeight="xs">
                {translate(osat?.title, osat?.translations)}
              </Text>
              <Text size="xl" fontWeight={700} color="blue.500" lineHeight="xs">
                {`${osat?.score ?? 0}%`}
              </Text>
            </HStack>

            {!!isEditable && (
              <IconButton
                variant="unstyled"
                bg="primary.500"
                p={0}
                w="30px"
                minH={7}
                borderRadius={6}
                icon={<Edit05Icon size="18px" color="black" />}
                onPress={modalVOC.setOpen}
              />
            )}
          </HStack>

          <GoalTypesSelection
            _container={{ space: 1, mt: 3, height: '50px' }}
            _score={{ size: 'lg' }}
            disabled
            goals={weeklyFocus.data?.goalTypeIds}
            goalTypes={goalTypes}
          />
        </VStack>
      </HStack>

      <FlatList
        flex={1}
        pb={3}
        bg="gray.50"
        data={categories}
        stickyHeaderIndices={[0]}
        extraData={[weeklyFocus.data?.focusItems]}
        ListHeaderComponent={
          <HStack alignItems="center" justifyContent="space-between" px={5} py={2} bg="gray.50">
            <VStack>
              <Text fontWeight={700} size="2xl" lineHeight="md" color="black" textTransform="uppercase">
                Weekly Goals
              </Text>
              {!!week && (
                <Text fontWeight={600} size="md" lineHeight="md" color="gray.700">
                  {formatToStartEndOfWeek(week.weekNumber, week.startTime)}
                </Text>
              )}
            </VStack>

            <Button startIcon={isEditable ? <Edit05Icon color="black" /> : <Target05Icon />} onPress={() => modalCategory.setOpen()}>
              {isEditable ? 'Edit Goals' : 'View Goals'}
            </Button>
          </HStack>
        }
        ItemSeparatorComponent={() => <Box h={3} />}
        renderItem={({ item: category }) => {
          const description = find(weeklyFocus.data?.focusItems, ['weeklyFocusCategoryId', category.id])?.description;
          return (
            <Box px={5}>
              <Pressable rounded="lg" onPress={() => modalCategory.setOpen({ categoryId: category.id })}>
                <Box rounded="lg" borderWidth={1} borderColor="gray.300" bg="white" p={2}>
                  <HStack space={2} alignItems="center" px={3} minH={9} bg="gray.200" rounded="lg">
                    <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
                      {category.seq}
                    </Text>
                    <Text size="lg" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                      {translate(category.title, category.translations)}
                    </Text>
                    <Spacer />
                    {isEditable && isEmpty(description) && (
                      <Text size="md" fontWeight={500} color="gray.700" lineHeight="xs">
                        Goal not set
                      </Text>
                    )}
                  </HStack>
                  {!isEmpty(description) && (
                    <VStack space={2} py={1} pl={4} pr={2}>
                      <Box pt={2} minH={9} alignItems="flex-start" justifyContent="center">
                        <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                          {description}
                        </Text>
                      </Box>
                    </VStack>
                  )}
                </Box>
              </Pressable>
            </Box>
          );
        }}
      />

      <Modal
        isOpen={modalCategory.isOpen}
        hideClose
        noPadding
        size="xl"
        _content={{ rounded: 'xl', height: '75%', w: { xl: '70%', lg: '90%' }, h: '85%' }}
      >
        {typeof weeklyFocusSettings.id === 'number' && (
          <WeeklyFocusCategories
            categories={categories}
            defaultCategoryId={modalCategory.payload?.categoryId}
            isEditable={isEditable}
            siteId={siteId}
            week={week}
            weeklyFocus={weeklyFocus.data}
            weeklyFocusSettingId={weeklyFocusSettings.id}
            onClose={handleUpdateQueryData}
          />
        )}
      </Modal>

      <Modal isOpen={modalVOC.isOpen} hideClose noPadding size="xl" _content={{ rounded: 'lg', w: { xl: '50%', lg: '70%' }, h: '85%' }}>
        {typeof weeklyFocusSettings.id === 'number' && (
          <WeeklyFocusVoc
            goalTypes={goalTypes}
            isEditable={isEditable}
            siteId={siteId}
            week={week}
            weeklyFocus={weeklyFocus.data}
            weeklyFocusSettingId={weeklyFocusSettings.id}
            onClose={handleUpdateQueryData}
          />
        )}
      </Modal>
    </View>
  );
};
