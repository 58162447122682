import { create } from 'zustand';

import { UserDto } from '@pimm/services/lib/sms-tenants';

interface AppGlobalState {
  users?: UserDto[];
}

type UsersWithSiteKeys = AppGlobalState & {
  setUsers: (_users?: UserDto[]) => void;
  clear: () => void;
};

export const useUsersWithSiteKeys = create<UsersWithSiteKeys>(set => ({
  setUsers: _users => set({ users: _users }),
  clear: () => set({ users: [] }),
}));
