import { useMutation } from 'react-query';
import { Box, CloseIcon, HStack, IconButton, useTheme } from 'native-base';
import { startCase, toLower } from 'lodash';

import { ArrowCircleBrokenLeftIcon, Button, MinusCircleIcon, MoveIcon, ReverseLeftIcon, SwitchHorizontalIcon, Text } from '@pimm/base';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { SwapAssignees } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { PositioningSlot } from '../reducers';
import { useClickToDrop } from '../context';
import { EmployeeShiftTime } from './employee-shift-time';

type PositioningNonServiceCardProps = {
  dayBlock: DayBlock;
  position: Partial<PositioningSlot>;
  isAvailable?: boolean;
  isDisabled?: boolean;
  isDragEnabled?: boolean;
  isPending?: boolean;
  subBlockTime?: string;
  onChange?: (position: Partial<PositioningSlot>, _position?: Partial<PositioningSlot>) => void;
  onPress?: () => void;
};

export const PositioningNonServiceCard = ({
  dayBlock,
  position,
  isAvailable,
  isDisabled,
  isDragEnabled,
  isPending,
  subBlockTime,
  ...props
}: PositioningNonServiceCardProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const { dropItem, isDisabled: isClickToDropDisabled, setDragItem } = useClickToDrop();

  const swapAssignees = useMutation({ mutationFn: SwapAssignees });

  const isDropEnabled = !!dropItem;
  const isDropFocus = isDropEnabled && position.positionJobId === (dropItem as PositioningSlot)?.positionJobId;
  const isDropService = isDropEnabled && (dropItem as PositioningSlot)?.positionType === PositionTypeEnum.Service;

  const handlePressDrop = async () => {
    if (dropItem) {
      const timeNow = siteTime.today();
      const blockStartTime = stringToDateLocal(subBlockTime) ?? dayBlock?.startTime;
      const startTime = blockStartTime && blockStartTime > timeNow ? blockStartTime : timeNow;

      // For assign, replace or swap
      if ('assignee' in dropItem) {
        // Swap position assignees
        const _position = dropItem as PositioningSlot;
        if (position.assignee?.employeeId !== _position.assignee?.employeeId) {
          swapAssignees.mutate({
            positionJobId1: _position.positionJobId,
            employeeId1: _position.assignee!.employeeId,
            positionJobId2: position.positionJobId,
            employeeId2: position.assignee?.employeeId,
            subBlockDateTime: formatToISOString(startTime),
          });
          if (props.onChange) props.onChange(position, _position);
        }
      }
    }
    // Cancel "click to drop"
    setDragItem(undefined);
  };

  return (
    <HStack
      rounded="lg"
      alignItems="center"
      justifyContent="space-between"
      py={1}
      px={2.5}
      opacity={isDropEnabled && !isDropFocus && !isDropService ? 0.2 : 1}
      minH="48px"
      borderWidth={1}
      borderColor={isDropFocus ? 'primary.500' : 'gray.300'}
      bg={isDropFocus ? 'primary.500' : 'white'}
      style={{
        shadowColor: colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
      }}
    >
      <Box>
        <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
          {startCase(toLower(position.assignee?.name ?? ''))}
        </Text>

        {!!dayBlock && !!position.assignee && <EmployeeShiftTime dayBlock={dayBlock} employee={position.assignee} />}
      </Box>

      <HStack space={2} alignItems="center" justifyContent="flex-end">
        {/* Action Button for pending changes */}
        {!isDisabled && !isDropEnabled && (
          <IconButton
            rounded="lg"
            shadow={1}
            boxSize={8}
            p={0}
            borderWidth={1}
            borderColor="gray.300"
            bg="white"
            _pressed={{ borderColor: 'gray.400', bg: 'white' }}
            _hover={{ borderColor: 'gray.400', bg: 'white' }}
            icon={
              isAvailable ? (
                <MinusCircleIcon size={5} color={colors.gray[700]} />
              ) : isPending ? (
                <ReverseLeftIcon size={3.5} color={colors.gray[700]} />
              ) : (
                <ArrowCircleBrokenLeftIcon size={5} color={colors.gray[700]} />
              )
            }
            onPress={props.onPress}
          />
        )}

        {/* Toggle "Click to Drop" */}
        {!isDisabled && !isAvailable && !isPending && !isClickToDropDisabled && !isDropService && (
          <Button
            variant={!isDropFocus ? 'unstyled' : 'solid'}
            rounded="lg"
            minH={8}
            minW={8}
            shadow={1}
            p={0}
            bg={isDropFocus ? 'black' : undefined}
            borderColor={isDropFocus ? 'black' : undefined}
            _hover={{ bg: isDropFocus ? 'black' : undefined }}
            _pressed={{ bg: isDropFocus ? 'black' : undefined }}
            isDisabled={isDropEnabled && !isDropFocus}
            onPress={() => setDragItem(isDropEnabled ? undefined : position)}
          >
            {isDropFocus ? (
              <CloseIcon size={18} color="white" />
            ) : (
              <MoveIcon size="18px" color={isDropFocus ? 'white' : colors.gray[700]} />
            )}
          </Button>
        )}

        {/* Swap "Service Position" to "Non-Service Position" */}
        {!isDisabled && !isAvailable && !isPending && isDropEnabled && isDropService && (
          <Button rounded="lg" minH={8} minW={8} shadow={1} p={0} onPress={handlePressDrop}>
            <SwitchHorizontalIcon size={5} color="black" />
          </Button>
        )}
      </HStack>
    </HStack>
  );
};
