import { Box } from 'native-base';

import { Text } from '@pimm/base';
import { CalendarPicker } from '@app/components/shared';
import { CalendarEvent } from './context/calendar-events-context';

type CalendarDayPickerProps = {
  events?: CalendarEvent[];
  todayDate?: Date;
  onPressDay?: (date: Date) => void;
};

export const CalendarDayPicker = ({ events = [], onPressDay, todayDate }: CalendarDayPickerProps) => {
  const handlePressDay = (day: Date) => {
    if (onPressDay) onPressDay(day);
  };

  return (
    <CalendarPicker
      mode="week"
      today={todayDate}
      renderDay={(date, { isToday }) => {
        if (!isToday) return undefined;
        return (
          <Box justifyContent="center" h="full" w="full" rounded="full" bgColor="primary.500">
            <Text size="md" color="white" textAlign="center">
              {date.getDate()}
            </Text>
          </Box>
        );
      }}
      onChange={handlePressDay}
    />
  );
};
