import { useEffect, useState } from 'react';
import { HStack, VStack, useTheme } from 'native-base';
import { filter, find, map, orderBy, partition } from 'lodash';

import { MinusCircleIcon, Text } from '@pimm/base';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { PositioningSlot } from '../reducers';
import { usePositioningPlan } from '../context';
import { PositioningNonServiceCard } from './positioning-nonservice-card';
import { PendingChangesCard } from './pending-changes';
import { PositioningActionTools } from './positioning-action-tools';

type PositioningPlanNonServicesProps = {
  isDisabled?: boolean;
};

export const PositioningPlanNonServices = ({ isDisabled }: PositioningPlanNonServicesProps) => {
  const { colors } = useTheme();
  const { changes, positioning, deletePositionSlot, updatePositionSlot, moveTo } = usePositioningPlan();
  const [nonServices, setNonServices] = useState<PositioningSlot[][]>([]);

  const handlePressUndo = (slot: any) => () => {
    // This will remove the employee from the list un-save changes
    const employee = find(positioning?.employees, ['employeeId', slot.assignee?.employeeId]);
    if (employee) {
      moveTo(employee);
      deletePositionSlot(slot);
    }
  };

  useEffect(() => {
    const nonServices = orderBy(
      filter<PositioningSlot>(positioning?.positionSlots, _ => _.positionType === PositionTypeEnum.NonService && !!_.assignee),
      ['assignee.name'],
    );
    let sections = [nonServices];

    // Check if we have an active positionGroup then differentiate active and pending non-service
    if (!!positioning?.id) sections = partition(nonServices, _ => !!_.id);
    setNonServices(sections);
  }, [positioning]);

  return (
    <VStack space={2} px={3} pb={2}>
      <HStack space={1.5} alignItems="center" h={12}>
        {/* <MinusCircleIcon size="18px" color={colors.gray[600]} /> */}
        <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
          Non-Service Positions
        </Text>
      </HStack>

      {changes.length > 0 && <PositioningActionTools isDisabled={isDisabled} />}

      {/* Pending Non-Service */}
      {nonServices[1]?.length > 0 && (
        <PendingChangesCard>
          {!!positioning?.dayBlock &&
            map(nonServices[1], (slot, index) => (
              <PositioningNonServiceCard
                key={`pending-nonservice[${index}]`}
                dayBlock={positioning.dayBlock}
                position={slot}
                isDisabled={isDisabled}
                isPending
                onPress={handlePressUndo(slot)}
              />
            ))}
        </PendingChangesCard>
      )}

      {/* Assigned Non-Service */}
      {!!positioning?.dayBlock && nonServices[0]?.length > 0 && (
        <VStack space={1.5}>
          {map(nonServices[0], (slot, index) => (
            <PositioningNonServiceCard
              key={`assigned-nonservice[${index}][${slot.positionJobId}][${slot.assignee?.employeeId}]`}
              dayBlock={positioning.dayBlock}
              position={slot}
              isDisabled={isDisabled}
              isDragEnabled={!isDisabled}
              subBlockTime={positioning.subBlockTime}
              onPress={() => deletePositionSlot(slot)}
              onChange={updatePositionSlot}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
};
