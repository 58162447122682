import { useState } from 'react';
import { HStack, VStack } from 'native-base';

import { Button, Text } from '@pimm/base';
import { SensorSettingType } from './sensor-setting';
import { useMutateDisableEnable } from '../hooks';

export interface EnableDisableSensor {
  equipmentId?: string;
  instanceId?: string;
  isEnabled?: boolean;
  isDoor?: boolean;
  sensorName?: string;
}

type SensorSettingConfirmationProps = {
  enableDisableSensor?: EnableDisableSensor;
  sensorSettingType?: SensorSettingType;
  siteId?: string;
  onClose: () => void;
};

export const SensorSettingConfirmation = ({ enableDisableSensor, sensorSettingType, siteId, ...props }: SensorSettingConfirmationProps) => {
  const isDoor = enableDisableSensor?.isDoor;
  const isEnabled = enableDisableSensor?.isEnabled;

  const [messages] = useState(
    [
      {
        description: 'Disabling the entire system is not recommended unless the store is closed/non-operational.',
        sensorSettingType: 'Enable System',
      },
      {
        description: 'If a single refrigerated equipment is under maintenance, utilize the "Disable Sensor tool instead.',
        sensorSettingType: 'Enable System',
      },
      { description: 'Re-enable the system immediately after the equipment is operational again.', sensorSettingType: 'Enable System' },
      {
        description: 'Only disable the sensor if the equipment is under maintenance and no food items are being stored.',
        sensorSettingType: 'Sensor',
      },
      { description: 'Re-enable the sensor immediately after the equipment is operational again.', sensorSettingType: 'Sensor' },
    ].filter(_ => _.sensorSettingType === sensorSettingType),
  );

  const changeStatus = useMutateDisableEnable({
    onSuccess: () => props?.onClose(),
  });

  const handleApply = () => {
    if (siteId && enableDisableSensor?.instanceId && enableDisableSensor?.equipmentId) {
      changeStatus.mutate({
        isEnabled: isEnabled,
        InstanceId: enableDisableSensor.instanceId,
        EquipmentId: enableDisableSensor.equipmentId,
        siteId: siteId,
      });
    }
  };

  return (
    <VStack space={4}>
      <VStack space={4} p={4}>
        <VStack space={0}>
          <Text size="xl" color="black" fontWeight={700}>
            {`${isEnabled ? 'Disable' : 'Enable'} ${
              sensorSettingType === 'Sensor' ? (isDoor ? 'Door Sensor' : 'Temperature Sensor') : 'System'
            }`}
          </Text>
          <Text size="md" color="gray.500" fontWeight={500}>
            {enableDisableSensor?.sensorName}
          </Text>
        </VStack>
        <VStack space={1}>
          <Text size="lg" color="error.600" fontWeight={600}>
            Warning:
          </Text>
          <Text size="md" color="black" fontWeight={500}>
            {sensorSettingType === 'Sensor'
              ? `Disabling the sensor will result in the permanent loss of all ${
                  isDoor ? 'door' : 'temperature'
                } activity data collected during this period. This data cannot be recovered.`
              : 'Disabling the System will result in the permanent loss of all temperature data collected during this period for ALL Equipment sensors! This data cannot be recovered.'}
          </Text>
        </VStack>
        <VStack space={2}>
          <Text size="lg" color="black" fontWeight={600}>
            Reminder:
          </Text>
          {messages.map(item => (
            <Text color="black" size="md" fontWeight={500} italic>
              {`\u2022  ${item.description}`}
            </Text>
          ))}
        </VStack>
      </VStack>

      <VStack space={2} flex={1} px={4} py={3} roundedBottom="lg" borderTopWidth={1} bgColor="gray.50">
        <Text size="lg" color="black" fontWeight={600}>
          Confirmation
        </Text>

        <Text color="black" size="md" fontWeight={500}>
          {`Are you sure you want to ${isEnabled ? 'disable' : 'enable'} this sensor?`}
        </Text>

        {changeStatus.isError && (
          <Text size="xs" color="error.600" fontWeight={500} alignSelf="center">
            Sorry but something went wrong, please try again later.
          </Text>
        )}

        <HStack space={3} justifyContent="space-between" pt={2} flex={1}>
          <Button flex={1} variant="unstyled" disabled={changeStatus.isLoading} onPress={props.onClose}>
            Cancel
          </Button>
          <Button flex={1} isLoading={changeStatus.isLoading} onPress={handleApply}>
            Confirm
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};
