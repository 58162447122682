import { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, VStack, View, Pressable, Spacer, ScrollView } from 'native-base';
import { filter, isEmpty, map, partition, startCase, toLower } from 'lodash';

import { Button, Text, SearchField } from '@pimm/base';
import { ShiftEmployeeDto, ShiftDto, AddUpdateOpsTaskAssignmentRequest } from '@pimm/services/lib/sms-workforce';
import { AddUpdateOpsTaskAssignment } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString, formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { OpsPhasePositionAssignee } from '../types';

export type SelectEligableEmployeeProps = {
  assignee?: OpsPhasePositionAssignee;
  employees?: ShiftDto[];
  opsPhaseId: number;
  positionId: string;
  salesVolumeProfileId: number;
  siteId: string;
  startOfTask?: Date;
  onChange?: (assignee?: OpsPhasePositionAssignee, isDelete?: boolean) => void;
  onClose?: () => void;
};

export const SelectEligableEmployee = ({
  employees,
  opsPhaseId,
  positionId,
  salesVolumeProfileId,
  siteId,
  startOfTask,
  ...props
}: SelectEligableEmployeeProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [assignee, setAssignee] = useState<Partial<OpsPhasePositionAssignee> | undefined>(props.assignee);
  // Group employees by roles (Manager and regular employee)
  const [sections] = useState<{ title: string; data: ShiftDto[] }[]>(
    partition(employees, _ => ['SMS USER', 'SMS MANAGER'].includes(_.employee?.role ?? '')).map((data, index) => ({
      title: index === 0 ? 'Managers' : 'Employees',
      data: data,
    })),
  );

  const addUpdateTaskAssignment = useMutation({ mutationFn: AddUpdateOpsTaskAssignment });

  const searchResults = useMemo(() => {
    const keyword = searchValue?.trim();
    if (!!keyword) {
      return map(sections, _ => {
        const result = filter(_.data, ({ employee }) =>
          [employee?.firstName, employee?.lastName].filter(Boolean).join(' ').toLowerCase().includes(keyword.toLowerCase()),
        );

        return { ..._, data: result };
      }).filter(_ => _.data.length);
    }
    return sections;
  }, [sections, searchValue]);

  const handlePressSelect = (employee: ShiftEmployeeDto) => () => {
    if (!addUpdateTaskAssignment.isLoading) {
      setAssignee({
        name: startCase(toLower([employee?.firstName, employee?.lastName].filter(Boolean).join(' '))),
        employeeId: employee.id!,
      });
    }
  };

  const handlePressSave = async () => {
    if (assignee && startOfTask && siteId) {
      const payload: AddUpdateOpsTaskAssignmentRequest = {
        id: props.assignee?.id,
        siteId: siteId,
        employeeId: assignee.employeeId,
        assignDate: formatToISOString(startOfTask, true),
        opsPhaseId: opsPhaseId,
        salesVolumeProfileId: salesVolumeProfileId,
        positionId: positionId,
      };

      const result = await addUpdateTaskAssignment.mutateAsync(payload);

      if (props.onChange && assignee && result.id) {
        props.onChange({ id: result.id, employeeId: assignee.employeeId!, name: assignee.name! });
      }
    }
  };

  return (
    <View w="full" h="full">
      <HStack alignItems="center" px={4} h="56px" borderBottomWidth={1}>
        <Text size="2xl" fontWeight={700} color="gray.900">
          Select Employee
        </Text>
      </HStack>

      <HStack flex={1} p={4} space={5}>
        {/* Employee List */}
        <VStack flex={1} space={4} position="relative" h="full">
          <Box flex={1}>
            <VStack rounded="xl" overflow="hidden" h="full" w="full" borderColor="gray.200" borderWidth={1}>
              <HStack height={68} px={4} py={6} alignItems="center" justifyContent="space-between" borderBottomWidth={1}>
                <Box flex={1} maxW="320px">
                  <SearchField
                    placeholder="Search Employee"
                    value={searchValue}
                    onChangeText={setSearchValue}
                    onClear={() => setSearchValue(undefined)}
                  />
                </Box>
              </HStack>

              {/* List of employees */}
              <Box flex={1} bg="gray.50">
                <ResourceLoader h="full" w="full" emptyMessage="No available employees" isEmpty={isEmpty(employees)}>
                  <ScrollView py={3} h="full">
                    <VStack space={2}>
                      {map(searchResults, section => {
                        return (
                          <Box key={section.title} px={3}>
                            <Box mb={1} px={1}>
                              <Text color="gray.900" fontWeight={700} size="lg" lineHeight="sm">
                                {section.title}
                              </Text>
                            </Box>

                            <HStack flexWrap="wrap">
                              {map(section.data, ({ id, employee, ...shift }) => {
                                const isActive = !!assignee && employee?.id === assignee.employeeId;
                                const startTime = stringToDateLocal(shift.startTime);
                                const endTime = stringToDateLocal(shift.endTime);

                                return (
                                  <Box key={`${id}.${employee?.id}`} flex="none" p={1} w="1/6">
                                    <Pressable rounded="lg" onPress={handlePressSelect(employee!)}>
                                      {({ isHovered }) => (
                                        <Box
                                          position="relative"
                                          rounded="lg"
                                          alignItems="center"
                                          justifyContent="center"
                                          px={2}
                                          h="64px"
                                          borderWidth={1}
                                          borderColor={isActive ? 'primary.200' : isHovered ? 'gray.200' : 'white'}
                                          bg={isActive ? 'primary.50' : 'white'}
                                        >
                                          <Text
                                            color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.700'}
                                            fontWeight={600}
                                            size="md"
                                            lineHeight="sm"
                                            textAlign="center"
                                            numberOfLines={2}
                                            ellipsizeMode="tail"
                                          >
                                            {startCase(toLower([employee?.firstName, employee?.lastName].filter(Boolean).join(' ')))}
                                          </Text>

                                          <Text
                                            color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.600'}
                                            fontWeight={400}
                                            size="sm"
                                            textAlign="center"
                                            numberOfLines={1}
                                          >
                                            {`${formatToTimeOnly(startTime)} - ${formatToTimeOnly(endTime)}`}
                                          </Text>
                                        </Box>
                                      )}
                                    </Pressable>
                                  </Box>
                                );
                              })}
                            </HStack>
                          </Box>
                        );
                      })}
                    </VStack>
                  </ScrollView>
                </ResourceLoader>
              </Box>
            </VStack>
          </Box>
        </VStack>
      </HStack>

      <HStack space={2} alignItems="center" py={2.5} px={4} borderTopWidth={1}>
        <Spacer />
        <Button variant="unstyled" minWidth={120} disabled={addUpdateTaskAssignment.isLoading} onPress={props.onClose}>
          Cancel
        </Button>
        <Button minWidth={120} isLoading={addUpdateTaskAssignment.isLoading} isDisabled={!assignee} onPress={handlePressSave}>
          Save
        </Button>
      </HStack>
    </View>
  );
};
