import { Fragment, useEffect } from 'react';
import { Box, HStack, IconButton, QuestionIcon, QuestionOutlineIcon, Spacer, useTheme, VStack } from 'native-base';
import Octicons from 'react-native-vector-icons/Octicons';
import { filter, map, some } from 'lodash';

import { Button, HelpCircleIcon, InfoCircleIcon, Modal, PlusCircleIcon, Text } from '@pimm/base';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useClickToDrop, usePositioningPlan } from '../context';
import { PositioningSlot } from '../reducers';
import { PositioningSelectEmployee } from './positioning-select-employee';
import { PositioningSelectPosition } from './positioning-select-position';
import DroppablePositionSlot from './droppable-position-slot';
import { PopoverSelectOpsLeader } from './popover-select-ops-leader';
import { PopoverTooltipPositionActions } from './popover-tooltip-position-actions';

type PositioningPlanServicesProps = {
  emptyMessage?: React.ReactNode;
  isDisabled?: boolean;
};

export const PositioningPlanServices = ({ emptyMessage, isDisabled }: PositioningPlanServicesProps) => {
  const { positioning, deleteAssignee, replaceAssignee, updatePositionSlot, moveTo } = usePositioningPlan();
  const { dropItem, setIsDisabled: setIsClickToDropDisabled } = useClickToDrop();
  const modalSelect = useModalFocus<PositioningSlot>();
  const modalPosition = useModalFocus<{ isPrimary?: boolean; positionSlotId: string }>();

  const isConfirmed = !!positioning?.confirmedTime;

  useEffect(() => {
    let hasPending = positioning?.positionSlots?.some(_ => !_.id && _.positionType === PositionTypeEnum.NonService) ?? false;

    if (!hasPending) {
      const availableEmployees = filter(
        positioning?.employees,
        _ => !some<PositioningSlot>(positioning?.positionSlots, slot => slot.assignee?.employeeId === _.employeeId),
      ).map(employee => ({ assignee: employee }));

      hasPending = availableEmployees.some(_ => _.assignee?.positionJobId);
    }
    setIsClickToDropDisabled(!positioning?.confirmedTime || hasPending);
  }, [positioning]);

  return (
    <Fragment>
      <VStack minH="full">
        {/* Title Header */}
        <HStack space={1.5} alignItems="center" px={3} w="full" h={12}>
          {/* <PlusCircleIcon size="18px" color={colors.gray[600]} /> */}
          <HStack space={2} alignItems="flex-start">
            <Text size="xl" fontWeight={700} color="black" lineHeight="xs" alignSelf="center">
              Service Positions
            </Text>

            <PopoverTooltipPositionActions
              crossOffset={-120}
              placement="right top"
              trigger={triggerProps => (
                <Button variant="unstyled" shadow={1} px={0} minH={8} minW={8} bg="white" {...triggerProps}>
                  <QuestionOutlineIcon size={5} color="gray.900" />
                </Button>
              )}
            />
          </HStack>

          <Spacer />

          {isConfirmed && !dropItem && (
            <HStack space={1.5} alignItems="center">
              <Box
                rounded="full"
                alignItems="center"
                justifyContent="center"
                w={5}
                h={5}
                borderWidth={1}
                borderColor="white"
                bg="warning.300"
              >
                <Octicons name="star" size={14} color="black" />
              </Box>

              <Text size="md" fontWeight={600} color="black">
                Ops Leader
              </Text>

              <PopoverSelectOpsLeader isDisabled={isDisabled} positionSlots={positioning?.positionSlots} onChange={updatePositionSlot} />
            </HStack>
          )}
        </HStack>

        {isConfirmed ? (
          <HStack flexWrap="wrap" px={1} pb={2}>
            {/* Position Slots */}
            {map(positioning?.positionSlots, (position, index) => {
              if (position.positionType === PositionTypeEnum.NonService) return undefined;

              return (
                <Box key={`position-slot.${index}.${position.id}`} p={1.5} w="1/3">
                  <DroppablePositionSlot
                    key={`droppable-position-slot.${[
                      position.id,
                      position.positionId,
                      position.positionType,
                      isDisabled ? 'disabled' : 'enabled',
                      positioning?.subBlockTime,
                      position.assignee?.employeeId,
                    ]
                      .filter(Boolean)
                      .join('.')}`}
                    dayBlock={positioning?.dayBlock}
                    isDisabled={isDisabled}
                    position={position}
                    subBlockTime={positioning?.subBlockTime}
                    onChange={updatePositionSlot}
                    onDeleteAssignee={deleteAssignee}
                    onMoveTo={moveTo}
                    onEditPosition={isPrimary => modalPosition.setOpen({ isPrimary: isPrimary, positionSlotId: position.id! })}
                    onReplaceAssignee={replaceAssignee}
                    onSelect={() => modalSelect.setOpen(position)}
                  />
                </Box>
              );
            })}
          </HStack>
        ) : (
          <Box flex={1} alignItems="center" justifyContent="center">
            {emptyMessage}
          </Box>
        )}
      </VStack>

      <Modal isOpen={modalSelect.isOpen} noPadding size="md" _content={{ maxWidth: 340 }} hideClose>
        {!!modalSelect.payload && (
          <PositioningSelectEmployee
            isDisabled={isDisabled}
            position={modalSelect.payload}
            onCancel={modalSelect.setHide}
            onSave={updatePositionSlot}
          />
        )}
      </Modal>

      <Modal
        isOpen={modalPosition.isOpen}
        noPadding
        size="xl"
        hideClose
        _content={{ maxWidth: 980, h: '80%' }}
        onClose={modalPosition.setHide}
      >
        {!!modalPosition.payload && (
          <PositioningSelectPosition
            positionSlots={positioning?.positionSlots}
            {...modalPosition.payload}
            onCancel={modalPosition.setHide}
            onSave={updatePositionSlot}
          />
        )}
      </Modal>
    </Fragment>
  );
};
