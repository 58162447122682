import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetWeeklyFocus } from '@pimm/services/lib/sms-workforce/services';

type GetWeeklyGoalsQueryParams = {
  siteId?: string;
  weekStartDate?: Date;
};

export const useGetWeeklyFocus = (
  query?: GetWeeklyGoalsQueryParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetWeeklyFocus>>> => {
  const weeklyGoals = useQuery({
    enabled: !!query?.siteId && !!query?.weekStartDate,
    queryKey: [queryKey ?? 'GetWeeklyFocus', query] as [QueryKey, GetWeeklyGoalsQueryParams],
    queryFn: ({ queryKey }) =>
      GetWeeklyFocus({
        siteId: queryKey[1].siteId,
        weekStartDate: moment(queryKey[1].weekStartDate).format().slice(0, 10),
      }),
  });

  return weeklyGoals;
};
