import { useState } from 'react';
import { useMutation } from 'react-query';
import { HStack, ScrollView, useTheme, View, VStack } from 'native-base';
import { findIndex, isEmpty, isEqual, map, omit, sortBy } from 'lodash';

import { Button, Modal, Text } from '@pimm/base';
import { GoalTypeDto } from '@pimm/services/lib/sms-positioning';
import { AddUpdateWeeklyFocusRequest, AggGoalTypeDto, WeeklyFocusDto } from '@pimm/services/lib/sms-workforce';
import { AddUpdateWeeklyFocus } from '@pimm/services/lib/sms-workforce/services';
import { ResourceLoader } from '@app/components/shared';
import { useSiteTime } from '@app/features/store-core';
import { formatToISOString, formatToStartEndOfWeek, stringToDateLocal } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { GoalTypesSelection } from './goal-type-selection';
import { WeeklyGoalsConfirmUnSave } from './weekly-goals-confirm-unsave';

type WeeklyFocusVocProps = {
  isEditable?: boolean;
  goals?: number[];
  goalTypes?: AggGoalTypeDto[];
  numOfAreas?: number;
  siteId: string;
  week: ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeekBlock']>;
  weeklyFocus?: WeeklyFocusDto;
  weeklyFocusSettingId: number;
  onClose: (_weeklyFocus?: WeeklyFocusDto) => void;
};

export const WeeklyFocusVoc = ({
  goalTypes,
  isEditable,
  numOfAreas = 3,
  siteId,
  week,
  weeklyFocus,
  weeklyFocusSettingId,
  ...props
}: WeeklyFocusVocProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const modalConfirmUnSave = useModalFocus();
  const [goalTypeIds, setGoalTypeIds] = useState<number[]>(sortBy(weeklyFocus?.goalTypeIds));

  const addUpdateWeeklyFocus = useMutation({ mutationFn: AddUpdateWeeklyFocus });

  const isDirty = !isEqual(sortBy(weeklyFocus?.goalTypeIds), goalTypeIds);
  const previousWeeklyFocus = weeklyFocus?.previousWeeklyFocus;

  const handleAddGoalType = (goalType: GoalTypeDto) => {
    if (goalType.id) {
      const _goalTypeIds = [...goalTypeIds];
      const index = findIndex(_goalTypeIds, id => id === goalType.id);

      if (index !== -1) _goalTypeIds.splice(index, 1);
      else _goalTypeIds.push(goalType.id);

      if (_goalTypeIds.length > numOfAreas) return;

      setGoalTypeIds(sortBy(_goalTypeIds));
    }
  };

  const handlePressCancel = (confirm?: boolean) => () => {
    modalConfirmUnSave.setHide();

    if (!confirm && isDirty) {
      modalConfirmUnSave.setOpen();
      return;
    }
    if (props.onClose) props.onClose();
  };

  const handlePressSave = async () => {
    if (!week?.startTime) return;
    const weekStartDate = weeklyFocus?.weekStartDate;
    const payload: AddUpdateWeeklyFocusRequest = {
      ...omit(weeklyFocus, ['previousWeeklyFocus']),
      goalTypeIds: goalTypeIds,
      siteId: weeklyFocus?.siteId ?? siteId,
      weekStartDate: (weekStartDate ?? formatToISOString(week.startTime)).slice(0, 10),
      weeklyFocusSettingId: weeklyFocus?.weeklyFocusSettingId ?? weeklyFocusSettingId,
    };

    const response = await addUpdateWeeklyFocus.mutateAsync(payload);
    if (props.onClose) props.onClose(response);
  };

  return (
    <View w="full" h="full">
      <HStack alignItems="center" minHeight="55px" borderBottomWidth={1} px={5}>
        <Text size="xl" fontWeight={700} color="black" textTransform="uppercase">
          Voc
        </Text>
      </HStack>

      <ScrollView flex={1} p={4}>
        <VStack rounded="lg" borderWidth={1} mb={4} p={4} bg="primary.50">
          <Text size="lg" fontWeight={700} color="black">
            {formatToStartEndOfWeek(week.weekNumber, week.startTime)}
          </Text>

          {!!isEditable && (
            <Text size="md" fontWeight={600} color="black">
              Select up to 3
            </Text>
          )}

          <GoalTypesSelection
            _container={{ space: 1, mt: 3, height: '60px' }}
            _score={{ size: 'lg' }}
            _icon={{ color: colors.black }}
            disabled={!isEditable}
            goals={goalTypeIds}
            goalTypes={goalTypes}
            onAddGoalType={handleAddGoalType}
            showTitle
          />
        </VStack>

        <VStack space={2}>
          <Text size="xl" fontWeight={700} color="gray.900">
            Weekly History
          </Text>

          <VStack rounded="lg" borderWidth={1}>
            {/* Previous Week Data */}
            <ResourceLoader h="full" w="full" emptyMessage="No available data" isEmpty={isEmpty(previousWeeklyFocus)}>
              <VStack overflowY="auto" flex={1} rounded="lg">
                {map(previousWeeklyFocus, (_previousWeek, index) => {
                  const startOfWeek = stringToDateLocal(_previousWeek.weekStartDate);
                  const _week = siteTime.toStartEndOfWeek(startOfWeek);

                  return (
                    <VStack space={3} bg="white" key={`previous-weekly-[${_previousWeek.id}]`} borderTopWidth={index ? 1 : 0} p={4} pt={3}>
                      <Text fontWeight={700} color="black" size="lg">
                        {formatToStartEndOfWeek(_week.weekNumber, _week.startDate)}
                      </Text>

                      <GoalTypesSelection
                        _caption={{
                          color: colors.gray[700],
                        }}
                        _container={{ space: 1, height: '40px' }}
                        _score={{ size: 'lg' }}
                        _icon={{ color: colors.gray[700] }}
                        goals={_previousWeek?.goalTypeIds}
                        goalTypes={goalTypes}
                        disabled
                        showTitle
                        isGoalHistory
                      />
                    </VStack>
                  );
                })}
              </VStack>
            </ResourceLoader>
          </VStack>
        </VStack>
      </ScrollView>

      <HStack alignItems="center" px={4} h="56px" borderTopWidth={1} justifyContent="flex-end" space={3}>
        <Button variant="unstyled" minWidth={120} isDisabled={addUpdateWeeklyFocus.isLoading} onPress={handlePressCancel()}>
          Cancel
        </Button>

        <Button minWidth={120} isDisabled={!isDirty} isLoading={addUpdateWeeklyFocus.isLoading} onPress={handlePressSave}>
          Save
        </Button>
      </HStack>

      <Modal
        size="md"
        _content={{ rounded: 'xl', maxW: 400 }}
        isOpen={modalConfirmUnSave.isOpen}
        noPadding
        hideClose
        onClose={modalConfirmUnSave.setHide}
      >
        <WeeklyGoalsConfirmUnSave onConfirm={handlePressCancel(true)} onClose={modalConfirmUnSave.setHide} />
      </Modal>
    </View>
  );
};
