import { useEffect, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon, HStack, VStack, View } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { last, range } from 'lodash';
import moment from 'moment';

import { Button, ButtonGroup } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { formatTo, formatToISOString } from '@app/utils/date-formatter';
import { DashboardUtilization } from '@app/features/app-insights';
import { DateTimePopover, useSiteConfig, useSiteTime } from '@app/features/store-core';
import { useGetDashboardUtilization } from '@app/features/app-insights';

type DashboardUtilizationPanelProps = {
  onNavigateTo: (screen: string, params?: any) => void;
};

export const DashboardUtilizationPanel = ({ onNavigateTo }: DashboardUtilizationPanelProps) => {
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const { locale } = useAppLocale();
  const [week, setWeek] = useState<ReturnType<typeof siteTime.toStartEndOfWeekBlock> | undefined>(undefined);
  const [dateFocus, setDateFocus] = useState<Date | 'all'>();
  const [dashboardUtilization, setDashboardUtilizationParams] = useGetDashboardUtilization();

  const dayBlocks = siteTime.toDayBlocks();
  const endOfDayBlock = last(dayBlocks);
  const tabDayFocus = dateFocus instanceof Date ? formatToISOString(dateFocus, true) : dateFocus;

  const handleChangeDateFocus = (value: string) => {
    const dt = moment(value);
    setDateFocus(dt.isValid() ? dt.toDate() : 'all');
  };

  const handleSelectWeek = date => {
    setWeek(siteTime.toStartEndOfWeekBlock(date));
  };

  const handleChangeWeek = (days?: number) => () => {
    if (week) {
      let startOfWeek;

      if (days) {
        startOfWeek = moment(week.startTime).add(days, 'days').toDate();
      }
      const startTime = siteTime.toDayBlocks()?.[0]?.startTime;
      const startEndOfWeek = siteTime.toStartEndOfWeekBlock(startOfWeek);
      let _dateFocus = startEndOfWeek.startTime;

      if (startTime >= startEndOfWeek.startTime && startTime < startEndOfWeek.endTime) {
        _dateFocus = startTime;
      }

      setDateFocus(_dateFocus);
      setWeek(startEndOfWeek);
    }
  };

  useEffect(() => {
    if (siteConfig?.id && !week?.startTime) {
      const startEndOfWeek = siteTime.toStartEndOfWeekBlock();
      const dayBlocks = siteTime.toDayBlocks();
      setDateFocus(dayBlocks[0]?.startTime);
      setWeek(startEndOfWeek);
    }
  }, [siteConfig?.id, week]);

  useEffect(() => {
    if (siteConfig?.id && week?.startTime) {
      setDashboardUtilizationParams({ siteId: siteConfig.id, weekStartDate: moment(week.startTime).format('YYYY-MM-DD') });
    }
  }, [siteConfig?.id, week]);

  return (
    <VStack h="full">
      <HStack alignItems="center" justifyContent="space-between" py={2} px={4} minH="58px" borderBottomWidth={1}>
        <HStack space={1.5} alignItems="center" justifyContent="space-between">
          <Button variant="unstyled" w={10} onPress={handleChangeWeek(-7)}>
            <ArrowBackIcon size={4} color="gray.700" />
          </Button>
          <DateTimePopover
            key={`date-picker-${week?.startTime?.getTime()}`}
            selectedDate={week?.startTime}
            mode="week"
            onChange={handleSelectWeek}
            max={moment(siteTime.toStartEndOfWeek().endDate).toDate()}
          >
            <Button
              key={`current-week[${locale}]`}
              variant="unstyled"
              startIcon={<FeatherIcons name="calendar" size={18} color="gray.700" />}
            >
              {week
                ? `Wk ${week.weekNumber} (${range(2)
                    .map(d =>
                      moment(week.startTime)
                        .add(6 * d, 'day')
                        .format('MMM DD'),
                    )
                    .join(' - ')})`
                : ''}
            </Button>
          </DateTimePopover>
          <Button
            disabled={!week?.endTime || moment(week?.endTime).toDate() >= siteTime.today()}
            variant="unstyled"
            w={10}
            onPress={handleChangeWeek(7)}
          >
            <ArrowForwardIcon size={4} color="gray.700" />
          </Button>

          <Button
            display={{ base: 'none', xl: 'flex' }}
            variant="unstyled"
            onPress={handleChangeWeek()}
            disabled={moment(week?.endTime).toDate() >= siteTime.today()}
          >
            Current Week
          </Button>
        </HStack>

        {!!week?.startTime && (
          <ButtonGroup key={locale} value={tabDayFocus} onChange={handleChangeDateFocus}>
            {range(8).map(day => {
              if (day < 7) {
                const date = moment(week.startTime).add(day, 'day').toDate();
                const displayText = formatTo(date, 'ddd MM/DD');
                const isEnabled = endOfDayBlock && date < endOfDayBlock?.endTime;
                const value = formatToISOString(date, true);

                return (
                  <ButtonGroup.Item
                    disabled={!isEnabled}
                    key={displayText}
                    alignItems="center"
                    value={value}
                    _text={{
                      color: !isEnabled ? 'gray.400' : value === tabDayFocus ? 'black' : 'gray.600',
                    }}
                  >
                    {displayText}
                  </ButtonGroup.Item>
                );
              }
              return (
                <ButtonGroup.Item key="weekly" alignItems="center" value="all">
                  Weekly Summary
                </ButtonGroup.Item>
              );
            })}
          </ButtonGroup>
        )}
      </HStack>

      <View flex={1}>
        {!!dateFocus && !!week?.startTime && (
          <DashboardUtilization
            date={dateFocus instanceof Date ? dateFocus : week.startTime}
            isWeekly={typeof dateFocus === 'string'}
            queryDashboardUtilization={dashboardUtilization}
            onNavigateTo={onNavigateTo}
          />
        )}
      </View>
    </VStack>
  );
};
