import { Path, Svg, SvgProps } from 'react-native-svg';

export const BurgerIcon = ({ ...props }: SvgProps) => {
  return (
    <Svg height="21" width="18" viewBox="0 0 21 18" fill="none" {...props}>
      <Path
        d="M3.31522 13.375H17.4402V14.3125C17.4402 14.9755 17.1768 15.6114 16.708 16.0803C16.2391 16.5491 15.6032 16.8125 14.9402 16.8125H5.81522C5.15217 16.8125 4.51629 16.5491 4.04745 16.0803C3.57861 15.6114 3.31522 14.9755 3.31522 14.3125V13.375Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.7527 9.56252H17.3777C17.6291 9.55413 17.8797 9.59644 18.1144 9.68691C18.3492 9.77737 18.5633 9.91415 18.7441 10.0891C18.9249 10.2641 19.0686 10.4736 19.1667 10.7053C19.2648 10.9369 19.3153 11.1859 19.3152 11.4375C19.3152 11.9514 19.1111 12.4442 18.7477 12.8075C18.3844 13.1709 17.8916 13.375 17.3777 13.375H3.37769C3.12611 13.3752 2.8771 13.3247 2.64544 13.2266C2.41378 13.1285 2.20422 12.9847 2.02927 12.8039C1.85432 12.6231 1.71753 12.409 1.62707 12.1743C1.5366 11.9395 1.4943 11.689 1.50269 11.4375C1.49396 11.1889 1.5365 10.9412 1.62764 10.7098C1.71878 10.4784 1.85657 10.2682 2.03245 10.0923C2.20834 9.91639 2.41852 9.7786 2.64996 9.68746C2.88139 9.59632 3.12911 9.55379 3.37769 9.56252H8.8777"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.3152 8.3125C19.3105 8.64221 19.1757 8.95672 18.9402 9.18751C18.8306 9.31018 18.6955 9.40733 18.5443 9.47212C18.3931 9.53691 18.2296 9.56776 18.0652 9.5625H14.8152L12.6902 12L10.5652 9.5625H2.69021C2.52887 9.56272 2.36916 9.53007 2.22085 9.46652C2.07255 9.40298 1.93874 9.30987 1.82762 9.19289C1.71649 9.07592 1.63037 8.93753 1.57451 8.78616C1.51865 8.6348 1.49421 8.47362 1.5027 8.3125C1.49421 8.15138 1.51865 7.99022 1.57451 7.83885C1.63037 7.68748 1.71649 7.54909 1.82762 7.43212C1.93874 7.31514 2.07255 7.22204 2.22085 7.15849C2.36916 7.09494 2.52887 7.06228 2.69021 7.0625H18.0652C18.3967 7.0625 18.7147 7.1942 18.9491 7.42862C19.1835 7.66304 19.3152 7.98098 19.3152 8.3125Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.6902 1.5H12.1277C13.5532 1.5 14.9204 2.06629 15.9284 3.0743C16.9364 4.0823 17.5027 5.44947 17.5027 6.875H3.31522C3.31522 5.44947 3.8815 4.0823 4.8895 3.0743C5.89751 2.06629 7.26466 1.5 8.6902 1.5Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M6.8777 4.3125L7.62771 5.125" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M13.6277 4L14.4402 4.875" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M9.87772 4.375L10.7527 3.5625" stroke="currentColor" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};
