import { CircleIcon, useMediaQuery, useTheme } from 'native-base';
import { find, map } from 'lodash';

import { ButtonGroup } from '@pimm/base';
import { formatToShortTimeOnly } from '@app/utils/date-formatter';
import { CompletionIcon } from '@app/components/shared';
import { ShiftBlock, useShiftBlockFocus } from '../hooks';

type PositioningSubBlocksProps = {
  isDisabled?: boolean;
  shiftBlockFocus: ReturnType<typeof useShiftBlockFocus>;
  onChange?: (shiftBlock: ShiftBlock) => void;
};

export const PositioningSubBlocks = ({ isDisabled, shiftBlockFocus, ...props }: PositioningSubBlocksProps) => {
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1024 });

  const handleChange = (value: string) => {
    const shiftBlock = find<ShiftBlock>(shiftBlockFocus.all, _ => _.id.includes(value));
    if (shiftBlock && props.onChange) props.onChange(shiftBlock);
  };

  return (
    <ButtonGroup py={1} h={9} value={shiftBlockFocus.selected?.replace('Z', '')} isDisabled={isDisabled} onChange={handleChange}>
      {map(shiftBlockFocus.all, (shift, i) => {
        let startIcon = <CompletionIcon variant="partial" size="14px" mr={-0.5} />;

        if (shift.notStarted) {
          startIcon = <CompletionIcon size="14px" mr={-0.5} />;
        } else if (shift.allAssigned) {
          startIcon = <CompletionIcon variant="complete" size="14px" mr={-0.5} />;
        }

        if (isSmallScreen) {
          const color = shift.allAssigned ? colors.success[500] : colors.warning[500];
          startIcon = <CircleIcon size={2} color={shift.notStarted ? colors.gray[700] : color} mr={-0.5} />;
        }

        return (
          <ButtonGroup.Item key={`shift-subblock[${i}]`} py={1} px={1.5} minH={7} startIcon={startIcon} value={shift.id?.replace('Z', '')}>
            {formatToShortTimeOnly(shift.startTime) ?? '00:00 AM'}
          </ButtonGroup.Item>
        );
      })}
    </ButtonGroup>
  );
};
