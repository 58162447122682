import { useState, useMemo } from 'react';
import { VStack, Box, View } from 'native-base';
import { orderBy, filter, some, map } from 'lodash';

import { Text } from '@pimm/base';
import { SiteConfigDto, UserBySiteKeyDto } from '@pimm/services/lib/sms-tenants';
import VirtualTable, { VirtualColumnProps } from '@app/components/virtual-table/virtual-table';
import { ResourceLoader } from '@app/components/shared';
import { useGetUsersWithSiteKeys } from './hooks';

type UserItem = Omit<UserBySiteKeyDto, 'pinIsSet' | 'emailConfirmed'> & {
  pinIsSet?: string;
  emailConfirmed?: string;
};

type StoreUsersProps = {
  queryUsers: ReturnType<typeof useGetUsersWithSiteKeys>;
  store?: SiteConfigDto;
};

export const StoreUsers = ({ queryUsers }: StoreUsersProps) => {
  const [tableColumns] = useState<VirtualColumnProps<UserItem>[]>([
    { dataField: 'status', name: 'Status' },
    { dataField: 'userName', name: 'Username' },
    { dataField: 'firstName', name: 'First Name' },
    { dataField: 'lastName', name: 'Last Name' },
    { dataField: 'roles', name: 'User Role' },
    { dataField: 'email', name: 'Recovery Email' },
    { dataField: 'emailConfirmed', name: 'Email Confirmed?' },
    { dataField: 'pinIsSet', name: 'Pin Set?' },
  ]);

  const tableData = useMemo(() => {
    return orderBy(
      filter(
        map(queryUsers.data, _user => ({
          ..._user,
          pinIsSet: _user.pinIsSet ? 'Set' : undefined,
          emailConfirmed: _user.emailConfirmed ? 'Confirmed' : undefined,
        })),
        _user => some(_user.roles, role => role === 'SMS User' || role === 'SMS Manager'),
      ),
      [_user => (_user.status === 'Active' ? 0 : 1), 'firstName', 'lastName'],
      ['asc', 'asc', 'asc'],
    );
  }, [queryUsers.data]);

  return (
    <VStack h="full" w="full" py={1} px={4} flex={1}>
      <Box py={2}>
        <Text size="xl" color="black" fontWeight={600}>
          Store User
        </Text>
      </Box>

      <View w="full" flex={1}>
        <Box maxHeight="full">
          <VirtualTable
            bordered
            columns={tableColumns}
            data={tableData}
            renderEmptyData={() => {
              const isLoading = queryUsers.isFetching;
              return <ResourceLoader isEmpty isLoading={isLoading} minHeight={100} />;
            }}
          />
        </Box>
      </View>
    </VStack>
  );
};
