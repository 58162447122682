import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, IconButton, ScrollView, VStack, useTheme } from 'native-base';
import { filter, find, isEmpty, map, partition, size, some } from 'lodash';

import { ArrowCircleBrokenLeftIcon, Button, ButtonGroup, Download02Icon, MinusCircleIcon, Text } from '@pimm/base';
import { DeleteAssignee } from '@pimm/services/lib/sms-workforce/services';
import { PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { useClickToDrop, usePositioningPlan } from '../context';
import { PositioningEmployee, PositioningSlot } from '../reducers';
import { DraggableEmployee } from './draggable-employee';
import { PositioningNonServiceCard } from './positioning-nonservice-card';
import { PendingChangesCard } from './pending-changes';

type PositioningAvailableEmployeesProps = {
  isDisabled?: boolean;
};

export const PositioningAvailableEmployees = ({ isDisabled }: PositioningAvailableEmployeesProps) => {
  const { colors } = useTheme();
  const {
    positioning,
    createPositionSlot,
    deleteAssignee: deletePlanAssignee,
    deletePositionSlot,
    moveTo,
    replaceAssignee,
  } = usePositioningPlan();
  const { dropItem, setDragItem } = useClickToDrop();
  const [tabFocus, setTabFocus] = useState<0 | 1>(0);
  const [employees, setEmployees] = useState<Partial<PositioningSlot>[][]>([]);

  const deleteAssignee = useMutation({ mutationFn: DeleteAssignee });

  const isDropEnabled = !!dropItem;
  const hasDroppableSlot = isDropEnabled && !!(dropItem as PositioningSlot)?.positionId;

  const handleChangeToNonService = (employee: PositioningEmployee) => () => {
    // Check if employee is already assigned in a non-service positions
    const positionSlot = find<PositioningSlot>(positioning?.positionSlots, slot => {
      return !slot.id && slot.positionType === PositionTypeEnum.NonService && slot.assignee?.employeeId === employee.employeeId;
    });

    if (positionSlot) {
      deletePositionSlot(positionSlot);
    } else {
      // Mimic blank position slot with assignee
      createPositionSlot({ assignee: employee, positionType: PositionTypeEnum.NonService, title: 'Non Service' });
    }
  };

  const handlePressDrop = () => {
    const _position = dropItem as PositioningSlot;
    deleteAssignee.mutate({ positionJobId: _position.positionJobId, employeeId: _position.assignee?.employeeId });
    deletePlanAssignee(_position);
    // Cancel "click to drop"
    setDragItem(undefined);
  };

  useEffect(() => setTabFocus(0), [positioning?.id]);

  useEffect(() => {
    const availableEmployees = filter(
      positioning?.employees,
      _ => !some<PositioningSlot>(positioning?.positionSlots, slot => slot.assignee?.employeeId === _.employeeId),
    ).map(employee => ({ assignee: employee }));
    setEmployees(partition(availableEmployees, _ => !_.assignee?.positionJobId));
  }, [positioning]);

  return (
    <VStack space={2} h="full">
      <Box px={3} opacity={isDropEnabled ? 0.3 : 1}>
        <ButtonGroup mt={2} value={tabFocus} isDisabled={isDropEnabled} onChange={setTabFocus}>
          <ButtonGroup.Item flex={1} alignItems="center" value={0}>
            Coverage
          </ButtonGroup.Item>
          <ButtonGroup.Item flex={1} alignItems="center" value={1}>
            Position History
          </ButtonGroup.Item>
        </ButtonGroup>
      </Box>

      <Box flex={1} borderTopWidth={1}>
        <ScrollView>
          <VStack space={2} p={3} bgColor="white">
            <VStack space={1.5} mb={1}>
              <HStack space={1.5} alignItems="center">
                {/* <ArrowCircleBrokenLeftIcon size={6} color={colors.gray[600]} /> */}
                <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
                  Available Employees
                </Text>
              </HStack>

              {!positioning?.confirmedTime && (
                <HStack space={1} alignItems="center">
                  <IconButton
                    p={1}
                    rounded="md"
                    shadow={1}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    isDisabled
                    _disabled={{ opacity: 1 }}
                    icon={<MinusCircleIcon size="14px" color={colors.gray[700]} />}
                  />
                  <Text size="md" fontWeight={600} color="gray.800">
                    to move employee to Non-Service
                  </Text>
                </HStack>
              )}
            </VStack>

            {!isDisabled && !!hasDroppableSlot && !!positioning?.confirmedTime && size(positioning.employees) > 0 && (
              <HStack
                space={2}
                rounded="xl"
                h={12}
                alignItems="center"
                justifyContent="center"
                borderWidth={2}
                borderColor="primary.500"
                bg="primary.50"
              >
                <Text size="lg" color="black" fontWeight={600} lineHeight="xs" textTransform="uppercase">
                  Assign Here
                </Text>

                <Box position="absolute" top={1.5} right={1.5} h="full">
                  <Button
                    p={0}
                    minHeight={undefined}
                    minH={8}
                    minW={8}
                    startIcon={<Download02Icon size={5} color="black" />}
                    onPress={handlePressDrop}
                  />
                </Box>
              </HStack>
            )}

            {/* Pending Changes */}
            {!isEmpty(employees[1]) && (
              <PendingChangesCard>
                {!!positioning?.dayBlock &&
                  map(employees[1], (slot, index) => (
                    <PositioningNonServiceCard
                      key={`pending-available-employee.${index}.${slot.assignee?.employeeId}`}
                      dayBlock={positioning.dayBlock}
                      position={slot}
                      isAvailable
                      isDisabled={isDisabled}
                      onPress={() => moveTo(slot.assignee!, true)}
                    />
                  ))}
              </PendingChangesCard>
            )}

            {/* Available Employees */}
            {!isEmpty(employees[0]) && (
              <VStack space={1} overflow="hidden">
                {map(employees[0], (slot, index) => {
                  const employeeId = slot.assignee?.employeeId;
                  const isFocused = (dropItem as PositioningEmployee)?.employeeId === employeeId;
                  return (
                    <Box
                      key={`available-employees.${index}.${employeeId}`}
                      overflow="hidden"
                      rounded="lg"
                      shadow={isFocused ? 1 : undefined}
                      borderWidth={1}
                      borderColor={isFocused ? 'primary.500' : 'gray.300'}
                      bg={isFocused ? 'primary.500' : 'white'}
                    >
                      <DraggableEmployee
                        // Important, this will help the useDrag to return the correct item
                        key={`available-employees.${employeeId}.${positioning?.confirmedTime ?? ''}`}
                        employee={slot.assignee!}
                        isConfirmed={!!positioning?.confirmedTime}
                        isDisabled={isDisabled}
                        showLastKnownPosition={tabFocus === 1}
                        onChangeToNonService={handleChangeToNonService(slot.assignee!)}
                        onReplaceAssignee={replaceAssignee}
                      />
                    </Box>
                  );
                })}
              </VStack>
            )}
          </VStack>
        </ScrollView>
      </Box>
    </VStack>
  );
};
