import { createElement } from 'react';
import { Box, HStack, IIconProps, useBreakpointValue, useTheme, VStack } from 'native-base';
import { get } from 'lodash';

import { CircularProgress, Text } from '@pimm/base';
import { SensorSummaryDto } from '@pimm/services/lib/store-equipment';
import { BatteryLowIcon, WifiOffIcon } from '../icons';
import { parseDuration } from '../_helper';

type GraphDiagnosticsProps = {
  _container?: React.ComponentProps<typeof VStack>;
  sensor: SensorSummaryDto;
};

export const GraphDiagnostics = ({ _container, sensor }: GraphDiagnosticsProps) => {
  const { colors } = useTheme();
  const circularRadius = useBreakpointValue({ sm: 18, xl: 20 });
  const circularStroke = useBreakpointValue({ sm: 6, xl: 7 });

  let oosDuration = parseDuration(sensor.OosDuration);
  let offlineDuration = parseDuration(sensor.OfflineDuration);
  const pattern = /^100(\.0)?$/;

  return (
    <VStack flex={1} space={2} {..._container}>
      <HStack space={2}>
        {[
          { dataField: 'UptimePct', title: 'Sensor\nUptime %' },
          { dataField: 'UploadPct', title: 'Data\nUpload %' },
        ].map(_ => {
          const value = Number(get(sensor, _.dataField) ?? 0);
          return (
            <HStack
              key={_.dataField}
              space={{ base: 1, xl: 3 }}
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              rounded="lg"
              py={1.5}
              px={{ base: 2, xl: 3 }}
              bg="gray.50"
            >
              <Text size="md" fontWeight={600} color="black" lineHeight="xs" numberOfLines={2}>
                {_.title}
              </Text>

              <CircularProgress
                _text={{
                  size: { base: 'xs', xl: 'sm' },
                  decimalScale: 1,
                  fixedDecimalScale: pattern.test(value.toString()),
                  suffix: '',
                  pt: '1px',
                }}
                activeStrokeColor={colors.primary[500]}
                inActiveStrokeColor={colors.gray[300]}
                radius={circularRadius}
                strokeWidth={circularStroke}
                value={Number((value ?? 0).toFixed(1))}
              />
            </HStack>
          );
        })}
      </HStack>

      <HStack space={2}>
        <VStack flex={1} space={1} rounded="lg" justifyContent="center" p={{ base: 1, xl: 2 }} bg="gray.50">
          <Text size="md" fontWeight={600} color="black" lineHeight="xs">
            Offline
          </Text>
          <HStack space={{ base: 1, xl: 1.5 }}>
            {[{ label: 'dd' }, { label: 'hh' }, { label: 'mm' }].map((_, i) => {
              return (
                <VStack key={_.label} rounded="md" alignItems="center" justifyContent="center" py={{ base: 1, xl: 1 }} flex={1} bg="white">
                  <Text size="sm" fontWeight={400} color="black" lineHeight="md" textTransform="uppercase">
                    {_.label}
                  </Text>
                  <Text size="md" fontWeight={700} color="black" lineHeight="xs">
                    {offlineDuration[i] ?? '00'}
                  </Text>
                </VStack>
              );
            })}
          </HStack>
          <Text size="md" fontWeight={600} color="black" lineHeight="xs" mt={1}>
            Disabled (OOS)
          </Text>

          <HStack space={{ base: 1, xl: 1.5 }}>
            {[{ label: 'dd' }, { label: 'hh' }, { label: 'mm' }].map((_, i) => {
              return (
                <VStack key={_.label} rounded="md" alignItems="center" justifyContent="center" py={{ base: 1, xl: 1 }} flex={1} bg="white">
                  <Text size="sm" fontWeight={400} color="black" lineHeight="sm" textTransform="uppercase">
                    {_.label}
                  </Text>
                  <Text size="md" fontWeight={700} color="black" lineHeight="xs">
                    {oosDuration[i] ?? '00'}
                  </Text>
                </VStack>
              );
            })}
          </HStack>
        </VStack>

        <VStack space={{ base: 1, xl: 2 }} flex={1} rounded="lg" py={2} p={{ base: 2, xl: 3 }} bg="gray.100">
          <Text size="md" fontWeight={600} color="black">
            Events
          </Text>

          <VStack space={1}>
            {[
              { icon: WifiOffIcon, dataField: 'OfflineEvents' },
              { icon: BatteryLowIcon, dataField: 'BatteryEvents' },
            ].map(_ => {
              const severity3 = Number(get(sensor, `${_.dataField}.Severity3`) ?? 0);
              const severity4 = Number(get(sensor, `${_.dataField}.Severity4`) ?? 0);
              return (
                <HStack
                  key={_.dataField}
                  space={2}
                  rounded="lg"
                  alignItems="center"
                  justifyContent="space-between"
                  py={1}
                  px={2}
                  bg="white"
                >
                  {createElement<IIconProps>(_.icon, { size: '18px', color: 'black' })}

                  <HStack space={1}>
                    <Box
                      rounded={{ base: 'md', xl: 'lg' }}
                      alignItems="center"
                      justifyContent="center"
                      minWidth={{ base: 5, xl: 6 }}
                      h={{ base: '24px', xl: '26px' }}
                      bg="error.500"
                    >
                      <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight="xs">
                        {severity3}
                      </Text>
                    </Box>

                    <Box
                      rounded={{ base: 'md', xl: 'lg' }}
                      alignItems="center"
                      justifyContent="center"
                      minWidth={{ base: 5, xl: 6 }}
                      h={{ base: '24px', xl: '26px' }}
                      bg="black"
                    >
                      <Text size={{ base: 'md', xl: 'lg' }} fontWeight={600} color="white" lineHeight="xs">
                        {severity4}
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </HStack>
    </VStack>
  );
};
