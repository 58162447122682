import { useMutation } from 'react-query';
import { HStack, View, VStack } from 'native-base';

import { Button, ControlInput, FormHelperText, Text } from '@pimm/base';
import { ChangePinRequest, PinResetDto } from '@pimm/services/lib/sms-tenants';
import { ChangePin } from '@pimm/services/lib/sms-tenants/services';
import { useFormPin } from '../hooks';

type FormPinProps = {
  isPinNotSet?: boolean;
  PinHeaderComponent?: React.ReactNode;
  userId: string;
  onCancel?: () => void;
  onPressYes?: () => void;
  onEndChange: (payload?: PinResetDto) => void;
};

export const FormPinSetup = ({ PinHeaderComponent, isPinNotSet, userId, ...props }: FormPinProps) => {
  const form = useFormPin();
  const errMessage = form.formState.errors.root?.message as string;

  const changePin = useMutation({
    mutationFn: ChangePin,
    onSuccess: data => props.onEndChange(data),
    onError: (error: any) => {
      // Reset input fields but keep the error message
      form.reset(undefined, { keepErrors: true });

      form.setError('root', {
        type: 'custom',
        message:
          error.code === 400
            ? 'You have entered an invalid password. Please try again'
            : 'Sorry, something went wrong there. Please try again',
      });
    },
  });

  const handleSavePin = () => {
    const { currentPassword, pin, confirmPin } = form.getValues();

    if (pin !== confirmPin) {
      form.setError('confirmPin', { type: 'custom', message: 'PIN and Confirm PIN do not match' });
      return;
    }

    const payload: ChangePinRequest = {
      userId: userId,
      currentPassword: btoa(currentPassword),
      newPin: pin,
    };

    changePin.mutate(payload);
  };

  return (
    <VStack height="full" w="full" p={6}>
      {PinHeaderComponent && <View>{PinHeaderComponent}</View>}

      {!isPinNotSet && (
        <VStack space={4} justifyContent="center" pb={4}>
          <VStack alignItems="flex-start" justifyContent="center">
            <Text size="md" fontWeight={500} color="gray.900">
              Current Password
            </Text>
            <HStack space={2} alignItems="center">
              <ControlInput
                control={form.control}
                type="password"
                name="currentPassword"
                _helperText={{ textAlign: 'center' }}
                _input={{ w: 300 }}
              />
            </HStack>
          </VStack>

          <VStack alignItems="flex-start" justifyContent="center">
            <Text size="md" fontWeight={500} color="gray.900">
              New PIN
            </Text>
            <HStack space={2} alignItems="center">
              <ControlInput
                numericOnly
                control={form.control}
                type="password"
                name="pin"
                _helperText={{ textAlign: 'center' }}
                _input={{ w: 300, maxLength: 4 }}
              />
            </HStack>
          </VStack>

          <VStack alignItems="flex-start" justifyContent="center">
            <Text size="md" fontWeight={500} color="gray.900">
              Confirm PIN
            </Text>
            <HStack space={2} alignItems="center">
              <ControlInput
                numericOnly
                control={form.control}
                type="password"
                name="confirmPin"
                _helperText={{ textAlign: 'center' }}
                _input={{ w: 300, maxLength: 4 }}
              />
            </HStack>
            <Text color="gray.600" fontWeight={500} size="md" lineHeight="md" italic>
              PIN must be a 4-digit number
            </Text>
          </VStack>

          <FormHelperText error visible={!!errMessage} textAlign="center">
            {errMessage ?? ''}
          </FormHelperText>
        </VStack>
      )}

      <HStack space={3} justifyContent="flex-end" width="full">
        <Button variant="unstyled" flex={1} disabled={changePin.isLoading} onPress={props.onCancel}>
          {!!isPinNotSet ? 'No' : 'Cancel'}
        </Button>

        <Button
          flex={1}
          disabled={!form.formState.isValid && !isPinNotSet}
          isLoading={changePin.isLoading}
          onPress={!isPinNotSet ? form.handleSubmit(handleSavePin) : props.onPressYes && props.onPressYes}
        >
          {!!isPinNotSet ? 'Yes' : 'Save'}
        </Button>
      </HStack>
    </VStack>
  );
};
