import { useMutation } from 'react-query';
import { Box, HStack, IconButton, Pressable, Spacer, Spinner, useTheme } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { startCase, toLower } from 'lodash';

import { ChevronSelectorVerticalIcon, Text } from '@pimm/base';
import { ShiftDto } from '@pimm/services/lib/sms-workforce';
import { DeleteSchedule } from '@pimm/services/lib/sms-workforce/services';
import { convertMinutesToHoursAndMinutes, formatToShortDateOnly, formatToShortTimeOnly } from '@app/utils/date-formatter';

type EmployeeScheduleProps = {
  employee: ShiftDto;
  isDisabled?: boolean;
  isMultipleSchedules?: boolean;
  onEdit?: (employee: ShiftDto) => void;
  onDelete?: (id: number) => void;
};

export const EmployeeSchedule = ({ employee, isDisabled, isMultipleSchedules, ...props }: EmployeeScheduleProps) => {
  const { colors } = useTheme();

  const deleteSchedule = useMutation({
    mutationFn: DeleteSchedule,
    onSuccess: (data, params) => {
      if (props.onDelete) props.onDelete(params?.id!);
    },
  });

  const handlePressEdit = () => {
    if (props.onEdit) props.onEdit(employee);
  };

  const handlePressDelete = () => {
    deleteSchedule.mutate({ id: employee.id });
  };

  return (
    <HStack h="48px" borderBottomWidth={1}>
      <Box flex={1} h="full" pl={4} pr={2} justifyContent="center" borderRightWidth={1}>
        <Text size="lg" fontWeight={600} color="gray.900">
          {startCase(toLower([employee.employee?.firstName, employee.employee?.lastName].join(' ')))}
        </Text>
      </Box>

      <Box
        flex={1}
        px={2}
        h="full"
        maxW={200}
        justifyContent="center"
        borderRightWidth={1}
        bg={(employee && (isMultipleSchedules ? 'red.500' : !employee.isImport && 'warning.200')) || undefined}
      >
        <Pressable rounded="lg" isDisabled={isDisabled} onPress={handlePressEdit}>
          <HStack space={1} rounded="lg" alignItems="center" pl={2} pr={1} h={8} borderWidth={1} borderColor="gray.200" bg="white">
            <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="xs">
              {formatToShortDateOnly(employee.startTime)}
            </Text>
            <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="xs">
              {formatToShortTimeOnly(employee.startTime)}
            </Text>
            <Spacer />
            <ChevronSelectorVerticalIcon color={isDisabled ? 'gray.200' : 'gray.500'} />
          </HStack>
        </Pressable>
      </Box>

      <Box
        flex={1}
        px={2}
        h="full"
        maxW={200}
        justifyContent="center"
        borderRightWidth={1}
        bg={(employee && (isMultipleSchedules ? 'red.500' : !employee.isImport && 'warning.200')) || undefined}
      >
        <Pressable rounded="lg" isDisabled={isDisabled} onPress={handlePressEdit}>
          <HStack space={1} rounded="lg" alignItems="center" pl={2} pr={1} h={8} borderWidth={1} borderColor="gray.200" bg="white">
            <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="xs">
              {formatToShortDateOnly(employee.endTime)}
            </Text>
            <Text size="md" fontWeight={500} color="black" textAlign="center" lineHeight="xs">
              {formatToShortTimeOnly(employee.endTime)}
            </Text>
            <Spacer />
            <ChevronSelectorVerticalIcon color={isDisabled ? 'gray.200' : 'gray.500'} />
          </HStack>
        </Pressable>
      </Box>

      <Box flex={1} h="full" maxW={100} alignItems="center" justifyContent="center" borderRightWidth={1}>
        <Text size="lg" fontWeight={600} color="gray.900" backgroundColor="gray.400">
          {convertMinutesToHoursAndMinutes(employee.durationInMinutes ?? 0)}
        </Text>
      </Box>

      {!isDisabled && (
        <Box flex={1} h="full" maxW={60} alignItems="center" justifyContent="center">
          {!deleteSchedule.isLoading ? (
            <IconButton p={1} icon={<FeatherIcons name="trash-2" size={18} color={colors.primary[500]} />} onPress={handlePressDelete} />
          ) : (
            <Spinner size={18} color="gray.400" />
          )}
        </Box>
      )}
    </HStack>
  );
};
