import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetTimerData } from '@pimm/services/lib/sms-drivethru/services';
import { useSiteTime } from '@app/features/store-core';

export type GetTimerDataParams = {
  date?: Date;
  siteId?: string;
};

export const useGetTimerData = (
  query?: GetTimerDataParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetTimerData>>> => {
  const siteTime = useSiteTime();
  let refetchInBackground = false;

  // Need to make sure that the current daypart is today before doing refetch every 10 minutes
  if (query?.date) {
    const daypart = siteTime.toDaypartNow();

    if (daypart?.startTime && daypart?.endTime) {
      refetchInBackground = daypart.startTime >= query.date && query.date < daypart.endTime;
    }
  }

  const timerData = useQuery({
    cacheTime: 0,
    enabled: !!query?.siteId && !!query?.date,
    refetchIntervalInBackground: refetchInBackground,
    refetchOnMount: 'always',
    refetchInterval: () => {
      const timeNow = new Date();
      // Refetch the timerData every 10 minutes
      const nearestMinutes = timeNow.toNearestNextMinutes(10);
      return nearestMinutes.getTime() - timeNow.getTime();
    },
    queryKey: [queryKey ?? 'GetTimerData', query] as [QueryKey, GetTimerDataParams],
    queryFn: ({ queryKey: [key, params] }) => GetTimerData({ date: moment(params.date).format('YYYY-MM-DD'), siteId: params.siteId }),
  });

  return timerData;
};
