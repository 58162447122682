import { ClipPath, Defs, G, Path, Rect, Svg, SvgProps } from 'react-native-svg';

export const Target05Icon = (props: SvgProps) => {
  return (
    <Svg height="20" width="20" viewBox="0 0 20 20" fill="none" {...props}>
      <G clipPath="url(#clip0_20449_5168)">
        <Path
          d="M10 18.3333C14.6024 18.3333 18.3333 14.6023 18.3333 9.99996C18.3333 5.39759 14.6024 1.66663 10 1.66663C5.39763 1.66663 1.66667 5.39759 1.66667 9.99996C1.66667 14.6023 5.39763 18.3333 10 18.3333Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10 15C12.7614 15 15 12.7614 15 9.99996C15 7.23854 12.7614 4.99996 10 4.99996C7.23858 4.99996 5.00001 7.23854 5.00001 9.99996C5.00001 12.7614 7.23858 15 10 15Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M10 11.6666C10.9205 11.6666 11.6667 10.9204 11.6667 9.99996C11.6667 9.07949 10.9205 8.33329 10 8.33329C9.07953 8.33329 8.33334 9.07949 8.33334 9.99996C8.33334 10.9204 9.07953 11.6666 10 11.6666Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_20449_5168">
          <Rect width="20" height="20" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
