import { NavigationContainer } from '@react-navigation/native';
import { NativeBaseProvider } from 'native-base';

import { linking } from '@app/navigations/linking';
import { themeConfig } from '@pimm/base';

const theme = themeConfig();

type ThemeContainerProps = {
  children: React.ReactNode;
};

export const ThemeContainer = ({ children }: ThemeContainerProps) => {
  return (
    // TODO: Fix type return issue
    <NativeBaseProvider theme={theme as any}>
      <NavigationContainer
        linking={linking}
        theme={{
          dark: false,
          colors: {
            primary: theme.colors.primary[600],
            background: theme.colors.white,
            card: theme.colors.primary[600],
            text: theme.colors.black,
            border: theme.colors.primary[600],
            notification: theme.colors.white,
          },
        }}
      >
        {children}
      </NavigationContainer>
    </NativeBaseProvider>
  );
};
