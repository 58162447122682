import { useMutation } from 'react-query';
import { Box, CloseIcon, HStack, IconButton, VStack, useTheme } from 'native-base';
import { startCase, toLower } from 'lodash';

import { Button, MinusCircleIcon, MoveIcon, SwitchHorizontalIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AssignEmployee } from '@pimm/services/lib/sms-workforce/services';
import { AddUpdateAssigneeRequest, PositionTypeEnum } from '@pimm/services/lib/sms-workforce';
import { hexToRGBA } from '@app/utils/string-formatter';
import { useKitchenLayout } from '@app/features/kitchen-positioning';
import { useClickToDrop } from '../context';
import { PositioningEmployee, PositioningSlot } from '../reducers';
import { EmployeeTimeline } from './employee-timeline';

type DraggableEmployeeProps = {
  employee: PositioningEmployee;
  isChecked?: boolean;
  isConfirmed?: boolean;
  isDisabled?: boolean;
  showLastKnownPosition?: boolean;
  onChangeToNonService?: () => void;
  onReplaceAssignee?: (position: Partial<PositioningSlot>, employee: Partial<PositioningEmployee>) => void;
};

export const DraggableEmployee = ({
  employee,
  isChecked,
  isConfirmed,
  isDisabled,
  showLastKnownPosition,
  ...props
}: DraggableEmployeeProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const { positionLookup } = useKitchenLayout();
  const { dropItem, isDisabled: isClickToDropDisabled, setDragItem } = useClickToDrop();

  const assignEmployee = useMutation({ mutationFn: AssignEmployee });

  const lastKnownPosition = employee.lastPositionId ? positionLookup[employee.lastPositionId] : undefined;
  const isDropEnabled = !!dropItem;
  const isDropFocus = isDropEnabled && (dropItem as PositioningEmployee)?.employeeId === employee.employeeId;
  const isDropService = !!isDropEnabled && (dropItem as PositioningSlot).positionType === PositionTypeEnum.Service;

  const handlePressDrop = async () => {
    if (dropItem && !isDropFocus) {
      // Swap available employee into a position slot
      if (employee && 'assignee' in dropItem) {
        const position = dropItem as PositioningSlot;
        // setAssignee(employee);
        const payload: AddUpdateAssigneeRequest = {
          id: position.assignee?.id,
          positionJobId: position.positionJobId,
          employeeId: employee.employeeId,
        };
        assignEmployee.mutateAsync(payload);
        if (props.onReplaceAssignee) props.onReplaceAssignee(position, employee);
      }
    }
    // Cancel click to drop
    setDragItem(undefined);
  };

  return (
    <HStack alignItems="center" py={1.5} pl={1} pr={2.5} minHeight="68px" opacity={isDropEnabled && !isDropFocus && !isDropService ? 0.2 : 1}>
      <Box flex={1} pl={2}>
        <VStack space={2} mb={0.5}>
          <HStack alignItems="center" justifyContent="space-between" w="full">
            <Box flex={1}>
              <Text size="md" fontWeight={700} color="black" lineHeight="sm" numberOfLines={1} ellipsizeMode="tail">
                {`${startCase(toLower(employee.name ?? ''))}`}
              </Text>
              <Text size="sm" fontWeight={500} color="gray.900" lineHeight="xs">
                {employee?.title ?? 'Unknown'}
              </Text>
            </Box>

            {!isDisabled && (
              <HStack space={1.5} alignItems="center">
                {isDropService && (
                  <Button
                    rounded="lg"
                    minHeight={8}
                    boxSize={8}
                    shadow={1}
                    p={0}
                    isLoading={assignEmployee.isLoading}
                    onPress={handlePressDrop}
                  >
                    <SwitchHorizontalIcon size={5} color="black" />
                  </Button>
                )}

                {!isDropEnabled && (
                  <IconButton
                    rounded="lg"
                    shadow={1}
                    boxSize={8}
                    p={0}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    _pressed={{ borderColor: 'gray.400', bg: 'white' }}
                    _hover={{ borderColor: 'gray.400', bg: 'white' }}
                    icon={<MinusCircleIcon size={5} color={colors.gray[700]} />}
                    onPress={props.onChangeToNonService}
                  />
                )}

                {!isClickToDropDisabled && !isDropService && (
                  <Button
                    variant={!isDropFocus ? 'unstyled' : 'solid'}
                    rounded="lg"
                    minHeight={8}
                    boxSize={8}
                    bg={isDropFocus ? 'black' : undefined}
                    borderColor={isDropFocus ? 'black' : undefined}
                    _hover={{ bg: isDropFocus ? 'black' : undefined }}
                    _pressed={{ bg: isDropFocus ? 'black' : undefined }}
                    shadow={1}
                    p={0}
                    onPress={() => setDragItem(isDropEnabled ? undefined : employee)}
                  >
                    {isDropFocus ? <CloseIcon size={18} color="white" /> : <MoveIcon size="18px" color={colors.gray[700]} />}
                  </Button>
                )}
              </HStack>
            )}
          </HStack>
        </VStack>

        {showLastKnownPosition ? (
          <HStack space={2} flexWrap="wrap" alignItems="center" justifyContent="space-between" minH={6}>
            <Text size="sm" fontWeight={500} color="gray.700" lineHeight="xs">
              Last known
            </Text>

            {!!lastKnownPosition ? (
              <Box
                flex="none"
                maxW="100%"
                height={4}
                borderRadius="full"
                bg={hexToRGBA(lastKnownPosition?.color ?? colors.gray[400], 0.5)}
                overflow="hidden"
              >
                <Text size="sm" fontWeight={500} color="gray.900" px={1.5} numberOfLines={1} ellipsizeMode="tail">
                  {translate(lastKnownPosition?.title, lastKnownPosition.translations)}
                </Text>
              </Box>
            ) : (
              <Text size="sm" fontWeight={500} color="gray.500" lineHeight="xs">
                Not found
              </Text>
            )}
          </HStack>
        ) : (
          <EmployeeTimeline _text={{ color: isDropFocus ? 'black' : 'gray.600' }} employee={employee} />
        )}
      </Box>
    </HStack>
  );
};
