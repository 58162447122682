import { useMemo } from 'react';
import { Image } from 'native-base';
import { find, first, sortBy } from 'lodash';
import { Locale } from 'locale-enum';

import { ButtonGroup } from '@pimm/base';
import { selectStoreConfig } from '@app/store/selectors';
import { useAppSelector } from '@app/store/store';

type CultureTabProps = {
  value?: string;
  languages?: string[];
  onChange?: (value: Locale) => void;
};

export const CultureTab = ({ value, languages = [], onChange }: CultureTabProps) => {
  const storeConfig = useAppSelector(selectStoreConfig);

  const filteredLanguageCodes = useMemo(() => {
    return sortBy(
      languages
        .map(_locale => {
          const cultureInfo = find(storeConfig?.companyInfo?.brand?.availableCultures, _store => _store?.culture === _locale);

          if (cultureInfo?.culture && cultureInfo.imageUrl) {
            return { culture: cultureInfo.culture, imageUrl: cultureInfo.imageUrl };
          }
          return null;
        })
        .filter(Boolean) as { culture: string; imageUrl: string }[],
      'culture',
    );
  }, [languages, storeConfig]);

  return (
    <ButtonGroup value={value} onChange={onChange}>
      {filteredLanguageCodes.map(({ culture, imageUrl }) => (
        <ButtonGroup.Item
          key={culture}
          pl={1}
          minWidth={100}
          value={culture}
          startIcon={<Image source={{ uri: imageUrl }} alt={culture} size="18px" />}
          _text={{
            textTransform: 'uppercase',
          }}
        >
          {first(culture?.split('-'))}
        </ButtonGroup.Item>
      ))}
    </ButtonGroup>
  );
};
