import { ComponentProps, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, View } from 'native-base';
import { find, isEmpty, isEqual, omit, values } from 'lodash';

import { Button, Modal, Text } from '@pimm/base';
import { AddUpdateWeeklyFocusRequest, WeeklyFocusDto, WeeklyFocusItemDto } from '@pimm/services/lib/sms-workforce';
import { AddUpdateWeeklyFocus } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { FormWeeklyFocusData, useFormWeeklyFocus } from '../hooks';
import { WeeklyGoalsConfirmUnSave } from './weekly-goals-confirm-unsave';
import { FormWeeklyCategories } from './form-weekly-categories';

type WeeklyFocusCategoriesProps = Omit<ComponentProps<typeof FormWeeklyCategories>, 'form' | 'previousWeeklyFocus'> & {
  siteId: string;
  weeklyFocus?: WeeklyFocusDto;
  weeklyFocusSettingId: number;
  onClose?: (_weeklyFocus?: WeeklyFocusDto) => void;
};

export const WeeklyFocusCategories = ({
  categories,
  defaultCategoryId,
  isEditable,
  siteId,
  week,
  weeklyFocus,
  weeklyFocusSettingId,
  ...props
}: WeeklyFocusCategoriesProps) => {
  const modalConfirmUnSave = useModalFocus();
  const formWeeklyFocus = useFormWeeklyFocus();

  const addUpdateWeeklyFocus = useMutation({ mutationFn: AddUpdateWeeklyFocus });

  const handlePressCancel = (confirm?: boolean) => () => {
    modalConfirmUnSave.setHide();

    if (!confirm && formWeeklyFocus.formState.isDirty) {
      modalConfirmUnSave.setOpen();
      return;
    }
    if (props.onClose) props.onClose();
  };

  const handlePressSave = async () => {
    if (!week?.startTime) return;
    const { categories } = formWeeklyFocus.getValues();
    const weekStartDate = weeklyFocus?.weekStartDate;

    const payload: AddUpdateWeeklyFocusRequest = {
      ...omit(weeklyFocus, ['previousWeeklyFocus']),
      focusItems: values(categories).filter(_ => !isEmpty(_.description)),
      siteId: weeklyFocus?.siteId ?? siteId,
      weekStartDate: (weekStartDate ?? formatToISOString(week.startTime)).slice(0, 10),
      weeklyFocusSettingId: weeklyFocus?.weeklyFocusSettingId ?? weeklyFocusSettingId,
    };

    const response = await addUpdateWeeklyFocus.mutateAsync(payload);
    if (props.onClose) props.onClose(response);
  };

  useEffect(() => {
    const values = categories?.reduce((items: FormWeeklyFocusData['categories'], _) => {
      const item: WeeklyFocusItemDto = find(weeklyFocus?.focusItems, ['weeklyFocusCategoryId', _.id]) ?? {
        weeklyFocusCategoryId: _.id,
      };
      if (!_.id) return items;
      return { ...items, [_.id.toString()]: item };
    }, {});

    formWeeklyFocus.reset({ categories: values ?? {} });
  }, []);

  return (
    <View w="full" h="full">
      <HStack alignItems="center" minHeight="55px" borderBottomWidth={1} px={4}>
        <Text size="xl" fontWeight={700} color="black">
          Weekly Goals
        </Text>
      </HStack>

      <Box flex={1}>
        <FormWeeklyCategories
          categories={categories}
          defaultCategoryId={defaultCategoryId}
          form={formWeeklyFocus}
          isEditable={isEditable}
          previousWeeklyFocus={weeklyFocus?.previousWeeklyFocus}
          week={week}
        />
      </Box>

      <HStack alignItems="center" px={4} h="56px" borderTopWidth={1} justifyContent="flex-end" space={3}>
        <Button variant="unstyled" minWidth={120} isDisabled={addUpdateWeeklyFocus.isLoading} onPress={handlePressCancel()}>
          {isEditable ? 'Cancel' : 'Close'}
        </Button>

        {!!isEditable && (
          <Button
            minWidth={120}
            isDisabled={!formWeeklyFocus.formState.isDirty}
            isLoading={addUpdateWeeklyFocus.isLoading}
            onPress={handlePressSave}
          >
            Save
          </Button>
        )}
      </HStack>

      <Modal
        size="md"
        _content={{ rounded: 'xl', maxW: 400 }}
        isOpen={modalConfirmUnSave.isOpen}
        noPadding
        hideClose
        onClose={modalConfirmUnSave.setHide}
      >
        <WeeklyGoalsConfirmUnSave onConfirm={handlePressCancel(true)} onClose={modalConfirmUnSave.setHide} />
      </Modal>
    </View>
  );
};
