import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const FormPinSchema = yup.object({
  currentPassword: yup.string().required('Please provide your current password').default(''),
  pin: yup.string().required('Please provide your new PIN').min(4, 'PIN must contain 4 digits').default(''),
  confirmPin: yup
    .string()
    .required('Please confirm your new PIN')
    .oneOf([yup.ref('pin')], 'PIN do not match')
    .default(''),
});

export const useFormPin = () => {
  return useForm({ resolver: yupResolver(FormPinSchema) });
};
