import { UseQueryResult, useQuery } from 'react-query';

import { GetUsersWithSiteKey } from '@pimm/services/lib/sms-tenants/services';

type GetUsersWithSiteKeyQueryParams = {
  siteId?: string;
  siteLevelOnly?: boolean;
};

export const useGetUsersWithSiteKeys = (
  query?: GetUsersWithSiteKeyQueryParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetUsersWithSiteKey>>> => {
  const _usersWithSiteKey = useQuery({
    enabled: !!query?.siteId,
    queryKey: [queryKey ?? 'GetUsersWithSiteKey', query],
    queryFn: () => {
      return GetUsersWithSiteKey({ siteId: query?.siteId!, siteLevelOnly: query?.siteLevelOnly ?? false });
    },
  });
  return _usersWithSiteKey;
};
