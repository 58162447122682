import React, { useEffect, useState } from 'react';
import { Modal } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { ArrowBackIcon, ArrowForwardIcon, HStack, IconButton, useTheme, View } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import { isEmpty, last, range } from 'lodash';
import moment from 'moment';

import { Button, ButtonGroup, LightBulbIcon, SettingsIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { ModuleTitle } from '@app/features/app';
import { MainScreenProps } from '@app/navigations/root';
import { formatToISOString } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { MainContainer } from '@app/components/core';
import { ScreenLayout } from '@app/components/layout';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { GoalSettings } from '@app/features/speed-of-service/components/goal-settings';
import { DateTimePopover, Daypart, useSiteConfig, useSiteTime } from '@app/features/store-core';
import {
  SpeedOfService,
  useGetSiteGoals,
  useGetTimerData,
  useSiteDailyGoals,
  QuerySettingsAndGoalsProvider,
  useGetSettingsAndGoals,
  ServiceConnection,
} from '@app/features/speed-of-service';

export type SpeedOfServiceScreenProps = MainScreenProps<'SpeedOfService'>;

export const SpeedOfServiceScreen: React.FC<SpeedOfServiceScreenProps> = ({ route }) => {
  const { colors } = useTheme();
  const { locale } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const modalGoalSettings = useModalFocus();
  const [dayparts, setDayparts] = useState<Daypart[]>([]);
  const [week, setWeek] = useState<ReturnType<typeof siteTime.toStartEndOfWeekPart> | undefined>();

  const today = siteTime.today();
  const dateFocus = !isEmpty(dayparts) ? formatToISOString(dayparts[0]?.startTime) : undefined;
  const maxDate = siteTime.toStartEndOfWeekPart().endTime;

  const settingsAndGoals = useGetSettingsAndGoals(siteConfig?.id);
  const siteGoals = useGetSiteGoals(siteConfig?.id);

  const timerData = useGetTimerData({ date: dayparts?.[0]?.startTime, siteId: siteConfig?.id });

  const handleSelectWeek = date => {
    const _week = siteTime.toStartEndOfWeek(date);
    let _dayparts = siteTime.toDayparts(_week.endDate);

    if (_dayparts[0].startTime && _dayparts[0].startTime > today) {
      _dayparts = siteTime.toDayparts();
    }

    setDayparts(_dayparts);
    setWeek(siteTime.toStartEndOfWeekPart(date));
  };

  const handleChangeWeek = (days?: number) => () => {
    if (week) {
      let startOfWeek = siteTime.toStartEndOfWeek().startDate;
      if (days) {
        startOfWeek = moment(week.startTime).add(days, 'days').toDate();
      }
      handleSelectWeek(startOfWeek);
    }
  };

  const handleChangeDate = (date: string) => {
    const _dayparts = siteTime.toDayparts(new Date(date));
    setDayparts(_dayparts);
  };

  useEffect(() => {
    useSiteDailyGoals.getState().setGoals(siteGoals.data);
  }, [siteGoals.data]);

  useEffect(() => {
    if (siteTime.siteId && !week) {
      setDayparts(siteTime.toDayparts());
      setWeek(siteTime.toStartEndOfWeekPart());
    }
  }, [siteTime.siteId]);

  return (
    <MainContainer>
      <QuerySettingsAndGoalsProvider settingsAndGoals={settingsAndGoals}>
        <ScreenLayout
          title={
            <HStack flex={1} alignItems="center">
              <ModuleTitle name={route.name} defaultText="Store Settings" />
              <ModalAppDocuments
                identifierName="Module.DriveThruTimer"
                moduleType="Speed of Service"
                trigger={triggerProps => {
                  if (!triggerProps.documents?.length) return undefined;
                  return (
                    <IconButton
                      rounded="lg"
                      borderWidth={1}
                      borderColor="gray.300"
                      ml={2}
                      p={0}
                      w={9}
                      h={9}
                      icon={<LightBulbIcon size="md" />}
                      onPress={triggerProps.onPress}
                    />
                  );
                }}
              />
            </HStack>
          }
          actionTool={
            <HStack justifyContent="flex-end" space={2}>
              {settingsAndGoals.isSuccess && <ServiceConnection settingsAndGoals={settingsAndGoals} timerData={timerData} />}

              <Button
                variant="unstyled"
                startIcon={<SettingsIcon size={4} color={colors.gray[700]} />}
                onPress={() => modalGoalSettings.setOpen()}
              >
                Goal Settings
              </Button>
            </HStack>
          }
        >
          <HStack alignItems="center" justifyContent="space-between" py={2} px={4} borderBottomWidth={1}>
            <HStack key={`week-dates.${locale}`} space={2} alignItems="center" justifyContent="space-between">
              <Button variant="unstyled" w={10} onPress={handleChangeWeek(-7)}>
                <ArrowBackIcon size={4} color="gray.700" />
              </Button>

              <DateTimePopover
                key={`date-picker-${week?.startTime.getTime()}`}
                selectedDate={week?.startTime}
                mode="week"
                onChange={handleSelectWeek}
                max={moment(maxDate).add(-1, 'day').toDate()}
              >
                <Button variant="unstyled" startIcon={<FeatherIcons name="calendar" size={18} color="gray.700" />}>
                  {week
                    ? `Wk ${week.weekNumber} (${range(2)
                        .map(d =>
                          moment(week.startTime)
                            .add(6 * d, 'day')
                            .format('MMM DD'),
                        )
                        .join('- ')})`
                    : ''}
                </Button>
              </DateTimePopover>

              <Button variant="unstyled" disabled={!week || week.endTime >= maxDate} w={10} onPress={handleChangeWeek(7)}>
                <ArrowForwardIcon size={4} color="gray.700" />
              </Button>

              <Button
                variant="unstyled"
                display={{ base: 'none', xl: 'flex' }}
                disabled={!week || week.endTime >= maxDate}
                onPress={handleChangeWeek()}
              >
                Current Week
              </Button>
            </HStack>

            {!!week && (
              <HStack>
                <ButtonGroup key={locale} value={dateFocus} onChange={handleChangeDate}>
                  {range(7).map(day => {
                    const dt = moment(week?.startTime).add(day, 'day');
                    const startTime = siteTime.toStartEndOfPart(dt.toDate()).startTime;
                    const isoDate = formatToISOString(dt.toDate());
                    const isEnabled = startTime < today;
                    return (
                      <ButtonGroup.Item
                        key={isoDate}
                        value={isoDate}
                        disabled={!isEnabled}
                        _text={{
                          color: !isEnabled ? 'gray.400' : isoDate === dateFocus ? 'black' : 'gray.600',
                        }}
                      >
                        {dt.format('ddd MM/DD')}
                      </ButtonGroup.Item>
                    );
                  })}
                </ButtonGroup>
              </HStack>
            )}
          </HStack>

          <View flex={1}>
            {!isEmpty(dayparts) && <SpeedOfService key={dayparts[0].startTime.getTime()} dayparts={dayparts} timerData={timerData} />}
          </View>

          {/* <Modal
            _content={{ maxWidth: { base: 800, xl: '95%' }, height: '88%', maxHeight: 900 }}
            size="full"
            isOpen={modalGoalSettings.isOpen}
            onClose={modalGoalSettings.setHide}
            hideClose
            noPadding
          > */}
          {/* TODO: need to revisit the base modal */}
          <Modal animationType="fade" presentationStyle="fullScreen" transparent={true} visible={modalGoalSettings.isOpen}>
            <MenuProvider>
              <View alignItems="center" justifyContent="center" w="full" h="full" bgColor={'rgba(0, 0, 0, 0.8)'}>
                <View rounded="xl" w="90%" height="full" maxHeight={805} bg="white">
                  <GoalSettings onClose={modalGoalSettings.setHide} />
                </View>
              </View>
            </MenuProvider>
          </Modal>
          {/* </Modal> */}
        </ScreenLayout>
      </QuerySettingsAndGoalsProvider>
    </MainContainer>
  );
};
