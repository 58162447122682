import { useMemo, useState } from 'react';
import { Box, FlatList, HStack, IconButton, Pressable, useTheme } from 'native-base';
import Ionicons from 'react-native-vector-icons/Ionicons';
import moment from 'moment';
import { range } from 'lodash';

import { Text } from '@pimm/base';
import { startOfWeek } from '@app/utils/date-formatter';
import { useAppGlobalsGoals } from '@app/features/app';

const CALENDAR_WEEKS = 6;
const CALENDAR_DAY_WIDTH = 34;
const CALENDAR_WEEK_DAY_HEGHT = 28;
const CALENDAR_WIDTH = 252;
const CALENDAR_HEIGHT = CALENDAR_DAY_WIDTH * CALENDAR_WEEKS + CALENDAR_WEEK_DAY_HEGHT;

export type CalendarPickerMode = 'single' | 'week';

export type CalendarPickerProps = {
  date?: Date;
  dimension?: {
    width?: number;
    height?: number;
  };
  mode?: CalendarPickerMode;
  today?: Date;
  min?: Date;
  max?: Date;
  utcOffset?: string;
  weekday?: number;
  renderDay?: (day: Date, options: { isToday?: boolean; isActive: boolean }) => React.ReactNode;
  onChange?: (date: Date, endDate?: Date) => void;
};

export const CalendarPicker = ({ dimension, max, min, mode = 'single', utcOffset = '', ...props }: CalendarPickerProps) => {
  const { colors } = useTheme();
  const weekdayGlobal = useAppGlobalsGoals(state => state.weekday);
  const [currentDate, setCurrentDate] = useState<Date>(props.date ?? props.today ?? new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(currentDate);

  const weekday = props.weekday ?? weekdayGlobal ?? 1;

  const month = useMemo(() => {
    let startOfDate: Date | undefined;
    let endOfDate: ReturnType<typeof moment> | undefined;

    const startOfMonth = moment(currentDate).startOf('month').toDate();
    const endOfMonth = moment(startOfMonth).endOf('month').toDate();
    const startOfLastWeekOfMonth = startOfWeek(endOfMonth, weekday);
    const endOfCalendarMonth = moment(startOfLastWeekOfMonth).add(6, 'days');

    endOfDate = endOfCalendarMonth;
    startOfDate = startOfWeek(startOfMonth, weekday);

    return {
      numOfDays: endOfDate.diff(startOfDate, 'days'),
      startOfDate: startOfDate,
      endOfDate: endOfDate.toDate(),
    };
  }, [currentDate]);

  // Get start and end of week
  const week = useMemo(() => {
    if (mode !== 'week' || !selectedDate) return undefined;
    const startDate = startOfWeek(selectedDate, weekday);
    const endOfDate = moment(startDate).add(6, 'days').toDate();
    return {
      startOfWeek: startDate,
      endOfDate: endOfDate,
    };
  }, [selectedDate, weekday]);

  const handleChangeDate = date => () => {
    if (props.onChange) {
      if (mode === 'week' && week) {
        const day = moment(date);
        const startOfWeek = day.startOf('week').weekday(weekday);
        const endOfWeek = startOfWeek.clone().add(6, 'days');
        props.onChange(startOfWeek.toDate(), endOfWeek.toDate());
      } else props.onChange(date);
    }
    setSelectedDate(date);
  };

  const handleChangeMonth = (step: number) => () => {
    setCurrentDate(moment(currentDate).add(step, 'month').toDate());
  };

  return (
    <Box pt={3} pb={2} px={2}>
      <HStack flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: CALENDAR_WIDTH }} px={1}>
        {/* Back to previous month */}
        <IconButton
          width={7}
          height={7}
          onPress={handleChangeMonth(-1)}
          _hover={{ backgroundColor: 'gray.100' }}
          icon={<Ionicons name="chevron-back-sharp" size={20} color={colors.gray[700]} />}
        />
        {/* Month and Year text display */}
        <Text size="lg" fontWeight={600} color="black">
          {moment(currentDate).format('MMM YYYY')}
        </Text>
        {/* Got to the next month */}
        <IconButton
          width={7}
          height={7}
          onPress={handleChangeMonth(1)}
          _hover={{ backgroundColor: 'gray.100' }}
          icon={<Ionicons name="chevron-forward-sharp" size={20} color={colors.gray[700]} />}
        />
      </HStack>

      {/* List of days in a month */}
      <FlatList
        width={CALENDAR_WIDTH}
        height={CALENDAR_HEIGHT}
        flex={1}
        data={range(6)}
        style={{ height: CALENDAR_HEIGHT }}
        ListHeaderComponent={() => (
          // Render the list of week days
          <HStack w="full">
            {range(0, 7).map(index => {
              const day = moment(month.startOfDate).add(index, 'days');
              return (
                <Box key={index} flex={1} alignItems="center" justifyContent="center" w={CALENDAR_WIDTH / 7} h={10}>
                  <Text size="sm" fontWeight={400} color="gray.700">
                    {day.format('ddd')}
                  </Text>
                </Box>
              );
            })}
          </HStack>
        )}
        renderItem={({ item, index }) => {
          const startingDays = index * 7;
          let isActiveWeek: boolean = false;

          if (mode === 'week') {
            // Highlight the whole row if the current mode is 'week'
            const startOfWeek = moment(month.startOfDate).add(startingDays, 'days').weekday(weekday);
            const endOfWeek = startOfWeek.clone().add(6, 'days').endOf('day');
            isActiveWeek = moment(selectedDate).isBetween(startOfWeek, endOfWeek, null, '[]');
          }

          return (
            <HStack
              key={index}
              w="full"
              backgroundColor={isActiveWeek ? 'gray.200' : undefined}
              borderRadius={isActiveWeek ? CALENDAR_WEEK_DAY_HEGHT : undefined}
            >
              {range(startingDays, startingDays + 7).map(days => {
                const day = moment(month.startOfDate).add(days, 'days');
                const isToday = day.isSame(props.today ?? new Date(), 'days');
                // Highlight dates for the current month
                const isSameMonth = day.isSame(moment(currentDate), 'months');
                // Highlight current active date base on the given selected or current date
                const isActiveDate = day.isSame(moment(selectedDate || currentDate), 'days');
                const textColor = isSameMonth ? 'black' : 'gray.500';
                const itemDay = props.renderDay
                  ? props.renderDay(day.toDate(), {
                      isActive: isActiveDate,
                      isToday: isToday,
                    })
                  : undefined;

                return (
                  <Pressable
                    key={startingDays + days}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    disabled={(min && day.toDate() < min) || (max && day.toDate() > max)}
                    backgroundColor={isActiveDate ? 'gray.200' : undefined}
                    borderRadius={isActiveDate ? '50%' : undefined}
                    onPress={handleChangeDate(day.toDate())}
                    style={{
                      // total rows must be 6 to ensure that the active cell is rounded
                      height: (CALENDAR_HEIGHT - CALENDAR_WEEK_DAY_HEGHT) / CALENDAR_WEEKS,
                      width: CALENDAR_WIDTH / 7,
                    }}
                    _disabled={{
                      opacity: 0.2,
                    }}
                    _hover={
                      !isActiveWeek && !isActiveDate
                        ? {
                            backgroundColor: 'gray.100',
                            borderRadius: '50%',
                          }
                        : undefined
                    }
                  >
                    {itemDay || (
                      <Text size="md" color={isToday ? 'primary.500' : textColor}>
                        {day.toDate().getDate()}
                      </Text>
                    )}
                  </Pressable>
                );
              })}
            </HStack>
          );
        }}
        scrollEnabled={false}
        numColumns={1}
      />
    </Box>
  );
};
