import { HStack, Popover, QuestionOutlineIcon, VStack } from 'native-base';
import { Button, EllipseBlankIcon, EllipseCompletedIcon, EllipsePartialIcon, Text } from '@pimm/base';

export const PopoverTooltipShiftCheckPoints = () => {
  return (
    <Popover
      isKeyboardDismissable
      trigger={popoverProps => {
        return (
          <Button variant="unstyled" shadow={1} px={0} minH={8} minW={8} bg="white" {...popoverProps}>
            <QuestionOutlineIcon size={5} color="gray.900" />
          </Button>
        );
      }}
    >
      <Popover.Content accessibilityLabel="Shift Checkpoints Tooltip" bg="white" rounded="lg">
        <Popover.Arrow zIndex={1} bg="white" />
        <Popover.Body p={3} pb={4} w="300" bg="white">
          <VStack space={1.5}>
            <Text size="lg" fontWeight={700} color="black" lineHeight="sm">
              Shift Checkpoints
            </Text>
            <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
              Each tab corresponds to the scheduled start time for a manager or employee.
            </Text>

            <VStack space={1.5} mt={3}>
              <Text size="md" fontWeight={600} color="black" lineHeight="sm" mb={1}>
                Status Indicators
              </Text>
              <HStack space={2} alignItems="center">
                <EllipseCompletedIcon size={4} />
                <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
                  Fully positioned
                </Text>
              </HStack>
              <HStack space={2} alignItems="center">
                <EllipsePartialIcon size={4} />
                <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
                  Partially positioned
                </Text>
              </HStack>
              <HStack space={2} alignItems="center">
                <EllipseBlankIcon size={4} color="gray.700" />
                <Text size="md" fontWeight={500} color="gray.700" lineHeight="sm">
                  No position plan set
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
