import { QueryKey, UseQueryResult, useQuery } from 'react-query';
import moment from 'moment';

import { GetSchedules } from '@pimm/services/lib/sms-workforce/services';
import { GetSchedulesQueryParams } from '@pimm/services/lib/sms-workforce';

export type GetEmployeeSchedulesParams = Omit<GetSchedulesQueryParams, 'startDate' | 'endDate'> & {
  startDate?: Date;
  endDate?: Date;
  showAll?: boolean;
};

export const useGetEmployeeSchedules = (
  query?: GetEmployeeSchedulesParams,
  queryKey?: string,
): UseQueryResult<Awaited<ReturnType<typeof GetSchedules>>> => {
  const schedules = useQuery({
    cacheTime: 0,
    enabled: !!query?.siteId && !!query?.startDate && !!query?.endDate,
    queryKey: [queryKey ?? 'GetEmployeeSchedules', query] as [QueryKey, GetEmployeeSchedulesParams],
    queryFn: ({ queryKey: [key, params] }) => {
      return GetSchedules({
        siteId: params.siteId,
        pageSize: params.pageSize ?? params.showAll ? 9999 : 25,
        startDate: moment(params.startDate).format().slice(0, 19),
        endDate: moment(params.endDate).format().slice(0, 19),
      });
    },
  });

  return schedules;
};
