import { Box, HStack, Pressable, Progress, VStack, useTheme } from 'native-base';
import { get, last, map, startCase, toLower } from 'lodash';

import { Text } from '@pimm/base';
import { EmpLearningPathStatusEnum, HubStatDto } from '@pimm/services/lib/sms-workforce';
import { ActivityIcon } from '../icons';
import { LearningPathBadge } from './learning-path-badge';
import { getLearningPathMetaStats, learningPathProgressValue } from '../_helper';

type LearningHubCardProps = {
  _container?: React.ComponentProps<typeof VStack>;
  hubStat: HubStatDto;
  isSelected: boolean;
  onPress?: () => void;
};

export const LearningHubCard = ({ _container, hubStat, isSelected, ...props }: LearningHubCardProps) => {
  const { colors } = useTheme();

  return (
    <Box p={2} {..._container}>
      <Pressable rounded="xl" onPress={props.onPress}>
        <Box
          rounded="xl"
          py={2}
          px={4}
          minH="220px"
          borderWidth={1}
          borderColor={isSelected ? 'primary.500' : 'transparent'}
          bg={isSelected ? 'gray.25' : 'white'}
          style={{
            shadowColor: colors.gray[500],
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.8,
            shadowRadius: 2,
          }}
        >
          <Text size="2xl" fontWeight={700} color="black" lineHeight="xl">
            {startCase(toLower([hubStat.firstName, hubStat.lastName].filter(Boolean).join(' ')))}
          </Text>

          <VStack>
            {map(hubStat.learningPathStats, (_learningPath, index) => {
              const stats = getLearningPathMetaStats(_learningPath.learningStatus);
              const progress = learningPathProgressValue(_learningPath.learningPathProgress);

              return (
                <VStack key={_learningPath.learningPathId} space={0.5} justifyContent="center" minH="50px" borderTopWidth={index ? 1 : 0}>
                  <HStack alignItems="center" justifyContent="space-between">
                    <Box flex={1} pt={1} pr={4}>
                      <Text size="md" fontWeight={500} color="black" numberOfLines={2} lineHeight="sm" ellipsizeMode="tail">
                        {_learningPath.learningPath ?? ''}
                      </Text>
                    </Box>

                    <LearningPathBadge status={_learningPath.learningStatus ?? EmpLearningPathStatusEnum.Pending} />
                  </HStack>

                  <HStack space={2} alignItems="center" justifyContent="space-between">
                    <HStack space={2} flex={1} alignItems="center">
                      <Progress
                        flex={1}
                        focusable={false}
                        value={progress}
                        w="full"
                        h="6px"
                        maxWidth="150px"
                        _filledTrack={{
                          bg: get(colors, stats.fillColor),
                        }}
                      />
                      <Box w={8}>
                        <Text size="md" fontWeight={400} color="gray.600">{`${Math.round(progress)}%`}</Text>
                      </Box>
                    </HStack>

                    <HStack space={1} alignItems="center" justifyContent="flex-end" w="110px">
                      <ActivityIcon size="sm" color={colors.gray[600]} />

                      <Text size="md" fontWeight={400} color="gray.600">
                        {_learningPath.lastActivityDaysAgo}
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
              );
            })}
          </VStack>
        </Box>
      </Pressable>
    </Box>
  );
};
