import { useEffect, useMemo, useRef, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { useMutation } from 'react-query';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Box,
  CloseIcon,
  FlatList,
  HStack,
  IconButton,
  Spacer,
  VStack,
  View,
  useBreakpointValue,
  useTheme,
} from 'native-base';
import { chunk, filter, map, partition } from 'lodash';

import { Button, CalendarCheck01Icon, CalendarCheckIcon, Text, ZapFastIcon } from '@pimm/base';
import { ResetConfirmAndAssignByDay } from '@pimm/services/lib/sms-workforce/services';
import { ResetConfirmAndAssignByDayRequest } from '@pimm/services/lib/sms-workforce';
import { formatToISOString, formatToLongDateOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { Pagination, ResourceLoader } from '@app/components/shared';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { useGetPlanStaffs } from '../hooks';
import { DailyStaffReviewList } from './daily-staff-review-block';

type DailyStaffReviewProps = {
  selectedDate: Date;
  siteId: string;
  onClose?: (reset?: boolean) => void;
  onNavigate?: (screen: 'DailySchedules') => void;
};

export const DailyStaffReview = ({ selectedDate, siteId, ...props }: DailyStaffReviewProps) => {
  const { colors } = useTheme();
  const siteTime = useSiteTime();
  const [dayBlocks] = useState<DayBlock[]>(siteTime.toDayBlocks(selectedDate));
  const [startOfBlock] = useState<Date>(dayBlocks[0].startTime);
  const [layout, setLayout] = useState<Pick<LayoutRectangle, 'width' | 'height'>>({ width: 0, height: 0 });
  const [page, setPage] = useState<number>(1);

  const flatRef = useRef<any>(); // TODO: fix typeof ref
  const cols = useBreakpointValue({ md: 4, xl: dayBlocks.length > 4 ? 6 : 4 });
  const [planStaffs, setPlanStaffsParams] = useGetPlanStaffs();

  const resetConfirmAndAssign = useMutation({
    mutationFn: async (params: ResetConfirmAndAssignByDayRequest) => {
      const result = await ResetConfirmAndAssignByDay(params);
      const delay = new Promise(resolve => setTimeout(resolve, 3000));
      // Add delay so the server can process the request before doing refetch
      await delay;
      return result;
    },
  });

  const pages: React.ComponentProps<typeof DailyStaffReviewList>[][] = useMemo(() => {
    const blocks = dayBlocks.map(dayBlock => {
      const staffs = filter(planStaffs.data?.data, staff => {
        const startTime = stringToDateLocal(staff.startTime);
        const endTime = stringToDateLocal(staff.endTime);
        // Return either staff starting time or the original dayblock start time
        const dayStartTime = dayBlock.staffStartTime ?? dayBlock.startTime;

        return !!startTime && !!endTime && (startTime >= dayStartTime || endTime > dayStartTime) && startTime < dayBlock.endTime;
      }).map(({ employee, startTime, endTime }) => {
        return {
          name: [employee?.firstName, employee?.lastName].filter(Boolean).join(' '),
          startTime: stringToDateLocal(startTime),
          endTime: stringToDateLocal(endTime),
        };
      });

      const sections = partition(staffs, staff => {
        const starTime = dayBlock.staffStartTime ?? dayBlock.startTime;
        return !!staff.startTime && !!staff.endTime && staff.startTime <= starTime;
      }).map((staffs, index) => ({
        title: index ? 'Arriving Later' : 'Starting Staff',
        bgColor: index ? undefined : 'blueLight.200',
        color: index ? undefined : 'blueLight.700',
        data: [staffs],
      }));

      return { dayBlock: dayBlock, sections: sections };
    });

    return chunk(blocks, cols);
  }, [cols, dayBlocks, planStaffs.data]);

  const handleChangeLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    setLayout(nativeEvent.layout);
  };

  const handleChangePage = (page: number) => {
    if (flatRef.current) {
      flatRef.current.scrollToOffset({ animated: true, offset: layout.width * (page - 1) });
      setPage(page);
    }
  };

  const handleCloseAndReset = (reset?: boolean) => {
    if (props.onClose) props.onClose(reset);
  };

  const handleResetConfirmAndAssign = async () => {
    const today = siteTime.today();
    const starTime = dayBlocks[0].staffStartTime ?? dayBlocks[0].startTime;
    const currentDateTime = new Date(Math.max(today.getTime(), starTime.getTime()));

    await resetConfirmAndAssign.mutateAsync({
      currentDateTime: formatToISOString(currentDateTime),
      siteId: siteId,
    });
    handleCloseAndReset(true);
  };

  const handlePressNavigate = () => {
    if (props.onNavigate) props.onNavigate('DailySchedules');
  };

  useEffect(() => {
    if (siteId && startOfBlock) {
      // Get employee schedules
      setPlanStaffsParams({ date: startOfBlock, siteId: siteId });
    }
  }, [siteId, startOfBlock]);

  return (
    <VStack w="full" h="full">
      <HStack space={2} alignItems="center" justifyContent="space-between" px={4} minH="55px" borderBottomWidth={1}>
        <CalendarCheckIcon size={6} color={colors.gray[900]} />

        <Text size="xl" fontWeight={600} color="gray.900" lineHeight="xs">
          Schedule Overview
        </Text>

        <Spacer />

        <Box justifyContent="center" flex="none" rounded="lg" px={2} h={7} bg="gray.100">
          <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
            {formatToLongDateOnly(startOfBlock)}
          </Text>
        </Box>

        {!!props.onClose && (
          <IconButton
            p={2}
            disabled={resetConfirmAndAssign.isLoading}
            _pressed={{ bg: 'gray.100' }}
            _hover={{ bg: 'gray.50' }}
            icon={<CloseIcon size={3.5} color="gray.700" />}
            onPress={() => handleCloseAndReset()}
          />
        )}
      </HStack>

      <View flex={1}>
        <ResourceLoader w="full" h="full" isLoading={planStaffs.isIdle || planStaffs.isLoading}>
          <VStack h="full">
            {pages.length > 1 && (
              <HStack alignItems="center" justifyContent="space-between" pt={1.5} px={4}>
                <Button
                  disabled={page === 1}
                  variant="outline"
                  minH={9}
                  minWidth="90px"
                  borderColor="gray.300"
                  startIcon={<ArrowBackIcon color="gray.700" />}
                  onPress={() => handleChangePage(page - 1)}
                >
                  Previous
                </Button>

                <Pagination
                  _container={{ accessibilityHint: 'Daily Staff Review Pagination' }}
                  activePage={page}
                  totalPages={pages.length}
                  onChange={handleChangePage}
                />

                <Button
                  disabled={page == pages.length}
                  variant="outline"
                  minH={9}
                  minWidth="90px"
                  borderColor="gray.300"
                  endIcon={<ArrowForwardIcon color="gray.700" />}
                  onPress={() => handleChangePage(page + 1)}
                >
                  Next
                </Button>
              </HStack>
            )}

            <FlatList
              ref={flatRef}
              flex={1}
              w="full"
              h="full"
              data={pages}
              onLayout={handleChangeLayout}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{ height: 'full' }}
              contentOffset={{ x: 0, y: 0 }}
              pagingEnabled
              horizontal
              extraData={[cols, layout]}
              initialNumToRender={1}
              initialScrollIndex={0}
              decelerationRate={'normal'}
              scrollEventThrottle={16}
              renderItem={({ item: columns }) => {
                if (layout.width === 0) return null;
                return (
                  <HStack py={1.5} px={3} w={layout.width} h={layout.height}>
                    {map(columns, column => {
                      return (
                        <Box key={column.dayBlock.blockNumber} px={1} w={`1/${cols}`}>
                          <DailyStaffReviewList dayBlock={column.dayBlock} sections={column.sections} />
                        </Box>
                      );
                    })}
                  </HStack>
                );
              }}
            />
          </VStack>
        </ResourceLoader>
      </View>

      <HStack space={2} alignItems="center" justifyContent="flex-end" px={4} minH="55px" borderTopWidth={1}>
        <Text size="md" fontWeight={500} color="gray.600" lineHeight="xs" numberOfLines={2}>
          Use{' '}
          <Text size="md" fontWeight={700} color="primary.600" lineHeight="xs">
            "Schedule"
          </Text>{' '}
          to add or update shifts.
        </Text>

        <Button
          variant="unstyled"
          disabled={resetConfirmAndAssign.isLoading}
          startIcon={<CalendarCheck01Icon size="18px" />}
          onPress={handlePressNavigate}
        >
          Schedule
        </Button>
      </HStack>
    </VStack>
  );
};
