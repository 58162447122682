import React, { useMemo } from 'react';
import { DrawerActions, useNavigation, useNavigationState, useRoute } from '@react-navigation/native';
import { Box, Container, Divider, IconButton, IContainerProps, Image, ScrollView, Tooltip, useTheme, View, VStack } from 'native-base';
import { Asset } from 'expo-asset';
import { findLast, groupBy, map, merge } from 'lodash';

import { ChevronRightDoubleIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { ModuleScreen, useAppModules } from '../context';

export type TabContainerProps = IContainerProps & {
  element?: React.ElementType;
  onPressMenuItem?: (screen: ModuleScreen) => void;
};

export const TabContainer: React.FC<TabContainerProps> = ({ children, element, onPressMenuItem, ...props }) => {
  const { colors } = useTheme();
  // const navigation = useNavigation<DrawerNavigationProp<MainNavigationParams>>();
  // TODO: Fix useNavigation typing issue and passing of default/history params
  const navigation = useNavigation();
  const route = useRoute();
  const navigationState = useNavigationState(state => state);
  const { translate } = useAppLocale();
  const { screens } = useAppModules();

  const history = (navigationState?.history ?? []) as { key: string; type: string }[];
  const lastRoute = findLast(history, _ => _.type === 'route');
  const params = route.params as { siteId?: string };

  const menuItems = useMemo(() => {
    return map(groupBy(screens, 'payload.sectionTitle'), (screens, category) => ({
      title: category,
      name: category,
      children: screens,
    }));
  }, [screens]);

  const handlePressMenu = () => {
    navigation.dispatch(DrawerActions.openDrawer());
  };

  const handlePressItem = (screen: ModuleScreen) => () => {
    if (screen.component) {
      // @ts-ignore
      navigation.navigate('Main', { screen: screen.name, siteId: params.siteId });
    }
    if (onPressMenuItem) onPressMenuItem(screen);
  };

  return (
    <Container size="full" maxW="full" safeAreaTop {...props}>
      <View flexDirection="row" size="full" overflowY="hidden">
        <View position="sticky" top={0} left={0} pt={1} h="full" w={{ md: 60, xl: 70 }} zIndex={1} bg="gray.900" overflowY="auto">
          <ScrollView
            h="full"
            contentContainerStyle={{ flexGrow: 1 }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <Box alignItems="center" justifyContent="center" mb={1} h={12}>
              <IconButton
                accessibilityHint="This will open the drawer menu"
                rounded="full"
                p={0}
                size={8}
                bg="white"
                _hover={{ bg: 'white' }}
                _pressed={{ bg: 'gray.100' }}
                icon={<ChevronRightDoubleIcon size={3} color="black" />}
                onPress={handlePressMenu}
              />
            </Box>

            <VStack flex={1} pb={2}>
              {menuItems.map((item, index) => {
                return (
                  <VStack key={item.name} space={0.5} w="full" alignItems="center">
                    {!!index && <Divider mt={1} mb={0.5} w={{ md: 8, xl: 10 }} backgroundColor="gray.600" />}
                    {item.children?.map(screen => {
                      const isActive = !!lastRoute && lastRoute.key.split('-')[0] === screen.name;
                      const title = translate(screen.title, screen.titleTranslations);
                      return (
                        <Tooltip key={screen.name} label={title ?? ''} openDelay={500} placement="right" bgColor="gray.700">
                          <IconButton
                            key={item.name}
                            icon={React.createElement(
                              screen.icon,
                              merge({ size: 'md', color: colors.gray[isActive ? 100 : 300] }, screen.iconProps || {}),
                            )}
                            _hover={{ bg: isActive ? 'gray.700' : 'gray.900' }}
                            bg={isActive ? 'gray.700' : undefined}
                            color={screen.disabled ? 'gray.500' : 'gray.50'}
                            rounded="md"
                            isDisabled={screen.disabled}
                            h={12}
                            w={12}
                            onPress={handlePressItem(screen)}
                          />
                        </Tooltip>
                      );
                    })}
                  </VStack>
                );
              })}
            </VStack>

            <Box position="sticky" bottom={0} alignItems="center" justifyContent="center" h={12} bg="gray.800">
              <Box rounded="full" size="32px" alignItems="center" justifyContent="center" bg="white">
                <Image
                  source={{
                    uri: Asset.fromModule(require('assets/opsbee.svg')).uri,
                  }}
                  resizeMode="contain"
                  resizeMethod="scale"
                  size="30px"
                  justifyContent="center"
                />
              </Box>
            </Box>
          </ScrollView>
        </View>
        <View flex={1} zIndex={0}>
          {element ? React.createElement(element, {}) : children}
        </View>
      </View>
    </Container>
  );
};
