import { Box, HStack, IconButton, Popover, useBreakpointValue, useTheme, VStack } from 'native-base';

import { CircularProgress, InfoCircleIcon, Text } from '@pimm/base';
import { RelayConfigDto } from '@pimm/services/lib/store-equipment';
import { WifiOffIcon } from '../icons';
import { useBoolean } from 'usehooks-ts';
import { PropsWithChildren } from 'react';
import { SensorInfo } from './sensor-info';

type GatewayCardProps = PropsWithChildren & {
  hub: RelayConfigDto;
  isPrimary?: boolean;
  deviceType?: string;
  onPressInfo?: () => void;
};

export const GatewayCard = ({ hub, isPrimary, deviceType, ...props }: GatewayCardProps) => {
  const popoverIsOpen = useBoolean();
  const handlePopoverClose = () => {
    popoverIsOpen.setFalse();
  };

  return (
    <HStack space={2} rounded="xl" alignItems="center" justifyContent="space-between" py={2} px={{ base: 2, xl: 3 }} h="full" bg="white">
      <Box flex={1} justifyContent="center" pl={1} h="full">
        <Text size={{ base: 'lg', xl: 'xl' }} fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase" mb={1}>
          {hub.Label ?? hub.Type}
        </Text>
        <Text size={{ base: 'sm', xl: 'md' }} fontWeight={700} color="black" lineHeight="md">
          {hub?.AdditionalProperties?.Model ? `${hub.AdditionalProperties?.Model} ${hub.Type}` : ' '}
        </Text>
        <Text size={{ base: 'sm', xl: 'md' }} fontWeight={700} color="black" lineHeight="xs">
          <Text size={{ base: 'sm', xl: 'md' }} fontWeight={400} color="black" lineHeight="xs">
            S/N
          </Text>{' '}
          {hub.Serial}
        </Text>
      </Box>

      <HStack space={2} h="full">
        <Popover
          isKeyboardDismissable
          isOpen={popoverIsOpen.value}
          onClose={handlePopoverClose}
          onOpen={popoverIsOpen.setTrue}
          trigger={triggerProps => (
            <IconButton
              rounded="full"
              p={1}
              size="md"
              icon={<InfoCircleIcon color="primary.500" />}
              onPress={props.onPressInfo}
              {...triggerProps}
            />
          )}
        >
          <Popover.Content accessibilityLabel="Sensor Info" borderWidth={0} shadow="5" borderRadius={15}>
            <Popover.Arrow zIndex={100} borderWidth={0} bgColor="gray.50" borderColor="transparent" />
            <Popover.Body py={3} w={260} bgColor="gray.50" justifyContent="center">
              <VStack justifyContent="center" w="full">
                <Box px={0.5} h={8}>
                  <Text size="xl" fontWeight={700} color="black" lineHeight="xs">
                    Hub Information
                  </Text>
                </Box>

                <Box position="relative" alignItems="center">
                  <HStack space={3} w="full">
                    <Box flex={1}>
                      <SensorInfo isHubInfo isPrimary={isPrimary} sensor={hub} sensorName={hub.Type} deviceType={deviceType} />
                    </Box>
                  </HStack>
                </Box>
              </VStack>
            </Popover.Body>
          </Popover.Content>
        </Popover>
      </HStack>
    </HStack>
  );
};
