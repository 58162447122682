import React, { createContext, useContext } from 'react';

import { useGetPositionGuideDataLive } from '../hooks/get-position-guide-live.hook';

export type KitchenPositionGuideLiveContextReturn = ReturnType<typeof useGetPositionGuideDataLive>[0];

export const KitchenPositionGuideLiveContext = createContext<KitchenPositionGuideLiveContextReturn>(undefined!);

export type KitchenPositionGuideLiveProviderProps = {
  children: React.ReactNode;
  livePositionGuide: ReturnType<typeof useGetPositionGuideDataLive>[0];
};

export const KitchenPositionGuideLiveProvider = ({ children, livePositionGuide }: KitchenPositionGuideLiveProviderProps) => {
  return <KitchenPositionGuideLiveContext.Provider value={livePositionGuide}>{children}</KitchenPositionGuideLiveContext.Provider>;
};

export const useKitchenPositionGuideLive = () => {
  // Get the context
  const context = useContext(KitchenPositionGuideLiveContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useKitchenPositionGuideLive was used outside of its Provider');
  }
  return context;
};
