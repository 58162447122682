import { Box, HStack, useTheme, View } from 'native-base';
import { isEmpty } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalInsightDto } from '@pimm/services/lib/sms-workforce';
import { ResourceLoader } from '@app/components/shared';
import { EllipseCompletedIcon, Ellipse51Icon } from '../icons';

type TalkToMeProps = {
  goalInsights?: GoalInsightDto[];
};

export const TalkToMe = ({ goalInsights }: TalkToMeProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();

  return (
    <View rounded="lg" p={3} borderWidth={1} bg="white">
      <ResourceLoader h="full" w="full" emptyMessage="No available goals" isEmpty={isEmpty(goalInsights)}>
        <HStack space={2}>
          {goalInsights?.map(item => {
            return (
              <Box
                key={item.title}
                rounded="xl"
                flex={1}
                justifyContent="center"
                px={2}
                h="56px"
                borderWidth={1}
                borderColor={item.hasGoal ? colors.success[500] : undefined}
                bgColor={item.hasGoal ? colors.success[50] : undefined}
              >
                <HStack space={2} alignItems="center" justifyContent="space-between" pl={1}>
                  <Text size="md" fontWeight={700} color="black" lineHeight="xs" numberOfLines={3}>
                    {translate(item.title, item?.translations)}
                  </Text>
                  <Box w={5}>
                    {item.hasGoal ? (
                      <EllipseCompletedIcon size={5} color={colors.success[500]} />
                    ) : (
                      <Ellipse51Icon size={5} color={colors.gray[400]} />
                    )}
                  </Box>
                </HStack>
              </Box>
            );
          })}
        </HStack>
      </ResourceLoader>
    </View>
  );
};
