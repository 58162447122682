import { useEffect, useState } from 'react';
import { Box, HStack, ScrollView, VStack, useTheme } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { first } from 'lodash';

import { Button, Text } from '@pimm/base';
import { SiteConfigDto } from '@pimm/services/lib/sms-tenants';
import { KitchenLayoutDto } from '@pimm/services/lib/sms-positioning';
import { PositionGroupDto, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatToISOString } from '@app/utils/date-formatter';
import { useDayBlockFocus } from '@app/hooks/day-blocks.hook';

type StoreConfigProps = {
  kitchenLayout?: KitchenLayoutDto;
  siteConfig?: SiteConfigDto;
  onCancel?: () => void;
};

export const StoreConfig = ({ kitchenLayout, siteConfig, ...props }: StoreConfigProps) => {
  const { colors } = useTheme();
  const dayBlockFocus = useDayBlockFocus();
  const [positionGroup, setPositionGroup] = useState<PositionGroupDto>();

  useEffect(() => {
    if (siteConfig && siteConfig.id) {
      const dayBlock = dayBlockFocus.selectNow();
      const dateTime = formatToISOString(dayBlock.startTime).split('T');

      SmsWorkforceApi.GetPositionSchedules({
        siteId: siteConfig.id,
        date: dateTime[0],
        groupStartTime: dateTime[1],
      }).then(result => {
        setPositionGroup(first(result));
      });
    }
  }, [siteConfig?.id]);

  return (
    <VStack>
      <HStack alignItems="center" px={4} h="52px" borderBottomWidth={1}>
        <Text size="xl" fontWeight={600} color="gray.900">
          Store Configuration
        </Text>
      </HStack>

      <Box flex={1}>
        <ScrollView bg="gray.50">
          <VStack space={4} py={3} px={4}>
            {/* Corporate Information */}
            <Box>
              <Text size="lg" fontWeight={700} mb={1} color="gray.900">
                Corporate Information
              </Text>

              <VStack rounded="lg" space={3} p={4} borderWidth={1} bg="white">
                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Brand
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {siteConfig?.companyInfo?.brand?.name ?? '--'}
                    </Text>
                  </VStack>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Market
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {siteConfig?.configSettings?.marketTitle}
                    </Text>
                  </VStack>
                </HStack>

                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Customer Name
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {siteConfig?.companyInfo?.consolidationName ?? '--'}
                    </Text>
                  </VStack>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Customer Code
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {siteConfig?.companyInfo?.chainName ?? '--'}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Box>

            {/* Site Configuration */}
            <Box>
              <Text size="lg" fontWeight={700} mb={1} color="gray.900">
                Site Configuration
              </Text>

              <VStack rounded="lg" space={3} p={4} borderWidth={1} bg="white">
                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Ops Mode
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {siteConfig?.configSettings?.opsModeTitle ?? '--'}
                    </Text>
                  </VStack>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Sales Volume Profile
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {siteConfig?.configSettings?.salesVolumeProfile ?? '--'}
                    </Text>
                  </VStack>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      SMS Plan
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {siteConfig?.config?.smsPlan ?? '--'}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Box>

            {/* Kitchen Information */}
            <Box>
              <Text size="lg" fontWeight={700} mb={1} color="gray.900">
                Kitchen Information
              </Text>

              <VStack rounded="lg" space={3} p={4} borderWidth={1} bg="white">
                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Type
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {kitchenLayout?.kitchenType?.title ?? '--'}
                    </Text>
                  </VStack>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Code
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {kitchenLayout?.code ?? '--'}
                    </Text>
                  </VStack>
                </HStack>

                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      # PUW
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {kitchenLayout?.puwCount ?? 0}
                    </Text>
                  </VStack>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      # Sandwich Lines
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {kitchenLayout?.slCount ?? 0}
                    </Text>
                  </VStack>
                </HStack>

                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Title
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {kitchenLayout?.title ?? '--'}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Box>

            {/* Position Plan (Current) */}
            <Box>
              <Text size="lg" fontWeight={700} mb={1} color="gray.900">
                Position Plan (Current)
              </Text>

              <VStack rounded="lg" space={3} p={4} borderWidth={1} bg="white">
                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Code
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {positionGroup?.positionMap?.code ?? '--'}
                    </Text>
                  </VStack>
                </HStack>

                <HStack space={2}>
                  <VStack space={1}>
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Title
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {positionGroup?.positionMap?.title ?? '--'}
                    </Text>
                  </VStack>
                </HStack>

                <HStack space={2}>
                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Start Time
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {positionGroup?.positionMap?.daypartStartTime ?? '--'}
                    </Text>
                  </VStack>

                  <VStack space={1} w="1/3">
                    <Text size="md" fontWeight={500} color="gray.600" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      Min Staff
                    </Text>
                    <Text size="md" fontWeight={600} color="black" numberOfLines={1} ellipsizeMode="tail" lineHeight="xs">
                      {positionGroup?.positionMap?.minStaff ?? '--'}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </ScrollView>
      </Box>

      <HStack space={3} alignItems="center" py={3} px={4} borderTopWidth={1}>
        <Button flex={1} variant="unstyled" onPress={props.onCancel}>
          Cancel
        </Button>

        <Button flex={1} endIcon={<MaterialCommunityIcons name="share-outline" size={20} color="black" />}>
          Share
        </Button>
      </HStack>
    </VStack>
  );
};
