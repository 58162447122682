import { cloneElement, useMemo, useState } from 'react';
import { Box, Button, HStack, IIconProps, IconButton, Pressable, ScrollView, Spacer, VStack, useTheme } from 'native-base';
import { find, isEmpty, map, sortBy } from 'lodash';
import SimpleLineIcons from '@expo/vector-icons/SimpleLineIcons';
import Feather from '@expo/vector-icons/Feather';

import { ButtonGroup, CloseIcon, SearchField, Text } from '@pimm/base';
import { SiteSimpleDto } from '@pimm/services/lib/sms-tenants';
import { useAppSelector } from '@app/store/store';
import { selectUserProfile } from '@app/store/selectors';
import { completeAddress } from '@app/utils/complete-address';
import { ResourceLoader } from '@app/components/shared';

type SiteListProps = {
  siteId?: string;
  onClose: () => void;
  onSelectSite: (site: SiteSimpleDto) => void;
};

export const SiteList = ({ siteId, onSelectSite, ...props }: SiteListProps) => {
  const { colors } = useTheme();
  const userProfile = useAppSelector(selectUserProfile);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>(siteId);
  const [tabFocus, setTabFocus] = useState<'Active' | 'No Service'>('Active');

  const siteList = useMemo(() => {
    const { sites = [] } = userProfile ?? {};
    const filteredSites = sites.filter(site => (tabFocus === 'Active' ? site.isActive : !site.isActive));

    if (!isEmpty(searchValue?.trim())) {
      const loweredSearchValue = searchValue.toLowerCase();
      return filteredSites.filter(
        site =>
          site.name?.toLowerCase().includes(loweredSearchValue) ||
          completeAddress(site.address)?.toLowerCase().includes(loweredSearchValue),
      );
    }

    return sortBy(filteredSites, ['name']);
  }, [userProfile?.sites, searchValue, tabFocus]);

  const handleApplySite = () => {
    const site = find(userProfile.sites, ['id', selectedSiteId]);
    if (site) {
      onSelectSite(site);
      props.onClose();
    }
  };

  return (
    <VStack w="full" h="full">
      {/* Header */}
      <HStack justifyContent="space-between" alignItems="center" h="56px" pl={4} pr={3}>
        <Text size="2xl" fontWeight={600} color="gray.900">
          Select a Site
        </Text>
        <IconButton
          variant="unstyled"
          rounded="sm"
          p={1}
          icon={<CloseIcon size="18px" color={colors.gray[700]} />}
          _pressed={{ bg: 'gray.100' }}
          onPress={props.onClose}
        />
      </HStack>

      {/* Body */}
      <VStack flex={1}>
        <HStack alignItems="center" justifyContent="space-between" py={2} px={4} borderWidth="1px" borderColor="gray.200">
          <ButtonGroup value={tabFocus} onChange={setTabFocus}>
            <ButtonGroup.Item value="Active" minW={90}>
              Active
            </ButtonGroup.Item>
            <ButtonGroup.Item value="No Service" minW={90}>
              No Service
            </ButtonGroup.Item>
          </ButtonGroup>

          <Spacer />

          <Box w={320}>
            <SearchField placeholder="Search Sites" value={searchValue} onChangeText={setSearchValue} onClear={() => setSearchValue('')} />
          </Box>
        </HStack>

        <Box flex={1}>
          <ResourceLoader h="full" w="full" emptyMessage="No Data Available" isEmpty={siteList.length === 0}>
            <ScrollView h="full" bgColor="gray.50">
              <VStack py={3}>
                <HStack space={2} px={4} alignItems="center">
                  <Text size="lg" fontWeight={600} color="gray.900">
                    {tabFocus}
                  </Text>

                  <Box
                    minW={6}
                    h={6}
                    rounded="md"
                    justifyContent="center"
                    alignItems="center"
                    borderWidth={1}
                    borderColor="gray.300"
                    bgColor="white"
                  >
                    <Text size="md" fontWeight={600} color="gray.700" lineHeight="md">
                      {siteList?.length}
                    </Text>
                  </Box>
                </HStack>

                <HStack flexWrap="wrap" p={3} pt={2}>
                  {map(siteList, (site, index) => {
                    const isSiteSelected = selectedSiteId && site?.id === selectedSiteId;

                    return (
                      <Box key={`${site.name}.${index}`} w={{ md: '1/3', xl: '1/4' }} p={1}>
                        <Pressable
                          flex={1}
                          rounded="lg"
                          borderWidth={1}
                          isDisabled={!site.isActive}
                          borderColor={isSiteSelected ? 'primary.500' : 'gray.300'}
                          bgColor={isSiteSelected ? 'primary.50' : 'white'}
                          style={{
                            shadowColor: colors.gray[400],
                            shadowOffset: { width: 0, height: 1 },
                            shadowOpacity: 0.7,
                            shadowRadius: 1,
                          }}
                          onPress={() => setSelectedSiteId(site?.id)}
                        >
                          <VStack space={2} p={3} pb={2.5} opacity={site?.isActive ? 1 : 0.3} minH={110}>
                            <Text size="md" fontWeight={700} color="black" lineHeight="sm">
                              {site.name}
                            </Text>

                            <Box flex={1}>
                              <HStack space={1}>
                                <SimpleLineIcons name="location-pin" size={12} color="black" />
                                <Text size="md" fontWeight={500} color="gray.800" lineHeight="sm" noOfLines={2} ellipsizeMode="tail">
                                  {completeAddress(site.address)}
                                </Text>
                              </HStack>
                            </Box>

                            <HStack
                              py={1}
                              px={2}
                              rounded="md"
                              bgColor="gray.25"
                              borderColor={`${isSiteSelected ? 'primary' : 'gray'}.100`}
                              borderWidth="1px"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Text size="sm" fontWeight={700} color="black" lineHeight="sm" textTransform="uppercase">
                                {site?.smsPlan ?? 'None'}
                              </Text>

                              <HStack space={1}>
                                {map(
                                  [
                                    { icon: <Feather name="tablet" />, planTypes: ['Basic', 'BasicPrep', 'Plus', 'Premium'] },
                                    { icon: <Feather name="grid" />, planTypes: ['Plus', 'Premium', 'Dop'] },
                                    { icon: <SimpleLineIcons name="printer" />, planTypes: ['BasicPrep', 'Premium', 'PrepLabel'] },
                                  ],
                                  (plan, planIndex) => {
                                    const hasPlan = !!site.smsPlan && plan.planTypes.includes(site.smsPlan);

                                    return (
                                      <Box
                                        key={`${site.name}.${index}.${planIndex}`}
                                        rounded="md"
                                        borderWidth="1px"
                                        borderColor={`${hasPlan ? 'success' : 'gray'}.400`}
                                        size="6"
                                        alignItems="center"
                                        justifyContent="center"
                                        display="flex"
                                      >
                                        {cloneElement<IIconProps>(plan.icon, {
                                          color: hasPlan ? colors.success[700] : colors.gray[500],
                                          size: 15,
                                        })}
                                      </Box>
                                    );
                                  },
                                )}
                              </HStack>
                            </HStack>
                          </VStack>
                        </Pressable>
                      </Box>
                    );
                  })}
                </HStack>
              </VStack>
            </ScrollView>
          </ResourceLoader>
        </Box>
      </VStack>

      {/* Footer */}
      <VStack py={3} px={4} borderTopWidth="1px" borderTopColor="gray.200">
        <Button
          alignSelf="flex-end"
          minW={120}
          isDisabled={!selectedSiteId || selectedSiteId === siteId || tabFocus === 'No Service'}
          onPress={handleApplySite}
        >
          Apply
        </Button>
      </VStack>
    </VStack>
  );
};
