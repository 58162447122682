import { useEffect, useState } from 'react';
import { Box, HStack, Spacer, VStack, Image, useMediaQuery } from 'native-base';
import { find } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { KitchenWeeklyGoals, useKitchenPositionGuideLive } from '@app/features/kitchen-positioning';
import { GoalTypesSelection } from '@app/features/ops-plan';
import { useGoalTypes } from '@app/features/store-info';
import { AggGoalTypeDto } from '@pimm/services/lib/sms-workforce';

type KitchenGoalsProps = React.ComponentProps<typeof VStack> & {
  onEditGoals?: () => void;
};

export const KitchenGoals = (props: KitchenGoalsProps) => {
  const { translate } = useAppLocale();
  const goalTypes = useGoalTypes();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const livePositionGuide = useKitchenPositionGuideLive();
  const weeklyFocusSetting = livePositionGuide.data?.weeklyFocusSetting;
  const [osat, setOsat] = useState<AggGoalTypeDto>();

  useEffect(() => {
    setOsat(find(goalTypes.data, ['seq', 0]));
  }, [goalTypes.data]);

  return (
    <VStack h="full">
      {livePositionGuide.isSuccess && (
        <VStack space={{ xl: 1 }} pt={1} pb={2} borderBottomWidth={1}>
          <HStack space={1} alignItems="center" px={3}>
            {!!weeklyFocusSetting?.headerImg && (
              <Image source={{ uri: weeklyFocusSetting?.headerImg }} style={{ width: 50, height: 50 }} resizeMode="contain" />
            )}

            <Text size={{ md: 'xl', xl: '2xl' }} fontWeight={700} color="black" lineHeight="xs" numberOfLines={2} ellipsizeMode="tail">
              {translate(weeklyFocusSetting?.title, weeklyFocusSetting?.translations)}
            </Text>

            <Spacer />

            <HStack space={2} alignItems="center" justifyContent="center" rounded="lg" py={2}>
              {!isSmallScreen && (
                <Text size="xl" fontWeight={700} color="blue.600" lineHeight="xs">
                  {`${translate(osat?.title, osat?.translations)} ${osat?.score ?? 0}%`}
                </Text>
              )}

              {isSmallScreen && (
                <HStack space={1}>
                  <VStack
                    space={1}
                    rounded="lg"
                    alignItems="center"
                    justifyContent="center"
                    w={110}
                    h="58px"
                    borderWidth={2}
                    borderColor="blueLight.500"
                    bg="blueLight.100"
                  >
                    <Text size="sm" fontWeight={700} color="blue.500" lineHeight="xs">
                      {translate(osat?.title, osat?.translations)}
                    </Text>
                    <Text size="lg" fontWeight={700} color="blue.500" lineHeight="xs">
                      {`${osat?.score ?? 0}%`}
                    </Text>
                  </VStack>
                  <GoalTypesSelection
                    _container={{ px: { md: 0, xl: 3 }, minW: { md: 520, xl: 'auto' }, h: '58px', flex: 1 }}
                    _icon={{
                      color: 'black',
                      size: 4,
                    }}
                    showTitle
                    disabled
                    goalTypes={goalTypes.data}
                    goals={weeklyFocusSetting?.goalTypeIds}
                  />
                </HStack>
              )}
            </HStack>
          </HStack>
          {!isSmallScreen && (
            <GoalTypesSelection
              _container={{ px: 3 }}
              _icon={{
                color: 'black',
                size: 4,
              }}
              disabled
              goalTypes={goalTypes.data}
              goals={weeklyFocusSetting?.goalTypeIds}
            />
          )}
          <Text color="gray.700" fontWeight={500} size="md" px={3} textAlign={isSmallScreen ? 'right' : 'left'}>
            {translate(weeklyFocusSetting?.subHeaderCaption, weeklyFocusSetting?.subHeaderCaptionTranslations)}
          </Text>
        </VStack>
      )}
      <Box flex={1} px={1}>
        <KitchenWeeklyGoals categories={livePositionGuide.data?.weeklyFocusSetting?.categories} onEditGoals={props.onEditGoals} />
      </Box>
    </VStack>
  );
};
