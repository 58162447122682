import { useMemo } from 'react';
import { Box, Flex, HStack, Progress, VStack, useTheme } from 'native-base';
import { chain, find, get, isEmpty, map } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { PositionInsightDto } from '@pimm/services/lib/sms-workforce';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { formatToISOString } from '@app/utils/date-formatter';
import { assignmentMapping } from '../_helper';
import { ProgressStatus } from './progress-status';

type DailyAssignmentsProps = {
  date: Date;
  dayBlocks: DayBlock[];
  positionInsights?: PositionInsightDto[];
};

export const DailyAssignments = ({ date, dayBlocks, positionInsights }: DailyAssignmentsProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();

  const blocksWithAssignments = useMemo(() => {
    return chain(positionInsights)
      .filter(item => !!item.blockStartDateTime && item.blockStartDateTime?.startsWith(formatToISOString(date, true)))
      .thru(filteredPositionInsights => {
        return map(dayBlocks, dayBlock => {
          const position = find(filteredPositionInsights, {
            blockTitle: dayBlock.title,
          });

          return {
            ...dayBlock,
            assignments: map(assignmentMapping, value => ({
              title: value.title,
              caption: value.caption,
              data: get(position, value.dataField),
            })),
          };
        });
      })
      .value();
  }, [date, dayBlocks, positionInsights]);

  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {map(blocksWithAssignments, (_block, index) => {
        const isEven = index % 2 === 0;
        return (
          <Box pb={3} pr={isEven ? 1.5 : 0} pl={!isEven ? 1.5 : 0} w="1/2" key={`assignment[${index}]`}>
            <Box rounded="xl" py={3} px={4} borderWidth={1} bg="gray.25">
              <Text size="lg" fontWeight={700} color="gray.900" textTransform="uppercase">
                {translate(_block.title, _block?.titleTranslations)}
              </Text>

              <VStack space={5} mt={3}>
                {map(_block.assignments, (_assignment, i) => {
                  const total = _assignment.data?.totalCount || 0;
                  const value = _assignment.data?.filledCount || 0;
                  const color = value === total ? colors.success[500] : colors.warning[400];

                  return (
                    <HStack key={`${_assignment.title}.${i}`} space={3}>
                      <ProgressStatus total={_assignment.data?.totalCount} value={_assignment.data?.filledCount} isWeekly={false} />
                      <VStack flex={1}>
                        <Text size="lg" fontWeight={600} color="black" lineHeight="xs" numberOfLines={1}>
                          {_assignment.title}
                        </Text>
                        {!!_assignment.caption && (
                          <Text size="md" fontWeight={500} color="gray.700" lineHeight="xs" numberOfLines={1}>
                            {isEmpty(_assignment?.data?.phases)
                              ? _assignment.caption
                              : map(_assignment.data.phases, ({ phase, phaseTranslations }) =>
                                  (translate(phase, phaseTranslations) ?? '').replace('|', '\n'),
                                ).join(' & ')}
                          </Text>
                        )}
                        <HStack alignItems="center" justifyContent="space-between" mt={1}>
                          <Progress
                            flex={1}
                            value={(value / total) * 100}
                            h="6px"
                            _filledTrack={{
                              bg: color,
                            }}
                          />
                          <Box minW="44px">
                            <Text size="lg" fontWeight={600} color="black" lineHeight="xs" textAlign="right">
                              {`${value}/${total}`}
                            </Text>
                          </Box>
                        </HStack>
                      </VStack>
                    </HStack>
                  );
                })}
              </VStack>
            </Box>
          </Box>
        );
      })}
    </Flex>
  );
};
