import { Icon, IIconProps } from 'native-base';
import { Path, G } from 'react-native-svg';

export const IdentificationCardIcon = ({
  color = 'currentColor',
  size = 'sm',
  ...props
}: IIconProps) => {
  return (
    <Icon
      color={color}
      size={size}
      strokeWidth="0"
      viewBox="0 0 20 19"
      {...props}
    >
      <Path
        d="M10 0.400024C9.33728 0.400024 8.80002 0.937283 8.80002 1.60002V2.80002C8.80002 3.46277 9.33728 4.00002 10 4.00002C10.6628 4.00002 11.2 3.46277 11.2 2.80002V1.60002C11.2 0.937283 10.6628 0.400024 10 0.400024Z"
        fill="currentColor"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80002 2.80002H6.40002C6.40002 4.78825 8.0118 6.40003 10 6.40003C11.9882 6.40003 13.6 4.78825 13.6 2.80002H17.2C18.5255 2.80002 19.6 3.87454 19.6 5.20003V16C19.6 17.3255 18.5255 18.4 17.2 18.4H2.80002C1.47454 18.4 0.400024 17.3255 0.400024 16V5.20003C0.400024 3.87454 1.47454 2.80002 2.80002 2.80002ZM5.80002 11.2C6.79414 11.2 7.60002 10.3941 7.60002 9.40003C7.60002 8.40591 6.79414 7.60003 5.80002 7.60003C4.80591 7.60003 4.00002 8.40591 4.00002 9.40003C4.00002 10.3941 4.80591 11.2 5.80002 11.2ZM8.7406 16C8.77995 15.8062 8.80061 15.6055 8.80061 15.4C8.80061 13.7432 7.45746 12.4 5.80061 12.4C4.14376 12.4 2.80061 13.7432 2.80061 15.4C2.80061 15.6055 2.82127 15.8062 2.86062 16H8.7406ZM12.4 8.80003C11.7373 8.80003 11.2 9.33728 11.2 10C11.2 10.6628 11.7373 11.2 12.4 11.2H16C16.6628 11.2 17.2 10.6628 17.2 10C17.2 9.33728 16.6628 8.80003 16 8.80003H12.4ZM11.2 13.6C11.2 12.9373 11.7373 12.4 12.4 12.4H14.8C15.4628 12.4 16 12.9373 16 13.6C16 14.2628 15.4628 14.8 14.8 14.8H12.4C11.7373 14.8 11.2 14.2628 11.2 13.6Z"
        fill="currentColor"
      />
    </Icon>
  );
};
