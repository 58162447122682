import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, HStack, Spacer, VStack, useTheme } from 'native-base';
import { find, first, isEmpty, isEqual, map, startCase, toLower } from 'lodash';

import { ConnectedDevicesIcon, CleanIcon, TargetIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AggPositionSlotDto, PositionAssigneeDto, ShiftDto } from '@pimm/services/lib/sms-workforce';
import { stringToDateLocal } from '@app/utils/date-formatter';
import { hexToRGBA } from '@app/utils/string-formatter';
import { useSiteTime } from '@app/features/store-core';
import { useKitchenPositionGuideLive } from '../context';
import { StarIcon } from '../icons';
import { CollapsibleTile } from './collapsible-tile';
import { TimeDisplay } from './time-display';

type KitchenPositionSlotProps = {
  isCollapsed?: boolean;
  hasCleaningTask?: boolean;
  positionSlot: AggPositionSlotDto;
  onPress?: (positionSlot: AggPositionSlotDto) => void;
};

const KitchenPositionSlot = ({
  isCollapsed,
  hasCleaningTask,
  positionSlot: { positionAssignee, positionJob, ...positionSlot },
  ...props
}: KitchenPositionSlotProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const livePositionGuide = useKitchenPositionGuideLive();
  const [assignee, setAssignee] = useState<PositionAssigneeDto>();

  const color = positionJob?.primaryJob?.color ?? colors.gray[500];
  const isEmptyJobs = isEmpty(positionJob?.secondaryJobs);
  const positionalGoal = first(positionJob?.primaryJob?.positionalGoals);
  const goal = translate(positionalGoal?.description, positionalGoal?.translations);

  const handlePressSelect = () => {
    if (props.onPress)
      props.onPress({
        ...positionSlot,
        positionAssignee: assignee,
        positionJob: positionJob,
      });
  };

  useEffect(() => {
    let timeoutRef;

    // Run a recheck every quarter to see if the assignee is still part of the current time plan
    const displayScheduledAssignee = (person?: PositionAssigneeDto, employeeSchedules?: ShiftDto[]) => {
      if (!person) {
        setAssignee(undefined);
        return undefined;
      }

      const timeNow = siteTime.today();
      const shift = find(employeeSchedules, shift => {
        if (person?.employeeId === shift.employee?.id) {
          const shiftStartTime = stringToDateLocal(shift.startTime);
          const shiftEndTime = stringToDateLocal(shift.endTime);
          return !!shiftStartTime && !!shiftEndTime && timeNow >= shiftStartTime && timeNow < shiftEndTime;
        }
        return false;
      });

      const startTime = stringToDateLocal(shift?.startTime ?? person.startTime);
      const endTime = stringToDateLocal(shift?.endTime ?? person.endTime);
      const isActive = startTime && endTime && timeNow >= startTime && timeNow < endTime;
      // For kitchen display, we need to show the actual shift start and end time
      const nextAssignee = isActive
        ? {
            ...person,
            startTime: shift?.startTime ?? person.startTime,
            endTime: shift?.endTime ?? person.endTime,
          }
        : undefined;

      setAssignee(nextAssignee);

      const nearestQuarterHour = timeNow.toNearestQuarterHour();
      nearestQuarterHour.setSeconds(0, 0);

      return setTimeout(() => {
        displayScheduledAssignee(person, employeeSchedules);
      }, nearestQuarterHour.getTime() - timeNow.getTime());
    };

    // Get the first assignee
    timeoutRef = displayScheduledAssignee(positionAssignee, livePositionGuide.data?.employeeSchedules);
    return () => clearInterval(timeoutRef);
  }, [positionAssignee, livePositionGuide.data?.employeeSchedules]);

  return (
    <CollapsibleTile _container={{ minHeight: isCollapsed ? '244px' : undefined }} isCollapsed={isCollapsed} onPress={handlePressSelect}>
      {/* Primary Position Title */}
      <VStack rounded="xl" space={2} p={1.5} borderWidth={1} borderColor={hexToRGBA(color, 0.4)} bg={hexToRGBA(color, 0.2)}>
        <HStack alignItems="center" space={1.5} pr={0.5}>
          <Box rounded="md" alignContent="center" justifyContent="center" h={6} w={7} bg={hexToRGBA(color, 0.4)}>
            <Text size="lg" color="black" fontWeight={700} textAlign="center">
              {positionSlot.slotNumber ?? ''}
            </Text>
          </Box>

          <HStack flex={1} alignItems="center" h="full">
            {positionJob?.primaryJob?.id ? (
              <Box flex={1} pl={1}>
                <Text size="lg" fontWeight={700} color="black" lineHeight="xs" numberOfLines={2}>
                  {translate(positionJob?.primaryJob?.title, positionJob?.primaryJob.translations)}
                </Text>
              </Box>
            ) : (
              <Box rounded="md" justifyContent="center" px={2.5} w="full" h="full" bg="gray.700">
                <Text size="md" fontWeight={700} color="white" lineHeight="md">
                  No Position Assigned
                </Text>
              </Box>
            )}
          </HStack>

          {!!positionSlot.isOpsLeader && (
            <Box flex="none" rounded="full" p="2px" borderWidth={1} borderColor="white" bg="warning.300">
              <StarIcon size={3.5} />
            </Box>
          )}
        </HStack>

        {/* Assignee */}
        <VStack
          position="relative"
          justifyContent="space-between"
          rounded="lg"
          py={1.5}
          px={2}
          minHeight="58px"
          borderWidth={1}
          borderColor={hexToRGBA(color, 0.5)}
          bg="white"
        >
          {assignee ? (
            <Text size="lg" fontWeight={700} color="black" lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
              {startCase(toLower(assignee.name))}
            </Text>
          ) : (
            <Box position="absolute" justifyContent="center" rounded="lg" px={2} h="44px" maxW="100px" bg="gray.700">
              <Text size="md" fontWeight={600} color="white" lineHeight="xs" textAlign="center" numberOfLines={2}>
                {t('common:no_employee_assigned')}
              </Text>
            </Box>
          )}

          <HStack space={1} alignItems="center">
            {!!assignee?.startTime && !!assignee?.endTime && (
              <TimeDisplay dayBlock={siteTime.toDayBlockNow()} startTime={assignee.startTime} endTime={assignee.endTime} keepAlive />
            )}

            <Spacer />

            {/* Cleaning Task Icon */}
            <CleanIcon size="16px" color={hasCleaningTask && assignee?.id ? colors.primary[500] : colors.gray[300]} />
            {/* Positional Goals Icon */}
            <TargetIcon size="16px" color={!goal ? colors.gray[300] : colors.primary[500]} />
          </HStack>
        </VStack>
      </VStack>

      {/* Secondary Jobs */}
      <HStack alignItems="flex-start" minHeight={isCollapsed ? 1 : 0}>
        {!isEmptyJobs && (
          <Box mt={1.5} mr={1}>
            <ConnectedDevicesIcon size="14px" color="gray.600" />
          </Box>
        )}
        <HStack flex={1} flexWrap="wrap" pt={!isEmptyJobs ? 1.5 : 0}>
          {map(positionJob?.secondaryJobs, job => (
            <Box key={job.id} flex="none" pb={0.5}>
              <Box
                flex="none"
                rounded="md"
                justifyContent="center"
                mr={1.5}
                px={2}
                minH={5}
                borderWidth={1}
                borderColor={hexToRGBA(color, 0.5)}
                bg={hexToRGBA(color, 0.2)}
              >
                <Text size="sm" fontWeight={500} color="black" lineHeight="xs">
                  {translate(job.title, job.translations)}
                </Text>
              </Box>
            </Box>
          ))}
        </HStack>
      </HStack>

      {/* Daily Positional Goal */}
      {isCollapsed && (
        <VStack space={2} pt={1}>
          <Divider bg="gray.200" />

          <HStack space={1.5} alignItems="center" px={1.5}>
            <TargetIcon size="16px" color={!!goal ? colors.primary[500] : colors.gray[500]} />
            <Text size="md" color={!!goal ? 'gray.800' : 'gray.500'} fontWeight={700} lineHeight="xs">
              {t('common:daily_positional_goals')}
            </Text>
          </HStack>

          <Box rounded="lg" px={1.5} pb={2} maxHeight="80px">
            <Text size="md" fontWeight={500} color={!!goal ? 'black' : 'gray.500'} lineHeight="sm" ellipsizeMode="tail" numberOfLines={4}>
              {goal ?? t('kitchen_positioning:no_goals_today')}
            </Text>
          </Box>
        </VStack>
      )}
    </CollapsibleTile>
  );
};

const areEqual = (prevProps, nextProps) => {
  const { isCollapsed: prevIsCollapse, positionSlot: prevPositionSlot, hasCleaningTask: prevHasCleaningTask } = prevProps;
  const { isCollapsed, positionSlot, hasCleaningTask } = nextProps;

  /* if the props are equal, it won't update*/
  return prevIsCollapse === isCollapsed && prevHasCleaningTask === hasCleaningTask && isEqual(prevPositionSlot, positionSlot);
};

export default memo(KitchenPositionSlot, areEqual);
