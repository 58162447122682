import React, { useMemo } from 'react';
import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer';
import { useRoute } from '@react-navigation/native';
import { Box, Divider, HStack, IconButton, Image, Pressable, VStack, useTheme } from 'native-base';
import { Asset } from 'expo-asset';
import Constants from 'expo-constants';
import { findLast, groupBy, map, merge } from 'lodash';

import { Text, ChevronLeftDoubleIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { ModuleScreen, useAppModules } from '@app/features/app';
import { useDashboard } from '@app/features/dashboard';

export const MainDrawerContent = ({
  navigation,
  title,
  ...props
}: DrawerContentComponentProps & {
  title?: string | React.ReactNode;
}) => {
  const { colors } = useTheme();
  const route = useRoute();
  const { translate } = useAppLocale();
  const { toggleModal } = useDashboard();
  const { screens } = useAppModules();
  const lastRoute = findLast(props.state.history, ['type', 'route']) as { key: string } | undefined;
  const params = route.params as { siteId?: string };

  const menuItems = useMemo(() => {
    return map(groupBy(screens, 'payload.sectionTitle'), (screens, category) => ({
      title: category,
      name: category,
      children: screens,
    }));
  }, [screens]);

  const handlePressItem = (screen: ModuleScreen) => () => {
    if (screen.component) {
      navigation.navigate('Main', { screen: screen.name, siteId: params.siteId });
      return;
    }

    if (!!screen.payload?.modal?.type) {
      navigation.closeDrawer();
      setTimeout(() => {
        toggleModal({ type: screen.payload.modal.type });
      }, 300);
    }
  };

  return (
    <DrawerContentScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ padding: 0, backgroundColor: colors.gray[900] }}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      {...props}
    >
      <VStack h="full">
        <VStack flex={1} pb={2}>
          <HStack alignItems="center" justifyContent="space-between" mb={1} pl={6} pr={4} h={12}>
            <Text size="2xl" fontWeight={700} color="white" lineHeight="sm">
              Menu
            </Text>
            <IconButton
              accessibilityHint="This will open the drawer menu"
              rounded="full"
              p={0}
              size={8}
              bg="white"
              _hover={{ bg: 'white' }}
              _pressed={{ bg: 'gray.100' }}
              icon={<ChevronLeftDoubleIcon size={3} color="black" />}
              onPress={navigation.closeDrawer}
            />
          </HStack>

          <Box flex={1} px={3}>
            {menuItems.map((item, index) => (
              <VStack key={index} space={0.5} w="full">
                {!!index && <Divider mt={1} mb={0.5} w="full" backgroundColor="gray.600" />}
                {item.children?.map(screen => {
                  const active = lastRoute?.key?.split('-')[0] === screen.name;
                  return (
                    <Pressable
                      key={screen.name}
                      rounded="md"
                      bg={active ? 'gray.700' : undefined}
                      _hover={{
                        bg: active ? 'gray.700' : 'gray.800',
                      }}
                      onPress={handlePressItem(screen)}
                    >
                      <HStack alignItems="center" space={3} px={3} pr={5} h={12}>
                        <Box alignItems="center" justifyContent="center" w={6}>
                          {React.createElement(
                            screen.icon,
                            merge({ size: 'md', color: colors.gray[screen.active ? 100 : 300] }, screen.iconProps || {}),
                          )}
                        </Box>
                        <Text size="lg" fontWeight={600} color="white" numberOfLines={2} lineHeight="xs">
                          {translate(screen.title, screen.titleTranslations)}
                        </Text>
                      </HStack>
                    </Pressable>
                  );
                })}
              </VStack>
            ))}
          </Box>
        </VStack>

        <HStack position="sticky" bottom={0} space={3} alignItems="center" px={4} w="full" h={12} bg="gray.800">
          <Box rounded="full" size="32px" alignItems="center" justifyContent="center" bg="white">
            <Image
              source={{
                uri: Asset.fromModule(require('assets/opsbee.svg')).uri,
              }}
              resizeMode="contain"
              resizeMethod="scale"
              size="30px"
              justifyContent="center"
            />
          </Box>
          <Box>
            <Text size="2xl" fontWeight={400} color="white" lineHeight="sm">
              <Text size="2xl" fontWeight={700} color="white" lineHeight="sm">
                {Constants.expoConfig?.name}
              </Text>{' '}
              dashboard
            </Text>
            <Text size="sm" fontWeight={500} color="white">
              {`Version ${Constants.expoConfig?.version}`}
            </Text>
          </Box>
        </HStack>
      </VStack>
    </DrawerContentScrollView>
  );
};
