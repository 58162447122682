import { useQueryClient } from 'react-query';
import { Box, HStack, ScrollView, Spacer, VStack, useTheme } from 'native-base';
import { filter, find, map, startCase, toLower } from 'lodash';
import { useBoolean } from 'usehooks-ts';

import { ArrowSquareUpRightIcon, Button, CircularProgress, Modal, Text } from '@pimm/base';
import { FormPinSetup } from '@app/features/account-settings/components/form-pin-set-up';
import { EmpLearningPathStatusEnum, HubStatDto, LearningPathStatDto } from '@pimm/services/lib/sms-workforce';
import { useUsersWithSiteKeys } from '@app/features/app/context';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import FormPasscode from './form-passcode';
import { LearningPathCard } from './learning-path-card';

export type PinSetupModalType = 'pin-setup' | 'validate-pin';

export type ExtendedHubStatDto = HubStatDto & {
  userName?: string;
  pinIsSet?: boolean;
};

type LearningHubPreviewProps = {
  hubStat: ExtendedHubStatDto;
};

export const LearningHubPreview = ({ hubStat }: LearningHubPreviewProps) => {
  const { colors } = useTheme();
  const queryClient = useQueryClient();
  const usersWithSiteKey = useUsersWithSiteKeys(state => state.users);
  const modalFocus = useModalFocus<{ name: PinSetupModalType; url?: string }>();
  const modalName = modalFocus?.isOpen ? modalFocus.payload?.name : undefined;

  const countLearningPath = hubStat.learningPathStats?.length ?? 0;
  const completed = filter<LearningPathStatDto>(
    hubStat.learningPathStats,
    _ => _.learningStatus === EmpLearningPathStatusEnum.Complete,
  ).length;

  const team = find<ExtendedHubStatDto>(usersWithSiteKey, { userName: hubStat?.userName });

  const hidePinSetup = useBoolean(team?.pinIsSet);

  const name = startCase(toLower([team?.firstName, team?.lastName].filter(Boolean).join(' ')));

  const handleEndPinSetup = data => {
    handleOpenModal('validate-pin', modalFocus?.payload?.url)();

    const queryCache = queryClient.getQueryCache();
    const queryPositionGuide = find(queryCache.getAll(), _ => _.queryKey[0] === 'GetUsersWithSiteKey');

    queryClient.setQueryData<ExtendedHubStatDto[]>(
      ['GetUsersWithSiteKey', queryPositionGuide?.queryKey[1]],
      (_prevData): ExtendedHubStatDto[] => {
        const nextData = (_prevData ?? []).map((_user: ExtendedHubStatDto) => {
          if (_user.id === team?.id) {
            return {
              ..._user,
              pinIsSet: data?.success,
            };
          }
          return _user;
        });

        return nextData;
      },
    );
  };

  const handleOpenModal = (_name: PinSetupModalType, _url?: string) => () => {
    modalFocus.setOpen({ name: _name, url: _url });
  };

  return (
    <ScrollView p={4} pt={3}>
      <HStack alignItems="center" justifyContent="space-between" mb={3} space={4}>
        <Box>
          <Text size="3xl" fontWeight={600} color="black" lineHeight="md">
            {name}
          </Text>
          <Text size={hubStat.userName ? 'lg' : 'md'} fontWeight={400} color={hubStat.userName ? 'gray.600' : 'gray.500'} lineHeight="xs">
            {hubStat.userName ?? 'Username not available'}
          </Text>
        </Box>
        <Spacer />
        <CircularProgress
          _text={{ size: 'sm', decimalScale: 1, suffix: '%' }}
          activeStrokeColor={colors.blueLight[500]}
          inActiveStrokeColor={colors.gray[200]}
          radius={25}
          strokeWidth={10}
          value={Math.round((countLearningPath ? completed / countLearningPath : 0) * 100)}
        />

        <Button
          variant="unstyled"
          disabled={!hubStat?.availableLearningPathsUrl}
          bgColor="white"
          onPress={handleOpenModal(team?.pinIsSet ? 'validate-pin' : 'pin-setup', hubStat?.availableLearningPathsUrl)}
          endIcon={<ArrowSquareUpRightIcon size="sm" color="black" />}
        >
          Learning Paths
        </Button>
      </HStack>

      <VStack space={3}>
        {map(hubStat.learningPathStats, _learningPath => {
          return (
            <LearningPathCard
              key={`${hubStat.id}.${_learningPath.learningPathId}`}
              learningPath={_learningPath}
              isPinSet={team?.pinIsSet}
              onOpenModal={handleOpenModal}
            />
          );
        })}
      </VStack>

      <Modal size="sm" _content={{ rounded: 'xl' }} isOpen={modalName === 'validate-pin'} noPadding hideClose>
        <FormPasscode team={team} onClose={modalFocus.setHide} onOpenModal={handleOpenModal} learningPathUrl={modalFocus?.payload?.url} />
      </Modal>

      <Modal noPadding isOpen={modalName === 'pin-setup'} size="md" _content={{ rounded: 'xl', w: 350 }} hideClose>
        <FormPinSetup
          userId={team?.id!}
          isPinNotSet={!hidePinSetup?.value}
          onCancel={() => {
            modalFocus.setHide();
            if (!team?.pinIsSet) hidePinSetup.setFalse();
          }}
          onPressYes={() => hidePinSetup.setTrue()}
          onEndChange={handleEndPinSetup}
          PinHeaderComponent={
            !hidePinSetup?.value ? (
              <VStack pb={4}>
                <Text size="2xl" fontWeight={700} color="gray.900">
                  PIN number not set up
                </Text>
                <Text size="lg" color={colors.gray[900]} fontWeight={500} lineHeight={16}>
                  {name} does not have a PIN set up. Would you like to setup a PIN number for this user?
                </Text>
              </VStack>
            ) : (
              <VStack pb={4}>
                <Text size="2xl" fontWeight={700} color="gray.900">
                  Reset PIN
                </Text>
                <Text color="black" fontWeight={500} size="md">
                  User:{' '}
                  <Text color="black" fontWeight={700} size="lg">
                    {name}
                  </Text>
                </Text>
                <Text color="black" fontWeight={500} size="md">
                  Username:{' '}
                  <Text color="black" fontWeight={700} size="lg">
                    {team?.userName ?? ''}
                  </Text>
                </Text>
              </VStack>
            )
          }
        />
      </Modal>
    </ScrollView>
  );
};
