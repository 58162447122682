import { ComponentProps, createElement, useState } from 'react';
import { useMutation } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { Alert, Box, ChevronDownIcon, HStack, IIconProps, Popover, Pressable, Spinner, VStack, useTheme, useToast } from 'native-base';
import { reduce, size, some } from 'lodash';

import { Modal, RefreshIcon, ReverseLeftIcon, Text, ZapFastIcon, ZapIcon } from '@pimm/base';
import { PositionTypeEnum, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { useGetPositionScheduleLive } from '../hooks';
import { usePositioningPlan } from '../context';
import { PositioningSlot } from '../reducers';
import { ConfirmReverseTemplatePlan } from './confirm-reverse-template-plan';

enum AutoAssignEvent {
  CurrentTab,
  AllTabs,
  Reset,
  Reverse,
}

type PositioningDropdownOptionsProps = {
  _container?: ComponentProps<typeof HStack>;
  dayBlock: DayBlock;
  hideOptions?: boolean;
  isDisabled?: boolean;
  liveSchedules: ReturnType<typeof useGetPositionScheduleLive>[0];
  siteId: string;
};

export const PositioningDropdownOptions = ({
  _container,
  dayBlock,
  hideOptions,
  liveSchedules,
  siteId,
  ...props
}: PositioningDropdownOptionsProps) => {
  const { colors } = useTheme();
  const toast = useToast();
  const siteTime = useSiteTime();
  const isOpenDropdown = useBoolean();
  const modalConfirm = useModalFocus();
  const { changes, positioning } = usePositioningPlan();
  const [menuItems] = useState<{ type: AutoAssignEvent; icon: React.ElementType; isDisabled?: boolean; label: string }[]>([
    { type: AutoAssignEvent.AllTabs, icon: ZapFastIcon, label: 'Auto Assign All Plans' },
    { type: AutoAssignEvent.Reset, icon: RefreshIcon, label: 'Reset Current Plan' },
    { type: AutoAssignEvent.Reverse, icon: ReverseLeftIcon, label: 'Restore Template Plan' },
  ]);

  const confirmAndAssignGroup = useMutation({
    mutationFn: SmsWorkforceApi.ConfirmAndAssignGroup,
    onSuccess: () => {
      liveSchedules.refetch();
    },
    onError: () => {
      return toast.show({
        placement: 'top',
        render: () => (
          <Alert maxWidth="100%" alignSelf="center" flexDirection="row" status="error" variant="subtle">
            <VStack space={1} flexShrink={1} w="100%">
              <HStack space={2} flexShrink={1} alignItems="center">
                <Alert.Icon size="md" />
                <Box>
                  <Text size="lg" flexShrink={1} fontWeight={700} color="black" lineHeight="xs">
                    Confirmation Failed
                  </Text>
                  <Text size="md" color="gray.900">
                    Sorry, something went wrong. Please try again later.
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Alert>
        ),
      });
    },
  });

  const resetConfirmAndAssignByDayBlock = useMutation({
    mutationFn: SmsWorkforceApi.ResetConfirmAndAssignByDayBlock,
    onSuccess: () => {
      liveSchedules.refetch();
    },
  });

  const isConfirmed = !!positioning?.confirmedTime;
  const hasAvailableEmployees = some(
    positioning?.employees,
    _ => !some<PositioningSlot>(positioning?.positionSlots, slot => slot.assignee?.employeeId === _.employeeId),
  );
  const hasShiftSubBlocks = size(positioning?.shiftSubBlocks) > 1;
  const hasRestoreTemplate = some(positioning?.positionSlots, _ => !!_.origPositionId && _.positionId !== _.origPositionId);

  const resetDayblock = useMutation({ mutationFn: SmsWorkforceApi.ResetPositionGroup, onSuccess: () => liveSchedules.refetch() });

  const isDisabled = props.isDisabled || changes.length > 0;
  const isLoading = confirmAndAssignGroup.isLoading || resetConfirmAndAssignByDayBlock.isLoading || resetDayblock.isLoading;

  const handlePressAction = (type: AutoAssignEvent) => async () => {
    if (isLoading) return;

    let confirmDateTime = dayBlock.startTime;
    let currentDateTime = dayBlock.startTime;
    const today = siteTime.today();
    const subBlockDateTime = stringToDateLocal(liveSchedules.data?.positionGroup?.subBlockTime);
    const nonServiceEmployees = reduce(
      positioning?.positionSlots,
      (employeeIds: string[], _) => {
        if (!_.id && _.positionType === PositionTypeEnum.NonService && _.assignee?.employeeId) {
          return [...employeeIds, _.assignee.employeeId];
        }
        return employeeIds;
      },
      [],
    );

    // Check if today, use the current time when making a POST to the confirm endpoint.
    if (today >= dayBlock.startTime && today < dayBlock.endTime) {
      confirmDateTime = today;
      currentDateTime = today;
    }

    // confirmDateTime should be equal to the subBlockDateTime for all suBlocks set in the future.
    if (subBlockDateTime && confirmDateTime < subBlockDateTime) {
      confirmDateTime = subBlockDateTime;
    }

    isOpenDropdown.setFalse();

    if (type === AutoAssignEvent.CurrentTab) {
      // Confirm and auto-assign
      confirmAndAssignGroup.mutate({
        confirmDateTime: formatToISOString(confirmDateTime),
        siteId: siteId,
        isLocked: true,
        nonServiceEmployeeIds: nonServiceEmployees,
        subBlockDateTime: subBlockDateTime ? formatToISOString(subBlockDateTime) : undefined,
      });
    } else if (type === AutoAssignEvent.AllTabs) {
      // Reset and confirm all tabs
      resetConfirmAndAssignByDayBlock.mutate({
        currentDateTime: formatToISOString(currentDateTime),
        siteId: siteId,
      });
    } else if (type === AutoAssignEvent.Reset) {
      // Reset position group
      resetDayblock.mutate({ positionGroupId: positioning?.id });
    } else if (type === AutoAssignEvent.Reverse) {
      // Reverse positions back to original names
      isOpenDropdown.setFalse();
      modalConfirm.setOpen();
    }
  };

  return (
    <>
      <Popover
        isKeyboardDismissable
        offset={5}
        isOpen={(hasAvailableEmployees || hasShiftSubBlocks || hasRestoreTemplate) && isOpenDropdown.value}
        onClose={isOpenDropdown.setFalse}
        trigger={triggerProps => (
          <Pressable
            rounded="lg"
            h={9}
            bg="primary.500"
            isDisabled={isDisabled}
            opacity={isDisabled || isLoading ? 0.5 : 1}
            onPress={handlePressAction(hasAvailableEmployees ? AutoAssignEvent.CurrentTab : AutoAssignEvent.Reset)}
          >
            <HStack rounded="lg" alignItems="center" h="full">
              <HStack space={1} alignItems="center" justifyContent="center" px={2} h="full" minW="60px" {..._container}>
                {!isConfirmed || hasAvailableEmployees ? (
                  <>{hideOptions && isLoading ? <Spinner size={16} color="black" /> : <ZapIcon size={5} color="black" />}</>
                ) : (
                  <RefreshIcon size={4} color="black" />
                )}
                <Text size="md" fontWeight={600} color="black">
                  {!isConfirmed || hasAvailableEmployees ? 'Auto Assign' : 'Reset'}
                </Text>
              </HStack>

              {!hideOptions && (
                <Pressable
                  alignItems="center"
                  justifyContent="center"
                  px={1}
                  minW="36px"
                  h="full"
                  {...triggerProps}
                  disabled={isLoading}
                  borderLeftWidth={1}
                  borderLeftColor="primary.100"
                  onPress={isOpenDropdown.setTrue}
                >
                  {isLoading ? <Spinner size={16} color="black" /> : <ChevronDownIcon size="14px" color="black" />}
                </Pressable>
              )}
            </HStack>
          </Pressable>
        )}
      >
        <Popover.Content accessibilityLabel="Account Dropdown" mr={3} borderWidth={0} shadow="7">
          <Popover.Body p={0} w="full" bgColor="white" minWidth="200px">
            <Box bgColor="gray.25">
              {menuItems.map(menu => {
                const isDisabled = menu.isDisabled || (menu.type === AutoAssignEvent.Reset && !positioning?.id);
                // If shiftSubBlocks is less or equal to 1, hide "Auto Assign All Tabs"
                if (menu.type === AutoAssignEvent.AllTabs && !hasShiftSubBlocks) return undefined;
                if (menu.type === AutoAssignEvent.Reverse && !hasRestoreTemplate) return undefined;
                return (
                  <Pressable key={menu.label} isDisabled={isDisabled} opacity={isDisabled || isLoading ? 0.5 : 1} onPress={handlePressAction(menu.type)}>
                    {({ isHovered }) => {
                      const color = isDisabled ? colors.gray[400] : isHovered ? colors.black : colors.gray[800];
                      return (
                        <HStack space={2} alignItems="center" py={1} px={3} minH={10} bg={isHovered ? 'gray.50' : undefined}>
                          {createElement<IIconProps>(menu.icon, {
                            color: color,
                            size: menu.type === AutoAssignEvent.Reverse ? '14px' : '16px',
                          })}
                          <Text size="md" fontWeight={isHovered ? 600 : 500} color={color} textTransform="capitalize" lineHeight="xs">
                            {menu.label}
                          </Text>
                        </HStack>
                      );
                    }}
                  </Pressable>
                );
              })}
            </Box>
          </Popover.Body>
        </Popover.Content>
      </Popover>

      <Modal
        hideClose
        isOpen={modalConfirm.isOpen}
        onClose={modalConfirm.setHide}
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
      >
        <ConfirmReverseTemplatePlan
          blockStartTime={dayBlock.startTime}
          siteId={positioning?.siteId}
          onClose={modalConfirm.setHide}
          onSuccess={liveSchedules.refetch}
        />
      </Modal>
    </>
  );
};
