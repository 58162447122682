import React, { useEffect, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Popover } from 'native-base';

import { Button } from '@pimm/base';
import { CalendarPicker, CalendarPickerProps } from './calendar-picker';

type DateTimePopoverProps = {
  children: React.ReactElement;
  selectedDate?: Date;
  onChange?: (date?: Date, endDate?: Date) => void;
} & Pick<CalendarPickerProps, 'max' | 'min' | 'mode' | 'weekday'> &
  Omit<React.ComponentProps<typeof Popover>, 'children' | 'trigger'>;

export const DateTimePopover = ({ children, max, min, mode, weekday, ...props }: DateTimePopoverProps) => {
  const isOpen = useBoolean();
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const handleChange = (dates: Date[]) => () => {
    if (props.onChange) props.onChange(dates[0], dates[1]);
    isOpen.setFalse();
  };

  const handleDateChange = (date: Date, endDate) => {
    setSelectedDates([date, endDate].filter(Date));
  };

  useEffect(() => {
    setSelectedDates([props.selectedDate ?? new Date()]);
  }, [props.selectedDate, isOpen.value]);

  return (
    <Popover
      isKeyboardDismissable
      isOpen={isOpen.value}
      onClose={isOpen.setFalse}
      onOpen={isOpen.setTrue}
      trigger={popoverProps => {
        return React.cloneElement(children, {
          ...popoverProps,
          onPress: isOpen.setTrue,
        });
      }}
    >
      <Popover.Content accessibilityLabel="Calendar Picker">
        <Popover.Arrow zIndex={1} />
        <Popover.Body p={0} w="full">
          <CalendarPicker date={selectedDates[0]} max={max} min={min} mode={mode} weekday={weekday} onChange={handleDateChange} />
        </Popover.Body>
        <Popover.Footer justifyContent="flex-end" borderTopWidth={0} padding={0}>
          <Button size="lg" width="full" borderRadius={0} onPress={handleChange(selectedDates)}>
            Apply
          </Button>
        </Popover.Footer>
      </Popover.Content>
    </Popover>
  );
};
