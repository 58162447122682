import { useTranslation } from 'react-i18next';
import { Locale } from 'locale-enum';
import { Box, ChevronDownIcon, HStack, Image, Menu, Pressable, Tooltip } from 'native-base';
import { find, first, map } from 'lodash';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { CultureData } from '@pimm/services/lib/sms-tenants';
import { translateSystem } from '@app/localization/system';

type DropdownCulturesProps = {
  cultures?: CultureData[];
  defaultCulture?: string;
};

export const DropdownCultures = ({ cultures, defaultCulture = 'en-us' }: DropdownCulturesProps) => {
  const { i18n } = useTranslation();
  const { locale, setLocale } = useAppLocale();

  const selectedCulture: CultureData = find<CultureData>(
    cultures,
    _ => _.culture?.toLowerCase() === (locale ?? defaultCulture)?.toLowerCase(),
  ) ?? {
    culture: 'en-US',
    imageUrl: 'https://sms-cdn.b-cdn.net/assets-web/wendys/US.png',
  };

  const handleChange = (culture: CultureData) => () => {
    const value = culture.culture as Locale;
    // Translate system date format
    translateSystem(value);

    i18n.changeLanguage(value);
    setLocale(value);
  };

  return (
    <Menu
      p={0}
      crossOffset={15}
      offset={10}
      placement="bottom right"
      overflow="hidden"
      trigger={triggerProps => {
        return (
          <Tooltip label="Change Culture" openDelay={500} placement="right">
            <Pressable accessibilityLabel="Change Culture" {...triggerProps}>
              <HStack space={2} alignItems="center">
                <Image
                  source={{
                    uri: selectedCulture?.imageUrl,
                  }}
                  alt={selectedCulture?.culture}
                  size="18px"
                />
                <Text size="lg" fontWeight={600} color="black" textTransform="uppercase" lineHeight="xs">
                  {first(selectedCulture.culture?.split('-'))}
                </Text>

                <Box ml={-1}>
                  <ChevronDownIcon size={3} color="black" />
                </Box>
              </HStack>
            </Pressable>
          </Tooltip>
        );
      }}
    >
      {map(cultures, culture => (
        <Menu.Item key={culture.culture} py={2.5} px={1} onPress={handleChange(culture)}>
          <HStack space={2} alignItems="center">
            <Image
              source={{
                uri: culture?.imageUrl,
              }}
              alt={culture.culture}
              size="16px"
            />

            <Text size="lg" fontWeight={600} color="black" textTransform="uppercase" lineHeight="xs">
              {first(culture.culture?.split('-'))}
            </Text>
          </HStack>
        </Menu.Item>
      ))}
    </Menu>
  );
};
