import React, { createContext, useCallback, useContext, useState } from 'react';

import { GenericModalState } from '@pimm/common';

export enum DashboardModalType {
  CokeFreestyle = 100,
  CustomerSatisfactionSurvey = 101,
  StoreConfig = 102,
  OSAT = 200,
  AccountSettings = 300,
  AccountSettingsReminder = 301,
}

export type DashboardModalState = GenericModalState<DashboardModalType>;

export interface DashboardContextProps {
  modal?: DashboardModalState;
  toggleModal: (modal?: DashboardModalState) => void;
}

export const DashboardContext = createContext<DashboardContextProps>(undefined!);

export const DashboardProvider = ({ children }: { children: React.ReactNode | React.ReactElement }) => {
  const [modal, setModal] = useState<DashboardModalState>();

  const toggleModal = useCallback(
    nextModal => {
      setModal(t => nextModal ?? undefined);
    },
    [modal],
  );

  return <DashboardContext.Provider value={{ modal, toggleModal }}>{children}</DashboardContext.Provider>;
};

export const DashboardConsumer = DashboardContext.Consumer;
export const useDashboard = () => useContext(DashboardContext);
