import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const Copy01Icon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M4.16663 12.5C3.39006 12.5 3.00177 12.5 2.69549 12.3731C2.28711 12.204 1.96265 11.8795 1.79349 11.4711C1.66663 11.1648 1.66663 10.7766 1.66663 9.99999V4.33332C1.66663 3.3999 1.66663 2.93319 1.84828 2.57667C2.00807 2.26307 2.26304 2.0081 2.57664 1.84831C2.93316 1.66666 3.39987 1.66666 4.33329 1.66666H9.99996C10.7765 1.66666 11.1648 1.66666 11.4711 1.79352C11.8795 1.96268 12.2039 2.28714 12.3731 2.69552C12.5 3.0018 12.5 3.39009 12.5 4.16666M10.1666 18.3333H15.6666C16.6 18.3333 17.0668 18.3333 17.4233 18.1517C17.7369 17.9919 17.9918 17.7369 18.1516 17.4233C18.3333 17.0668 18.3333 16.6001 18.3333 15.6667V10.1667C18.3333 9.23324 18.3333 8.76653 18.1516 8.41001C17.9918 8.0964 17.7369 7.84143 17.4233 7.68165C17.0668 7.49999 16.6 7.49999 15.6666 7.49999H10.1666C9.23321 7.49999 8.7665 7.49999 8.40998 7.68165C8.09637 7.84143 7.8414 8.0964 7.68162 8.41001C7.49996 8.76653 7.49996 9.23324 7.49996 10.1667V15.6667C7.49996 16.6001 7.49996 17.0668 7.68162 17.4233C7.8414 17.7369 8.09637 17.9919 8.40998 18.1517C8.7665 18.3333 9.23321 18.3333 10.1666 18.3333Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
