import { Box, HStack, VStack, Spacer, ScrollView, IconButton, CloseIcon } from 'native-base';
import { isEmpty, map } from 'lodash';

import { Button, Text, TargetIcon } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalInsightDto } from '@pimm/services/lib/sms-workforce';
import { formatTo } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useSiteTime } from '@app/features/store-core';

export type TTMGoalsProps = {
  date?: Date;
  goalInsights?: GoalInsightDto[];
  onNavigateTo: (screen: string, params?: any) => void;
  onClose?: () => void;
};

export const TTMGoals = ({ date, goalInsights, ...props }: TTMGoalsProps) => {
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const week = siteTime.toStartEndOfWeek(date);

  return (
    <VStack w="full" h="full">
      <HStack alignItems="center" justifyContent="space-between" px={4} h="56px" borderBottomWidth={1}>
        <Text size="xl" fontWeight={600} color="gray.900">
          Weekly Goals
        </Text>

        {!!props.onClose && (
          <IconButton
            p={1}
            _pressed={{ bg: 'gray.100' }}
            _hover={{ bg: 'gray.100' }}
            icon={<CloseIcon size="16px" color="gray.700" />}
            onPress={props.onClose}
          />
        )}
      </HStack>

      <Box justifyContent="center" py={1} px={4} h={10} bg="white">
        <Text size="2xl" fontWeight={700} color="gray.900" lineHeight="xl">
          {`Wk ${week.weekNumber} (${formatTo(week.startDate, 'MMM DD')} - ${formatTo(week.endDate, 'MMM DD')})`}
        </Text>
      </Box>

      <Box flex={1}>
        <ResourceLoader h="full" w="full" emptyMessage="No available goals" isEmpty={isEmpty(goalInsights)}>
          <ScrollView p={4} pt={2} bg="gray.25">
            <VStack flex={1} space={3}>
              {map(goalInsights, goal => {
                return (
                  <Box key={`goal-[${goal.id}]`} borderWidth={1} borderColor="gray.300" bg="white" rounded="lg" p={2}>
                    <HStack space={2} alignItems="center" pl={4} pr={3} minH={9} bg="gray.200" rounded="lg">
                      <Text size="xl" fontWeight={700} color="black" lineHeight="xs" textAlign="center">
                        {goal.id}
                      </Text>
                      <Text size="xl" fontWeight={700} color="black" lineHeight="xs" textTransform="uppercase">
                        {translate(goal.title, goal.translations)}
                      </Text>
                      <Spacer />
                      {isEmpty(goal.focusItems) && (
                        <Text size="md" fontWeight={500} color="gray.700" lineHeight="xs">
                          Goal not set
                        </Text>
                      )}
                    </HStack>
                    {!isEmpty(goal?.focusItems) && (
                      <VStack space={2} py={1} pl={4} pr={2}>
                        {map(goal?.focusItems, (item, index) => (
                          <Box pt={2} minH={9} key={index} alignItems="flex-start" justifyContent="center">
                            <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                              {goal?.focusItems?.map(goalFocus => (goalFocus.description ?? '').replace('|', '\n')).join('\n')}
                            </Text>
                          </Box>
                        ))}
                      </VStack>
                    )}
                  </Box>
                );
              })}
            </VStack>
          </ScrollView>
        </ResourceLoader>
      </Box>

      <HStack space={2} alignItems="center" py={4} px={4} borderTopWidth={1}>
        <Spacer />

        <Button minWidth={100} onPress={() => props.onNavigateTo('OpsPlan')} startIcon={<TargetIcon size="md" color="black" />}>
          Edit Goals
        </Button>
      </HStack>
    </VStack>
  );
};
