import { Box, CheckIcon, HStack, IPressableProps, Pressable, Spinner, useTheme } from 'native-base';
import { useMutation } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { find, some } from 'lodash';

import { useAppLocale } from '@pimm/common';
import { GitBranchIcon, Text } from '@pimm/base';
import { OpsTaskTrackerDto } from '@pimm/services/lib/sms-workforce';
import { AddOpsTaskTracker, DeleteOpsTaskTracker } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString, formatToTimeOnly } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';
import { FlowChartPositionTask } from '../reducers/flow-chart-checklist.reducer';
import { KeyIcon, ShieldTickIcon, DigitalPhoneIcon, OfflineCircleIcon } from '../icons';

type FlowPositionTaskProps = Pick<IPressableProps, 'onPress'> & {
  isDisabled?: boolean;
  isHighlighted?: boolean;
  isPastTime?: boolean;
  hideCheckbox?: boolean;
  opsTaskAssignmentId?: number;
  task: FlowChartPositionTask;
  timeNow?: Date;
  onComplete: (opsTaskTracker: OpsTaskTrackerDto) => void;
};

export const FlowPositionTask = ({
  isDisabled,
  isHighlighted,
  isPastTime,
  hideCheckbox,
  opsTaskAssignmentId,
  task,
  timeNow,
  ...props
}: FlowPositionTaskProps) => {
  const { translate } = useAppLocale();
  const { colors } = useTheme();
  const siteTime = useSiteTime();

  const addOpsTaskTracker = useMutation({
    mutationFn: AddOpsTaskTracker,
    onSuccess: (result, params) => {
      const opsTaskTracker = find(result.opsTaskTrackers, ['opsTaskId', params.opsTaskId]);
      if (opsTaskTracker) props.onComplete(opsTaskTracker);
    },
  });

  const deleteOpsTaskTracker = useMutation({
    mutationFn: DeleteOpsTaskTracker,
    onSuccess: () => {
      props.onComplete({ opsTaskId: task.opsTaskId });
    },
  });

  const isCompleted = useBoolean(task.isComplete);
  const isLoading = addOpsTaskTracker.isLoading || deleteOpsTaskTracker.isLoading;
  const hasFoodSafety = some(task.items, _ => _.isFoodSafety);
  const hasOffline = some(task.items, _ => _.isOffline);
  const hasPreClose = some(task.items, _ => _.isPreClose);
  const hasDigital = some(task.items, { isDigital: true });
  const numOfItems = task.items.length;

  let bgColor = isPastTime ? 'warning.100' : 'white';
  let borderColor = isPastTime ? 'warning.100' : 'gray.300';
  let textColor = isPastTime ? 'warning.700' : 'black';

  if (isCompleted.value) {
    bgColor = 'success.100';
    borderColor = 'success.100';
    textColor = 'black';
  }

  const handlePressComplete = () => {
    if (task.opsTaskTrackerId) {
      // Delete task tracker
      deleteOpsTaskTracker.mutate({ id: task.opsTaskTrackerId });
    } else {
      const completedOn = siteTime.today();

      // Create task tracker
      addOpsTaskTracker.mutateAsync({
        completedOn: formatToISOString(completedOn),
        opsTaskAssignmentId: opsTaskAssignmentId,
        opsTaskId: task.opsTaskId,
      });
    }
  };
  return (
    <Pressable
      position="relative"
      rounded="xl"
      py={2}
      p={3}
      minHeight="64px"
      borderWidth={isHighlighted ? 2 : 1}
      borderColor={isHighlighted ? 'primary.500' : borderColor}
      bg={bgColor}
      style={{
        shadowColor: colors.gray[300],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: isHighlighted ? 0.7 : 0.5,
        shadowRadius: 1,
      }}
      onPress={props.onPress}
    >
      <HStack alignItems="flex-start" space={3}>
        <Box>
          <Text size="md" color={textColor} fontWeight={700} lineHeight="md">
            {formatToTimeOnly(task.startTime)}
          </Text>
          <Text size="md" color={textColor} fontWeight={500} lineHeight="md">
            {formatToTimeOnly(task.endTime)}
          </Text>
          <Text size="sm" color={textColor} fontWeight={400} lineHeight="md">
            {`(${task.durationInMinutes} mins)`}
          </Text>
        </Box>

        <Box flex={1}>
          <Text size="md" color="black" fontWeight={600} lineHeight="xs" numberOfLines={2} ellipsizeMode="tail" overflow="hidden">
            {translate(task.title, task?.translations)}
          </Text>

          <HStack space={2} alignItems="center" mt={1.5}>
            <HStack flex="none" space={1} alignItems="center" rounded="sm" pl={0.5} pr={1} h="18px" borderColor="gray.700" borderWidth={1}>
              <GitBranchIcon size={3.5} color={colors.gray[700]} />
              <Text size="md" color="gray.700" fontWeight={600} lineHeight="xs">
                {numOfItems}
              </Text>
            </HStack>

            <HStack space={1} alignItems="center">
              {hasDigital && <DigitalPhoneIcon size={4} color={colors.gray[700]} />}
              {hasFoodSafety && <ShieldTickIcon size={4} color={colors.gray[700]} />}
              {hasOffline && <OfflineCircleIcon size={4} color={colors.gray[700]} />}
              {hasPreClose && <KeyIcon size={4} color={colors.gray[700]} />}
            </HStack>
          </HStack>
        </Box>

        <Box alignItems="center" justifyContent="center" w={7} h={7}>
          {!hideCheckbox && !isDisabled && !isLoading && (
            <Pressable
              w={7}
              h={7}
              alignItems="center"
              justifyContent="center"
              borderColor={isCompleted.value ? 'success.600' : 'gray.300'}
              borderWidth={1}
              bg="white"
              rounded="md"
              _disabled={{ borderColor: 'gray.100' }}
              _hover={{ borderColor: isCompleted.value ? 'success.600' : 'gray.500' }}
              isDisabled={isDisabled}
              onPress={handlePressComplete}
            >
              {({ isHovered }) => {
                return (isHovered || isCompleted.value) && <CheckIcon size={6} color={isCompleted.value ? 'success.600' : 'gray.300'} />;
              }}
            </Pressable>
          )}

          {isLoading && <Spinner size={18} color={isCompleted.value ? colors.success[600] : colors.warning[500]} />}
        </Box>
      </HStack>
    </Pressable>
  );
};
