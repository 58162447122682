import { useState } from 'react';
import { Box, HStack, Pressable, VStack, useTheme } from 'native-base';
import { range } from 'lodash';
import moment from 'moment';

import { Button, CalendarIcon, Text } from '@pimm/base';
import { formatTo } from '@app/utils/date-formatter';
import { PopoverMenuCalendar, TimePicker } from '@app/components/shared';

export interface TimeInputs {
  startTime: Date;
  endTime: Date;
}

type ScheduleTimeEditorProps = {
  referenceId?: string | number;
  displayText?: string;
  startTime?: Date;
  endTime?: Date;
  startEndOfBlock: {
    startTime: Date;
    endTime: Date;
  };
  onApply: (inputs: { referenceId?: string | number; startTime: Date; endTime: Date }) => void;
  onCancel: () => void;
};

export const ScheduleTimeEditor = ({ referenceId, startEndOfBlock, ...props }: ScheduleTimeEditorProps) => {
  const { colors } = useTheme();
  const _startTime = props.startTime ?? startEndOfBlock.startTime;

  const [timeInputs, setTimeInputs] = useState<TimeInputs>({
    endTime: props.endTime ?? moment(_startTime).add(1, 'hours').toDate(),
    startTime: props.startTime ?? startEndOfBlock.startTime,
  });

  const isOutsideOfStartTime = timeInputs.startTime < startEndOfBlock.startTime!;
  const isOutsideOfEndTime = timeInputs.endTime > startEndOfBlock.endTime!;
  const isValidInputs = timeInputs.startTime < timeInputs.endTime;

  const handleChange = (_input: Partial<TimeInputs>) => {
    setTimeInputs(prev => ({ ...prev, ..._input }));
  };

  const handleChangeHours = (hours: number) => () => {
    setTimeInputs(prev => ({ ...prev, endTime: moment(prev.startTime).add(hours, 'hours').toDate() }));
  };

  const handlePressApply = () => {
    if (timeInputs.startTime < timeInputs.endTime) {
      const payload = {
        referenceId: referenceId,
        startTime: timeInputs.startTime,
        endTime: timeInputs.endTime,
      };
      props.onApply(payload);
    }
  };

  return (
    <VStack space={3} p={4} w="full" rounded="lg" overflow="hidden">
      {!!props.displayText && (
        <Text size="2xl" fontWeight={700} color="black">
          {props.displayText}
        </Text>
      )}

      {/* Shift Start */}
      <VStack space={3} rounded="xl" pt={4} pb={2} w="full" borderWidth={1} bg="gray.50">
        <HStack space={4} px={4}>
          <VStack space={3} w="268px" rounded="xl">
            <Text size="xl" fontWeight={600} color="black" lineHeight="xs">
              Shift Start
            </Text>

            <Box rounded="lg" p={3} borderWidth={1} bg="white">
              <PopoverMenuCalendar
                selectedDate={timeInputs.startTime}
                onChange={date => handleChange({ startTime: date })}
                trigger={triggerProps => (
                  <Button
                    variant="unstyled"
                    shadow={1}
                    startIcon={<CalendarIcon size={4} color={isOutsideOfStartTime ? colors.warning[500] : colors.gray[900]} />}
                    _text={{ color: isOutsideOfStartTime ? 'warning.500' : 'black' }}
                    {...triggerProps}
                  >
                    {formatTo(timeInputs.startTime, 'MMM DD, YYYY')}
                  </Button>
                )}
              />
            </Box>

            <Box rounded="lg" p={3} borderWidth={1} bg="white">
              <TimePicker value={timeInputs.startTime} onChange={value => handleChange({ startTime: value })} />
            </Box>
          </VStack>

          {/* Shift End */}
          <VStack space={3} w="268px" rounded="xl">
            <Text size="xl" fontWeight={600} color="black" lineHeight="xs">
              Shift End
            </Text>

            <Box rounded="lg" p={3} borderWidth={1} bg="white">
              <PopoverMenuCalendar
                selectedDate={timeInputs.endTime}
                onChange={date => handleChange({ endTime: date })}
                trigger={triggerProps => (
                  <Button
                    variant="unstyled"
                    shadow={1}
                    startIcon={<CalendarIcon size={4} color={isOutsideOfEndTime ? colors.warning[500] : colors.gray[900]} />}
                    _text={{ color: isOutsideOfEndTime ? 'warning.500' : 'black' }}
                    {...triggerProps}
                  >
                    {formatTo(timeInputs.endTime, 'MMM DD, YYYY')}
                  </Button>
                )}
              />
            </Box>

            <Box rounded="lg" p={3} borderWidth={1} bg="white">
              <TimePicker value={timeInputs.endTime} onChange={value => handleChange({ endTime: value })} />
            </Box>
          </VStack>
        </HStack>

        <HStack space={4} px={4}>
          <VStack space={1} w="268px">
            <Text size="lg" fontWeight={600} color="black">
              Quick add
            </Text>
            <HStack space={1} alignItems="center">
              {range(2, 11, 2).map(hour => {
                return (
                  <Button key={hour} variant="unstyled" flex={1} bg="white" onPress={handleChangeHours(hour)}>
                    <Text fontWeight={600} size="lg" color="gray.700">{`+${hour}H`}</Text>
                  </Button>
                );
              })}
            </HStack>
          </VStack>

          <Box w="268px"></Box>
        </HStack>
      </VStack>

      <HStack space={3} alignItems="center" pt={1}>
        <Button variant="unstyled" flex={1} onPress={props.onCancel}>
          Cancel
        </Button>
        <Button flex={1} isDisabled={!isValidInputs} onPress={handlePressApply}>
          Confirm
        </Button>
      </HStack>
    </VStack>
  );
};
