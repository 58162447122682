import { memo, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, CloseIcon, HStack, IconButton, Pressable, Spacer, VStack, useMediaQuery, useTheme } from 'native-base';
import Octicons from 'react-native-vector-icons/Octicons';
import { filter, isEmpty, map, startCase, toLower } from 'lodash';

import {
  Button,
  ChevronSelectorVerticalIcon,
  Download02Icon,
  MinusCircleIcon,
  MoveIcon,
  PlusCircleIcon,
  ReverseLeftIcon,
  SwitchHorizontalIcon,
  Text,
} from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { AddUpdateAssigneeRequest, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { formatToISOString, stringToDateLocal } from '@app/utils/date-formatter';
import { hexToRGBA } from '@app/utils/string-formatter';
import { DayBlock, useSiteTime } from '@app/features/store-core';
import { useKitchenLayout } from '@app/features/kitchen-positioning';
import { PositioningEmployee, PositioningSlot } from '../reducers';
import { PositioningPlanContextReturn, useClickToDrop } from '../context';
import { ArrowSquareUpIcon } from '../icons';
import { EmployeeShiftTime } from './employee-shift-time';

type DroppablePositionSlotProps = {
  dayBlock?: DayBlock;
  isDisabled?: boolean;
  position: PositioningSlot;
  subBlockTime?: string;
  onChange: (position: Partial<PositioningSlot>, _position?: Partial<PositioningSlot>) => void;
  onDeleteAssignee?: (position: Partial<PositioningSlot>) => void;
  onMoveTo: PositioningPlanContextReturn['moveTo'];
  onEditPosition?: (isPrimary?: boolean) => void;
  onReplaceAssignee?: (position: Partial<PositioningSlot>, employee: Partial<PositioningEmployee>) => void;
  onSelect?: () => void;
};

const DroppablePositionSlot = ({ dayBlock, isDisabled, position, subBlockTime, ...props }: DroppablePositionSlotProps) => {
  const { colors } = useTheme();
  const [isLargeScreen] = useMediaQuery({ maxWidth: 1200 });
  const { translate } = useAppLocale();
  const siteTime = useSiteTime();
  const { positionLookup = {} } = useKitchenLayout();
  const { dropItem, isDisabled: isClickToDropDisabled, setDragItem } = useClickToDrop();
  const [assignee, setAssignee] = useState<PositioningEmployee | undefined>(position.assignee);

  const addUpdateJob = useMutation({ mutationFn: SmsWorkforceApi.AddUpdateSlotJob });
  const assignEmployee = useMutation({ mutationFn: SmsWorkforceApi.AssignEmployee });
  const deleteAssignee = useMutation({ mutationFn: SmsWorkforceApi.DeleteAssignee });
  const swapAssignees = useMutation({ mutationFn: SmsWorkforceApi.SwapAssignees });

  const kitchenPosition = position.positionId ? positionLookup[position.positionId] : undefined;
  const originalPosition = position.origPositionId ? positionLookup[position.origPositionId] : undefined;
  const hasOrigPosition = !!position?.origTitle && position.origPositionId !== position.positionId;

  const isAssigned = !!assignee?.employeeId;
  const isDropEnabled = !!dropItem;
  const isDropFocus = isDropEnabled && position.positionJobId === (dropItem as PositioningSlot)?.positionJobId;
  const countSecondaryJobs = position.secondaryJobs?.length;
  let color = kitchenPosition?.color ?? colors.gray[500];

  if (isDropEnabled) color = colors.gray[500];

  const handlePressEditPosition = (isPrimary?: boolean) => () => {
    if (props.onEditPosition) props.onEditPosition(isPrimary);
  };

  const handlePressDeleteAssignee = () => {
    deleteAssignee.mutate({ positionJobId: position.positionJobId, employeeId: position.assignee?.employeeId });
    if (props.onDeleteAssignee) props.onDeleteAssignee(position);
  };

  // prettier-ignore
  const handlePressDrop = async () => {
    if (dropItem && !isDropFocus) {
      const timeNow = siteTime.today();
      const blockStartTime = stringToDateLocal(subBlockTime) ?? dayBlock?.startTime;
      const startTime = blockStartTime && blockStartTime > timeNow ? blockStartTime : timeNow;

      // For assign, replace or swap
      if ('assignee' in dropItem) {
        // Swap position assignees
        const _position = dropItem as PositioningSlot;
        if (position.assignee?.employeeId !== _position.assignee?.employeeId) {
          swapAssignees.mutate({
            positionJobId1: _position.positionJobId,
            employeeId1: _position.assignee!.employeeId,
            positionJobId2: position.positionJobId,
            employeeId2: position.assignee?.employeeId,
            subBlockDateTime: formatToISOString(startTime),
          });
          props.onChange(position, _position);
        }
      }

      // Replace position slot assignee from available employee
      else if (!!position.assignee && 'name' in dropItem) {
        const employee = dropItem as PositioningEmployee;
        setAssignee(employee);
        const payload: AddUpdateAssigneeRequest = {
          id: position.assignee.id,
          positionJobId: position.positionJobId,
          employeeId: employee.employeeId,
        };
        assignEmployee.mutateAsync(payload);
        if (props.onReplaceAssignee) props.onReplaceAssignee(position, employee);
      }

      // Drop to un-assign service position
      else if (!position.assignee) {
        // Assign new employee
        const employee = dropItem as PositioningEmployee;
        setAssignee(employee);
        const payload: AddUpdateAssigneeRequest = {
          positionJobId: position.positionJobId,
          employeeId: employee.employeeId,
          startTime: formatToISOString(startTime),
        };
        const result = await assignEmployee.mutateAsync(payload);
        props.onChange({
          ...position,
          assignee: { id: result.id, ...employee, positionJobId: position.positionJobId, positionSlotId: position.id },
        });
      }
    }

    // Cancel click to drop
    setDragItem(undefined);
  };

  const handlePressMoveTo = (isNonService?: boolean) => () => {
    props.onMoveTo(position, isNonService);
    setAssignee(undefined);
  };

  const handlePressDeleteJob = (positionId?: string) => () => {
    const secondaryJobs = filter(position.secondaryJobs, _ => _.positionId !== positionId);
    addUpdateJob.mutate({
      positionSlotId: position.id,
      positionId: position.positionId,
      positionTitle: position.title,
      secondaryJobs: secondaryJobs,
    });
    props.onChange({ id: position.id, secondaryJobs: secondaryJobs });
  };

  const handlePressReverse = () => {
    addUpdateJob.mutate({ positionSlotId: position.id, positionId: position.origPositionId, positionTitle: position.origTitle });
    props.onChange({ id: position.id, positionId: position.origPositionId, title: position.origTitle });
  };

  // Detect changes outside of this component
  useEffect(() => setAssignee(position.assignee), [position.assignee]);

  return (
    <Box
      rounded="2xl"
      p={2}
      borderWidth={2}
      borderColor="white"
      bg="white"
      style={{
        shadowColor: colors.gray[500],
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 7,
      }}
    >
      <VStack
        rounded="xl"
        space={2}
        p={isLargeScreen ? 1.5 : 2}
        borderWidth={1}
        borderColor={hexToRGBA(color, 0.4)}
        bg={hexToRGBA(color, 0.2)}
      >
        {/* Position Title */}
        <HStack rounded="lg" space={2} alignItems="center" h={8}>
          <Box rounded="md" alignItems="center" justifyContent="center" h="full" w={isLargeScreen ? 6 : 8} bg={hexToRGBA(color, 0.4)}>
            {!isDropEnabled && position.isOpsLeader ? (
              <Box
                rounded="full"
                alignItems="center"
                justifyContent="center"
                w={5}
                h={5}
                borderWidth={1}
                borderColor="white"
                bg="warning.300"
              >
                <Octicons name="star" size={14} color="black" />
              </Box>
            ) : (
              <Text size={isLargeScreen ? 'md' : 'lg'} color="black" fontWeight={700} textAlign="center" lineHeight="xs">
                {position.slotNumber}
              </Text>
            )}
          </Box>

          <HStack flex={1} alignItems="center" justifyContent="space-between" h="full">
            {!!position.id && (
              <Box flex={!!position.title ? 1 : undefined} pr={2}>
                <VStack>
                  <Text
                    size={{ base: 'md', lg: 'lg' }}
                    color="black"
                    fontWeight={700}
                    lineHeight="xs"
                    numberOfLines={hasOrigPosition ? 1 : 2}
                  >
                    {translate(position?.title, kitchenPosition?.translations)}
                  </Text>
                  {hasOrigPosition && (
                    <HStack space={0.5} alignItems="center" overflow="hidden">
                      <ArrowSquareUpIcon size={4} color={colors.gray[700]} />
                      <Text size="md" color="gray.700" fontWeight={500} fontStyle="italic" lineHeight="xs" h="14px" numberOfLines={1}>
                        {translate(position?.origTitle, originalPosition?.translations)}
                      </Text>
                    </HStack>
                  )}
                </VStack>
              </Box>
            )}

            {/* Select Position */}
            {!position.title && (
              <Pressable
                rounded="lg"
                flex={1}
                h={8}
                borderWidth={1}
                borderColor="primary.200"
                bg="primary.50"
                isDisabled={isDisabled}
                _disabled={{ borderColor: 'gray.300', bg: 'gray.50' }}
                _hover={{ borderColor: 'primary.500' }}
                onPress={handlePressEditPosition(true)}
              >
                <HStack alignItems="center" justifyContent="space-between" pl={3} pr={2} h="full">
                  <Text size="md" fontWeight={600} color={isDisabled ? 'gray.500' : 'primary.500'}>
                    Select Position
                  </Text>

                  <ChevronSelectorVerticalIcon size={4} color={isDisabled ? 'gray.500' : 'primary.500'} />
                </HStack>
              </Pressable>
            )}

            {!!position.title && (
              <HStack space={isLargeScreen ? 1.5 : 2}>
                {/* Reverse Position */}
                {!isDisabled && !isDropEnabled && !!position?.origTitle && position.origPositionId !== position.positionId && (
                  <Pressable
                    rounded="lg"
                    alignItems="center"
                    justifyContent="center"
                    w={8}
                    h={8}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    opacity={!position.positionId ? 0.3 : 1}
                    _disabled={{ opacity: 1 }}
                    _hover={{ borderColor: 'gray.400' }}
                    onPress={handlePressReverse}
                    isDisabled={isDisabled}
                  >
                    <ReverseLeftIcon size="14px" />
                  </Pressable>
                )}

                {!isDisabled && !isDropEnabled && (
                  <Pressable
                    rounded="md"
                    alignItems="center"
                    justifyContent="center"
                    w={8}
                    h={8}
                    borderWidth={1}
                    borderColor="gray.300"
                    bg="white"
                    _hover={{ borderColor: 'gray.400' }}
                    onPress={handlePressEditPosition(true)}
                  >
                    <ChevronSelectorVerticalIcon size="18px" color="black" />
                  </Pressable>
                )}
              </HStack>
            )}
          </HStack>
        </HStack>

        {/* Assignee */}
        {isAssigned ? (
          <HStack
            rounded="xl"
            alignItems="center"
            pl={3}
            pr={2}
            h="50px"
            borderWidth={1}
            borderColor={isDropFocus ? 'primary.500' : hexToRGBA(color, 0.4)}
            bg={isDropFocus ? 'primary.500' : 'white'}
          >
            <Box flex={1} pl={isLargeScreen ? 0 : 0.5} pr={1} overflow="hidden">
              <Text
                size={isLargeScreen ? 'lg' : 'xl'}
                fontWeight={700}
                color="black"
                lineHeight="md"
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {startCase(toLower(assignee?.name ?? ''))}
              </Text>
              {!!dayBlock && !!assignee && <EmployeeShiftTime dayBlock={dayBlock} employee={assignee} />}
            </Box>

            {isDropEnabled && (
              <Button
                variant={assignEmployee.isLoading ? 'unstyled' : 'solid'}
                rounded="lg"
                minH={8}
                minW={8}
                bg={isDropFocus ? 'black' : undefined}
                borderColor={isDropFocus ? 'black' : undefined}
                _hover={{ bg: isDropFocus ? 'black' : undefined }}
                _pressed={{ bg: isDropFocus ? 'black' : undefined }}
                shadow={1}
                p={0}
                isLoading={assignEmployee.isLoading}
                onPress={handlePressDrop}
              >
                {isDropFocus ? <CloseIcon size={18} color="white" /> : <SwitchHorizontalIcon size={5} color="black" />}
              </Button>
            )}

            {/* Delete Assignee */}
            {!isDisabled && !isDropEnabled && !!assignee.employeeId && !assignEmployee.isLoading && (
              <HStack space={1.5} alignItems="center">
                <IconButton
                  rounded="lg"
                  shadow={1}
                  boxSize={8}
                  p={0}
                  borderWidth={1}
                  borderColor="gray.300"
                  bg="white"
                  _pressed={{ borderColor: 'gray.400', bg: 'white' }}
                  _hover={{ borderColor: 'gray.400', bg: 'white' }}
                  icon={<MinusCircleIcon size={5} color={colors.gray[700]} />}
                  onPress={handlePressMoveTo(true)}
                />

                {!isClickToDropDisabled && (
                  <Button
                    variant={!isDropEnabled ? 'unstyled' : 'solid'}
                    rounded="lg"
                    minH={8}
                    minW={8}
                    shadow={1}
                    p={0}
                    onPress={() => setDragItem(isDropEnabled ? undefined : position)}
                  >
                    {isDropFocus ? <ReverseLeftIcon size="14px" color="black" /> : <MoveIcon size="18px" color={colors.gray[700]} />}
                  </Button>
                )}
              </HStack>
            )}
          </HStack>
        ) : (
          <HStack
            space={2}
            position="relative"
            rounded="xl"
            alignItems="center"
            p={1.5}
            h="50px"
            borderWidth={isDropEnabled ? 2 : 0}
            borderColor={isDropEnabled ? 'primary.500' : hexToRGBA(color, 0.4)}
            bg={isDropEnabled ? 'primary.50' : 'gray.700'}
          >
            {!isDropEnabled && (
              <Pressable rounded="lg" w="full" h="full" isDisabled={isDisabled}>
                <HStack rounded="lg" alignItems="center" justifyContent="center" h="full">
                  <Text size="md" color="white" fontWeight={700} lineHeight="xs">
                    No Employee Assigned
                  </Text>
                </HStack>
              </Pressable>
            )}

            {isDropEnabled && (
              <>
                <HStack space={2} flex={1} alignItems="center" justifyContent="center" px={2}>
                  <Text size="lg" color="black" fontWeight={700} lineHeight="xs" textTransform="uppercase">
                    Assign Here
                  </Text>
                </HStack>

                <Box position="absolute" top={2} right={2} h="full">
                  <Button rounded="lg" minH={8} minW={8} shadow={1} p={0} onPress={handlePressDrop}>
                    <Download02Icon size={5} color="black" />
                  </Button>
                </Box>
              </>
            )}
          </HStack>
        )}
      </VStack>

      {/* Secondary Jobs */}
      {!isDropEnabled && (
        <Box>
          {!isEmpty(position.secondaryJobs) && (
            <HStack flexWrap="wrap" pt={2}>
              {map(position.secondaryJobs, job => {
                const secondaryJob = job.positionId ? positionLookup[job.positionId] : undefined;
                return (
                  <HStack
                    key={job.positionId}
                    space={1}
                    rounded="lg"
                    alignItems="center"
                    mb={1}
                    mr={1}
                    pl={2}
                    pr={isDisabled ? 2 : 1}
                    h={7}
                    borderWidth={1}
                    borderColor={hexToRGBA(color, 0.4)}
                    bg={hexToRGBA(color, 0.2)}
                  >
                    <Text size={isLargeScreen ? 'sm' : 'md'} fontWeight={500} color="black">
                      {translate(job.title, secondaryJob?.translations)}
                    </Text>

                    {!isDisabled && (
                      <IconButton
                        p={1}
                        _hover={{ bg: hexToRGBA(color, 0.3) }}
                        icon={<CloseIcon size="10px " color="black" />}
                        onPress={handlePressDeleteJob(job.positionId)}
                      />
                    )}
                  </HStack>
                );
              })}
            </HStack>
          )}

          {!isDisabled && countSecondaryJobs !== 3 && (
            <HStack pt={countSecondaryJobs ? 0 : 2}>
              <Button
                variant="unstyled"
                px={2}
                minH={8}
                _hover={{ borderColor: 'gray.400' }}
                startIcon={<PlusCircleIcon size="18px" color={colors.gray[600]} />}
                onPress={handlePressEditPosition(false)}
              >
                {countSecondaryJobs === 0 ? 'Add Secondary Position' : undefined}
              </Button>
              <Spacer />
            </HStack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(DroppablePositionSlot);
