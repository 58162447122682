import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, ScrollView, View, useMediaQuery } from 'native-base';
import { find, map } from 'lodash';

import { Modal } from '@pimm/base';
import { SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { PositioningActionTypes, useKitchenPositioning } from './context/kitchen-positioning.context';
import { AvailableEmployees } from './components/available-employees';
import PositionCard from './components/position-card';
import { SelectPosition } from './components/select-position';
import { KitchenPosition } from './types';

type KitchenPositionsProps = {
  disabled?: boolean;
  scrollDisabled?: boolean;
};

export const KitchenPositions = ({ disabled }: KitchenPositionsProps) => {
  const [isSmallScreen, isLargeScreen] = useMediaQuery([{ maxWidth: 1024 }, { maxWidth: 1200 }]);
  const { positioning, dispatch } = useKitchenPositioning();
  const modalSelect = useModalFocus<KitchenPosition>();
  const modalPosition = useModalFocus<{ isPrimary?: boolean; positionSlotId: string }>();

  const addUpdateSlot = useMutation({ mutationFn: SmsWorkforceApi.AddUpdateSlot });

  const handlePositionUpdate = useCallback(
    (input: Partial<KitchenPosition>) => {
      const positionSlot = find(positioning.positions, ['id', input.id]);

      if (disabled || !positionSlot) return;

      if (input.isOpsLeader) {
        // If the input request contains isOpsLeader, update position slot
        addUpdateSlot.mutate({
          positionGroupId: positioning.id,
          positionSlotId: positionSlot.id!,
          isOpsLeader: true,
        });

        dispatch({
          type: PositioningActionTypes.UPDATE_OPS_LEADER,
          payload: { id: positionSlot.id! },
        });
      } else {
        // Update position title or secondary jobs
        dispatch({
          type: PositioningActionTypes.UPDATE_POSITION_JOB,
          payload: { ...input, id: input.id ?? positionSlot.id! },
        });
      }
    },
    [disabled, positioning],
  );

  return (
    <View w="full" h="full">
      <ScrollView p={isLargeScreen ? 1.5 : 2} h="full" w="full" bg="gray.50">
        <HStack flexWrap="wrap">
          {map(positioning.positions, position => {
            return (
              <Box
                key={position.id}
                p={isLargeScreen ? 1.5 : 2}
                w={isSmallScreen ? '1/2' : isLargeScreen ? '1/3' : '1/4'}
              >
                <PositionCard
                  key={`position-plan[${positioning?.id ?? 'blank'}][${position.id}]`}
                  isDisabled={disabled}
                  position={position}
                  onAssign={() => modalSelect.setOpen(position)}
                  onChange={handlePositionUpdate}
                  onEditPosition={isPrimary => modalPosition.setOpen({ isPrimary: isPrimary, positionSlotId: position.id! })}
                />
              </Box>
            );
          })}
        </HStack>
      </ScrollView>

      <Modal isOpen={modalSelect.isOpen} noPadding size="md" _content={{ maxWidth: 340 }} hideClose>
        {!!modalSelect.payload && (
          <AvailableEmployees position={modalSelect.payload} onCancel={modalSelect.setHide} onSave={handlePositionUpdate} />
        )}
      </Modal>

      <Modal
        isOpen={modalPosition.isOpen}
        noPadding
        size="xl"
        hideClose
        _content={{ maxWidth: 980, maxHeight: '95%' }}
        onClose={modalPosition.setHide}
      >
        {!!modalPosition.payload && (
          <SelectPosition
            positions={positioning.positions}
            {...modalPosition.payload}
            onCancel={modalPosition.setHide}
            onSave={handlePositionUpdate}
          />
        )}
      </Modal>
    </View>
  );
};
