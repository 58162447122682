import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, IconButton, ScrollView, Spacer, VStack, View, useMediaQuery, useTheme } from 'native-base';
import { filter, find, flatMap, isEmpty, map, startCase, toLower } from 'lodash';

import {
  ArrowSquareUpRightIcon,
  Button,
  ButtonGroup,
  CalendarClockIcon,
  ConnectedDevicesIcon,
  MinusCircleIcon,
  TargetIcon,
  Text,
} from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import {
  AggPositionAssigneeDto,
  AggPositionDetailsDto,
  AggPositionResponsibilityDto,
  AggPositionSlotDto,
} from '@pimm/services/lib/sms-workforce';
import { useSiteTime } from '@app/features/store-core';
import { formatToTimeOnly } from '@app/utils/date-formatter';
import { hexToRGBA } from '@app/utils/string-formatter';
import { TimeDisplay } from './time-display';
import { StarIcon } from '../icons';
import { CleaningTasks } from './cleaning-tasks';
import { useKitchenPositionGuideLive } from '../context';

type ShiftScopeProps = {
  positionSlot: AggPositionSlotDto;
  onClose?: () => void;
  onNavigateTo: (screen: string, params?: any) => void;
};

export const ShiftScope = ({ positionSlot, onNavigateTo, ...props }: ShiftScopeProps) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isSmallScreen] = useMediaQuery({ maxWidth: 1200 });
  const { translate } = useAppLocale();
  const livePositionGuide = useKitchenPositionGuideLive();
  const siteTime = useSiteTime();
  const [assignee] = useState<AggPositionAssigneeDto | undefined>(positionSlot.positionAssignee);
  const [primaryJob] = useState<AggPositionDetailsDto | undefined>(positionSlot.positionJob?.primaryJob);
  const [secondaryResponsibilities] = useState<AggPositionDetailsDto[]>(
    map(positionSlot.positionJob?.secondaryJobs, _job => ({ ..._job, responsibilities: filter(_job.responsibilities, _ => !_.isPrimary) })),
  );

  const [tabFocus, setTabFocus] = useState<number>(0);
  const dailyGoalRef = useRef<HTMLDivElement>(null);
  const cleaningTaskRef = useRef<HTMLDivElement>(null);

  const memomizedData = useMemo(() => {
    const data = livePositionGuide.data;

    const nonServicePlan = flatMap(data?.opsTaskFlowChartWidget, ({ phase, positions }) =>
      positions
        ?.filter(position => position.employeeId === assignee?.employeeId)
        .map(({ title, tasksStartTime }) => ({
          phase,
          title,
          startTime: tasksStartTime,
        })),
    );

    const cleaningTasks = data?.cleaningTasks?.find(s => s?.employeeId && assignee?.employeeId && s.employeeId === assignee.employeeId);

    return { nonServicePlan, cleaningTasks };
  }, [livePositionGuide.data, assignee]);

  const isEmptyNonService = isEmpty(memomizedData?.nonServicePlan);
  const primaryResponsibilities = find<AggPositionResponsibilityDto>(primaryJob?.responsibilities, _ => !!_.isPrimary);
  const isEmptyResponsibilities = isEmpty(primaryResponsibilities) && isEmpty(secondaryResponsibilities);
  const isEmptyPositionGoals = isEmpty(primaryJob?.positionalGoals);

  const handleTabClick = (index: number): void => {
    setTabFocus(index);
    (index ? cleaningTaskRef : dailyGoalRef).current?.scrollIntoView({ behavior: 'smooth' });
  };

  const color = primaryJob?.color ?? colors.gray[200];

  return (
    <VStack w="full" h="full">
      <HStack alignItems="center" px={4} h="56px" borderBottomWidth={1}>
        <Text size="xl" fontWeight={700} color="gray.900">
          {t('kitchen_positioning:shift_scope')}
        </Text>
      </HStack>

      <HStack flex={1}>
        <HStack width="full">
          {/* Master Content */}
          <ScrollView flex={1} borderRightWidth={1} scrollEnabled borderColor="gray.200" maxW={{ md: '1/3', xl: '1/4' }}>
            <VStack space={4} p={4}>
              <VStack rounded="xl" space={2} p={1.5} borderWidth={1} borderColor={hexToRGBA(color, 0.4)} bg={hexToRGBA(color, 0.2)}>
                <HStack alignItems="center" space={1.5} pr={0.5}>
                  {positionSlot.slotNumber && primaryJob?.positionType !== 'NonService' && (
                    <Box rounded="md" alignContent="center" justifyContent="center" h={6} w={7} bg={hexToRGBA(color, 0.4)}>
                      <Text size="lg" color="black" fontWeight={700} textAlign="center">
                        {positionSlot.slotNumber ?? ''}
                      </Text>
                    </Box>
                  )}

                  <HStack flex={1} alignItems="center" h="full">
                    {primaryJob?.title ? (
                      <Box pl={1}>
                        <Text size="lg" fontWeight={700} color="black" lineHeight="lg" numberOfLines={1}>
                          {translate(primaryJob?.title, primaryJob?.translations)}
                        </Text>
                      </Box>
                    ) : (
                      <Box rounded="md" justifyContent="center" px={2.5} w="full" h="full" bg="gray.700">
                        <Text size="md" fontWeight={700} color="white" lineHeight="md">
                          {t('kitchen_positioning:no_position_assigned')}
                        </Text>
                      </Box>
                    )}
                  </HStack>
                  {/* TODO: Need to confirm what the condition will be for this. */}
                  {primaryJob?.positionType === 'NonService' && (
                    <Button
                      backgroundColor="white"
                      px={2}
                      minH={7}
                      variant="unstyled"
                      shadow={1}
                      endIcon={<CalendarClockIcon size={4} color="gray.600" />}
                      onPress={() => onNavigateTo('DailyPlan')}
                    >
                      {t('common:position_plan')}
                    </Button>
                  )}
                  {!!positionSlot.isOpsLeader && (
                    <Box flex="none" rounded="full" p="2px" borderWidth={1} borderColor="white" bg="warning.300">
                      <StarIcon size={3.5} />
                    </Box>
                  )}
                </HStack>

                {/* Assignee */}
                <VStack
                  position="relative"
                  justifyContent="center"
                  rounded="lg"
                  py={1.5}
                  px={2}
                  minHeight="48px"
                  borderWidth={1}
                  borderColor={hexToRGBA(color, 0.5)}
                  bg="white"
                >
                  {!!assignee ? (
                    <VStack space={1}>
                      <Text size="lg" fontWeight={700} color="black" lineHeight="xs" numberOfLines={1} ellipsizeMode="tail">
                        {startCase(toLower(assignee.name))}
                      </Text>

                      <HStack space={1} alignItems="center">
                        {!!assignee?.startTime && !!assignee?.endTime && (
                          <TimeDisplay
                            dayBlock={siteTime.toDayBlockNow()}
                            startTime={assignee.startTime}
                            endTime={assignee.endTime}
                            keepAlive
                          />
                        )}

                        <Spacer />
                      </HStack>
                    </VStack>
                  ) : (
                    <HStack alignItems="center">
                      <Text size="lg" fontWeight={600} color="black" lineHeight="xs" textAlign="center">
                        {t('kitchen_positioning:no_employee_assigned')}
                      </Text>
                      <Spacer />
                      {/* TODO: Confirm what would be the condition to show this */}
                      {primaryJob?.positionType !== 'NonService' && (
                        <Button
                          size="sm"
                          px={2}
                          minH={7}
                          variant="unstyled"
                          shadow={1}
                          endIcon={<CalendarClockIcon size={4} color="gray.600" />}
                          onPress={() => onNavigateTo('DailyPlan')}
                        >
                          {t('common:position_plan')}
                        </Button>
                      )}
                    </HStack>
                  )}
                </VStack>
              </VStack>

              {/* Position Responsibilities */}
              <VStack space={!isEmptyResponsibilities ? 1 : 0}>
                <Text size="lg" fontWeight={700} color="black" lineHeight="2xl">
                  {t('common:position_responsibilities')}
                </Text>
                {!isEmptyResponsibilities ? (
                  <VStack space={4}>
                    {/* Primary Position */}
                    {!isEmpty(primaryResponsibilities) && (
                      <VStack rounded="lg" p={2.5} borderWidth={1} bg="gray.25" space={2}>
                        <Text size="md" fontWeight={700} color="black" lineHeight="xs">
                          {t('common:primary_position')}
                        </Text>
                        <Box flex={1} rounded="lg" p={2.5} borderWidth={1} bg="white" minH={10} justifyContent="center">
                          <Text size="md" fontWeight={500} color="black" lineHeight="xs" numberOfLines={12} ellipsizeMode="tail">
                            {translate(primaryResponsibilities?.responsibility, primaryResponsibilities?.translations)?.replace('|', '\n')}
                          </Text>
                        </Box>
                      </VStack>
                    )}

                    {/* Secondary Position(s) */}
                    {!isEmpty(secondaryResponsibilities) && (
                      <VStack rounded="lg" p={2.5} borderWidth={1} bg="gray.25" space={2}>
                        <Text size="md" fontWeight={700} color="black" lineHeight="xs">
                          {t('common:secondary_position_s')}
                        </Text>
                        <VStack space={2}>
                          {map(secondaryResponsibilities, _position => (
                            <Box
                              key={`secondary-jobs[${_position.id}]`}
                              rounded="lg"
                              flex={1}
                              p={2.5}
                              borderWidth={1}
                              bg="white"
                              minH="auto"
                              justifyContent="center"
                              opacity={_position ? 1 : 0.5}
                            >
                              <Text size="md" fontWeight={700} color="black" lineHeight="sm" numberOfLines={3} ellipsizeMode="tail">
                                {translate(_position?.title, _position?.translations)}
                              </Text>
                              {map(_position?.responsibilities, _responsibility => (
                                <Text
                                  size="md"
                                  fontWeight={500}
                                  color="black"
                                  lineHeight="xs"
                                  numberOfLines={12}
                                  ellipsizeMode="tail"
                                  pt={2}
                                >
                                  {translate(_responsibility.responsibility, _responsibility?.translations)}
                                </Text>
                              ))}
                            </Box>
                          ))}
                        </VStack>
                      </VStack>
                    )}
                  </VStack>
                ) : (
                  <Box py={2}>
                    <Text size="md" fontWeight={500} color="gray.600" lineHeight="lg">
                      {t('kitchen_positioning:empty_message_responsibilities')}
                    </Text>
                  </Box>
                )}
              </VStack>
            </VStack>
          </ScrollView>

          {/* Detail/Tab Content */}
          <VStack flex={1}>
            {isSmallScreen && (
              <HStack space={2} justifyContent="flex-start" px={4} py={2} borderBottomWidth={1}>
                <ButtonGroup value={tabFocus} onChange={index => handleTabClick(index)}>
                  <ButtonGroup.Item alignItems="center" value={0} minW={120}>
                    {t('common:daily_positional_goals')}
                  </ButtonGroup.Item>
                  <ButtonGroup.Item alignItems="center" value={1}>
                    {t('common:cleaning_task_schedule')}
                  </ButtonGroup.Item>
                </ButtonGroup>
              </HStack>
            )}
            <HStack flex={1}>
              {/* Left Content */}
              {(!isSmallScreen || tabFocus === 0 || tabFocus === 1) && (
                <ScrollView
                  flex={1}
                  my={4}
                  scrollEnabled
                  contentContainerStyle={{
                    flexGrow: isEmptyResponsibilities || isEmptyNonService ? 1 : 0,
                  }}
                  maxW={{ md: 'full', xl: 'md' }}
                >
                  <VStack flex={1} space={4} px={4} ref={dailyGoalRef}>
                    {/* Daily Positional Goal */}
                    {primaryJob?.positionType !== 'NonService' && (
                      <VStack
                        rounded="lg"
                        p={2.5}
                        borderWidth={1}
                        bg="gray.25"
                        space={2}
                        minH={!isEmptyPositionGoals || !isSmallScreen ? 0 : '1/3'}
                      >
                        <HStack space={2} alignItems="center">
                          <TargetIcon size="md" color="black" />
                          <Text size="lg" fontWeight={700} color="black" lineHeight="2xl">
                            {t('common:daily_positional_goals')}
                          </Text>
                          <Spacer />
                          <IconButton
                            p={0}
                            rounded="md"
                            w={8}
                            h={8}
                            borderWidth={1}
                            borderColor="gray.300"
                            backgroundColor="white"
                            _hover={{ bg: undefined }}
                            _pressed={{ bg: undefined }}
                            icon={<ArrowSquareUpRightIcon color="gray.700" />}
                            onPress={() => onNavigateTo('OpsPlan')}
                          />
                        </HStack>
                        {!isEmptyPositionGoals ? (
                          <Box flex={1} rounded="lg" p={2.5} borderWidth={1} bg="white" minH={10}>
                            <VStack space={1}>
                              <Text size="md" fontWeight={500} color="black" lineHeight="xs" numberOfLines={12} ellipsizeMode="tail">
                                {map(primaryJob?.positionalGoals, goal => translate(goal.description, goal.translations)).join('\n')}
                              </Text>
                              <HStack alignItems="center" space={1}>
                                <ConnectedDevicesIcon size="12px" color="gray.500" />
                                <Text size="md" fontWeight={500} color="gray.700" lineHeight="xs">
                                  {translate(primaryJob?.title, primaryJob?.translations)}
                                </Text>
                              </HStack>
                            </VStack>
                          </Box>
                        ) : (
                          <Box flex={1} justifyContent="center">
                            <Text
                              size="md"
                              fontWeight={500}
                              color="gray.700"
                              textAlign={!isEmptyPositionGoals ? 'left' : 'center'}
                              padding={2}
                              lineHeight="sm"
                            >
                              {t('kitchen_positioning:no_assigned_goals')}
                            </Text>
                          </Box>
                        )}
                      </VStack>
                    )}

                    {/* Non-Service Plan */}
                    <VStack
                      rounded="lg"
                      p={2.5}
                      borderWidth={1}
                      bg="gray.25"
                      flex={isEmptyNonService ? 1 : 0}
                      minH={isSmallScreen && isEmptyNonService ? '1/4' : 'fit-content'}
                      height="full"
                      space={2}
                    >
                      <HStack space={2} alignItems="center">
                        <MinusCircleIcon size="md" color="black" />
                        <Text size="lg" fontWeight={700} color="black" lineHeight="2xl">
                          {t('common:non_service_plan')}
                        </Text>
                        <Spacer />
                        <IconButton
                          p={0}
                          rounded="md"
                          w={8}
                          h={8}
                          borderWidth={1}
                          borderColor="gray.300"
                          backgroundColor="white"
                          _hover={{ bg: undefined }}
                          _pressed={{ bg: undefined }}
                          icon={<ArrowSquareUpRightIcon color="gray.700" />}
                          onPress={() => onNavigateTo('DailyPlan', { tabFocus: 'non-service' })}
                        />
                      </HStack>

                      {!isEmptyNonService ? (
                        <VStack rounded="lg" borderWidth={1} overflow="hidden">
                          <HStack alignItems="center" minH={8} bg="gray.200" space={1} flex={1} px={2.5}>
                            <Box w="1/3" flex={1}>
                              <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                                {t('common:phase')}
                              </Text>
                            </Box>
                            <Box w="1/3">
                              <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                                {t('common:position')}
                              </Text>
                            </Box>
                            <Box maxW={88} flex={1}>
                              <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                                {t('common:start_time')}
                              </Text>
                            </Box>
                          </HStack>
                          {memomizedData?.nonServicePlan?.map((nonService, index) => (
                            <HStack
                              bg="white"
                              key={index}
                              alignItems="center"
                              minH={12}
                              borderTopWidth={index ? 1 : 0}
                              flex={1}
                              space={1}
                              px={2.5}
                            >
                              <Box w="1/3" flex={1}>
                                <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs" numberOfLines={2} ellipsizeMode="tail">
                                  {nonService?.phase}
                                </Text>
                              </Box>
                              <Box justifyContent="center" w="1/3">
                                <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs" numberOfLines={2} ellipsizeMode="tail">
                                  {nonService?.title}
                                </Text>
                              </Box>
                              <Box maxW={88} flex={1}>
                                <Text size="md" fontWeight={500} color="gray.900" lineHeight="xs">
                                  {formatToTimeOnly(nonService?.startTime) ?? '--'}
                                </Text>
                              </Box>
                            </HStack>
                          ))}
                        </VStack>
                      ) : (
                        <Box flex={1} justifyContent="center">
                          <Text size="md" fontWeight={500} color="gray.700" textAlign="center">
                            {t('common:no_assigned_tasks')}
                          </Text>
                        </Box>
                      )}
                    </VStack>

                    {/* Cleaning Task */}
                    {isSmallScreen && (
                      <VStack rounded="lg" borderWidth={1} bg="gray.25" space={2} ref={cleaningTaskRef} minH="1/3">
                        <CleaningTasks
                          assignee={assignee}
                          cleaningTasks={memomizedData.cleaningTasks}
                          isSmallScreen={isSmallScreen}
                          onNavigateTo={onNavigateTo}
                        />
                      </VStack>
                    )}
                  </VStack>
                </ScrollView>
              )}

              {!isSmallScreen && (
                <View flex={1} borderLeftWidth={isSmallScreen ? 0 : 1}>
                  <CleaningTasks
                    assignee={assignee}
                    cleaningTasks={memomizedData.cleaningTasks}
                    isSmallScreen={isSmallScreen}
                    onNavigateTo={onNavigateTo}
                  />
                </View>
              )}
            </HStack>
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  );
};
