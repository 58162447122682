import { useMemo } from 'react';
import { VStack, HStack, Spacer } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { decode } from 'html-entities';
import { chunk, isEmpty, map, chain } from 'lodash';

import { Text } from '@pimm/base';
import { AlarmLevel } from '@pimm/services/lib/store-equipment';
import { ResourceLoader } from '@app/components/shared';
import { useGetInstances } from '@app/features/store-equipment';
import { WidgetBox, WidgetBoxProps } from '../widget-box';

interface TempBoxProps {
  title: string;
  temp: number;
  isOpenDoor: boolean;
  color?: string;
}

type WidgetTemperaturesProps = WidgetBoxProps & {
  instances: ReturnType<typeof useGetInstances>;
};

export const WidgetTemperatures = ({ instances, title = 'Temperatures', ...props }: WidgetTemperaturesProps) => {
  // TODO: to revisit again
  const temperatures: TempBoxProps[] = useMemo(() => {
    if (isEmpty(instances)) return [];

    let temps = [] as TempBoxProps[]; // Type assertion

    const _instances = map(['walk'], key =>
      chain(instances.data)
        .filter(instance => !!instance.Instances?.Temperature?.ThresholdProfile?.DisplayGroup?.toLowerCase().includes(key))
        .map('Instances')
        .value(),
    )[0];

    if (!isEmpty(_instances)) {
      const transformedArray = _instances.map(instance => {
        const Temperature = instance?.Temperature;

        if (!Temperature) return null;

        let color: string = 'gray';

        if (Temperature?.IsOnline) {
          switch (Temperature?.AlarmLevel) {
            case AlarmLevel.Info:
              color = 'moss';
              break;
            case AlarmLevel.Warning:
              color = 'warning';
              break;
            case AlarmLevel.Alarm:
              color = 'error';
              break;
            case AlarmLevel.Dead:
              color = 'gray';
              break;
            default:
              color = 'blue';
              break;
          }
        }

        return {
          title: Temperature.Description || null,
          temp: parseFloat(Number(Temperature?.StaticValue || 0).toFixed(1)),
          isOpenDoor: false,
          color: color,
        };
      });

      temps = transformedArray.filter(item => item !== null) as TempBoxProps[]; // Type assertion here
    }

    return temps;
  }, [instances.data]);


  if (temperatures.length === 0) return null;

  const chunks = chunk(temperatures, 2);
  
  return (
    <WidgetBox title={title} minHeight={90} {...props}>
      <ResourceLoader h="full" bgColor="red" isEmpty={isEmpty(temperatures)} isLoading={instances.isLoading} _text={{ size: 'sm' }}>
        <VStack space={1}>
          {chunks.map((temps, index) => {
            return (
              <HStack key={index} space={1}>
                {temps.map((temp, subIndex) => {
                  return (
                    <HStack
                      key={subIndex}
                      flex={1}
                      justifyContent="space-between"
                      alignItems="center"
                      py={4}
                      px={3}
                      h="54px"
                      rounded="md"
                      bgColor={`${temp.color}.200`}
                    >
                      <Text size="lg" color="black" fontWeight={600} lineHeight="xs" numberOfLines={2} ellipsizeMode="tail">
                        {temp.title}
                      </Text>
                      <VStack width="40%" h={8} alignItems="flex-end" justifyContent="space-between">
                        <Text size="lg" color={`${temp.color}.900`} fontWeight={600} lineHeight="xs">
                          {`${(temp.temp ?? 0).toFixed(1)}${decode('&deg;')}`}
                        </Text>
                        <Spacer />
                        {temp.isOpenDoor && <MaterialCommunityIcons name="door-open" color={`${temp.color}.900`} size={16} />}
                      </VStack>
                    </HStack>
                  );
                })}
              </HStack>
            );
          })}
        </VStack>
      </ResourceLoader>
    </WidgetBox>
  );
};
