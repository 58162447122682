import { useMutation } from 'react-query';
import { HStack, View, Box } from 'native-base';

import { Button, ReverseLeftIcon, Text } from '@pimm/base';
import { DeleteJobOverrides } from '@pimm/services/lib/sms-workforce/services';
import { formatToISOString } from '@app/utils/date-formatter';
import { useSiteTime } from '@app/features/store-core';

type ConfirmReverseTemplatePlanProps = {
  blockStartTime?: Date;
  siteId?: string;
  onClose?: () => void;
  onSuccess?: () => void;
};

export const ConfirmReverseTemplatePlan = ({ blockStartTime, siteId, ...props }: ConfirmReverseTemplatePlanProps) => {
  const siteTime = useSiteTime();

  const deleteJobOverrides = useMutation({ mutationFn: DeleteJobOverrides, onSuccess: props.onSuccess });

  const handlePressConfirm = async () => {
    if (blockStartTime && siteId) {
      const today = siteTime.today();
      const currentDateTime = new Date(Math.max(today.getTime(), blockStartTime.getTime()));

      if (blockStartTime)
        await deleteJobOverrides.mutateAsync({
          blockStartTime: formatToISOString(blockStartTime).split('T')[1],
          currentDateTime: formatToISOString(currentDateTime),
          siteId: siteId,
        });
      if (props.onClose) props.onClose();
    }
  };

  return (
    <View>
      <HStack alignItems="flex-start" justifyContent="space-between" mb={4}>
        <Box flex="none" rounded="full" p={2} bg="error.50">
          <Box rounded="full" p={2} bg="error.100">
            <ReverseLeftIcon size={4} color="error.600" />
          </Box>
        </Box>
      </HStack>

      <Box mb={3}>
        <Text color="black" fontWeight={600} size="xl" mb={2} lineHeight="sm">
          Restore Template Plan
        </Text>
        <Text color="gray.900" fontWeight={500} size="md" mb={3} lineHeight="sm">
          This will restore the Position Plan back to the original template and remove all manual changes made.{`\n\n`}Do you wish to
          continue?
        </Text>
      </Box>

      <HStack space={2}>
        <Button variant="unstyled" minW={140} disabled={deleteJobOverrides.isLoading} onPress={props.onClose}>
          Cancel
        </Button>
        <Button
          flex={1}
          variant="outline"
          borderColor="error.600"
          bgColor="error.600"
          isLoading={deleteJobOverrides.isLoading}
          isLoadingText="Please wait"
          _hover={{ bg: 'error.600', borderColor: 'error.600' }}
          _pressed={{ bg: 'error.600', borderColor: 'error.600' }}
          onPress={handlePressConfirm}
        >
          Back to Original Template
        </Button>
      </HStack>
    </View>
  );
};
