import { HStack, VStack } from 'native-base';

import { Button, Modal, Text } from '@pimm/base';

type ModalConfirmProps = {
  message?: string;
  title?: string;
  isOpen?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
};

export const ModalConfirm = ({ isOpen, message, title, ...props }: ModalConfirmProps) => {
  return (
    <Modal hideClose noPadding isOpen={isOpen} onClose={props.onClose} size="md" _content={{ rounded: 'xl', p: 5, maxW: 400 }}>
      <VStack space={2}>
        <VStack space={4} py={2}>
          <HStack space={3} alignItems="center">
            <Text color="black" fontWeight={700} size="xl" lineHeight="xs">
              {title ?? 'Confirm Changes'}
            </Text>
          </HStack>

          <Text color="gray.900" fontWeight={500} size="md" mb={3} lineHeight="sm">
            {message ?? 'Are you sure you want to submit changes?'}
          </Text>
        </VStack>

        <HStack space={2}>
          <Button variant="unstyled" flex={1} onPress={props.onClose}>
            Cancel
          </Button>
          <Button flex={1} onPress={props.onConfirm}>
            Confirm
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
