import { useMemo, useState } from 'react';
import { Box, HStack, useTheme, View, VStack } from 'native-base';
import { flatMap, isUndefined, map, orderBy, startCase, sumBy, toLower } from 'lodash';

import { ButtonGroup, Nullable, Text } from '@pimm/base';
import { EventThresholdMode, EventTypeDto } from '@pimm/services/lib/threshold-profiles';
import { tempValue } from '@app/utils/string-formatter';
import { convertMinutesToHoursAndMinutes, dateDiffToMinutes, formatToShortDateTime, stringToDateLocal } from '@app/utils/date-formatter';
import VirtualTable from '@app/components/virtual-table';
import { VirtualColumnProps } from '@app/components/virtual-table/virtual-table';
import { ResourceLoader } from '@app/components/shared';
import { useEventTypes } from '../context';
import { useGetEventCounts, useGetStoreEvents } from '../hooks';
import { DoorEventIcon, MessageTextSquare02Icon, Thermometer02Icon, Tool02Icon } from '../icons';
import { formatViolationTrigger } from '../_helper';
import { ViolationTrigger } from './violation-trigger';
import { EventLog } from './event-log-data';

type EventLogsProps = {
  queryEventCounts: ReturnType<typeof useGetEventCounts>;
  queryStoreEvents: ReturnType<typeof useGetStoreEvents>;
  onSelectEvent: (eventLog?: EventLog) => void;
};

export const EventLogs = ({ queryEventCounts, queryStoreEvents, onSelectEvent }: EventLogsProps) => {
  const { colors } = useTheme();
  const eventTypes = useEventTypes();
  const [filter, setFilter] = useState<string>('all');

  const [tableColumns] = useState<VirtualColumnProps<EventLog>[]>([
    // { dataField: 'sensorGroup', name: 'SENSOR GROUP', hidden: !expanded },
    // { dataField: 'sensorName', name: 'SENSOR NAME', hidden: !expanded },
    { dataField: 'eventType', name: 'Event Type'.toUpperCase() },
    { dataField: 'violationTrigger', name: 'Violation Trigger'.toUpperCase() },
    {
      dataField: 'eventSeverity',
      name: 'Event Severity'.toUpperCase(),
      renderCell: ({ item }) => {
        if (!item.eventSeverity) {
          return item.eventType ? startCase(toLower(item.eventType)) : '';
        }
        return (
          <HStack>
            <ViolationTrigger value={item.eventSeverity} />
          </HStack>
        );
      },
    },
    { dataField: 'startTime', name: 'Start Time'.toUpperCase() },
    { dataField: 'endTime', name: 'End Time'.toUpperCase() },
    { dataField: 'duration', name: 'Duration'.toUpperCase() },
  ]);

  const events: { data: EventLog[]; diagnosticEvents: number; doorEvents: number; stickyNotes: number; temperatureEvents: number } =
    useMemo(() => {
      // Result: { [200]: EventTypeDto, [201]: EventTypeDto }
      const eventTypesLookup = eventTypes.data?.reduce((_events: Record<number, EventTypeDto>, _event) => {
        if (!_event.EventType) return _events;
        return { ..._events, [_event.EventType]: _event };
      }, {});

      const events: EventLog[] = [
        { eventType: 'temperature', events: flatMap(queryStoreEvents.data?.Equipment, _ => _.TemperatureEvents) },
        { eventType: 'offline', events: flatMap(queryStoreEvents.data?.Equipment, _ => _.RealTimeStatusEvents) },
        { eventType: 'door open', events: flatMap(queryStoreEvents.data?.Equipment, _ => _.DoorEvents) },
        { eventType: 'low battery', events: flatMap(queryStoreEvents.data?.Equipment, _ => _.BatteryEvents) },
      ].reduce((_events: EventLog[], _group) => {
        const __events = map(_group.events, _event => {
          const startTime = stringToDateLocal(_event?.StartTime);
          const endTime = stringToDateLocal(_event?.EndTime);
          const triggerValue = _event?.ViolationTriggerValue;

          let minutes = 0;
          let eventType, violationTrigger;

          const startTimeStr = formatToShortDateTime(startTime);
          const endTimeStr = formatToShortDateTime(endTime);

          if (startTime && endTime) {
            minutes = dateDiffToMinutes(startTime!, endTime!);
          }

          if (!isUndefined(triggerValue)) {
            violationTrigger = tempValue(triggerValue);

            if (_group.eventType === 'temperature') {
              violationTrigger = formatViolationTrigger(triggerValue, _event?.ThresholdMode === EventThresholdMode.Upper);
            } else if (_group.eventType === 'door open') {
              violationTrigger = 'Open';
            }
          }

          if (eventTypesLookup && _event?.EventType) {
            eventType = eventTypesLookup[_event.EventType]?.Name;
          }

          const _eventLog: EventLog = {
            sensorGroup: '',
            sensorName: '',
            eventType: startCase(_group.eventType),
            violationTrigger: violationTrigger,
            eventSeverity: _event!.Priority,
            duration: convertMinutesToHoursAndMinutes(minutes),
            startTime: startTimeStr,
            endTime: endTimeStr,
          };

          return _eventLog;
        });

        return [..._events, ...__events];
      }, []);

      return {
        data: orderBy(
          filter === 'all'
            ? events
            : events.filter(event => {
                return event?.eventType?.toLocaleLowerCase().includes(filter);
              }),
          ['startTime'],
          ['desc'],
        ),
        diagnosticEvents: sumBy(queryEventCounts.data?.Equipment, _ => _.DiagnosticEvents ?? 0),
        doorEvents: sumBy(queryEventCounts.data?.Equipment, _ => _.DoorEvents ?? 0),
        stickyNotes: sumBy(queryEventCounts.data?.Equipment, _ => _.StickyNotes ?? 0),
        temperatureEvents: sumBy(queryEventCounts.data?.Equipment, _ => _.TemperatureEvents ?? 0),
      };
    }, [eventTypes, queryEventCounts.data, queryStoreEvents.data, filter]);

  return (
    <VStack flex={1} space={2} py={2}>
      {/* Event Log - Filter UI */}
      <HStack w="full" alignItems="center" justifyContent="space-between">
        <Text size="xl" color="black" fontWeight={700} lineHeight="xs">
          Event Log
        </Text>

        {/* {!expanded ? (
          <Text size="xl" color="black" fontWeight={700}>
            Event Log
          </Text>
        ) : (
          <Popover
            placement="bottom left"
            trigger={triggerProps => {
              return (
                <Button {...triggerProps} background="white" borderColor="gray.100" borderWidth={1}>
                  <HStack space={2} alignItems="center">
                    <Text size="xl" color="black" fontWeight={700}>
                      {selectedSensorData.sensorName}
                    </Text>
                    <VectorIcon color="black" size={2} />
                  </HStack>
                </Button>
              );
            }}
          >
            <Popover.Content accessibilityLabel="Sensor Info Bubble" w="full">
              <Popover.Arrow />
              <SensorList instances={instances} childCallback={setselectedSensorData} />
            </Popover.Content>
          </Popover>
        )} */}

        {/* Filter View UI */}
        <HStack alignItems="center" space={2} justifyContent="flex-end">
          <ButtonGroup value={filter} onChange={setFilter}>
            <ButtonGroup.Item value="all" minWidth="44px">
              All
            </ButtonGroup.Item>
            <ButtonGroup.Item value="temperature" pl={1} startIcon={<Thermometer02Icon color="black" size="18px" />}>
              {events.temperatureEvents.toString()}
            </ButtonGroup.Item>
            <ButtonGroup.Item value="door" pl={1} startIcon={<DoorEventIcon color="black" size="18px" />}>
              {events.doorEvents.toString()}
            </ButtonGroup.Item>
            <ButtonGroup.Item value="repair" pl={1} startIcon={<Tool02Icon color="black" size="18px" />}>
              {events.diagnosticEvents.toString()}
            </ButtonGroup.Item>
            <ButtonGroup.Item value="note" pl={1} startIcon={<MessageTextSquare02Icon color="black" size="18px" />}>
              {events.stickyNotes.toString()}
            </ButtonGroup.Item>
          </ButtonGroup>

          {/* <IconButton p={1.5} icon={<Expand01Icon size={5} color={colors.primary[500]} />} /> */}
        </HStack>
      </HStack>

      {/* Event Logs Table */}
      <View w="full" flex={1}>
        <Box maxHeight="full">
          <VirtualTable
            bordered
            columns={tableColumns}
            data={events.data}
            onItemPress={onSelectEvent}
            renderEmptyData={() => {
              const isLoading = queryStoreEvents.isIdle || queryStoreEvents.isLoading || queryEventCounts.isLoading;
              return <ResourceLoader isEmpty isLoading={isLoading} minHeight={100} />;
            }}
          />
        </Box>
      </View>
    </VStack>
  );
};
