import { Icon, IIconProps } from 'native-base';
import { Path, G, Circle } from 'react-native-svg';

export const TemperatureIcon = ({ color = 'currentColor', size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon color={color} size={size} strokeWidth="0" viewBox="0 0 25 24" {...props}>
      <Path
        d="M16.1339 12.319L15.4569 13.055L16.1339 12.319ZM10.75 6C10.75 4.89543 11.6454 4 12.75 4V2C10.5409 2 8.75 3.79086 8.75 6H10.75ZM10.75 11.5001V6H8.75V11.5001H10.75ZM8.75 16C8.75 14.835 9.24678 13.7874 10.0431 13.055L8.68916 11.583C7.4984 12.6782 6.75 14.2524 6.75 16H8.75ZM12.75 20C10.5409 20 8.75 18.2091 8.75 16H6.75C6.75 19.3137 9.43629 22 12.75 22V20ZM16.75 16C16.75 18.2091 14.9591 20 12.75 20V22C16.0637 22 18.75 19.3137 18.75 16H16.75ZM15.4569 13.055C16.2532 13.7874 16.75 14.835 16.75 16H18.75C18.75 14.2524 18.0016 12.6782 16.8108 11.583L15.4569 13.055ZM14.75 6V11.5001H16.75V6H14.75ZM12.75 4C13.8546 4 14.75 4.89543 14.75 6H16.75C16.75 3.79086 14.9591 2 12.75 2V4ZM16.8108 11.583C16.7808 11.5553 16.7637 11.5296 16.7556 11.5128C16.7482 11.4973 16.75 11.4937 16.75 11.5001H14.75C14.75 12.145 15.0601 12.69 15.4569 13.055L16.8108 11.583ZM8.75 11.5001C8.75 11.4937 8.75183 11.4973 8.74438 11.5128C8.73627 11.5296 8.71922 11.5553 8.68916 11.583L10.0431 13.055C10.4399 12.69 10.75 12.145 10.75 11.5001H8.75Z"
        fill="currentColor"
      />
      <Circle cx="12.75" cy="16" r="2" fill="currentColor" />
    </Icon>
  );
};
