import { Divider, VStack, View } from 'native-base';

import { Button, Modal } from '@pimm/base';
import { PasswordResetDto, PinResetDto, UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { FormPasswordUpdate } from './form-password-update';
import { FormPinSetup } from './form-pin-setup';
import { ListItem } from './list-item';
import { FormEmailRecovery } from './form-email-recovery';

type UserSecurityModalType = 'change-password' | 'change-pin';

export type UserSecurityChangeEvent =
  | { name: 'update-password'; data?: PasswordResetDto }
  | { name: 'update-pin'; data?: PinResetDto }
  | { name: 'update-recovery-email'; data?: PinResetDto };

export type UserSecurityProps = {
  user?: UserProfileDto;
  onEndChange?: (changeEvent: UserSecurityChangeEvent) => void;
};

export const UserSecurity = ({ user, ...props }: UserSecurityProps) => {
  const modalFocus = useModalFocus<{ name: UserSecurityModalType }>();
  const modalName = modalFocus?.isOpen ? modalFocus.payload?.name : undefined;

  const pinStatusText = user?.pinStatusText === 'Not Set' ? undefined : user?.pinStatusText;

  const handleFormChange = (name: UserSecurityChangeEvent['name']) => (payload?: UserSecurityChangeEvent['data']) => {
    if (props.onEndChange) props.onEndChange({ name: name, data: payload });
    modalFocus.setHide();
  };

  const handleOpenModal = (_name: UserSecurityModalType) => () => {
    modalFocus.setOpen({ name: _name });
  };

  return (
    <View h="full" bg="gray.25" flex={1}>
      <VStack space={4} p={4}>
        <ListItem _content={{ alignItems: 'flex-end' }} alignItems="center" text="Password">
          <Button minWidth={90} alternate outline onPress={handleOpenModal('change-password')}>
            Change
          </Button>
        </ListItem>

        <Divider bg="gray.200" />

        <ListItem _content={{ alignItems: 'flex-end' }} alignItems="center" text="PIN">
          <Button minWidth={90} alternate outline onPress={handleOpenModal('change-pin')}>
            {!!pinStatusText ? 'Change' : 'Setup'}
          </Button>
        </ListItem>

        <Divider bg="gray.200" />
        {!!user?.userId && (
          <ListItem text="Account Recovery">
            <FormEmailRecovery
              email={user.recoveryEmail}
              status={user.recoveryEmailStatus}
              userId={user.userId}
              onEndChange={handleFormChange('update-recovery-email')}
            />
          </ListItem>
        )}
      </VStack>

      {!!user?.userId && (
        <>
          <Modal hideClose noPadding isOpen={modalName === 'change-password'} size="lg" _content={{ rounded: 'xl', w: 600 }}>
            <FormPasswordUpdate userId={user.userId} onCancel={modalFocus.setHide} onEndChange={handleFormChange('update-password')} />
          </Modal>

          <Modal noPadding isOpen={modalName === 'change-pin'} onClose={modalFocus.setHide} size="lg" _content={{ rounded: 'xl', w: 500 }}>
            <FormPinSetup userId={user.userId} onCancel={modalFocus.setHide} onEndChange={handleFormChange('update-pin')} />
          </Modal>
        </>
      )}
    </View>
  );
};
