import { Modal } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { View } from 'native-base';

import { ModalProps } from '@pimm/base';
import { DayBlock } from '@pimm/common';
import { useAppSelector } from '@app/store/store';
import { selectStoreFocus } from '@app/store/selectors';
import { EmployeeSchedules } from '@app/features/employee-schedules';

type ModalEmployeeSchedulesProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  dayBlock: DayBlock;
};

export const ModalEmployeeSchedules = ({ dayBlock, ...props }: ModalEmployeeSchedulesProps) => {
  const {
    data: { employees, store },
  } = useAppSelector(state => selectStoreFocus(state));

  return (
    <Modal animationType="fade" presentationStyle="fullScreen" transparent={true} visible={props.isOpen}>
      <MenuProvider>
        <View alignItems="center" justifyContent="center" w="full" h="full" bgColor={'rgba(0, 0, 0, 0.8)'}>
          <View rounded="xl" width="90%" height="95%" bg="white">
            {!!store?.companyInfo?.customerId && !!store.id && (
              <EmployeeSchedules
                customerId={store?.companyInfo?.customerId}
                defaultDate={dayBlock.startTime}
                employees={employees}
                siteId={store.id}
                onCancel={props.onClose}
              />
            )}
          </View>
        </View>
      </MenuProvider>
    </Modal>
  );
};
