import { useState } from 'react';
import { Box, HStack, Select, useTheme, View, VStack } from 'native-base';
import { useMutation } from 'react-query';

import { Button, Modal, SelectInput, Text } from '@pimm/base';
import { AddUpdateUserProfileRequest, RecoveryEmailStatusEnum, UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { AddUpdateUserProfile, GetUserProfile } from '@pimm/services/lib/sms-tenants/services';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { CheckCircleBrokenIcon } from '../icons';
import { ListItem } from './list-item';
import { FormEmailRecovery } from './form-email-recovery';
import { FormPinSetup } from './form-pin-setup';
import { ModalConfirm } from './modal-confirm';
import { UserSecurityChangeEvent } from './user-security';
import { UserInformationChangeEvent } from './form-user-information';

type UserModalType = 'change-pin' | 'confirm-site-access';

type UserSettingsReminderProps = {
  user?: UserProfileDto;
  onEndChange: (changeEvent: UserInformationChangeEvent | UserSecurityChangeEvent) => void;
  onClose?: () => void;
};

export const UserSettingsReminder = ({ user, ...props }: UserSettingsReminderProps) => {
  const [selectedDefaultSiteId, setSelectedDefaultSiteId] = useState<string | undefined>(user?.defaultSiteId);
  const pinStatusText = user?.pinStatusText === 'Not Set' ? undefined : user?.pinStatusText;

  const { colors } = useTheme();

  const modalFocus = useModalFocus<{ name: UserModalType }>();
  const modalName = modalFocus?.isOpen ? modalFocus.payload?.name : undefined;

  const addUpdateProfile = useMutation({
    mutationFn: async (payload: AddUpdateUserProfileRequest) => {
      await AddUpdateUserProfile(payload);
      // Get profile after successful update
      const profile = await GetUserProfile({ userId: payload.userId });
      return profile;
    },
    onSuccess: profile => {
      setSelectedDefaultSiteId(profile?.defaultSiteId);
      props?.onEndChange({ name: 'update-profile', data: profile });
    },
  });

  const handleConfirmChanges = () => {
    const payload: AddUpdateUserProfileRequest = {
      ...user,
      defaultSiteId: selectedDefaultSiteId,
    };

    addUpdateProfile.mutate(payload);
    modalFocus.setHide();
  };

  const handleFormChange = (name: UserSecurityChangeEvent['name']) => (payload?: UserSecurityChangeEvent['data']) => {
    props.onEndChange({ name: name, data: payload });
    modalFocus.setHide();
  };

  const handleOpenModal = (_name: UserModalType) => () => {
    modalFocus.setOpen({ name: _name });
  };

  const handleSelectChange = (siteId: string) => {
    setSelectedDefaultSiteId(siteId);
    if (siteId !== selectedDefaultSiteId) {
      handleOpenModal('confirm-site-access')();
    }
  };

  return (
    <View height="full" w="full">
      <VStack space={4} p={4}>
        <VStack alignItems="flex-start" justifyContent="center">
          <Text size="xl" fontWeight={600} color="gray.900">
            Update user information
          </Text>

          <Text size="md" fontWeight={400} color="gray.600">
            Item(s) highlighted in red require(s) your attention.
          </Text>
        </VStack>

        <Box py={3} px={4} w="full" borderRadius="lg" justifyContent="center" bgColor={!user?.defaultSiteId ? 'error.100' : 'gray.50'}>
          <VStack space={1} zIndex={1000}>
            <VStack>
              <Text size="md" fontWeight={500} color="gray.700">
                Default site access
              </Text>
              <SelectInput
                _actionSheetContent={{ bgColor: 'rose.100' }}
                onValueChange={handleSelectChange}
                selectedValue={selectedDefaultSiteId}
                placeholder="Choose default site access"
              >
                {user?.sites?.map(_site => (
                  <Select.Item key={_site?.id} value={_site?.id!} label={_site?.name ?? ''} />
                ))}
              </SelectInput>
            </VStack>
          </VStack>
        </Box>

        <ListItem
          _content={{ alignItems: 'flex-end' }}
          _title={{ justifyContent: 'center' }}
          py={3}
          px={4}
          w="full"
          borderRadius="lg"
          justifyContent="center"
          bgColor={!!pinStatusText ? 'gray.50' : 'error.100'}
          text="PIN"
        >
          <Button minWidth={90} variant="unstyled" bg="white" onPress={handleOpenModal('change-pin')}>
            {!!pinStatusText ? 'Change' : 'Setup'}
          </Button>
        </ListItem>

        <FormEmailRecovery
          email={user?.recoveryEmail}
          status={user?.recoveryEmailStatus}
          userId={user?.userId!}
          _container={{
            bg: user?.recoveryEmailStatus === RecoveryEmailStatusEnum.Verified ? 'gray.50' : 'error.100',
            borderWidth: 0,
          }}
          onEndChange={handleFormChange('update-recovery-email')}
        />

        {!!user?.defaultSiteId && user?.recoveryEmailStatus === RecoveryEmailStatusEnum.Verified && !!pinStatusText ? (
          <VStack space={4} alignItems="flex-end">
            <HStack space={2} py={3} px={4} borderRadius="lg" justifyContent="center" bgColor="success.100">
              <HStack alignItems="center" space={1}>
                <CheckCircleBrokenIcon size={4} color={colors.success[500]} />
                <Text size="md" fontWeight={500} color="success.500">
                  All Set!
                </Text>
              </HStack>

              <Text size="md" fontWeight={600} color="gray.700" lineHeight="sm">
                Required fields have been updated! You can always update your information in the User Settings menu.
              </Text>
            </HStack>
            <Button variant="unstyled" minWidth={100} onPress={props.onClose}>
              Continue
            </Button>
          </VStack>
        ) : (
          <Box alignItems="flex-start">
            <Button variant="unstyled" minWidth={100} onPress={props.onClose}>
              Skip
            </Button>
          </Box>
        )}
      </VStack>

      {!!user?.userId && (
        <>
          <Modal noPadding isOpen={modalName === 'change-pin'} onClose={modalFocus.setHide} size="lg" _content={{ rounded: 'xl', w: 500 }}>
            <FormPinSetup userId={user.userId} onCancel={modalFocus.setHide} onEndChange={handleFormChange('update-pin')} />
          </Modal>

          <ModalConfirm
            isOpen={modalName === 'confirm-site-access'}
            onClose={() => {
              setSelectedDefaultSiteId(user?.defaultSiteId);
              modalFocus.setHide();
            }}
            onConfirm={handleConfirmChanges}
          />
        </>
      )}
    </View>
  );
};
