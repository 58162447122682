import { useEffect, useState } from 'react';

export const useGetClientId = (): string | undefined => {
  const [clientId, setClientId] = useState<string>();

  useEffect(() => {
    const populateClient = async () => {
      const xClientId = await localStorage.getItem('x-client-id');
      if (xClientId) setClientId(xClientId);
    };

    populateClient();
  }, []);

  return clientId;
};
