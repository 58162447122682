import { useBoolean } from 'usehooks-ts';
import { Divider, HStack, VStack } from 'native-base';

import { Button, Modal, Text } from '@pimm/base';
import { useServiceWorker } from '@app/context';

export const NewVersionAvailable = () => {
  const remindMeLater = useBoolean(false);
  const { availableVersion, updateWorker } = useServiceWorker();

  return (
    <Modal
      hideClose
      isOpen={!!availableVersion && !remindMeLater.value}
      size="md"
      _content={{ rounded: 'xl', maxW: 400, overflow: 'hidden' }}
      noPadding
    >
      <VStack space={4}>
        <VStack space={4} p={6} pb={4}>
          <Text color="black" fontWeight={600} size="2xl" lineHeight="sm" textAlign="center">
            New version available
          </Text>
          <Text color="gray.900" fontWeight={400} size="lg" lineHeight="sm" textAlign="center">
            There is newer a version of this application, click{' '}
            <Text color="primary.500" fontWeight={400} size="lg" lineHeight="sm">
              refresh
            </Text>{' '}
            to get the latest version.
          </Text>
        </VStack>

        <HStack borderTopWidth={1} overflow="hidden">
          <Divider orientation="vertical" bgColor="gray.300" />
          <Button
            flex={1}
            minH={12}
            alternate
            outline
            rounded="none"
            borderWidth={0}
            _text={{ fontSize: 'lg', color: 'primary.500' }}
            _hover={{ bg: 'gray.50' }}
            onPress={updateWorker}
          >
            Refresh
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};
