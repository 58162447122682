import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const SecurityLock = ({ color = 'currentColor', size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon color={color} size={size} strokeWidth="0" viewBox="0 0 25 25" {...props}>
      <Path
        d="M12.5 13.5664C12.2348 13.5664 11.9804 13.6718 11.7929 13.8593C11.6054 14.0468 11.5 14.3012 11.5 14.5664V17.5664C11.5 17.8316 11.6054 18.086 11.7929 18.2735C11.9804 18.4611 12.2348 18.5664 12.5 18.5664C12.7652 18.5664 13.0196 18.4611 13.2071 18.2735C13.3946 18.086 13.5 17.8316 13.5 17.5664V14.5664C13.5 14.3012 13.3946 14.0468 13.2071 13.8593C13.0196 13.6718 12.7652 13.5664 12.5 13.5664ZM17.5 9.56641V7.56641C17.5 6.24032 16.9732 4.96855 16.0355 4.03087C15.0979 3.09319 13.8261 2.56641 12.5 2.56641C11.1739 2.56641 9.90215 3.09319 8.96447 4.03087C8.02678 4.96855 7.5 6.24032 7.5 7.56641V9.56641C6.70435 9.56641 5.94129 9.88248 5.37868 10.4451C4.81607 11.0077 4.5 11.7708 4.5 12.5664V19.5664C4.5 20.3621 4.81607 21.1251 5.37868 21.6877C5.94129 22.2503 6.70435 22.5664 7.5 22.5664H17.5C18.2956 22.5664 19.0587 22.2503 19.6213 21.6877C20.1839 21.1251 20.5 20.3621 20.5 19.5664V12.5664C20.5 11.7708 20.1839 11.0077 19.6213 10.4451C19.0587 9.88248 18.2956 9.56641 17.5 9.56641ZM9.5 7.56641C9.5 6.77076 9.81607 6.0077 10.3787 5.44509C10.9413 4.88248 11.7044 4.56641 12.5 4.56641C13.2956 4.56641 14.0587 4.88248 14.6213 5.44509C15.1839 6.0077 15.5 6.77076 15.5 7.56641V9.56641H9.5V7.56641ZM18.5 19.5664C18.5 19.8316 18.3946 20.086 18.2071 20.2735C18.0196 20.4611 17.7652 20.5664 17.5 20.5664H7.5C7.23478 20.5664 6.98043 20.4611 6.79289 20.2735C6.60536 20.086 6.5 19.8316 6.5 19.5664V12.5664C6.5 12.3012 6.60536 12.0468 6.79289 11.8593C6.98043 11.6718 7.23478 11.5664 7.5 11.5664H17.5C17.7652 11.5664 18.0196 11.6718 18.2071 11.8593C18.3946 12.0468 18.5 12.3012 18.5 12.5664V19.5664Z"
        fill="currentColor"
      />
    </Icon>
  );
};
