import { useCallback, useState } from 'react';
import { VStack, HStack, View, useTheme, Box } from 'native-base';
import { chunk, range } from 'lodash';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useBoolean } from 'usehooks-ts';

import { Button, Text } from '@pimm/base';
import { SecurityLock, SecurityUnlock } from '../icons';

type PinCodeProps = {
  pinLength?: number;
  pinHeaderComponent?: React.ReactNode;
  pinFootNote?: React.ReactNode;
  okLabel?: string;
  onPressCancel?: () => void;
  onPressOk?: (pin?: string) => Promise<any>;
};

export const PinCode = ({ pinLength = 4, pinHeaderComponent, pinFootNote, okLabel, ...props }: PinCodeProps) => {
  const { colors } = useTheme();
  const isLoading = useBoolean();
  const isSuccess = useBoolean();
  const [pin, setPin] = useState('');
  const [errMessage, setErrMessage] = useState('');

  const [keypads] = useState<(number | undefined)[][]>(chunk([...range(1, 10), undefined, 0, -1] as (number | undefined)[], 3));

  const handleKeyPress = (key: number | undefined) => {
    if (!!errMessage) setErrMessage('');

    if (key === -1) {
      setPin(prev => prev.slice(0, -1)); // handles x button
    } else if (key !== undefined) {
      setPin(prev => (prev.length < pinLength ? prev + key : prev));
    }
  };

  const handlePressOk = useCallback(async () => {
    isLoading.setTrue();
    if (props.onPressOk) {
      try {
        await props.onPressOk(pin);
        isLoading.setFalse();
        isSuccess.setTrue();
      } catch (error: any) {
        isLoading.setFalse();
        isSuccess.setFalse();
        setPin('');
        setErrMessage(error?.data?.detail ?? 'Incorrect PIN. Please try again');
      }
    }
  }, [props.onPressOk, pin]);

  return (
    <VStack w="full" h="full" space={6}>
      {/* Pin Header */}
      {pinHeaderComponent && <View>{pinHeaderComponent}</View>}

      {/* Pin Display */}
      <HStack w="full" space={2} alignItems="center" justifyContent="center">
        {range(pinLength).map(_length => (
          <View
            key={`pin-${_length}`}
            style={{
              width: 24,
              height: 24,
              borderRadius: 14,
              backgroundColor: pin[_length] ? colors.black : colors.white,
              borderWidth: 2,
              borderColor: 'black',
            }}
          />
        ))}
      </HStack>

      {!!errMessage && (
        <HStack space={2} alignItems="center" justifyContent="center">
          <SecurityLock color="error.500" size="md" />
          <Text color={colors.error[600]} fontWeight={500} size="md">
            {errMessage}
          </Text>
        </HStack>
      )}

      {isSuccess?.value && (
        <HStack space={2} alignItems="center" justifyContent="center">
          <SecurityUnlock color="success.500" size="md" />
          <Text color={colors.success[600]} fontWeight={500} size="md">
            Success!
          </Text>
        </HStack>
      )}

      {/* Keypad */}
      <VStack w="full" space={3}>
        {keypads.map(_keypad => (
          <HStack key={`row-${_keypad}`} justifyContent="center" space={2} w="full">
            {_keypad.map((_key, index) => (
              <Box key={`key-${_key !== undefined ? _key : `empty-${index}`}`} w="1/3">
                {typeof _key === 'number' ? (
                  <Button
                    onPress={() => handleKeyPress(_key)}
                    height={46}
                    borderRadius={8}
                    backgroundColor={colors.white}
                    borderColor={colors.gray[200]}
                    w="full"
                  >
                    {_key === -1 ? (
                      <Ionicons name="ios-backspace" size={24} color={colors.black} />
                    ) : (
                      <Text size="4xl" color={colors.black} fontWeight={500}>
                        {_key}
                      </Text>
                    )}
                  </Button>
                ) : (
                  <Text />
                )}
              </Box>
            ))}
          </HStack>
        ))}
      </VStack>

      {pinFootNote && <View>{pinFootNote}</View>}

      {/* Pin Footer */}
      <HStack space={2} width="full">
        <Button variant="unstyled" width="1/2" onPress={props.onPressCancel}>
          Cancel
        </Button>
        <Button disabled={pin.length !== pinLength} width="1/2" isLoading={isLoading?.value} onPress={handlePressOk}>
          {okLabel ?? 'Ok'}
        </Button>
      </HStack>
    </VStack>
  );
};

export default PinCode;
