import { memo } from 'react';
import { useMutation } from 'react-query';
import { AddIcon, Box, HStack, Pressable, Spacer, Spinner, VStack, useTheme } from 'native-base';

import { Button, Edit05Icon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { GoalCategoryDto, GoalDto } from '@pimm/services/lib/sms-positioning';
import { DailyPositionGoalDto, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import CategoryIcon from './category-icon';
import { DailyGoalsList } from './daily-goals-list';

type DailyGoalCardProps = Pick<React.ComponentProps<typeof DailyGoalsList>, 'onPressSelect'> & {
  category: GoalCategoryDto;
  goal?: GoalDto;
  positionGoal?: DailyPositionGoalDto;
  isLoading?: boolean;
  disabled?: boolean;
  onChange?: (positionGoal: DailyPositionGoalDto[], isDelete?: boolean) => void;
};

const DailyGoalCard = ({ category, goal, isLoading, disabled, positionGoal, ...props }: DailyGoalCardProps) => {
  const { colors } = useTheme();
  const { translate } = useAppLocale();
  const isDisabled = disabled || !goal;

  const deleteDailyGoal = useMutation({ mutationFn: SmsWorkforceApi.DeleteDailyPositionGoal });

  const handlePressDelete = async () => {
    if (positionGoal) {
      await deleteDailyGoal.mutateAsync({ id: positionGoal.id });
      if (props.onChange) props.onChange([positionGoal], true);
    }
  };

  const handlePressSelect = () => {
    if (props.onPressSelect) props.onPressSelect({ category, goalId: goal?.id });
  };

  return (
    <Box width="1/3">
      <Box mx={1.5} borderWidth={1} rounded="lg" bg="white">
        <VStack space={3} pt={3} pb={2} px={4}>
          <HStack space={3} alignItems="center" minH={8}>
            <Box alignItems="center" justifyContent="center" h={9} w={9} bg="gray.300" rounded="md" bgColor={category.color || 'gray.300'}>
              <CategoryIcon iconUri={category.icon} size={36} color="black" />
            </Box>
            <Text size="lg" fontWeight={700} color="black" lineHeight="xs" ellipsizeMode="tail" numberOfLines={2}>
              {translate(category.title, category.translations)}
            </Text>
            <Spacer />
            {!isDisabled && (
              <Pressable rounded="md" w={8} h={8} disabled={isDisabled} onPress={handlePressSelect}>
                <Box alignItems="center" justifyContent="center" rounded="md" h="full" borderWidth={1} borderColor="gray.300">
                  <Edit05Icon name="trash-2" size={18} color={colors.gray[700]} />
                </Box>
              </Pressable>
            )}
          </HStack>

          <Box justifyContent={!isLoading && goal ? 'flex-start' : 'center'} pb={2} minHeight={100}>
            {isLoading ? (
              <Spinner color="gray.300" />
            ) : (
              <>
                {!disabled && !goal?.description ? (
                  <Button startIcon={<AddIcon size="14px" color="black" />} onPress={handlePressSelect} />
                ) : (
                  <Text
                    size={!!goal?.description ? 'md' : 'lg'}
                    color={!!goal?.description ? 'gray.900' : 'gray.500'}
                    textAlign={!!goal?.description ? 'left' : 'center'}
                    fontWeight={500}
                    numberOfLines={4}
                    ellipsizeMode="tail"
                  >
                    {!!goal?.description ? translate(goal.description, goal.translations) : 'Goal Not Set'}
                  </Text>
                )}
              </>
            )}
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default memo(DailyGoalCard);
