import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { RecursivePartial } from '@pimm/base';
import { WeeklyFocusItemDto } from '@pimm/services/lib/sms-workforce';

export interface FormWeeklyFocusData {
  categories: { [key: string]: WeeklyFocusItemDto };
}

export const WeeklyFocusItemDtoSchema = yup.object().shape({
  id: yup.number().optional(),
  seq: yup.number().optional().default(0),
  weeklyFocusCategoryId: yup.number(),
  description: yup.string().optional(),
});

export const FormWeeklyFocusSchema = yup.object({
  categories: yup.lazy(value => {
    const newEntries = Object.keys(value).reduce(
      (_category, categoryId) => ({
        ..._category,
        [categoryId]: WeeklyFocusItemDtoSchema,
      }),
      {},
    );
    return yup.object().shape(newEntries).default({});
  }),
});

export type FormWeeklyFocusProps = {
  defaultValues?: RecursivePartial<FormWeeklyFocusData>;
};

export const useFormWeeklyFocus = (props?: FormWeeklyFocusProps) => {
  return useForm<FormWeeklyFocusData>({
    defaultValues: { ...FormWeeklyFocusSchema.getDefault(), ...props?.defaultValues },
    resolver: yupResolver(FormWeeklyFocusSchema),
  });
};
