import { useCallback, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

import { Modal } from '@pimm/base';
import { RecoveryEmailStatusEnum } from '@pimm/services/lib/sms-tenants';
import { RootStackNavigation } from '@app/navigations/root';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { selectStoreConfig, selectUserProfile } from '@app/store/selectors';
import { userProfileSlice } from '@app/store/slices/userProfileSlice';
import { useAuthPermissions } from '@app/features/app';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';
import { UserSettingChangeEvent, UserSettings, UserSettingsReminder } from '@app/features/account-settings';

export const ModalUserSettings = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigation>();
  const profile = useAppSelector(selectUserProfile);
  const storeConfig = useAppSelector(selectStoreConfig);
  const permissions = useAuthPermissions(state => state.permissions);
  const { modal, toggleModal } = useDashboard();

  const hasReminder =
    !!permissions?.userSettingsSecurityReminder &&
    (!profile?.defaultSiteId || !profile?.pinSetDateTimeUtc || profile?.recoveryEmailStatus !== RecoveryEmailStatusEnum.Verified);

  const handleEndChange = useCallback(
    (event?: UserSettingChangeEvent) => {
      switch (event?.name) {
        case 'update-profile':
        case 'update-recovery-email':
          dispatch(userProfileSlice.actions.update(event.data));
          break;

        case 'update-password':
          toggleModal();
          // clear token and force user to re-login
          localStorage.removeItem('token');
          navigation.navigate('Login');
          break;

        case 'update-pin':
          dispatch(
            userProfileSlice.actions.update({
              pinSetDateTimeUtc: new Date().toISOString(),
              pinStatusText: event.data?.resultMessage,
            }),
          );
          break;

        default:
          break;
      }
    },
    [profile],
  );

  useEffect(() => {
    if (hasReminder) {
      toggleModal({ type: DashboardModalType.AccountSettingsReminder });
    }
  }, [profile?.userId, permissions?.userSettingsSecurityReminder]);

  return (
    <>
      <Modal
        _content={{
          rounded: 'xl',
          w: { base: 900, xl: 1024 },
          h: '90%',
          maxH: 900,
        }}
        size="full"
        noPadding
        isOpen={modal?.type === DashboardModalType.AccountSettings}
        onClose={toggleModal}
      >
        <UserSettings
          hasReminder={hasReminder}
          user={profile}
          cultures={storeConfig?.companyInfo?.brand?.availableCultures}
          onClose={toggleModal}
          onEndChange={handleEndChange}
        />
      </Modal>

      <Modal
        size="md"
        _content={{ rounded: 'xl', w: 400 }}
        noPadding
        hideClose
        isOpen={modal?.type === DashboardModalType.AccountSettingsReminder}
      >
        <UserSettingsReminder user={profile} onEndChange={handleEndChange} onClose={toggleModal} />
      </Modal>
    </>
  );
};
