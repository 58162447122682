import { Fragment, useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useBoolean } from 'usehooks-ts';
import { Box, HStack, VStack, FlatList, View, Pressable, useBreakpointValue, Spacer, Divider, useTheme } from 'native-base';
import { filter, find, first, isEmpty, last, orderBy, partition, range, some, split, startCase, toLower, trim } from 'lodash';
import moment from 'moment';

import { Button, Text, SearchField, ButtonGroup, ResponsiveScrollView, CalendarIcon } from '@pimm/base';
import { AddScheduleRequest, Employee, ShiftDto, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { useSiteTime } from '@app/features/store-core';
import {
  convertMinutesToHoursAndMinutes,
  dateDiffToMinutes,
  formatTo,
  formatToISOString,
  formatToDateOnly,
  formatToTimeOnly,
  stringToDateLocal,
} from '@app/utils/date-formatter';
import { PopoverMenuCalendar, ResourceLoader, TimePicker } from '@app/components/shared';
import { useScheduleEmployees } from '../context/schedule-employees.context';
import { useGetEmployeeSchedules } from '../hooks';
import { TimeInputs } from './schedule-time-editor';

type GetEmployeesReturn = Awaited<ReturnType<typeof SmsWorkforceApi.GetSchedules>>;

interface EmployeeWithShifts {
  id: string;
  name: string;
  shifts?: ShiftDto[];
}

export type EmployeeAddScheduleProps = {
  isManager?: boolean;
  employeeSchedules: ReturnType<typeof useGetEmployeeSchedules>;
  scheduledDate?: Date;
  onCancel?: () => void;
};

export const EmployeeAddSchedule = ({ isManager, employeeSchedules, scheduledDate, ...props }: EmployeeAddScheduleProps) => {
  const queryClient = useQueryClient();
  const { colors } = useTheme();
  const queryCache = queryClient.getQueryCache();
  const numOfColumns = useBreakpointValue({ md: 4, xl: 5 });
  const { customerId, employees, siteId } = useScheduleEmployees();
  const siteTime = useSiteTime();

  const isScheduled = useBoolean();
  const [roleName] = useState<string>(isManager ? 'Manager' : 'Employee');
  const [roles] = useState<string[]>(isManager ? ['SMS USER', 'SMS MANAGER'] : ['SMS EMPLOYEE']);
  const [searchValue, setSearchValue] = useState<string>();
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [startEndOfBlock] = useState<{ startTime?: Date; endTime?: Date }>(siteTime.toStartEndOfBlock(scheduledDate));
  const [timeInputs, setTimeInputs] = useState<TimeInputs>({
    startTime: startEndOfBlock.startTime!,
    endTime: moment(startEndOfBlock.startTime!).add(1, 'hour').toDate(),
  });

  // Distinct employee if it has an existing scheduled or available for new assignment
  // Filter by role (Manager or Regular) employee with their shift scheduled
  const [sections] = useState<Employee[][]>(
    partition(
      (employees ?? []).map(employee => {
        const shifts = orderBy(
          filter(employeeSchedules.data?.data, ['employee.id', employee.id]),
          ['startTime', 'endTime'],
          ['asc', 'asc'],
        );

        return {
          ...employee,
          shifts: shifts,
        };
      }),
      ({ id }) => !some<ShiftDto>(employeeSchedules.data?.data, _ => _.employee?.id === id),
    ).map(arr => arr.filter(_ => _.role && roles.includes(_.role.trim().toUpperCase()))),
  );

  const addSchedule = useMutation({ mutationFn: SmsWorkforceApi.AddSchedule });

  const searchResults = useMemo(() => {
    // Create an array of new object { name: string, id: string } from EmployeeDto
    // and check filter by searchValue
    const result = sections[isScheduled.value ? 1 : 0].reduce((arr: EmployeeWithShifts[], { firstName, lastName, ...employee }) => {
      const name = [firstName, lastName].filter(Boolean).join(' ');
      if (searchValue?.trim().length && !name.toLowerCase().includes(searchValue.toLowerCase())) return arr;
      return [...arr, { id: employee.id!, name: startCase(toLower(name)), shifts: employee.shifts }];
    }, []);
    return orderBy(result, ['name']);
  }, [searchValue, isScheduled.value, sections]);

  const errorMessage = useMemo(() => {
    // For reference only, need to revisit if needed
    // const diffInMinutes = dateDiffToMinutes(timeInputs.startTime, correctedEndTime);

    // if (diffInMinutes > 720) return 'Schedule cannot exceed 12 hours';

    // // Validate that the shift times are within the allowed day block times and do not overlap themselves.
    // const timeValid =
    //   timeInputs.startTime >= dayBlocks[0].startTime && correctedEndTime <= endOfBlock && timeInputs.startTime < correctedEndTime;

    // if (!timeValid) {
    //   // Format the allowed day block times for display.
    //   const startTimeFormatted = formatTo(dayBlocks[0].startTime, 'MMM D, h:mm A');
    //   const endTimeFormatted = formatTo(endOfBlock, 'MMM D, h:mm A');

    //   return `Please choose a valid schedule: ${startTimeFormatted} - ${endTimeFormatted}`;
    // }
    if (timeInputs.endTime < timeInputs.startTime) {
      return 'End time cannot be before the start time';
    }

    if (!isEmpty(selectedEmployee?.shifts)) {
      // Look for an overlapping shift among the selected employee's shifts
      const conflict = find(selectedEmployee?.shifts || [], shift => {
        const shiftStartTime = stringToDateLocal(shift.startTime);
        const shiftEndTime = stringToDateLocal(shift.endTime);

        if (!shiftStartTime || !shiftEndTime) return false;
        // Determine if the input times overlap with the current shift's time.
        return (
          (timeInputs.startTime >= shiftStartTime && timeInputs.startTime < shiftEndTime) ||
          (timeInputs.endTime > shiftStartTime && timeInputs.endTime <= shiftEndTime)
        );
      });

      // If an overlap is found, return details of the overlapping shift.
      if (conflict?.startTime && conflict?.endTime) {
        // Format the overlapping shift times for display.
        const startTimeFormatted = formatTo(conflict.startTime, 'MMM D, h:mm A');

        const endTimeFormatted =
          formatToDateOnly(conflict.startTime) === formatToDateOnly(conflict.endTime)
            ? formatToTimeOnly(conflict.endTime)
            : formatTo(conflict.endTime, 'MMM D, h:mm A');

        return `New schedule overlaps with existing: ${startTimeFormatted} to ${endTimeFormatted}`;
      }
    }
    return undefined;
  }, [timeInputs.startTime, timeInputs.endTime, selectedEmployee?.shifts]);

  const isOutsideOfStartTime = timeInputs.startTime < startEndOfBlock.startTime!;
  const isOutsideOfEndTime = timeInputs.endTime > startEndOfBlock.endTime!;
  const shiftDuration =
    timeInputs.startTime < timeInputs.endTime
      ? convertMinutesToHoursAndMinutes(dateDiffToMinutes(timeInputs.startTime, timeInputs.endTime))
      : '00:00';

  const handleChange = (_input: Partial<TimeInputs>) => {
    setTimeInputs(prev => ({ ...prev, ..._input }));
  };

  const handleChangeHours = (hours: number) => () => {
    setTimeInputs(prev => ({ ...prev, endTime: moment(timeInputs.startTime).add(hours, 'hours').toDate() }));
  };

  const handleChangeTab = (newValue: boolean) => {
    isScheduled.setValue(newValue);
    setSelectedEmployee(undefined);
    setTimeInputs({
      startTime: startEndOfBlock.startTime!,
      endTime: moment(startEndOfBlock.startTime).add(1, 'hour').toDate(),
    });
  };

  const handlePressSelect = useCallback(
    (employee: EmployeeWithShifts) => () => {
      setSelectedEmployee(employee);
      setTimeInputs({
        startTime: startEndOfBlock.startTime!,
        endTime: moment(startEndOfBlock.startTime).add(1, 'hour').toDate(),
      });
    },
    [],
  );

  const handlePressSave = async () => {
    if (timeInputs.endTime < timeInputs.startTime) return;

    const payload: AddScheduleRequest = {
      customerId: customerId,
      siteId: siteId,
      employeeId: selectedEmployee && selectedEmployee.id,
      startTime: formatToISOString(timeInputs.startTime),
      endTime: formatToISOString(timeInputs.endTime),
    };
    const nextEmployee = await addSchedule.mutateAsync(payload);

    queryCache.getAll().forEach(cache => {
      // Refetch live schedules
      if (cache.queryKey.includes('GetEmployeeSchedules')) {
        // Update query data with the newly added schedule
        queryClient.setQueryData<GetEmployeesReturn>(cache.queryKey, (previous): GetEmployeesReturn => {
          return {
            ...previous,
            data: [...(previous?.data ?? []), nextEmployee],
            totalCount: (previous?.totalCount ?? 0) + 1,
          };
        });
      }

      if (cache.queryKey.includes('GetPositionScheduleLive')) {
        queryClient.refetchQueries({ queryKey: cache.queryKey });
      }
    });

    if (props.onCancel) props.onCancel();
  };

  return (
    <View w="full" h="full">
      <HStack alignItems="center" px={4} h="56px" borderBottomWidth={1}>
        <Text size="2xl" fontWeight={700} color="gray.900">
          {`Add ${roleName} Schedule for`}
        </Text>
      </HStack>

      <ResponsiveScrollView h="full" w="full" showScroll contentContainerStyle={{ flexGrow: 1 }}>
        <HStack flex={1} p={4} space={5}>
          {/* Employee List */}
          <VStack flex={1} space={4} position="relative" h="full">
            <Text size="2xl" fontWeight={700} color="black" lineHeight="xs">
              {`Select ${roleName}`}
            </Text>

            <Box flex={1}>
              <VStack rounded="xl" overflow="hidden" h="full" w="full" borderColor="gray.200" borderWidth={1}>
                <HStack height={68} px={4} py={6} alignItems="center" justifyContent="space-between" borderBottomWidth={1}>
                  <Box flex={1} maxW="320px">
                    <SearchField
                      placeholder="Search Employee"
                      value={searchValue}
                      onChangeText={setSearchValue}
                      onClear={() => setSearchValue(undefined)}
                    />
                  </Box>

                  <ButtonGroup rounded="sm" value={isScheduled.value} onChange={handleChangeTab}>
                    {['Available', 'Scheduled'].map((title, index) => (
                      <ButtonGroup.Item
                        key={title}
                        value={Boolean(index)}
                        pl={3}
                        pr={2}
                        endIcon={
                          <Box
                            alignItems="center"
                            justifyContent="center"
                            bgColor="white"
                            size={6}
                            rounded="md"
                            ml={1}
                            px={1}
                            borderWidth="1"
                          >
                            <Text size="md" color="gray.700" fontWeight={500}>
                              {sections[index]?.length ?? 0}
                            </Text>
                          </Box>
                        }
                      >
                        <Text size="md" color="gray.700" fontWeight={600} textTransform="capitalize">
                          {title}
                        </Text>
                      </ButtonGroup.Item>
                    ))}
                  </ButtonGroup>
                </HStack>

                {/* List of employees */}
                <Box flex={1}>
                  <ResourceLoader h="full" w="full" emptyMessage="No available employees" isEmpty={isEmpty(employees)}>
                    <FlatList
                      w="full"
                      p={1.5}
                      data={searchResults}
                      backgroundColor="gray.100"
                      scrollEnabled={true}
                      extraData={[numOfColumns, selectedEmployee]}
                      numColumns={numOfColumns}
                      renderItem={({ item: employee }) => {
                        const isActive = employee.id === selectedEmployee?.id;

                        const startTime = (first(employee.shifts) as ShiftDto)?.startTime;
                        const endTime = (last(employee.shifts) as ShiftDto)?.endTime;

                        return (
                          <Box flex={1} p={1.5} maxW={`1/${numOfColumns}`}>
                            <Pressable rounded="lg" onPress={handlePressSelect(employee)}>
                              {({ isHovered }) => (
                                <Box
                                  position="relative"
                                  rounded="lg"
                                  alignItems="center"
                                  justifyContent="center"
                                  px={2}
                                  h="64px"
                                  borderWidth={1}
                                  borderColor={isActive ? 'primary.200' : isHovered ? 'gray.200' : 'white'}
                                  bg={isActive ? 'primary.50' : isHovered ? 'gray.50' : 'white'}
                                >
                                  <Text
                                    color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.700'}
                                    fontWeight={600}
                                    size="lg"
                                    lineHeight="sm"
                                    textAlign="center"
                                    numberOfLines={2}
                                    ellipsizeMode="tail"
                                  >
                                    {employee.name}
                                  </Text>
                                  {!!startTime && !!endTime && (
                                    <Text
                                      color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.700'}
                                      fontWeight={400}
                                      size="md"
                                      lineHeight="xs"
                                      textAlign="center"
                                      numberOfLines={1}
                                      ellipsizeMode="tail"
                                    >
                                      {`${formatTo(startTime, 'h:mm A')} - ${formatTo(endTime, 'h:mm A')}`}
                                    </Text>
                                  )}
                                </Box>
                              )}
                            </Pressable>
                          </Box>
                        );
                      }}
                    />
                  </ResourceLoader>
                </Box>
              </VStack>
            </Box>
          </VStack>

          {/* Shift Time */}
          <VStack flex={1} maxWidth={{ md: 300, xl: 340 }}>
            <VStack w="full" rounded="lg" space={2} p={3} minHeight={{ md: 50, xl: 60 }} borderColor="gray.300" bg="gray.100">
              <Text size="xl" fontWeight={600} color="black" lineHeight="xs">
                Shift Start
              </Text>

              <Box rounded="lg" p={3} borderWidth={1} bg="white">
                <PopoverMenuCalendar
                  selectedDate={timeInputs.startTime}
                  onChange={date => handleChange({ startTime: date })}
                  trigger={triggerProps => (
                    <Button
                      variant="unstyled"
                      isDisabled={!selectedEmployee}
                      shadow={1}
                      startIcon={<CalendarIcon size={4} color={isOutsideOfStartTime ? colors.warning[500] : colors.gray[900]} />}
                      _text={{ color: isOutsideOfStartTime ? 'warning.500' : 'black' }}
                      {...triggerProps}
                    >
                      {formatTo(timeInputs.startTime, 'MMM DD, YYYY')}
                    </Button>
                  )}
                />
              </Box>

              <Box rounded="lg" mb={2} p={4} borderWidth={1} bg="white">
                <TimePicker
                  disabled={!selectedEmployee}
                  value={timeInputs.startTime}
                  onChange={_date => handleChange({ startTime: _date })}
                />
              </Box>

              <Text size="lg" fontWeight={600} color="black">
                Quick add
              </Text>

              <HStack flex={1} space={1} alignItems="center">
                {range(2, 11, 2).map(hour => {
                  return (
                    <Button
                      key={hour}
                      variant="unstyled"
                      flex={1}
                      disabled={!selectedEmployee}
                      bg="white"
                      onPress={handleChangeHours(hour)}
                    >
                      <Text fontWeight={600} size="lg" color="gray.700">{`+${hour}H`}</Text>
                    </Button>
                  );
                })}
              </HStack>

              <Divider mt={2} mb={3} />

              <Text size="xl" fontWeight={600} color="black" lineHeight="xs">
                Shift End
              </Text>

              <Box rounded="lg" p={3} borderWidth={1} bg="white">
                <PopoverMenuCalendar
                  selectedDate={timeInputs.endTime}
                  onChange={date => handleChange({ endTime: date })}
                  trigger={triggerProps => (
                    <Button
                      variant="unstyled"
                      isDisabled={!selectedEmployee}
                      shadow={1}
                      startIcon={<CalendarIcon size={4} color={isOutsideOfEndTime ? colors.warning[500] : colors.gray[900]} />}
                      _text={{ color: isOutsideOfEndTime ? 'warning.500' : 'black' }}
                      {...triggerProps}
                    >
                      {formatTo(timeInputs.endTime, 'MMM DD, YYYY')}
                    </Button>
                  )}
                />
              </Box>

              <Box rounded="lg" p={4} borderWidth={1} bg="white">
                <TimePicker disabled={!selectedEmployee} value={timeInputs.endTime} onChange={_date => handleChange({ endTime: _date })} />
              </Box>
            </VStack>

            <Spacer />

            {!!selectedEmployee && (
              <HStack space={3} alignItems="center" justifyContent="center" pt={3}>
                <Text size="2xl" fontWeight={700} color="gray.600" lineHeight="xs">
                  Shift Duration
                </Text>
                <Text size="2xl" fontWeight={700} color={timeInputs.endTime < timeInputs.startTime ? 'error.500' : 'black'} lineHeight="xs">
                  {shiftDuration}
                </Text>
              </HStack>
            )}
          </VStack>
        </HStack>
      </ResponsiveScrollView>

      <HStack space={2} alignItems="center" py={2.5} px={4} borderTopWidth={1}>
        {errorMessage && (
          <Fragment>
            <Text size="md" fontWeight={500} color="error.500">
              {split(errorMessage, ':', 1)[0]}
              {!!errorMessage && (
                <Text size="md" fontWeight={700} color="error.500">
                  {`: ${trim(split(errorMessage, /:(.+)/)[1])}`}
                </Text>
              )}
            </Text>
          </Fragment>
        )}

        <Spacer />

        <Button variant="unstyled" minWidth={120} disabled={addSchedule.isLoading} onPress={props.onCancel}>
          Cancel
        </Button>

        <Button minWidth={120} isLoading={addSchedule.isLoading} isDisabled={!selectedEmployee || !!errorMessage} onPress={handlePressSave}>
          Save
        </Button>
      </HStack>
    </View>
  );
};
