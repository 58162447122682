import { PayloadAction } from '@reduxjs/toolkit';

import { UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { GenericState } from '@pimm/common';
import { ASYNC_PROFILE } from '../consts';
import { createGenericSlice } from './genericSlice';

export const UserProfileStateDefault = {
  status: 'idle',
  data: undefined,
};

export const userProfileSlice = createGenericSlice<UserProfileDto>({
  name: ASYNC_PROFILE,
  initialState: UserProfileStateDefault,
  reducers: {
    update(state: GenericState<UserProfileDto>, { payload }: PayloadAction<Partial<UserProfileDto>>) {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
  },
});

export const userProfileReducer = userProfileSlice.reducer;
