import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Box } from 'native-base';
import { isEmpty, isEqual } from 'lodash';

import { AddUpdateWeeklyFocusRequest, AggGoalTypeDto, SmsWorkforceApi, WeeklyFocusDto } from '@pimm/services/lib/sms-workforce';
import { formatToISOString } from '@app/utils/date-formatter';
import { ResourceLoader } from '@app/components/shared';
import { useSiteTime } from '@app/features/store-core';
import { useGetWeeklyFocus, useGoalSettings, WeeklyFocusItemList } from '@app/features/ops-plan';

type WeeklyPositionalGoalsProps = {
  goalTypes?: AggGoalTypeDto[];
  siteId: string;
  week: ReturnType<ReturnType<typeof useSiteTime>['toStartEndOfWeekBlock']>;
  weeklyFocus: ReturnType<typeof useGetWeeklyFocus>;
};

export const WeeklyPositionalGoals = ({ goalTypes, siteId, week, weeklyFocus, ...props }: WeeklyPositionalGoalsProps) => {
  const queryClient = useQueryClient();
  const siteTime = useSiteTime();
  const { weeklyFocusSettings } = useGoalSettings();

  const isEditable = week.endTime >= siteTime.today();

  const addUpdateWeeklyFocus = useMutation({
    mutationFn: async (payload: AddUpdateWeeklyFocusRequest) => {
      const result = await SmsWorkforceApi.AddUpdateWeeklyFocus(payload);
      return result;
    },
    onSuccess: data => {
      const params: Parameters<typeof useGetWeeklyFocus>[0] = {
        siteId: siteId,
        weekStartDate: week.startTime,
      };
      queryClient.setQueryData<WeeklyFocusDto>(['GetWeeklyFocus', params], (_prevData): WeeklyFocusDto => {
        const nextData = { ..._prevData, ...data };

        return {
          ...nextData,
          previousWeeklyFocus: isEmpty(nextData.previousWeeklyFocus) ? _prevData?.previousWeeklyFocus : nextData.previousWeeklyFocus,
        };
      });
    },
  });

  const handleFormChange = useCallback(
    (inputs: Partial<WeeklyFocusDto>) => {
      let timeoutRef: NodeJS.Timeout | undefined;
      const weeklyFocusSettingId = weeklyFocusSettings.data?.id;

      if (isEditable && weeklyFocusSettingId) {
        clearTimeout(timeoutRef);

        const queryCache = queryClient.getQueryCache();
        const prevData: WeeklyFocusDto = weeklyFocus.data?.id
          ? weeklyFocus.data
          : {
              focusItems: [],
              siteId: siteId,
              weeklyFocusSettingId: weeklyFocusSettingId,
              weekStartDate: formatToISOString(week.startTime, true),
            };

        const payload: WeeklyFocusDto = { ...prevData, ...inputs };

        const cachedQueryWeeklyFocus = queryCache.getAll().find(cache => {
          const params: Parameters<typeof useGetWeeklyFocus>[0] = {
            siteId: siteId,
            weekStartDate: week.startTime,
          };
          return cache.queryKey.includes('GetWeeklyFocus') && isEqual(cache.queryKey[1], params);
        });

        if (cachedQueryWeeklyFocus) {
          queryClient.setQueryData<WeeklyFocusDto>(cachedQueryWeeklyFocus?.queryKey, (_prevData): WeeklyFocusDto => {
            const nextData = { ..._prevData, ...inputs };

            return {
              ..._prevData,
              ...nextData,
              previousWeeklyFocus: isEmpty(nextData.previousWeeklyFocus) ? _prevData?.previousWeeklyFocus : nextData.previousWeeklyFocus,
            };
          });
        }

        // remember the changes, and await 1 second before calling the api
        timeoutRef = setTimeout(() => addUpdateWeeklyFocus.mutate(payload), 1000);
      }
    },
    [weeklyFocusSettings.data?.id, week, weeklyFocus.data],
  );

  return (
    <Box h="full" w={{ md: 'full' }} maxWidth={{ xl: 720 }} borderRightColor="gray.200" borderRightWidth={1}>
      <ResourceLoader
        h="full"
        isEmpty={isEmpty(weeklyFocusSettings.data)}
        isLoading={weeklyFocusSettings.isIdle || weeklyFocusSettings.isLoading || weeklyFocus.isIdle || weeklyFocus.isLoading}
      >
        {!!siteId && !!weeklyFocusSettings.data && (
          <WeeklyFocusItemList
            goalTypes={goalTypes}
            isEditable={isEditable}
            siteId={siteId}
            week={week}
            weeklyFocus={weeklyFocus}
            weeklyFocusSettings={weeklyFocusSettings.data}
            onChange={handleFormChange}
          />
        )}
      </ResourceLoader>
    </Box>
  );
};
