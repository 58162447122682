import { Nullable } from '@pimm/base';
import { createSignalRContext } from 'react-signalr/signalr';

export interface SignalRData<
  T = Record<string, unknown> | ReadonlyArray<Record<string, unknown>>,
  P = Record<string, unknown> | ReadonlyArray<Record<string, unknown>>,
> {
  siteId: string;
  userName: Nullable<string>;
  moduleName: 'OpsPlan' | 'PositionSchedule';
  actionName: 'AssignmentChanged' | 'Changed' | 'ProfileChanged' | 'TaskStatusChanged';
  data: {
    payload: P;
    data: T;
    siteId: string;
    origin: string;
    clientId: string;
    timestampUtc: string;
    userName: string;
  };
  eventTimestampUtc: string;
}

export const SignalREffectKey = 'ReceiveSyncInfo';

export let SignalRContext = createSignalRContext({
  // shareConnectionBetweenTab: true,
});
