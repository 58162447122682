import React, { createContext, useCallback, useContext, useState } from 'react';
import { SiteConfigDto } from '@pimm/services/lib/sms-tenants/types';

type SiteConfigState = Partial<SiteConfigDto> & {
  utcOffsetInMinutes?: number;
};

export type SiteConfigContextReturn = {
  siteConfig: SiteConfigState;
  setSiteConfig: (siteConfig?: Partial<SiteConfigDto>) => void;
};

export const SiteConfigContext = createContext<SiteConfigContextReturn>(undefined!);

export type SiteConfigProviderProps = {
  children: React.ReactNode;
  siteConfig?: SiteConfigDto;
};

export const SiteConfigProvider = ({ children, ...props }: SiteConfigProviderProps) => {
  const [siteConfig, setSiteConfig] = useState<SiteConfigState>({
    ...props.siteConfig,
    utcOffsetInMinutes: props.siteConfig?.address?.utcOffsetInMinutes,
  });

  const changeSiteConfig = useCallback((config?: SiteConfigDto) => {
    setSiteConfig({
      ...config,
      utcOffsetInMinutes: config?.address?.utcOffsetInMinutes,
    });
  }, []);

  return <SiteConfigContext.Provider value={{ siteConfig, setSiteConfig: changeSiteConfig }}>{children}</SiteConfigContext.Provider>;
};

export const useSiteConfig = () => {
  // get the context
  const context = useContext(SiteConfigContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useSiteConfig was used outside of its Provider');
  }
  return context;
};
