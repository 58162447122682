import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import {
  ArrowForwardIcon,
  Box,
  CheckCircleIcon,
  CloseIcon,
  HStack,
  IBoxProps,
  IconButton,
  InfoOutlineIcon,
  Pressable,
  useTheme,
  VStack,
} from 'native-base';
import { useBoolean } from 'usehooks-ts';
import { isEmpty } from 'lodash';

import { Button, ControlInput, FormHelperText, Modal, Text } from '@pimm/base';
import { ChangeEmailRequest, ConfirmEmailRequest, RecoveryEmailStatusEnum, UserProfileDto } from '@pimm/services/lib/sms-tenants';
import { ChangeEmail, ConfirmChangeEmail, GetUserProfile } from '@pimm/services/lib/sms-tenants/services';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { FormEmailRecoverySchema, FormEmailRecoveryStep, useFormEmailRecovery } from '../hooks';
import { CheckCircleBrokenIcon, Mail01Icon } from '../icons';

type FormEmailRecoveryProps = {
  _container?: IBoxProps;
  email?: string;
  status?: RecoveryEmailStatusEnum;
  userId: string;
  onClose?: () => void;
  onEndChange: (payload?: UserProfileDto) => void;
};

export const FormEmailRecovery = ({ email, userId, _container, ...props }: FormEmailRecoveryProps) => {
  const { colors } = useTheme();
  const modalDialog = useModalFocus();
  const isResendEnabled = useBoolean(props.status === RecoveryEmailStatusEnum.PendingVerification);
  const form = useFormEmailRecovery({
    defaultValues: {
      status: props.status,
      step: props.status === RecoveryEmailStatusEnum.PendingVerification ? FormEmailRecoveryStep.Verify : FormEmailRecoveryStep.Update,
      email:
        props.status === RecoveryEmailStatusEnum.PendingVerification || props.status === RecoveryEmailStatusEnum.Verified
          ? email
          : undefined,
    },
  });
  const code = useWatch({ control: form.control, name: 'code' });
  const password = useWatch({ control: form.control, name: 'password' });
  const recoveryEmail = useWatch({ control: form.control, name: 'email' });
  const status = useWatch({ control: form.control, name: 'status' });
  const step = useWatch({ control: form.control, name: 'step' });
  const serverError = form.formState.errors.root?.message;

  const isDisable = isEmpty(recoveryEmail) || (status === RecoveryEmailStatusEnum.Verified && recoveryEmail === email);

  const isPendingVerification = status === RecoveryEmailStatusEnum.PendingVerification;
  const isVerified = status === RecoveryEmailStatusEnum.Verified;
  const isVerifyCode = step === FormEmailRecoveryStep.Verify;

  const changeEmail = useMutation({
    mutationFn: async (payload: ChangeEmailRequest) => {
      await ChangeEmail(payload);
      // Get profile after successful update
      const profile = await GetUserProfile({ userId: payload.userId });
      return profile;
    },
    onSuccess: profile => {
      // Change step into 'verify code'
      form.setValue('step', FormEmailRecoveryStep.Verify);
      form.setValue('password', '');

      props.onEndChange(profile);
    },
    onError: (error: any) => {
      form.setValue('password', '');
      form.setError('root', {
        type: 'custom',
        message:
          error.code === 400
            ? 'You have entered an invalid password. Please try again'
            : 'Sorry, something went wrong there. Please try again',
      });
    },
  });

  const resendCode = useMutation({
    mutationFn: async (query: { email: string; userId: string }) => {
      // Force change type to any to prevent requiring a password
      const result = await ChangeEmail(query as any);
      return result;
    },
  });

  const confirmChangeEmail = useMutation({
    mutationFn: async (payload: ConfirmEmailRequest) => {
      await ConfirmChangeEmail(payload);
      // Get profile after successful update
      const profile = await GetUserProfile({ userId: payload.userId });
      return profile;
    },
    onSuccess: profile => {
      // Change step into 'verify code'
      form.setValue('status', RecoveryEmailStatusEnum.Verified);
      form.setValue('step', FormEmailRecoveryStep.Update);
      form.setValue('code', '');
      modalDialog.setHide();

      props.onEndChange(profile);
    },
    onError: (error: any) => {
      form.setValue('code', '');
      form.setError('root', {
        type: 'custom',
        message:
          error.code === 400
            ? 'You have entered an invalid verification code. Please try again.'
            : 'Sorry, something went wrong there. Please try again.',
      });
    },
  });

  // TODO: Fix type issue
  const handlePressEnter = (e: any) => {
    if (e.key === 'Enter') {
      form.handleSubmit(modalDialog.setOpen)();
    }
  };

  const handlePressConfirm = () => {
    const { email, password } = form.getValues();

    form.clearErrors();

    if (step === FormEmailRecoveryStep.Update) {
      // Disable resending code for 1 minute
      isResendEnabled.setFalse();

      changeEmail.mutate({
        email: email!,
        currentPassword: btoa(password!),
        userId: userId,
      });
      return;
    }

    // Confirm recovery email with verification code
    confirmChangeEmail.mutate({
      verificationCode: form.getValues().code,
      userId: userId,
    });
  };

  const handleResendCode = () => {
    const { email } = form.getValues();
    resendCode.mutate({ email: email!, userId: userId });
    // Force to open modal to be able to enter the verification code
    if (!modalDialog.isOpen) modalDialog.setOpen();
    isResendEnabled.setFalse();
  };

  const handleCloseModal = () => {
    if (status === RecoveryEmailStatusEnum.NotSet) {
      form.reset({ ...FormEmailRecoverySchema.getDefault(), email: email });
    }
    modalDialog.setHide();
  };

  const handleReset = () => {
    form.reset({ ...FormEmailRecoverySchema.getDefault() });
  };

  useEffect(() => {
    let timer;

    if (isVerifyCode && !isResendEnabled.value) {
      // re-enable resend code after a minute to prevent spam request
      timer = setTimeout(isResendEnabled.setTrue, 60000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isVerifyCode, isResendEnabled.value]);

  return (
    <Box
      flex={1}
      py={3}
      px={4}
      borderRadius="lg"
      justifyContent="center"
      bgColor="white"
      borderWidth={1}
      borderColor="gray.200"
      {..._container}
    >
      <VStack space={2}>
        <Box flex={1} mb={1}>
          <HStack alignItems="center" justifyContent="space-between" mb={0.5}>
            <Text size="md" fontWeight={500} color="gray.700">
              Recovery email address
            </Text>
            {(isPendingVerification || isVerified) && (
              <HStack space={1} alignItems="center" justifyContent="center">
                {isPendingVerification ? (
                  <InfoOutlineIcon size={3} color={colors.warning[500]} />
                ) : (
                  <CheckCircleBrokenIcon size={3} color={colors.success[500]} />
                )}
                <Text size="md" fontWeight={500} color={isPendingVerification ? 'warning.500' : 'success.500'}>
                  {isPendingVerification ? 'Pending verification' : 'Verified'}
                </Text>
              </HStack>
            )}
          </HStack>

          <ControlInput
            control={form.control}
            name="email"
            disabled={isPendingVerification || isVerified}
            placeholder="Please provide a recovery email address"
            _input={{
              keyboardType: 'email-address',
              returnKeyType: 'go',
              InputLeftElement: <Mail01Icon />,
              InputRightElement: !isVerified ? (
                <IconButton
                  disabled={isDisable}
                  rounded="full"
                  bg="gray.25"
                  p={0.5}
                  boxSize={6}
                  mr={1}
                  icon={<ArrowForwardIcon size={4} color={isDisable ? colors.gray[300] : undefined} />}
                  onPress={form.handleSubmit(modalDialog.setOpen)}
                />
              ) : undefined,
              onKeyPress: handlePressEnter,
            }}
          />

          <Text size="md" fontWeight={400} color="gray.600" lineHeight="sm" mt={2}>
            Note: A recovery email address helps you get back in and is where we send your security notifications.
          </Text>
        </Box>

        {isVerifyCode && (
          <HStack justifyContent="space-between">
            <Text size="md" fontWeight={400} color="gray.700">
              Didn’t receive a verification code?{' '}
              <Pressable disabled={!isResendEnabled.value} onPress={handleResendCode}>
                <Text size="md" fontWeight={600} color={isResendEnabled.value ? 'primary.500' : 'gray.400'}>
                  Resend Code
                </Text>
              </Pressable>
            </Text>

            <Pressable onPress={handleReset}>
              <Text size="md" fontWeight={600} color="primary.500">
                Clear Email
              </Text>
            </Pressable>
          </HStack>
        )}

        {isVerified && (
          <Pressable onPress={handleReset}>
            <Text size="md" fontWeight={500} color="primary.500">
              Change recovery email
            </Text>
          </Pressable>
        )}
      </VStack>

      <Modal
        hideClose
        noPadding
        isOpen={modalDialog.isOpen}
        onClose={props.onClose}
        size="md"
        _content={{ rounded: 'xl', p: 0, maxW: 480 }}
      >
        <VStack py={2} px={5}>
          <HStack alignItems="center" justifyContent="space-between" mb={2} minHeight="50px">
            <Text size="2xl" fontWeight={700} color="gray.900">
              Update Recovery Email
            </Text>

            <IconButton
              rounded="sm"
              p={1}
              bg="gray.50"
              icon={<CloseIcon size={4} color={colors.gray[700]} />}
              disabled={changeEmail.isLoading || confirmChangeEmail.isLoading}
              onPress={handleCloseModal}
            />
          </HStack>

          {/* Enter Password  */}
          {!isVerifyCode && (
            <VStack>
              <Text size="md" fontWeight={400} color="gray.700" mb={3}>
                To change your recovery email address, confirm your password below.
              </Text>

              <Box mb={5}>
                <ControlInput
                  control={form.control}
                  type="password"
                  name="password"
                  placeholder="Please enter your password"
                  _helperText={{ textAlign: 'center' }}
                  _input={{ w: 300 }}
                />
                {!!serverError && (
                  <FormHelperText error visible>
                    {serverError ?? ''}
                  </FormHelperText>
                )}
              </Box>

              <Text size="md" fontWeight={400} color="gray.600" mb={2}>
                <Text size="md" fontWeight={600} color="gray.900" underline>
                  Important
                </Text>{' '}
                For additional security you will receive a verification code sent to{' '}
                {!!recoveryEmail && (
                  <Text size="md" fontWeight={500} color="gray.700">
                    {recoveryEmail}
                  </Text>
                )}{' '}
                that you must provide in the next step before we complete your request.
              </Text>
            </VStack>
          )}

          {/* Enter Verification Code */}
          {isVerifyCode && (
            <VStack>
              <Text size="md" fontWeight={400} color="gray.700" mb={3}>
                Verification code has been sent to your new recovery email:{' '}
                {!!recoveryEmail && (
                  <Text size="md" fontWeight={600} color="black">
                    {recoveryEmail}
                  </Text>
                )}
                . Please check your inbox and copy it to the input box below:
              </Text>

              <VStack space={4} mb={5}>
                <Box>
                  <ControlInput
                    control={form.control}
                    name="code"
                    label="Verification Code"
                    placeholder="Please enter the 6 digits code"
                    numericOnly
                    _helperText={{ textAlign: 'center' }}
                    _input={{ w: 300, keyboardType: 'numeric', maxLength: 6 }}
                  />
                  {!!serverError && (
                    <FormHelperText error visible>
                      {serverError ?? ''}
                    </FormHelperText>
                  )}
                </Box>
                <Text size="md" fontWeight={400} color="gray.700">
                  Didn’t receive a verification code?{' '}
                  <Pressable disabled={!isResendEnabled.value} onPress={handleResendCode}>
                    <Text size="md" fontWeight={600} color={isResendEnabled.value ? 'primary.500' : 'gray.400'}>
                      Resend Code
                    </Text>
                  </Pressable>
                </Text>
              </VStack>
            </VStack>
          )}
        </VStack>

        <HStack space={2} justifyContent="space-between" py={3} px={5} borderTopWidth={1}>
          <Button
            disabled={isVerifyCode ? isEmpty(code) : isEmpty(password)}
            isLoading={changeEmail.isLoading || confirmChangeEmail.isLoading}
            isLoadingText="Please wait"
            minW={120}
            onPress={handlePressConfirm}
          >
            {isVerifyCode ? 'Verify Code' : 'Confirm & Send Verification Code'}
          </Button>
        </HStack>
      </Modal>
    </Box>
  );
};
