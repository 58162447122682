import { ComponentProps, useState } from 'react';
import { Box, CloseIcon, HStack, Popover, VStack } from 'native-base';
import { map } from 'lodash';

import {
  ChevronSelectorVerticalIcon,
  Download02Icon,
  MinusCircleIcon,
  MoveIcon,
  ReverseLeftIcon,
  SwitchHorizontalIcon,
  Text,
} from '@pimm/base';

type PopoverTooltipPositionActionsProps = Omit<ComponentProps<typeof Popover>, 'children'> & {};

export const PopoverTooltipPositionActions = (props: PopoverTooltipPositionActionsProps) => {
  const [tips] = useState<{ title: string; tips: { bgColor?: string; icon?: React.ReactNode; title: string; description: string }[] }[]>([
    {
      title: 'Position Actions',
      tips: [
        {
          icon: <ChevronSelectorVerticalIcon size="20px" color="gray.700" />,
          title: 'Change Primary Position',
          description: 'Override/change the Primary Position',
        },
        {
          icon: <ReverseLeftIcon size="14px" color="gray.700" />,
          title: 'Restore Primary Position',
          description: 'Restore the Primary Position back to default (only available if manual override assignment was used)',
        },
        {
          icon: <MinusCircleIcon size="18px" color="gray.700" />,
          title: 'Non-Service Position',
          description: 'Designate employee as non-service (off service line)',
        },
        {
          icon: <MoveIcon size="18px" color="gray.700" />,
          title: 'Move Employee',
          description: 'Enter Edit mode to quickly assign or swap employee to new position',
        },
      ],
    },
    {
      title: 'Edit Mode',
      tips: [
        {
          bgColor: 'primary.500',
          icon: <SwitchHorizontalIcon size="18px" color="black" />,
          title: 'Swap Employee',
          description: 'Swap employees between positions',
        },
        {
          bgColor: 'primary.500',
          icon: <Download02Icon size="18px" color="black" />,
          title: 'Assign Employee',
          description: 'Assign employee to a position (Available, Service or Non-Service)',
        },
        {
          bgColor: 'black',
          icon: <CloseIcon size="14px" color="white" />,
          title: 'Exit Edit Mode',
          description: 'Cancels the current position editing',
        },
      ],
    },
  ]);
  return (
    <Popover isKeyboardDismissable _backdrop={{ bg: 'black' }} {...props}>
      <Popover.Content accessibilityLabel="Position Actions Tooltip" rounded="lg" bg="white" borderWidth={0}>
        <Popover.Arrow zIndex={1} borderWidth={0} borderColor="white" bg="white" />
        <Popover.Body py={3} px={4} w="320" borderWidth={0} bg="white">
          <VStack space={2}>
            {tips.map(_tip => {
              return (
                <Box key={_tip.title}>
                  <Text size="lg" fontWeight={600} color="black" lineHeight="xs" mb={2}>
                    {_tip.title}
                  </Text>

                  <VStack space={1}>
                    {map(_tip.tips, _ => (
                      <HStack key={_.title} space={3} py={1} minH={10}>
                        <Box
                          rounded="md"
                          alignItems="center"
                          justifyContent="center"
                          boxSize={8}
                          borderWidth={1}
                          borderColor={_.bgColor ?? 'gray.300'}
                          bg={_.bgColor ?? 'white'}
                          shadow={1}
                        >
                          {_.icon}
                        </Box>
                        <VStack space={1} flex={1} pr={2}>
                          <Text size="md" fontWeight={600} color="black" lineHeight="xs">
                            {_.title}
                          </Text>
                          <Text size="md" fontWeight={500} color="gray.700" numberOfLines={3} lineHeight="sm" lineBreakMode="tail">
                            {`${_.description}`}
                          </Text>
                        </VStack>
                      </HStack>
                    ))}
                  </VStack>
                </Box>
              );
            })}
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
