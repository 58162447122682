import { Center, Link, VStack } from 'native-base';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { Modal, Text } from '@pimm/base';
import { useServiceWorker } from '@app/context';
import { useAuthToken } from '../context/auth-token.context';

export const SessionTimeout = (props: { onPressReturn?: () => void }) => {
  const { sessionTimeout } = useAuthToken();
  const { availableVersion } = useServiceWorker();

  const handlePressReturn = () => {
    sessionTimeout.setFalse();
    props.onPressReturn!();
  };

  return (
    <Modal hideClose isOpen={!availableVersion && sessionTimeout.value} _content={{ width: 400, height: 300, justifyContent: 'center' }}>
      <VStack space={2} alignItems="center">
        <MaterialIcons name="timer-off" size={60} color="gray.500" />
        <Text size="3xl" color="black">
          Session Expired
        </Text>
      </VStack>
      <VStack my={4}>
        <Center>
          <Text size="md" color="gray.900" fontWeight={500}>
            Sorry, but your session has expired.
          </Text>
          <Text size="md" color="gray.900" fontWeight={500}>
            Please click on the link below to log back in.
          </Text>
          <Link mt={4}>
            <Text size="md" color="primary.500" underline onPress={handlePressReturn}>
              Return to Login
            </Text>
          </Link>
        </Center>
      </VStack>
    </Modal>
  );
};
