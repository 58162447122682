import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, IBoxProps, Pressable, ScrollView, Spacer, VStack } from 'native-base';
import { differenceBy, filter, find, flatMap, groupBy, includes, map, partition, reject, sortBy, without } from 'lodash';

import { Button, Card, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { KitchenPositionDto } from '@pimm/services/lib/sms-positioning';
import { PositionTypeEnum, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { hexToRGBA } from '@app/utils/string-formatter';
import { useUniqValues } from '@app/hooks/use-uniq-values';
import { useKitchenLayout } from '@app/features/kitchen-positioning';
import { PositioningSlot } from '../reducers';
import { usePositionCategories } from '../context';

type PositioningSelectPositionProps = {
  _container?: IBoxProps;
  isPrimary?: boolean;
  positionSlots?: PositioningSlot[];
  positionSlotId?: string;
  onCancel?: () => void;
  onSave?: (position: KitchenPositionDto) => void;
};

export const PositioningSelectPosition = ({
  _container,
  isPrimary,
  positionSlots,
  positionSlotId,
  ...props
}: PositioningSelectPositionProps) => {
  const { translate } = useAppLocale();
  const { kitchenLayout, positionLookup } = useKitchenLayout();
  const categories = usePositionCategories();
  const [size] = useState<number>(isPrimary ? 1 : 3);
  const uniqValues = useUniqValues<string>({ maxLength: size });

  const addUpdateJob = useMutation({ mutationFn: SmsWorkforceApi.AddUpdateSlotJob });

  const sections: { title: string; positions: Record<number, KitchenPositionDto[]> }[] = useMemo(() => {
    // Get current position slot
    const positionSlot = find(positionSlots, ({ id }) => id === positionSlotId);

    // Get service positions
    const servicePositions = filter(
      kitchenLayout.data?.availablePositions,
      ({ positionType }) => positionType === PositionTypeEnum.Service,
    );

    // Get all assigned position without the current selected position slot
    const assignedPositions = flatMap(
      filter<PositioningSlot>(positionSlots, _ => _.id !== positionSlotId),
      position => {
        const secondaryJobIds = map(position.secondaryJobs, job => job.positionId);
        return without([...secondaryJobIds, position.positionId], undefined);
      },
    );

    // Get the id of all available positions
    const availablePositionIds = map(
      differenceBy(
        // Skip non-service position
        reject(servicePositions, { positionType: PositionTypeEnum.NonService }),
        // Skip if position is included in the list
        reject(positionSlots, { id: positionSlotId }),
        'positionId',
      ),
      'positionId',
    );

    // Create a partition Available and Unavailable positions
    const sections = isPrimary
      ? map(
          partition(servicePositions, position => position.positionId && availablePositionIds.includes(position.positionId)),
          (data, index) => ({
            title: index ? 'Unavailable Positions (Currently Assigned)' : 'Available Positions',
            positions: data,
          }),
        )
      : [
          {
            title: 'Available Positions',
            positions: [...servicePositions],
          },
        ];

    // Group positions by positionCategory
    return map(sections, section => ({
      ...section,
      positions: groupBy(sortBy(section.positions, ['title']), 'positionCategory.id'),
    }));
  }, [categories.data, isPrimary, kitchenLayout, positionSlots, positionSlotId]);

  const handlePressSave = async () => {
    const positionSlot = find(positionSlots, ['id', positionSlotId]);

    if (positionSlot) {
      const payload: Partial<PositioningSlot> = {
        id: positionSlot.id,
        positionId: positionSlot.positionId,
        title: positionSlot.title,
        secondaryJobs: positionSlot.secondaryJobs ?? [],
      };
      if (isPrimary) {
        // Change primary position
        const primary = positionLookup[uniqValues.values[0]];
        payload.title = primary.title;
        payload.positionId = primary.positionId;
      } else {
        // Change secondary jobs
        payload.secondaryJobs = map(uniqValues.values, id => {
          const secondaryJob = positionLookup[id];
          return {
            positionId: secondaryJob.positionId,
            title: secondaryJob.title,
          };
        });
      }
      await addUpdateJob.mutateAsync({
        positionSlotId: payload.id,
        positionId: payload.positionId,
        positionTitle: payload.title,
        secondaryJobs: payload.secondaryJobs ?? [],
      });
      if (props.onSave) props.onSave(payload);
      if (props.onCancel) props.onCancel();
    }
  };

  const handlePressClear = () => {
    uniqValues.reset([]);
  };

  const handlePressItem = (positionId: string) => () => {
    if (isPrimary) {
      uniqValues.reset([positionId]);
      return;
    }
    uniqValues.append(positionId);
  };

  useEffect(() => {
    const positionSlot = find(positionSlots, ['id', positionSlotId]);

    if (positionSlot) {
      const secondaryJobIds = map(positionSlot?.secondaryJobs, job => job.positionId!!);

      if (isPrimary && positionSlot.positionId) {
        uniqValues.reset([positionSlot.positionId]);
      } else if (!isPrimary) {
        uniqValues.reset(secondaryJobIds);
      }
    }
  }, [isPrimary, positionSlotId]);

  return (
    <Box h="full" bgColor="white" rounded="lg" overflow="hidden">
      <VStack h="full">
        <HStack alignItems="center" justifyContent="space-between" px={4} minH="50px" borderBottomWidth={1}>
          <Text size="xl" fontWeight={700} color="black">
            {isPrimary ? 'Select one (1) Primary Position' : 'Select up to three (3) Secondary Positions'}
          </Text>

          {!isPrimary && (
            <Box rounded="lg" alignItems="center" justifyContent="center" minH={7} minWidth={110} borderWidth={1} backgroundColor="gray.50">
              <Text size="lg" fontWeight={700} color="black" lineHeight="xs">
                {`${uniqValues.values.length}/${size} `}
                <Text size="lg" fontWeight={700} color="gray.600" lineHeight="xs">
                  Selected
                </Text>
              </Text>
            </Box>
          )}
        </HStack>

        <ScrollView flex={1} bg="gray.50">
          <VStack space={3} py={3} px={5}>
            {map(sections, (section, index) => {
              return (
                <Box key={section.title}>
                  <Text size="lg" fontWeight={700} mb={2} color="gray.900">
                    {section.title}
                  </Text>

                  <HStack space={4}>
                    {map(sortBy(categories.data, ['seq']), category => {
                      const sectionPositions = section.positions[category.id!];
                      return (
                        <VStack key={`${index}.${category.id}`} space={2} flex={1}>
                          <Text size="lg" fontWeight={700} color="gray.600">
                            {translate(category.title, category.translations)}
                          </Text>

                          <Card flex={1} rounded="lg" pt={4} px={2} minHeight="220px" bg={index ? 'gray.25' : 'white'}>
                            <HStack flexWrap="wrap">
                              {map(sectionPositions, position => {
                                const isActive = includes(uniqValues.values, position.positionId);
                                const isDisabled =
                                  !isActive && isPrimary && positionSlots?.some(s => s?.positionId === position.positionId);
                                const translations = positionLookup[position.positionId!]?.translations;
                                return (
                                  <Box key={position.positionId} mb={2} px={2} w="1/2">
                                    <Pressable
                                      rounded="lg"
                                      overflow="hidden"
                                      h="46px"
                                      disabled={isDisabled}
                                      shadow={1}
                                      onPress={handlePressItem(position.positionId as string)}
                                    >
                                      {({ isHovered }) => {
                                        const color = hexToRGBA(position.color, isDisabled ? 0.3 : 1);

                                        return (
                                          <Box
                                            justifyContent="center"
                                            rounded="lg"
                                            w="full"
                                            h="full"
                                            borderWidth={index === 0 || !isDisabled ? 3 : 0}
                                            borderColor={isHovered || isActive ? 'error.600' : color}
                                            bg={color}
                                          >
                                            <Text
                                              size="md"
                                              fontWeight={500}
                                              color={isDisabled ? 'gray.600' : 'black'}
                                              px={3}
                                              numberOfLines={2}
                                              ellipsizeMode="tail"
                                              textAlign="center"
                                              lineHeight="xs"
                                            >
                                              {translate(position.title, translations)}
                                            </Text>
                                          </Box>
                                        );
                                      }}
                                    </Pressable>
                                  </Box>
                                );
                              })}
                            </HStack>
                          </Card>
                        </VStack>
                      );
                    })}
                  </HStack>
                </Box>
              );
            })}
          </VStack>
        </ScrollView>

        <HStack space={2} py={3} px={5} borderTopWidth={1}>
          <Button
            variant="unstyled"
            disabled={addUpdateJob.status === 'loading' || uniqValues.values.length === 0}
            onPress={handlePressClear}
          >
            Clear Selection
          </Button>

          <Spacer />

          <Button variant="unstyled" minWidth={120} disabled={addUpdateJob.status === 'loading'} outline onPress={props.onCancel}>
            Cancel
          </Button>

          <Button
            minWidth={120}
            disabled={addUpdateJob.status === 'loading' || (isPrimary && uniqValues.values.length === 0)}
            isLoading={addUpdateJob.status === 'loading'}
            onPress={handlePressSave}
          >
            Save
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
