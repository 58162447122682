import { Icon, IIconProps } from 'native-base';
import { Path } from 'react-native-svg';

export const ClockRewindIcon = ({ size = 'sm', ...props }: IIconProps) => {
  return (
    <Icon size={size} viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M11.9583 1.79187L10.2916 3.45804L11.9583 5.1252M10.7083 3.2085C14.8504 3.2085 18.2083 6.56636 18.2083 10.7085C18.2083 14.8506 14.8504 18.2085 10.7083 18.2085C6.56618 18.2085 3.20831 14.8506 3.20831 10.7085C3.20831 7.9569 4.69009 5.55139 6.89922 4.24641"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path d="M10 7.5V11.6667L12.5 13.3333" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};
