import { useEffect, useState } from 'react';
import { CommonActions } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import Constants from 'expo-constants';
import { Box, Divider, HStack, IconButton, Image, Pressable, useMediaQuery } from 'native-base';
import FeatherIcons from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { find, map, pick, startCase, toLower, values } from 'lodash';

import { GraduationHat02Icon, Modal, NavBar, NavDropdown, SettingsIcon, SwitchHorizontalIcon, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { selectStoreConfig, selectUserProfile } from '@app/store/selectors';
import { CreateATicketLink, HelpCenterLink } from '@app/consts/hyperlinks';
import { DropdownCultures } from '@app/features/app';
import { useSiteConfig } from '@app/features/store-core';
import { DashboardModalType, useDashboard } from '@app/features/dashboard';
import { NavSectionDto, NavSectionModuleDto, SiteSimpleDto } from '@pimm/services/lib/sms-tenants';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { appConfigSlice } from '@app/store/slices/appConfigSlice';
import { SiteList } from '@app/features/store-core';
import { Clock, DayblockCountdown } from '../shared';

type DropdownAccessorKeys = 'settings' | 'help-center' | 'create-a-ticket' | 'learning-hub';

type HeaderProps = StackHeaderProps & { onChangeStore?: (siteId?: string) => void };

export const Header = ({ navigation, route }: HeaderProps) => {
  const params = route.params as { siteId: string };
  const [isTabletScreen] = useMediaQuery({ maxWidth: 1200 });
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const storeConfig = useAppSelector(selectStoreConfig);

  const { translate } = useAppLocale();
  const { toggleModal } = useDashboard();
  const { siteConfig } = useSiteConfig();

  const [dropdownItems] = useState<{ accessorKey: DropdownAccessorKeys; icon: React.ReactElement; text: string; url?: string }[]>([
    { accessorKey: 'settings', icon: <SettingsIcon size="18px" />, text: 'Settings' },
    { accessorKey: 'learning-hub', icon: <GraduationHat02Icon size="18px" />, text: 'Learning Hub' },
    { accessorKey: 'help-center', icon: <FeatherIcons name="help-circle" size={18} />, text: 'Help Center', url: HelpCenterLink },
    {
      accessorKey: 'create-a-ticket',
      icon: <MaterialCommunityIcons name="ticket-confirmation-outline" size={18} />,
      text: 'Create a Ticket',
      url: CreateATicketLink,
    },
  ]);
  const [titleSection, setTitleSection] = useState<NavSectionDto>();
  const siteListModal = useModalFocus();
  const isSiteModalEnabled = (userProfile?.sites ?? [])?.length > 1;

  const hasStore = !!siteConfig.config;
  const headerTitle = Constants.expoConfig?.extra?.name ?? '';

  const selectedSite = find(userProfile?.sites, _ => _.id === (siteConfig?.id ?? userProfile?.defaultSiteId));

  const fullName = values(pick(userProfile, ['firstName', 'lastName']))
    .filter(Boolean)
    .join(' ');

  const handleChangeStore = (store: SiteSimpleDto) => {
    if (siteConfig.id !== store.id) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'Main', params: { screen: 'PositionGuide', siteId: store.id } }],
        }),
      );
    }
  };

  const handlePressMore = () => {
    dispatch(appConfigSlice.actions.toggleWidget());
  };

  const handlePressInfo = () => {
    toggleModal({ type: DashboardModalType.StoreConfig });
  };

  const handlePressDropdownItem = (accessorKey: DropdownAccessorKeys) => () => {
    const item = find(dropdownItems, ['accessorKey', accessorKey]);
    let openUrl = item?.url;

    if (item?.accessorKey === 'learning-hub') openUrl = userProfile.lmsUrl;
    if (item?.accessorKey === 'settings') {
      toggleModal({ type: DashboardModalType.AccountSettings });
      return;
    }
    if (openUrl) window.open(openUrl, '_blank');
  };

  const handlePressLogout = () => {
    localStorage.removeItem('token');
    navigation.navigate('Login');
  };

  useEffect(() => {
    if (storeConfig?.companyInfo?.brand || siteConfig.companyInfo?.brand) {
      const brand = siteConfig.companyInfo?.brand ?? storeConfig?.companyInfo?.brand;
      const platform = brand?.navSections?.find(_ => _.appType?.toLowerCase() === 'dop');
      const title = platform?.sections?.reduce((_title: NavSectionModuleDto | undefined, _section) => {
        if (_title) return _title;
        return _section.modules?.find(_ => _.identifier?.toLowerCase() === 'doptitle');
      }, undefined);

      setTitleSection(title);
    }
  }, [storeConfig?.companyInfo?.brand, siteConfig.companyInfo?.brand]);

  return (
    <NavBar
      _container={isTabletScreen ? { pr: 0 } : undefined}
      title={
        <HStack alignItems="center">
          <Box alignItems="center" w={{ md: '60px', xl: '70px' }}>
            <Image
              alt={translate(titleSection?.title ?? headerTitle, titleSection?.titleTranslations)}
              source={{
                uri: titleSection?.iconUrl,
              }}
              rounded="full"
              resizeMode="contain"
              resizeMethod="scale"
              size={10}
            />
          </Box>
          <Text color="black" size="xl" fontWeight={700}>
            {translate(titleSection?.title ?? headerTitle, titleSection?.titleTranslations)}
          </Text>
        </HStack>
      }
      more={
        isTabletScreen ? (
          <Pressable pr={1.5} alignItems="center" justifyContent="center" h="full" onPress={handlePressMore}>
            <FeatherIcons name="more-vertical" color="white" size={22} />
          </Pressable>
        ) : undefined
      }
      rightComponent={
        <HStack space={3} alignItems="center">
          {!isTabletScreen && hasStore && (
            <Clock px={2} rounded="md" borderWidth={1} borderColor="black" _text={{ size: 'xl', fontWeight: 700, color: 'black' }} />
          )}

          {hasStore && (
            <>
              <DayblockCountdown />
              <Divider height="22px" bg="gray.50" orientation="vertical" />
            </>
          )}

          <DropdownCultures cultures={siteConfig?.companyInfo?.brand?.availableCultures} defaultCulture={siteConfig?.culture} />

          <Divider height="22px" bg="gray.50" orientation="vertical" />

          <NavDropdown
            mr={3}
            accessibilityLabel="Account Dropdown"
            person={{ name: startCase(toLower(fullName)), email: userProfile?.contactEmail }}
            onPressLogout={handlePressLogout}
          >
            {hasStore &&
              map(dropdownItems, item => {
                if (item.accessorKey === 'learning-hub' && userProfile.lmsEnabled !== true) return null;
                return (
                  <NavDropdown.Item
                    key={item.accessorKey}
                    icon={item.icon}
                    text={item.text}
                    onPress={handlePressDropdownItem(item.accessorKey)}
                  />
                );
              })}
          </NavDropdown>
        </HStack>
      }
    >
      {!!selectedSite && (
        <HStack space={1} alignItems="center" w={360}>
          <Box flex={1}></Box>
          <Box rounded="lg" w={300} bg="white">
            <Pressable flex={1} onPress={siteListModal.setOpen} isDisabled={!isSiteModalEnabled}>
              <HStack space={2} alignItems="center" justifyContent="center" px={3} h={8} rounded="md">
                <Text
                  color="black"
                  size="lg"
                  textTransform="uppercase"
                  textAlign="center"
                  fontWeight={700}
                  lineHeight="xs"
                  numberOfLines={1}
                >
                  {selectedSite?.name ?? ''}
                </Text>
                {isSiteModalEnabled && (
                  <Box position="absolute" right={2}>
                    <SwitchHorizontalIcon size={4} color="black" />
                  </Box>
                )}
              </HStack>
            </Pressable>
          </Box>
          <Box flex={1}>
            <IconButton rounded="full" p={0} icon={<FeatherIcons name="info" size={20} color="black" />} onPress={handlePressInfo} />
          </Box>
        </HStack>
      )}

      <Modal
        size="xl"
        hideClose
        noPadding
        _content={{ maxWidth: { md: '95%', lg: '90%' }, h: '80%', maxHeight: 680 }}
        isOpen={siteListModal.isOpen}
      >
        <SiteList siteId={selectedSite?.id} onClose={siteListModal.setHide} onSelectSite={handleChangeStore} />
      </Modal>
    </NavBar>
  );
};
