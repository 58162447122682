import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { PositioningEmployee, PositioningSlot } from '../reducers';

type DroppableItem = PositioningEmployee | PositioningSlot;

type ClickToDropProviderProps = PropsWithChildren & {};

export type ClickToDropContextReturn = {
  dropItem?: DroppableItem;
  isDisabled?: boolean;
  setDragItem: (_positionSlot?: DroppableItem) => void;
  setIsDisabled: (_disabled: boolean) => void;
};

const ClickToDropContext = createContext<ClickToDropContextReturn>(undefined!);

export const ClickToDropProvider = ({ children }: ClickToDropProviderProps) => {
  const [dropItem, setDragItem] = useState<DroppableItem>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  return <ClickToDropContext.Provider value={{ dropItem, isDisabled, setDragItem, setIsDisabled }}>{children}</ClickToDropContext.Provider>;
};

export const ClickToDropConsumer = ClickToDropContext.Consumer;

export const useClickToDrop = () => {
  // get the context
  const context = useContext(ClickToDropContext);
  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useClickToDrop was used outside of its Provider');
  }
  return context;
};
