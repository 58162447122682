import { useCallback } from 'react';
import { Provider } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { View } from 'native-base';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Constants from 'expo-constants';
// import './i18n';

import * as SplashScreen from 'expo-splash-screen';
import {
  useFonts,
  Inter_100Thin,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from '@expo-google-fonts/inter';

import { RootNavigationParams } from '@app/navigations/root';
import { AppThemeProvider, AuthTokenProvider } from '@app/features/app';
import { MainScreen } from '@app/screens/main';
import { LauncherScreen, LoginScreen, RecoverPasswordScreen, ResetPasswordScreen } from '@app/screens/access';

import store from './src/store/store';
import { ThemeContainer } from '@app/theme';
import { Header } from '@app/components/core';
import { DashboardProvider } from '@app/features/dashboard';
import { SiteConfigProvider } from '@app/features/store-core';
import { ServiceWorkerProvider } from '@app/context';
import { AppLocaleProvider } from '@pimm/common';

const persistor = persistStore(store);
const RootNavigation = createStackNavigator<RootNavigationParams>();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

SplashScreen.preventAutoHideAsync();

const App = () => {
  const appName = Constants.expoConfig?.extra?.name;
  // TODO: need to revisit
  const [fontsLoaded, fontError] = useFonts({
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) await SplashScreen.hideAsync();
  }, [fontsLoaded]);

  if (!fontsLoaded && !fontError) return null;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthTokenProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeContainer>
              <View onLayout={onLayoutRootView} />
              <ServiceWorkerProvider>
                <AppLocaleProvider>
                  <DashboardProvider>
                    <SiteConfigProvider>
                      <RootNavigation.Navigator initialRouteName="Main">
                        <RootNavigation.Screen
                          name="Launcher"
                          component={LauncherScreen}
                          options={{ headerShown: false, title: appName }}
                        />
                        <RootNavigation.Screen name="Login" component={LoginScreen} options={{ headerShown: false, title: appName }} />
                        <RootNavigation.Screen
                          name="RecoverPassword"
                          component={RecoverPasswordScreen}
                          options={{ headerShown: false, title: appName }}
                        />
                        <RootNavigation.Screen
                          name="ResetPassword"
                          component={ResetPasswordScreen}
                          options={{ headerShown: false, title: appName }}
                        />
                        <RootNavigation.Screen
                          name="Main"
                          component={MainScreen}
                          options={{ header: stackHeaderProps => <Header {...stackHeaderProps} />, title: appName }}
                        />
                      </RootNavigation.Navigator>
                    </SiteConfigProvider>
                  </DashboardProvider>
                </AppLocaleProvider>
              </ServiceWorkerProvider>
            </ThemeContainer>
          </QueryClientProvider>
        </AuthTokenProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
